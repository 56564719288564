<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.sm" @keydown.esc="setDialog(false)" @click:outside="setDialog(false)"
              v-model="dialog" max-width="500">
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-overlay :value="loading_dialog">
            <v-dialog v-model="loading_dialog" hide-overlay persistent width="400">
                <v-card color="primary" dark>
                    <v-card-text class="pt-2">
                        {{ loading_message }}
                        <v-progress-linear indeterminate color="white" class="mt-6 mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-overlay>
        <!--Dialog ---------------------------------------------------------------------------------------------------->

        <v-card class="pl-4 pr-4">
            <v-card-title class="headline">Adicionar informações</v-card-title>
            <v-card-text>
                <v-file-input
                    class="mt-2"
                    label="Anexos de faturas"
                    append-icon="mdi-paperclip"
                    prepend-icon=""
                    :rules="[v => !!v || 'Obrigatório.']"
                    multiple outlined clearable
                    @change="updateAnexos"
                ></v-file-input>
                <v-textarea
                    label="Comentário sobre a proposta"
                    outlined v-model="$v.form.comentario.$model"
                    @blur="$v.form.comentario.$touch()"
                    :error-messages="itemErrors($v.form.comentario)"
                ></v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-btn color="red darken-1" text @click="setDialog(false)">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="ml-auto" color="green darken-1" text @click="submit">Enviar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {required} from "vuelidate/lib/validators";

export default {
    name: "InformacoesAdicionais",

    props: {
        dialog_info: {
            type: Boolean,
            required: true
        },
        dados_cadastrais: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            dialog: false,
            loading_dialog: null,
            loading_message: null,
            form: {
                anexo_fatura: [],
                comentario: ""
            }
        }
    },

    watch: {
        dialog_info: function () {
            this.dialog = this.dialog_info;
        }
    },

    methods: {
        async updateAnexos(e) {
            if (e) {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = error => reject(error);
                });

                let files = e;
                this.form.anexo_fatura = [];
                for (let i = 0; i < files.length; i++) {
                    this.form.anexo_fatura.push({
                        'fileData': [files[i].name, await toBase64(files[i])]
                    });
                }
            }
        },

        setLoading(state, message) {
            this.loading_dialog = state;
            this.loading_message = message;
        },

        async submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                await this.setLoading(true, 'Um momento...');
                await this.$http.post('pre/cadastro/novo', {
                    dados_cadastrais: this.$props.dados_cadastrais,
                    dados_proposta: this.form
                }).then(resp => {
                    this.setDialog(false);
                    this.setLoading(false, '');
                    document.location.reload(true);
                }).catch(error => {
                    this.$toast.open({
                        message: 'Opa! Já existe um cadastro em seu CPF!',
                        type: "error",
                        duration: 7500,
                    });
                    this.setDialog(false);
                    this.setLoading(false, '');
                });
            }
        },

        setDialog(state) {
            this.dialog = state;
            this.$emit("update-dialog", state);
        },

        itemErrors(item) {
            const errors = []
            if (!item.$dirty) return errors
            !item.required && errors.push('Obrigatório.')
            return errors
        },
    },

    mounted() {
        this.dialog = this.dialog_info;
    },

    validations: {
        form: {
            comentario: {required},
        }
    }
}
</script>
