<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.mobile" @keydown.esc="setDialog(false)" @click:outside="setDialog(false)"
              v-model="dialog" max-width="500">
        <!--overlay ---------------------------------------------------------------------------------------------------->
        <v-overlay :value="loading_dialog">
            <v-dialog v-model="loading_dialog" hide-overlay persistent width="400">
                <v-card color="primary" dark>
                    <v-card-text class="pt-2">
                        {{ loading_message }}
                        <v-progress-linear indeterminate color="white" class="mt-6 mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-overlay>
        <!--overlay ---------------------------------------------------------------------------------------------------->

        <v-card>
            <v-card-title class="headline">Atenção!</v-card-title>
            <v-card-text>
                Pergunte ao CLIENTE se ele possui um novo endereço! <br>
                Só assim tenha a certeza de que a proposta deve ser descartada.
                <br><br>
                <v-select
                    :items="lista_motivos_recusa"
                    v-model="motivo"
                    filled
                    label="Motivo da recusa"
                ></v-select>
            </v-card-text>
            <v-card-actions>
                <v-btn color="red darken-1" text @click="setDialog(false)">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="submit">Recusar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {lista_motivos_recusa} from "../../data/motivo_recusa";

export default {
    name: "NegacaoCliente",

    props: {
        negacao: {
            type: Boolean,
            required: true
        },
        deal_id: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            lista_motivos_recusa: lista_motivos_recusa,
            dialog: false,
            loading_dialog: null,
            loading_message: null,

            motivo: '7343',
        }
    },

    watch: {
        negacao: function () {
            this.dialog = this.negacao;
        }
    },

    methods: {
        goToNovoEndereco() {
            this.$router.push('/proposta/' + this.deal_id + '/edit');
        },
        novoEndereco() {

            this.setDialog(false);
        },
        setDialog(state) {
            this.dialog = state;
            this.$emit("update-dialog", state);
        },
        async submit() {
            await this.setLoading(true, 'Um momento, por favor...');
            await this.$http.put('proposta/' + this.deal_id, {
                event: 'cliente_negou',
                motivo: this.motivo,
            }).then(resp => {
                this.setDialog(false);
                document.location.reload(true);
            });
            await this.setLoading(false, '');
        },
        setLoading(state, message) {
            this.loading_dialog = state;
            this.loading_message = message;
        },
    },

    mounted() {
        this.dialog = this.negacao;
    },
}
</script>

<style scoped>

</style>
