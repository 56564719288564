<template>
    <v-row align="center" justify="center" no-gutters>
        <v-col>
            <v-text-field
                v-model="newMessage"
                placeholder="Digite sua mensagem..."
                @keyup.enter="sendMessage"
                :loading="loading"
                filled
            ></v-text-field>
        </v-col>
        <v-col sm="4">
            <v-file-input multiple prepend-icon="mdi-paperclip" v-model="input" @change="updateAnexos"/>
        </v-col>
        <v-btn :disabled="disabled" fab class="ml-1 mb-6" small color="primary" @click="sendMessage">
            <v-icon dark>mdi-chevron-right</v-icon>
        </v-btn>
    </v-row>
</template>

<script>
export default {
    props: ['user', 'loading'],

    data() {
        return {
            newMessage: '',
            anexos: [],
            input: [],
            disabled: true,
        }
    },

    watch: {
        newMessage: function (value) {
            this.disabled = value === '';
        },
    },

    methods: {
        async updateAnexos(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.anexos = [];
            // this.anexo_fatura = [files[0].name, await toBase64(files[0])];
            for (let i = 0; i < files.length; i++) {
                this.anexos.push([files[i].name, await toBase64(files[i])]);
            }
        },

        async sendMessage() {
            if (!this.disabled) {
                await this.$emit('messagesent', [this.newMessage, this.anexos]);
                this.newMessage = '';
                this.anexos = [];
                this.input = [];
            }
        }
    }
}
</script>
