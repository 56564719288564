<template>
    <v-dialog max-width="1000" @keydown.esc="setDialog(false)"
              @click:outside="setDialog(false)" v-model="dialog">
        <v-simple-table height="auto">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">Cliente</th>
                    <th class="text-left">Valor</th>
                    <th class="text-left">Tempo restante</th>
                    <th class="text-left">Vencimento</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in propostas" :key="item.index" @click="view(item)">
                    <td>{{ item.TITLE }}</td>
                    <td>{{ item.UF_CRM_1594641774 }}</td>
                    <td>{{ item.AVAILABLE }}</td>
                    <td>{{ item.UF_CRM_1601920651 }}</td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-dialog>
</template>

<script>
export default {
    name: "ListaKanban",

    methods: {
        setDialog(state) {
            this.dialog = state;
            this.$emit("update-dialog", state);
        },

        view(e) {
            this.$emit("show-details", e);
        },
    },

    watch: {
        lista_kanban: function () {
            this.dialog = this.lista_kanban;
        }
    },

    mounted() {
        this.dialog = this.lista_kanban;
    },

    data() {
        return {
            dialog: false,
        }
    },

    props: {
        propostas: {
            type: Array,
            required: true,
        },

        lista_kanban: {
            type: Boolean,
            required: true,
        }
    },
}
</script>
