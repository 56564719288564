<template>
    <div id="app">
        <router-view :username="username"></router-view>
    </div>
</template>

<script>
    import router from "../router";

    export default {
        name: "app",
        props: ['username'],

        components:{
            router,
        },
    }
</script>
