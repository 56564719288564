<template>
    <ul class="chat">
        <div v-for="message in messages">
            <div v-if="user === message.AUTHOR_ID">
                <v-row justify="end">
                    <v-card elevation="1" color="blue lighten-4" width="500" height="auto" class="pa-4 ma-2">
                        <v-row>
                            <p class="font-weight-thin">{{ message.CREATED }}</p>
                        </v-row>
                        <v-row>
                            <p class="text-break">{{ message.COMMENT }}</p>
                        </v-row>
                      <v-row>
                        <div v-for="anexo in message.FILES">
                          <v-chip class="ma-1" color="blue lighten-3" small @click="showUrl(anexo.urlShow)">
                            <v-icon left>mdi-attachment</v-icon>
                            {{ anexo.name }}
                          </v-chip>
                        </div>
                      </v-row>
                    </v-card>
                </v-row>
            </div>
            <div v-else>
                <v-row justify="start">
                    <v-card elevation="1" color="grey lighten-4" width="500" height="auto" class="pa-4 ma-2">
                        <v-row>
                            <p class="font-weight-thin">{{ message.CREATED }}</p>
                        </v-row>
                        <v-row>
                            <div class="text-break">{{ message.COMMENT }}</div>
                        </v-row>
                        <v-row>
                            <div v-for="anexo in message.FILES">
                                <v-chip class="ma-1" small @click="showUrl(anexo.urlShow)">
                                    <v-icon left>mdi-attachment</v-icon>
                                    {{ anexo.name }}
                                </v-chip>
                            </div>
                        </v-row>
                    </v-card>
                </v-row>
            </div>
        </div>
    </ul>
</template>

<script>
export default {
    props: ['messages', 'user'],

    methods: {
        showUrl(url) {
            window.open(url);
        },
    }
};
</script>
