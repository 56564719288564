<template>
    <v-app style="background-color: #f8fafc !important;">
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-overlay :value="loading_dialog">
            <v-dialog v-model="loading_dialog" hide-overlay persistent width="400">
                <v-card color="primary" dark>
                    <v-card-text class="pt-2">
                        {{ loading_message }}
                        <v-progress-linear indeterminate color="white" class="mt-6 mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-overlay>
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-sheet outlined flat class="ma-0" color="#F8FAFC" height="auto">
            <v-sheet ref="form" flat height="auto" outlined>
                <v-tabs class="flat-tab" fixed-tabs grow background-color="primary">
                    <v-tab>
                        <v-icon left>mdi-flash</v-icon>
                        ENDEREÇO DE INSTALAÇÃO DA UNIDADE GERADORA
                    </v-tab>
                    <v-tab-item>
                        <v-container class="fill-height">
                            <v-layout row wrap align-center>
                                <v-flex class="text-xs-center">
                                    <v-row>
                                        <v-col></v-col>
                                        <v-col align="center">
                                            <v-text-field
                                                v-mask-cep
                                                label="CEP"
                                                v-if="!step4.rural_instalacao"
                                                v-model="step4.cep_instalacao"
                                                :error-messages="cepInstalacaoErrors"
                                                @input.native="$v.step4.cep_instalacao.$touch()"
                                                @blur="$v.step4.cep_instalacao.$touch()"
                                            ></v-text-field>
                                            <v-text-field
                                                :loading="loading"
                                                label="Logradouro"
                                                v-model="$v.step4.logradouro_instalacao.$model"
                                                :error-messages="logradouroInstalacaoErrors"
                                                @blur="$v.step4.logradouro_instalacao.$touch()"
                                            ></v-text-field>
                                            <v-text-field
                                                :loading="loading"
                                                label="Número"
                                                v-model="$v.step4.numero_instalacao.$model"
                                                :error-messages="numeroInstalacaoErrors"
                                                @blur="$v.step4.numero_instalacao.$touch()"
                                            ></v-text-field>
                                            <v-text-field
                                                :loading="loading"
                                                label="Bairro"
                                                v-model="$v.step4.bairro_instalacao.$model"
                                                :error-messages="bairroInstalacaoErrors"
                                                @blur="$v.step4.bairro_instalacao.$touch()"
                                            ></v-text-field>
                                            <v-text-field
                                                :loading="loading"
                                                label="Complemento"
                                                v-model="$v.step4.complemento_instalacao.$model"
                                                :error-messages="complementoInstalacaoErrors"
                                                @blur="$v.step4.complemento_instalacao.$touch()"
                                            ></v-text-field>
                                            <v-select
                                                :loading="loading"
                                                label="Estado"
                                                v-model="$v.step4.estado_instalacao.$model"
                                                :items="estados"
                                                :error-messages="estadoInstalacaoErrors"
                                                @blur="$v.step4.estado_instalacao.$touch()"
                                                menu-props="auto"
                                                append-icon="mdi-map"
                                            ></v-select>
                                            <v-select
                                                :loading="loading"
                                                label="Cidade"
                                                v-model="$v.step4.cidade_instalacao.$model"
                                                :items="cidades_instalacao"
                                                :error-messages="cidadeInstalacaoErrors"
                                                @blur="$v.step4.cidade_instalacao.$touch()"
                                                menu-props="auto"
                                                append-icon="mdi-map"
                                            ></v-select>
                                        </v-col>
                                        <v-col align="center">
                                            <v-checkbox
                                                v-model="step4.rural_instalacao"
                                                label="Endereço Rural"
                                            ></v-checkbox>
                                            <v-switch
                                                v-if="!this.novo_endereco"
                                                v-model="consumo"
                                                label="Informar consumo">
                                            </v-switch>
                                        </v-col>
                                    </v-row>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-tab-item>
                </v-tabs>
            </v-sheet>
        </v-sheet>
        <!--=======================================================================================================-->
        <div v-if="consumo">
            <v-sheet ref="form" flat min-height="500px" height="auto" outlined>
                <v-tabs v-model="tab" class="flat-tab" fixed-tabs background-color="primary">
                    <!-- TABS HEADER -------------------------------------------------------------------------->
                    <v-tab href="#1" @click="setSubGrupo(1)">
                        <v-icon left>mdi-flash</v-icon>
                        Sub-Grupo B
                    </v-tab>
                    <v-tab href="#2" @click="setSubGrupo(2)">
                        <v-icon left>mdi-flash</v-icon>
                        Sub-Grupo A
                    </v-tab>
                    <v-tab href="#3" @click="setSubGrupo(3)">
                        <v-icon left>mdi-wrench</v-icon>
                        Em construção
                    </v-tab>
                    <v-tab href="#4" @click="setSubGrupo(4)">
                        <v-icon left>mdi-star</v-icon>
                        Projetos Especiais
                    </v-tab>
                </v-tabs>
                <!-- TABS HEADER -------------------------------------------------------------------------->

                <v-tabs-items v-model="tab">
                    <!-- Sub-Grupo B -------------------------------------------------------------------------->
                    <v-tab-item value="1">
                        <v-tabs color="blue" v-model.lazy="b_sub_tab" dark height="30px">
                            <v-tab v-for="(grupo_b, i) in step5.grupos_b" :key="i">
                                {{ i + 1 }}
                            </v-tab>
                            <v-btn class="pb-1" icon @click="createSubGrupoB">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-tabs-items v-model.lazy="b_sub_tab">
                                <v-tab-item
                                    v-for="(grupo_b, index) in $v.step5.grupos_b.$each.$iter"
                                    :key="index">
                                    <v-card flat>
                                        <v-container class="fill-height">
                                            <v-layout row wrap align-center>
                                                <v-flex class="text-xs-center">
                                                    <v-row>
                                                        <v-col align="center">
                                                            <p v-if="index === '0'"
                                                               justify="center"
                                                               class="font-weight-bold subtitle-2">
                                                                UNIDADE CONSUMIDORA GERADORA
                                                            </p>
                                                            <p v-else
                                                               justify="center"
                                                               class="font-weight-bold subtitle-2">
                                                                UNIDADE CONSUMIDORA PARA COMPENSAÇÃO
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="ml-12 mr-12">
                                                        <v-col>
                                                            <v-text-field
                                                                style="width: 90%"
                                                                label="Unidade consumidora"
                                                                v-model.lazy="grupo_b.unidade_consumidora.$model"
                                                                @blur="grupo_b.unidade_consumidora.$touch()"
                                                                :error-messages="itemErrors(grupo_b.unidade_consumidora)"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col>
                                                            <v-select
                                                                v-if="index === '0'"
                                                                style="width: 90%"
                                                                label="Concessionária"
                                                                v-model.lazy="$v.step5.concessionaria.$model"
                                                                :items="concessionarias"
                                                                menu-props="auto"
                                                                @blur="$v.step5.concessionaria.$touch()"
                                                                :error-messages="concessionariaErrors"
                                                            ></v-select>
                                                            <v-select
                                                                v-else
                                                                disabled
                                                                style="width: 90%"
                                                                :label="step5.concessionaria"
                                                                menu-props="auto"
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col align="right">
                                                            <v-row>
                                                                <v-btn class="ml-3 pl-7 pr-7 mb-2" rounded
                                                                       color="success" dark
                                                                       @click="createSubGrupoB">
                                                                    <v-icon class="mr-2">mdi-card-plus
                                                                    </v-icon>
                                                                    Nova UC
                                                                </v-btn>
                                                                <v-btn class="ml-3" rounded color="primary"
                                                                       dark
                                                                       @click="deleteSubGrupoB(index)">
                                                                    <v-icon class="mr-2">mdi-delete</v-icon>
                                                                    Deletar UC
                                                                </v-btn>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col align="center">
                                                                    <v-switch
                                                                        v-if="index === '0'"
                                                                        v-model.lazy="step5.grupos_b[0].simplificado"
                                                                        label="Modo Simplificado">
                                                                    </v-switch>
                                                                    <v-switch
                                                                        v-else
                                                                        v-model.lazy="step5.grupos_b[0].simplificado"
                                                                        label="Modo Simplificado"
                                                                        disabled>
                                                                    </v-switch>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider></v-divider>
                                                    <v-row>
                                                        <v-col></v-col>
                                                        <!-- LEFT COLUMN ----------------------------------------------------->
                                                        <v-col align="center">
                                                            <div
                                                                v-if="step5.grupos_b[0].simplificado">
                                                                <v-text-field
                                                                    label="Consumo Médio"
                                                                    v-money="kwh_ano"
                                                                    v-model.lazy="step5.grupos_b[index].consumo_anual.Media"
                                                                    @blur="grupo_b.consumo_anual.Media.$touch()"
                                                                    :error-messages="itemErrors(grupo_b.consumo_anual.Media)"
                                                                ></v-text-field>
                                                            </div>
                                                            <div v-else>
                                                                <v-text-field
                                                                    v-for="(value, key, j) in grupo_b.consumo_anual.$model"
                                                                    v-if="j > 0"
                                                                    :key="j"
                                                                    :label="key"
                                                                    v-model.lazy="step5.grupos_b[index].consumo_anual[key]"
                                                                    v-money="kwh_mes"
                                                                    @blur="grupo_b.consumo_anual[key].$touch()"
                                                                    :error-messages="itemErrors(grupo_b.consumo_anual[key])"
                                                                ></v-text-field>
                                                            </div>
                                                        </v-col>
                                                        <!-- RIGHT COLUMN ----------------------------------------------------->
                                                        <v-col align="center">
                                                            <v-text-field
                                                                label="Tarifa com tributos"
                                                                v-money="money"
                                                                v-model.lazy="step5.grupos_b[index].tarifa_anual"
                                                                @blur="grupo_b.tarifa_anual.$touch()"
                                                                :error-messages="itemErrors(grupo_b.tarifa_anual)"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col></v-col>
                                                    </v-row>
                                                    <v-divider></v-divider>
                                                    <v-row v-if="index == 0">
                                                        <v-col></v-col>
                                                        <v-col class="mt-5">
                                                            <v-text-field
                                                                label="Geração desejada"
                                                                v-money="kwh_mes"
                                                                v-model.lazy="step5.consumo_desejado"
                                                            ></v-text-field>
                                                            <v-select
                                                                v-if="username.tipo_contato === '7427' || username.tipo_contato === '7429'"
                                                                label="Qual tipo de telhado?"
                                                                v-model.lazy="$v.step5.telhado.$model"
                                                                :items="telhados_adm"
                                                                menu-props="auto"
                                                                @blur="$v.step5.telhado.$touch()"
                                                                :error-messages="itemErrors($v.step5.telhado)"
                                                            ></v-select>
                                                            <v-select
                                                                v-else
                                                                label="Qual tipo de telhado?"
                                                                v-model.lazy="$v.step5.telhado.$model"
                                                                :items="telhados"
                                                                menu-props="auto"
                                                                @blur="$v.step5.telhado.$touch()"
                                                                :error-messages="itemErrors($v.step5.telhado)"
                                                            ></v-select>
                                                            <v-select
                                                                label="Categoria de atendimento"
                                                                v-model.lazy="$v.step5.categoria_atendimento.$model"
                                                                :items="categorias"
                                                                menu-props="auto"
                                                                @blur="$v.step5.categoria_atendimento.$touch()"
                                                                :error-messages="itemErrors($v.step5.categoria_atendimento)"
                                                            ></v-select>
                                                            <v-select
                                                                label="Haverá troca da titularidade?"
                                                                v-model.lazy="$v.troca_titularidade.$model"
                                                                :items="lista_troca_titularidade"
                                                                menu-props="auto"
                                                                @blur="$v.troca_titularidade.$touch()"
                                                                :error-messages="itemErrors($v.troca_titularidade)"
                                                            ></v-select>
                                                            <v-select
                                                                label="Passará de Grupo A para B?"
                                                                v-model.lazy="$v.a_para_b.$model"
                                                                :items="lista_a_para_b"
                                                                menu-props="auto"
                                                                @blur="$v.a_para_b.$touch()"
                                                                :error-messages="itemErrors($v.a_para_b)"
                                                            ></v-select>
                                                            <v-select
                                                                label="Modalidade de venda"
                                                                v-model.lazy="modalidade"
                                                                :items="lista_modalidade"
                                                                menu-props="auto"
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col class="mt-5">
                                                            <v-select
                                                                label="Tensão de alimentação"
                                                                v-model.lazy="$v.step5.tensao.$model"
                                                                :items="lista_tensao"
                                                                menu-props="auto"
                                                                @blur="$v.step5.tensao.$touch()"
                                                                :error-messages="itemErrors($v.step5.tensao)"
                                                            ></v-select>
                                                            <v-select
                                                                label="Potência do módulo"
                                                                v-model.lazy="$v.step5.p_modulo.$model"
                                                                :items="modelos_modulos_adm_only"
                                                                menu-props="auto"
                                                                @blur="$v.step5.p_modulo.$touch()"
                                                                :error-messages="itemErrors($v.step5.p_modulo)"
                                                            ></v-select>
                                                            <v-select
                                                                label="Qual a modalidade?"
                                                                v-model.lazy="$v.modalidade_consumo_geracao.$model"
                                                                :items="['Auto-Consumo','Geração Remota']"
                                                                menu-props="auto"
                                                                @blur="$v.modalidade_consumo_geracao.$touch()"
                                                                :error-messages="itemErrors($v.modalidade_consumo_geracao)"
                                                            ></v-select>
                                                            <v-file-input
                                                                class="mt-2"
                                                                label="Imagens do local"
                                                                outlined dense
                                                                multiple
                                                                @change="updateImagens"
                                                            ></v-file-input>
                                                            <v-file-input
                                                                label="Anexo de fatura"
                                                                multiple
                                                                outlined dense
                                                                class="mt-5"
                                                                @change="updateAnexos"
                                                            ></v-file-input>
                                                        </v-col>
                                                        <v-col></v-col>
                                                    </v-row>

                                                    <div v-if="index == 0 &&
                                                            (username.tipo_contato === '7427'
                                                            || username.tipo_contato === '7429')">
                                                        <v-divider/>
                                                        <custos-adicionais
                                                            :data="setCustosAdicionais"
                                                            @getData="getCustosAdicionais"
                                                            @invalid="setCustosAdicionaisInvalid">
                                                        </custos-adicionais>
                                                        <v-divider/>
                                                    </div>

                                                    <v-row>
                                                        <v-col class="ml-10 mr-10">
                                                            <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                            <v-textarea
                                                                label="Comentário sobre a proposta"
                                                                v-model.lazy="step5.comentario"
                                                                outlined
                                                            ></v-textarea>
                                                            <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col class="ml-10 mr-10">
                                                            <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                            <v-textarea
                                                                label="Observações sobre a proposta"
                                                                v-model.lazy="step5.observacoes"
                                                                outlined
                                                            ></v-textarea>
                                                            <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                        </v-col>
                                                    </v-row>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-tabs>
                    </v-tab-item>
                    <!-- Sub-Grupo B -------------------------------------------------------------------------->

                    <!-- Sub-Grupo A -------------------------------------------------------------------------->
                    <v-tab-item value="2">
                        <v-tabs color="blue" v-model.lazy="a_sub_tab" dark height="30px">
                            <!-- UNIDADES CONSUMIDORAS -------------------------------------------------------->
                            <v-tab v-for="(grupo_a, i) in step5.grupos_a" :key="i">
                                {{ i + 1 }}
                            </v-tab>
                            <v-btn class="pb-1" icon @click="createSubGrupoA">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <!--------------------------------------------------------------------------------->
                            <v-tabs-items v-model.lazy="a_sub_tab">
                                <v-tab-item
                                    v-for="(grupo_a, index) in $v.step5.grupos_a.$each.$iter"
                                    :key="index">
                                    <v-card flat>
                                        <v-container class="fill-height">
                                            <v-layout row wrap align-center>
                                                <v-flex class="text-xs-center">
                                                    <v-row>
                                                        <v-col align="center">
                                                            <p v-if="index == 0"
                                                               justify="center"
                                                               class="font-weight-bold subtitle-2">
                                                                UNIDADE CONSUMIDORA GERADORA
                                                            </p>
                                                            <p v-else
                                                               justify="center"
                                                               class="font-weight-bold subtitle-2">
                                                                UNIDADE CONSUMIDORA PARA COMPENSAÇÃO
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row class="ml-12 mr-12">
                                                        <v-col>
                                                            <v-text-field
                                                                label="Unidade consumidora"
                                                                v-model.lazy="grupo_a.unidade_consumidora.$model"
                                                                @blur="grupo_a.unidade_consumidora.$touch()"
                                                                :error-messages="itemErrors(grupo_a.unidade_consumidora)"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col>
                                                            <v-select
                                                                v-if="index == 0"
                                                                style="width: 90%"
                                                                label="Concessionária"
                                                                v-model.lazy="$v.step5.concessionaria.$model"
                                                                :items="concessionarias"
                                                                menu-props="auto"
                                                                @blur="$v.step5.concessionaria.$touch()"
                                                                :error-messages="concessionariaErrors"
                                                            ></v-select>
                                                            <v-select
                                                                v-else
                                                                disabled
                                                                style="width: 90%"
                                                                :label="step5.concessionaria"
                                                                menu-props="auto"
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col align="right">
                                                            <v-row>
                                                                <v-btn class="ml-3 pl-7 pr-7 mb-2" rounded
                                                                       color="success" dark
                                                                       @click="createSubGrupoA">
                                                                    <v-icon class="mr-2">mdi-card-plus
                                                                    </v-icon>
                                                                    Nova UC
                                                                </v-btn>
                                                                <v-btn class="ml-3" rounded color="primary"
                                                                       dark
                                                                       @click="deleteSubGrupoA(index)">
                                                                    <v-icon class="mr-2">mdi-delete</v-icon>
                                                                    Deletar UC
                                                                </v-btn>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col align="center">
                                                                    <v-switch
                                                                        v-if="index === '0'"
                                                                        v-model.lazy="step5.grupos_a[0].simplificado"
                                                                        label="Modo Simplificado">
                                                                    </v-switch>
                                                                    <v-switch
                                                                        v-else
                                                                        v-model.lazy="step5.grupos_a[0].simplificado"
                                                                        label="Modo Simplificado"
                                                                        disabled>
                                                                    </v-switch>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider></v-divider>
                                                    <v-row>
                                                        <!-- SIMPLIFICADO ------------------------------------->
                                                        <v-col
                                                            v-if="step5.grupos_a[0].simplificado"
                                                            align="center"
                                                            class="ml-12">
                                                            <p justify="center"
                                                               class="font-weight-bold subtitle-2">
                                                                Consumo de ponta em kWh
                                                            </p>
                                                            <v-text-field
                                                                label="Média"
                                                                v-model.lazy="step5.grupos_a[index].consumo_ponta.Media"
                                                                v-money="kwh_ano"
                                                                @blur="grupo_a.consumo_ponta.Media.$touch()"
                                                                :error-messages="itemErrors(grupo_a.consumo_ponta.Media)"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <!-- SIMPLIFICADO ------------------------------------->
                                                        <!-- DETALHADO ---------------------------------------->
                                                        <v-col v-else align="center" class="ml-12">
                                                            <p justify="center"
                                                               class="font-weight-bold subtitle-2">
                                                                Consumo de ponta em kWh
                                                            </p>
                                                            <v-text-field
                                                                v-for="(value, key, j) in grupo_a.consumo_ponta.$model"
                                                                v-if="j > 0"
                                                                :key="j"
                                                                :label="key"
                                                                v-model.lazy="step5.grupos_a[index].consumo_ponta[key]"
                                                                v-money="kwh_mes"
                                                                @blur="grupo_a.consumo_ponta[key].$touch()"
                                                                :error-messages="itemErrors(grupo_a.consumo_ponta[key])"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <!-- LEFT COLUMN -------------------------------------->
                                                        <v-col align="center">
                                                            <p justify="center"
                                                               class="font-weight-bold subtitle-2">
                                                                Valor de tarifa ponta com tributos
                                                            </p>
                                                            <v-text-field
                                                                label="Tarifa ponta"
                                                                v-model.lazy="step5.grupos_a[index].valor_tarifa_ponta"
                                                                v-money="money"
                                                                @blur="grupo_a.valor_tarifa_ponta.$touch()"
                                                                :error-messages="itemErrors(grupo_a.valor_tarifa_ponta)"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <!-- RIGHT COLUMN ------------------------------------->
                                                        <v-col align="center">
                                                            <p justify="center"
                                                               class="font-weight-bold subtitle-2">
                                                                Consumo fora de ponta em kWh
                                                            </p>
                                                            <!-- SIMPLIFICADO --------------------------------->
                                                            <div
                                                                v-if="step5.grupos_a[0].simplificado">
                                                                <v-text-field
                                                                    label="Média"
                                                                    v-model.lazy="step5.grupos_a[index].consumo_fora.Media"
                                                                    v-money="kwh_ano"
                                                                    @blur="grupo_a.consumo_fora.Media.$touch()"
                                                                    :error-messages="itemErrors(grupo_a.consumo_fora.Media)"
                                                                ></v-text-field>
                                                            </div>
                                                            <!-- SIMPLIFICADO --------------------------------->
                                                            <!-- DETALHADO ------------------------------------>
                                                            <div v-else>
                                                                <v-text-field
                                                                    v-for="(value, key, j) in grupo_a.consumo_fora.$model"
                                                                    v-if="j > 0"
                                                                    :key="j"
                                                                    :label="key"
                                                                    v-model.lazy="step5.grupos_a[index].consumo_fora[key]"
                                                                    v-money="kwh_mes"
                                                                    @blur="grupo_a.consumo_fora[key].$touch()"
                                                                    :error-messages="itemErrors(grupo_a.consumo_fora[key])"
                                                                ></v-text-field>
                                                            </div>
                                                            <!-- DETALHADO ------------------------------------>
                                                        </v-col>
                                                        <v-col align="center" class="mr-12">
                                                            <p justify="center"
                                                               class="font-weight-bold subtitle-2">
                                                                Valor de tarifa fora ponta com tributos
                                                            </p>
                                                            <v-text-field
                                                                label="Tarifa fora ponta"
                                                                v-model.lazy="step5.grupos_a[index].valor_tarifa_fora"
                                                                v-money="money"
                                                                @blur="grupo_a.valor_tarifa_fora.$touch()"
                                                                :error-messages="itemErrors(grupo_a.valor_tarifa_fora)"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <!-- 3 LINHA ---------------------------------------------->
                                                    <v-divider></v-divider>
                                                    <v-row v-if="index == 0">
                                                        <v-col></v-col>
                                                        <v-col>
                                                            <v-text-field
                                                                label="Demanda contratada em kWh"
                                                                v-model.lazy="step5.demanda_contratada"
                                                                v-mask-decimal.br="2"
                                                                @blur="$v.step5.demanda_contratada.$touch()"
                                                                :error-messages="itemErrors($v.step5.demanda_contratada)"
                                                            ></v-text-field>
                                                            <v-select
                                                                label="Potência do módulo"
                                                                v-model.lazy="$v.step5.p_modulo.$model"
                                                                :items="modelos_modulos_adm_only"
                                                                menu-props="auto"
                                                                @blur="$v.step5.p_modulo.$touch()"
                                                                :error-messages="itemErrors($v.step5.p_modulo)"
                                                            ></v-select>
                                                            <v-select
                                                                label="Tensão de alimentação"
                                                                v-model.lazy="$v.step5.tensao.$model"
                                                                :items="lista_tensao"
                                                                menu-props="auto"
                                                                @blur="$v.step5.tensao.$touch()"
                                                                :error-messages="itemErrors($v.step5.tensao)"
                                                            ></v-select>
                                                            <v-select
                                                                v-if="username.tipo_contato === '7427' || username.tipo_contato === '7429'"
                                                                label="Qual tipo de telhado?"
                                                                v-model.lazy="$v.step5.telhado.$model"
                                                                :items="telhados_adm"
                                                                menu-props="auto"
                                                                @blur="$v.step5.telhado.$touch()"
                                                                :error-messages="itemErrors($v.step5.telhado)"
                                                            ></v-select>
                                                            <v-select
                                                                v-else
                                                                label="Qual tipo de telhado?"
                                                                v-model.lazy="$v.step5.telhado.$model"
                                                                :items="telhados"
                                                                menu-props="auto"
                                                                @blur="$v.step5.telhado.$touch()"
                                                                :error-messages="itemErrors($v.step5.telhado)"
                                                            ></v-select>
                                                            <v-select
                                                                label="Categoria de atendimento"
                                                                v-model.lazy="$v.step5.categoria_atendimento.$model"
                                                                :items="categorias"
                                                                menu-props="auto"
                                                                @blur="$v.step5.categoria_atendimento.$touch()"
                                                                :error-messages="itemErrors($v.step5.categoria_atendimento)"
                                                            ></v-select>
                                                            <v-select
                                                                label="Haverá troca da titularidade?"
                                                                v-model.lazy="$v.troca_titularidade.$model"
                                                                :items="lista_troca_titularidade"
                                                                menu-props="auto"
                                                                @blur="$v.troca_titularidade.$touch()"
                                                                :error-messages="itemErrors($v.troca_titularidade)"
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field
                                                                label="Tarifa da demanda contratada"
                                                                v-money="money"
                                                                v-model.lazy="$v.step5.tarifa_demanda.$model"
                                                                @blur="$v.step5.tarifa_demanda.$touch()"
                                                                :error-messages="itemErrors($v.step5.tarifa_demanda)"
                                                            ></v-text-field>
                                                            <v-text-field
                                                                label="Demanda máxima registrada em kWh"
                                                                v-mask-decimal.br="2"
                                                                v-model.lazy="step5.demanda_maxima"
                                                                @blur="$v.step5.demanda_maxima.$touch()"
                                                                :error-messages="itemErrors($v.step5.demanda_maxima)"
                                                            ></v-text-field>
                                                            <v-select
                                                                label="Qual a modalidade?"
                                                                v-model.lazy="$v.modalidade_consumo_geracao.$model"
                                                                :items="['Auto-Consumo','Geração Remota']"
                                                                menu-props="auto"
                                                                @blur="$v.modalidade_consumo_geracao.$touch()"
                                                                :error-messages="itemErrors($v.modalidade_consumo_geracao)"
                                                            ></v-select>
                                                            <v-file-input
                                                                class="mt-2"
                                                                label="Imagens do local"
                                                                outlined dense
                                                                multiple
                                                                @change="updateImagens"
                                                            ></v-file-input>
                                                            <v-file-input
                                                                label="Anexo de fatura"
                                                                multiple
                                                                outlined dense
                                                                class="mt-1"
                                                                @change="updateAnexos"
                                                            ></v-file-input>
                                                        </v-col>
                                                        <v-col></v-col>
                                                    </v-row>

                                                    <div v-if="index == 0 &&
                                                            (username.tipo_contato === '7427'
                                                            || username.tipo_contato === '7429')">
                                                        <v-divider/>
                                                        <custos-adicionais
                                                            :data="setCustosAdicionais"
                                                            @getData="getCustosAdicionais"
                                                            @invalid="setCustosAdicionaisInvalid"></custos-adicionais>
                                                        <v-divider/>
                                                    </div>
                                                    <v-row>
                                                        <v-col class="ml-10 mr-10">
                                                            <!-- COMMENT AREA (SHOULD BE BIG) ----------------->
                                                            <v-textarea
                                                                label="Comentário sobre a proposta"
                                                                v-model.lazy="step5.comentario"
                                                                outlined
                                                            ></v-textarea>
                                                            <!-- COMMENT AREA (SHOULD BE BIG) ----------------->
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col class="ml-10 mr-10">
                                                            <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                            <v-textarea
                                                                label="Observações sobre a proposta"
                                                                v-model.lazy="step5.observacoes"
                                                                outlined
                                                            ></v-textarea>
                                                            <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                        </v-col>
                                                    </v-row>
                                                    <!--------------------------------------------------------->
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-tabs>
                    </v-tab-item>
                    <!-- Sub-Grupo A -------------------------------------------------------------------------->

                    <!-- Unidade consumidora em construção ---------------------------------------------------->
                    <v-tab-item value="3">
                        <v-card flat>
                            <v-container class="fill-height">
                                <v-layout row wrap align-center>
                                    <v-flex class="text-xs-center">
                                        <v-row class="ml-12 mr-12">
                                            <v-col>
                                                <v-text-field
                                                    label="Unidade consumidora"
                                                    v-model.lazy="step5.grupo_c.unidade_consumidora"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-select
                                                    label="Concessionária"
                                                    v-model.lazy="$v.step5.concessionaria.$model"
                                                    :items="concessionarias"
                                                    menu-props="auto"
                                                    @blur="$v.step5.concessionaria.$touch()"
                                                    :error-messages="concessionariaErrors"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row>
                                            <v-col></v-col>
                                            <!-- LEFT COLUMN ----------------------------------------------------->
                                            <v-col>
                                                <!-- BLANK SPACE -------------------------------------------------->
                                                <v-row style="height: 50px;"></v-row>
                                                <!-- BLANK SPACE -------------------------------------------------->
                                                <v-select
                                                    v-if="username.tipo_contato === '7427' || username.tipo_contato === '7429'"
                                                    label="Qual será o tipo de telhado?"
                                                    v-model.lazy="$v.step5.grupo_c.telhado.$model"
                                                    :items="telhados_adm"
                                                    menu-props="auto"
                                                    @blur="$v.step5.grupo_c.telhado.$touch()"
                                                    :error-messages="itemErrors($v.step5.grupo_c.telhado)"
                                                ></v-select>
                                                <v-select
                                                    v-else
                                                    label="Qual será o tipo de telhado?"
                                                    v-model.lazy="$v.step5.grupo_c.telhado.$model"
                                                    :items="telhados"
                                                    menu-props="auto"
                                                    @blur="$v.step5.grupo_c.telhado.$touch()"
                                                    :error-messages="itemErrors($v.step5.grupo_c.telhado)"
                                                ></v-select>
                                            </v-col>
                                            <!-- RIGHT COLUMN ----------------------------------------------------->
                                            <v-col align="center">
                                                <p justify="center"
                                                   class="font-weight-bold subtitle-2">
                                                    Anexar projetos arquitetônico e elétrico para
                                                    uma estimativa de consumo
                                                </p>
                                                <v-file-input
                                                    label="Anexo de fatura"
                                                    multiple
                                                    outlined dense
                                                    @change="updateAnexos"
                                                ></v-file-input>
                                                <!-- BLANK SPACE -------------------------------------------------->
                                                <v-row style="height: 50px;"></v-row>
                                                <!-- BLANK SPACE -------------------------------------------------->
                                                <v-file-input
                                                    label="Imagens do local"
                                                    outlined dense
                                                    multiple
                                                    @change="updateImagens"
                                                ></v-file-input>
                                            </v-col>
                                            <v-col></v-col>
                                        </v-row>

                                        <div>
                                            <v-divider/>
                                            <custos-adicionais
                                                :data="setCustosAdicionais"
                                                @getData="getCustosAdicionais"
                                                @invalid="setCustosAdicionaisInvalid">
                                            </custos-adicionais>
                                            <v-divider/>
                                        </div>

                                        <v-row>
                                            <v-col class="ml-10 mr-10">
                                                <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                <v-textarea
                                                    label="Comentário sobre a proposta"
                                                    v-model.lazy="step5.comentario"
                                                    outlined
                                                ></v-textarea>
                                                <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="ml-10 mr-10">
                                                <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                <v-textarea
                                                    label="Observações sobre a proposta"
                                                    v-model.lazy="step5.observacoes"
                                                    outlined
                                                ></v-textarea>
                                                <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                            </v-col>
                                        </v-row>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card>
                    </v-tab-item>
                    <!-- Projetos Especiais ------------------------------------------------------------------->
                    <v-tab-item value="4">
                        <v-card min-width="900" flat>
                            <v-container class="fill-height">
                                <v-layout row wrap align-center>
                                    <v-flex class="text-xs-center">
                                        <v-row class="ml-12 mr-12">
                                            <v-col>
                                                <v-select
                                                    label="Ambiente livre?"
                                                    :items="['Sim', 'Não']"
                                                    menu-props="auto"
                                                    v-model.lazy="$v.step5.especial.ambiente_livre.$model"
                                                    @blur="$v.step5.especial.ambiente_livre.$touch()"
                                                    :error-messages="itemErrors($v.step5.especial.ambiente_livre)"
                                                ></v-select>
                                                <v-file-input

                                                    label="Anexo da fatura"
                                                    multiple
                                                    outlined dense
                                                    class="mt-1"
                                                    @change="updateAnexosEspecial"
                                                    @input="$v.step5.especial.anexo_fatura.$touch()"
                                                    @blur="$v.step5.especial.anexo_fatura.$touch()"
                                                    :error-messages="itemErrors($v.step5.especial.anexo_fatura)"
                                                ></v-file-input>
                                                <v-file-input
                                                    v-if="step5.especial.ambiente_livre === 'Sim'"
                                                    label="Fatura da compra de energia"
                                                    multiple
                                                    outlined dense
                                                    class="mt-1"
                                                    @change="updateAnexosCompraEnergia"
                                                ></v-file-input>
                                            </v-col>
                                            <v-col>
                                                <v-select
                                                    label="Sistema para consumo Parcial ou Total?"
                                                    hint="O sistema solicitado pelo cliente é para atender totalmente ou parcialmente o consumo?"
                                                    v-model.lazy="$v.step5.especial.consumo_total_parcial.$model"
                                                    :items="['Total', 'Parcial']"
                                                    menu-props="auto"
                                                    @blur="$v.step5.especial.consumo_total_parcial.$touch()"
                                                    :error-messages="itemErrors($v.step5.especial.consumo_total_parcial)"
                                                ></v-select>
                                                <v-select
                                                    v-if="step5.especial.consumo_total_parcial === 'Parcial'"
                                                    label="Limite geração"
                                                    hint="Limitar a uma geração mensal ou a uma demanda contratada?"
                                                    v-model.lazy="step5.especial.limite_geracao"
                                                    :items="['Geração mensal', 'Demanda contratada']"
                                                    menu-props="auto"
                                                ></v-select>
                                                <v-text-field
                                                    v-if="step5.especial.limite_geracao === 'Geração mensal'"
                                                    label="Geração mensal"
                                                    v-model.lazy="step5.especial.geracao_mensal"
                                                    v-mask-decimal.br="2"
                                                ></v-text-field>
                                                <v-text-field
                                                    v-if="step5.especial.limite_geracao === 'Demanda contratada'"
                                                    label="Demanda contratada"
                                                    v-model.lazy="step5.especial.demanda_contratada"
                                                    v-mask-decimal.br="2"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <div
                                            v-if="(username.tipo_contato === '7427' || username.tipo_contato === '7429')">
                                            <v-divider/>
                                            <custos-adicionais
                                                :proposta="true"
                                                @getData="getCustosAdicionais"
                                                @invalid="setCustosAdicionaisInvalid"></custos-adicionais>
                                            <v-divider/>
                                        </div>

                                        <v-row>
                                            <v-col class="ml-10 mr-10">
                                                <!-- COMENT AREA (SHOULD BE BIG) ------------------------------>
                                                <v-textarea
                                                    label="Comentário sobre a proposta"
                                                    v-model.lazy="step5.comentario"
                                                    outlined
                                                ></v-textarea>
                                                <!-- COMENT AREA (SHOULD BE BIG) ------------------------------>
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            v-if="(username.tipo_contato === '7427' || username.tipo_contato === '7429')">
                                            <v-col class="ml-10 mr-10">
                                                <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                <v-textarea
                                                    label="Observações sobre a proposta"
                                                    v-model.lazy="step5.observacoes"
                                                    outlined
                                                ></v-textarea>
                                                <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                            </v-col>
                                        </v-row>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-sheet>
            <!-- Sub-Grupo B -------------------------------------------------------------------------->
            <!--        {{ this.element[0] }}-->
        </div>
        <div class="d-flex justify-space-between ma-2">
            <v-btn class="ml-auto" color="primary" @click="submit">Enviar</v-btn>
        </div>
    </v-app>
</template>

<script>
import {required, requiredIf, maxLength, email} from 'vuelidate/lib/validators'
import {estados} from "../data/estados";
import {concessionarias} from "../data/concessionarias";
import {
    cabines,
    categorias,
    lista_estudo_estrutura,
    lista_posto_transformacao, lista_reforco_estrutura,
    lista_tensao,
    m_inversor,
    p_modulos,
    padroes_entrada,
    responsabilidades_cabine,
    responsabilidades_extensao,
    responsabilidades_infraestrutura,
    responsabilidades_padrao_entrada, responsabilidades_subestacao, responsabilidades_transformador,
    subestacoes,
    telhados, telhados_adm,
    transformadores
} from "../data/SelectData";
import CustosAdicionais from "./CustosAdicionais";

function validaMedia(value, simplificado) {
    if (simplificado) {
        return validarKwhAno(value);
    } else {
        return true;
    }
}

function validaMes(value, simplificado) {
    if (!simplificado) {
        return validarKwhMes(value);
    } else {
        return true;
    }
}

function validarReal(value) {
    return value !== "R$ 0,00";
}

function validarKwhAno(value) {
    return value !== "0,00 kWh/ano";
}

function validarKwhMes(value) {
    return value !== "0,00 kWh/mês";
}

function validarCep(value) {
    return this.pesquisacep(value);
}

function validaCidade() {
    return this.estado_state;
}

function validaCidadeInstalacao() {
    return this.estado_instalacao_state_state;
}


function getGrupoB() {
    return {
        unidade_consumidora: null,
        tarifa_anual: 0, //tarifa com tributos
        simplificado: false,

        consumo_anual: {
            Media: null,
            Janeiro: null,
            Fevereiro: null,
            Marco: null,
            Abril: null,
            Maio: null,
            Junho: null,
            Julho: null,
            Agosto: null,
            Setembro: null,
            Outubro: null,
            Novembro: null,
            Dezembro: null,
        },
    }
}

function getGrupoA() {
    return {
        unidade_consumidora: null,
        valor_tarifa_ponta: 0,
        valor_tarifa_fora: 0,
        simplificado: false,

        consumo_ponta: {
            Media: null,
            Janeiro: null,
            Fevereiro: null,
            Marco: null,
            Abril: null,
            Maio: null,
            Junho: null,
            Julho: null,
            Agosto: null,
            Setembro: null,
            Outubro: null,
            Novembro: null,
            Dezembro: null,
        },
        consumo_fora: {
            Media: null,
            Janeiro: null,
            Fevereiro: null,
            Marco: null,
            Abril: null,
            Maio: null,
            Junho: null,
            Julho: null,
            Agosto: null,
            Setembro: null,
            Outubro: null,
            Novembro: null,
            Dezembro: null,
        },
    }
}

function getGrupoC() {
    return {
        unidade_consumidora: null,

        telhado: null,
    }

}

export default {
    name: "EditDeal",
    components: {CustosAdicionais},
    props: ['id', 'username'],

    data() {
        return {
            consumo: false,
            elements: [],
            deal: [],
            loading_message: null,
            loading_dialog: null,
            tab: '1',

            lista_posto_transformacao: lista_posto_transformacao,
            lista_estudo_estrutura: lista_estudo_estrutura,
            padroes_entrada: padroes_entrada,
            transformadores: transformadores,
            subestacoes: subestacoes,
            cabines: cabines,
            m_inversor: m_inversor,
            categorias: categorias,
            lista_tensao: lista_tensao,
            p_modulos: p_modulos,
            telhados: telhados,
            telhados_adm: telhados_adm,
            responsabilidades_cabine: responsabilidades_cabine,
            responsabilidades_extensao: responsabilidades_extensao,
            responsabilidades_infraestrutura: responsabilidades_infraestrutura,
            responsabilidades_padrao_entrada: responsabilidades_padrao_entrada,
            responsabilidades_subestacao: responsabilidades_subestacao,
            responsabilidades_transformador: responsabilidades_transformador,
            lista_reforco_estrutura: lista_reforco_estrutura,

            concessionarias: [{value: '', text: "Selecione um estado"}],
            //======================================================================================================
            loading: false,
            estados: estados,
            estado_state: true,
            estado_instalacao_state: true,
            //======================================================================================================
            cidades: [{value: '', text: "Selecione um estado"}],
            cidades_instalacao: [{value: '', text: "Selecione um estado"}],
            //==========================================================================================================
            troca_titularidade: '',
            modalidade_consumo_geracao: '',
            a_para_b: '',
            modalidade: '7327',

            novo_endereco: false,
            //Step 4 - Endereco instalacao =============================================================================
            step4: {
                cep_instalacao: '',
                logradouro_instalacao: '',
                numero_instalacao: '',
                bairro_instalacao: '',
                complemento_instalacao: '',
                cidade_instalacao: '',
                estado_instalacao: '',
                rural_instalacao: false,
            },
            //==========================================================================================================

            //Step 5 ===================================================================================================
            a_sub_tab: null,
            b_sub_tab: null,
            c_sub_tab: null,

            step5: {
                sub_grupo: 1, //1, 2, 3 or 4
                // SUB-GRUPO A =================================================================================
                grupos_a: [],
                // SUB-GRUPO B =================================================================================
                grupos_b: [],
                // EM CONSTRUCAO ===============================================================================
                grupo_c: {
                    unidade_consumidora: null,
                },
                especial: {
                    anexo_fatura: [],
                    anexo_compra_energia: [],

                    ambiente_livre: null, //Sim, Nao
                    consumo_total_parcial: null, //Total, Parcial
                    limite_geracao: null, //Geracao mensal, Demanda contratada

                    geracao_mensal: null, //Number
                    demanda_contratada: null, //Number
                },

                categoria_atendimento: null,
                tensao: '220-127V',
                p_modulo: null,
                consumo_desejado: null,
                anexo_fatura: [],
                anexo_imagens_local: [],
                b_resumido: false,

                tarifa_demanda: 0,
                demanda_maxima: null,
                demanda_contratada: null,

                telhado: null,
                comentario: null,
                observacoes: null,
                concessionaria: null,
            },
            //==========================================================================================================
        }
    },

    watch: {
        'step4.estado_instalacao': function () {
            let request = new Request('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'
                + this.step4.estado_instalacao + '/municipios/');
            this.estado_state = fetch(request)
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        return false;
                    }
                })
                .then(response => {
                    let aux = response.map(function (aux2) {
                        return aux2.nome
                    });
                    this.cidades_instalacao = aux;
                    return true;
                }).catch(error => {
                    console.error(error);
                    return false;
                });
            //Atualiza os estados das concessionarias
            this.concessionarias = [];
            for (let i = 0; i < concessionarias.length; i++) {
                if (concessionarias[i].UF === this.step4.estado_instalacao) {
                    this.concessionarias.push(concessionarias[i].concessionaria);
                }
            }
            if (this.concessionarias.length === 1) {
                this.step5.concessionaria = this.concessionarias[0];
            }
        },
    },

    async created() {
        await this.getInversoresModulos();
        this.loading_dialog = true;
        this.loading_message = 'Carregando...';
        await this.getDeal();
        await this.setEnderecoInstalacao();
        await this.setElements();
        // //Tem que ser por ultimo =======================================================================================
        // await this.setCustosAdicionais();
        // //==============================================================================================================
        this.setTab();
        this.loading_dialog = false;

        this.pushNotification('Atenção! Altere apenas o necessário...', 'warning', 10000)
    },

    methods: {
        setTab() {
            switch (this.getIndex(this.elements[0].PROPERTY_174)) {
                case '214': //Sub-grupo B
                    this.tab = '1';
                    this.step5.sub_grupo = 1;
                    this.step5.grupos_a.push(getGrupoA());
                    if (this.step5.grupos_b[0].consumo_anual.Media) {
                        this.step5.grupos_b[0].simplificado = true;
                    }
                    break;
                case '212': //Sub-grupo A
                    this.tab = '2';
                    this.step5.sub_grupo = 2;
                    this.step5.grupos_b.push(getGrupoB());
                    if (this.step5.grupos_b[0].consumo_ponta.Media) {
                        this.step5.grupos_b[0].simplificado = true;
                    }
                    break;
                case '283': //EM CONSTUCAO
                    this.tab = '3';
                    this.step5.sub_grupo = 3;
                    this.step5.grupos_a.push(getGrupoA());
                    this.step5.grupos_b.push(getGrupoB());
                    break;
                case '285': //Projetos Especiais
                    this.tab = '4';
                    this.step5.sub_grupo = 4;
                    this.step5.grupos_a.push(getGrupoA());
                    this.step5.grupos_b.push(getGrupoB());
                    break;
            }
        },

        async updateAnexosFatura(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.step5.especial.anexo_fatura = [];
            for (let i = 0; i < files.length; i++) {
                this.step5.especial.anexo_fatura.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },

        async updateAnexosCompraEnergia(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.step5.especial.anexo_compra_energia = [];
            for (let i = 0; i < files.length; i++) {
                this.step5.especial.anexo_compra_energia.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },

        async updateAnexosEspecial(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.step5.especial.anexo_fatura = [];
            for (let i = 0; i < files.length; i++) {
                this.step5.especial.anexo_fatura.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },

        setElements() {
            //Campos globais ===========================================================================================
            this.step5.categoria_atendimento = this.getIndex(this.elements[0].PROPERTY_269);
            this.step5.tensao = this.getIndex(this.elements[0].PROPERTY_267);
            this.step5.p_modulo = this.getIndex(this.elements[0].PROPERTY_265);
            this.step5.telhado = this.getIndex(this.elements[0].PROPERTY_251);
            this.step5.concessionaria = this.getIndex(this.elements[0].PROPERTY_259);
            //Projetos especiais =======================================================================================
            this.step5.especial.ambiente_livre = this.getIndex(this.elements[0].PROPERTY_405);
            this.step5.especial.consumo_total_parcial = this.getIndex(this.elements[0].PROPERTY_407);
            this.step5.especial.limite_geracao = this.getIndex(this.elements[0].PROPERTY_409);
            this.step5.especial.geracao_mensal = this.getIndex(this.elements[0].PROPERTY_411);
            this.step5.especial.demanda_contratada = this.getIndex(this.elements[0].PROPERTY_413);
            //==========================================================================================================
            this.modalidade_consumo_geracao = this.deal.UF_CRM_1599650863;
            this.troca_titularidade = this.deal.UF_CRM_1600281430;
            this.step5.observacoes = this.deal.UF_CRM_1600354174;
            this.step5.comentario = this.deal.UF_CRM_1602263439;
            //Alguns desses campos podem nao existir ===================================================================
            if (typeof this.elements[0].PROPERTY_257 != 'undefined') {
                this.step5.comentario = this.getIndex(this.elements[0].PROPERTY_257);
            }
            if (typeof this.elements[0].PROPERTY_271 != 'undefined') {
                this.step5.consumo_desejado = this.getIndex(this.elements[0].PROPERTY_271);
            }
            if (typeof this.elements[0].PROPERTY_170 != 'undefined') {
                this.step5.demanda_contratada = this.getIndex(this.elements[0].PROPERTY_170);
            }
            if (typeof this.elements[0].PROPERTY_263 != 'undefined') {
                this.step5.demanda_maxima = this.getIndex(this.elements[0].PROPERTY_263);
            }
            if (typeof this.elements[0].PROPERTY_275 != 'undefined') {
                this.step5.tarifa_demanda = this.getIndex(this.elements[0].PROPERTY_275);
            }
            //Campos individuais de cada elemento, sendo B, A ou C =====================================================
            for (const element of this.elements) {
                //Caso seja sub grupo B ================================================================================
                if (this.getIndex(element.PROPERTY_174) === '214') {
                    this.step5.grupos_b.push(this.buildGrupoB(element));
                } else if (this.getIndex(element.PROPERTY_174) === '212') {//Caso seja sub grupo A =====================
                    this.step5.grupos_a.push(this.buildGrupoA(element));
                } else {
                    this.step5.grupo_c.unidade_consumidora = this.getIndex(element.PROPERTY_140);
                }
            }
        },

        buildGrupoB(element) {
            let grupoB = getGrupoB();
            grupoB.tarifa_anual = this.getIndex(element.PROPERTY_255).slice(0, -4);
            grupoB.unidade_consumidora = this.getIndex(element.PROPERTY_140);
            grupoB.consumo_anual.Media = this.getIndex(element.PROPERTY_253);
            grupoB.consumo_anual.Janeiro = this.getIndex(element.PROPERTY_144);
            grupoB.consumo_anual.Fevereiro = this.getIndex(element.PROPERTY_146);
            grupoB.consumo_anual.Marco = this.getIndex(element.PROPERTY_148);
            grupoB.consumo_anual.Abril = this.getIndex(element.PROPERTY_150);
            grupoB.consumo_anual.Maio = this.getIndex(element.PROPERTY_152);
            grupoB.consumo_anual.Junho = this.getIndex(element.PROPERTY_154);
            grupoB.consumo_anual.Julho = this.getIndex(element.PROPERTY_156);
            grupoB.consumo_anual.Agosto = this.getIndex(element.PROPERTY_158);
            grupoB.consumo_anual.Setembro = this.getIndex(element.PROPERTY_160);
            grupoB.consumo_anual.Outubro = this.getIndex(element.PROPERTY_162);
            grupoB.consumo_anual.Novembro = this.getIndex(element.PROPERTY_164);
            grupoB.consumo_anual.Dezembro = this.getIndex(element.PROPERTY_166);

            return grupoB;
        },

        buildGrupoA(element) {
            let grupoA = getGrupoA();
            grupoA.unidade_consumidora = this.getIndex(element.PROPERTY_140);
            grupoA.valor_tarifa_ponta = this.getIndex(element.PROPERTY_243);
            grupoA.valor_tarifa_fora = this.getIndex(element.PROPERTY_245);
            grupoA.consumo_ponta.Media = this.getIndex(element.PROPERTY_247);
            grupoA.consumo_ponta.Janeiro = this.getIndex(element.PROPERTY_195);
            grupoA.consumo_ponta.Fevereiro = this.getIndex(element.PROPERTY_197);
            grupoA.consumo_ponta.Marco = this.getIndex(element.PROPERTY_199);
            grupoA.consumo_ponta.Abril = this.getIndex(element.PROPERTY_201);
            grupoA.consumo_ponta.Maio = this.getIndex(element.PROPERTY_217);
            grupoA.consumo_ponta.Junho = this.getIndex(element.PROPERTY_203);
            grupoA.consumo_ponta.Julho = this.getIndex(element.PROPERTY_205);
            grupoA.consumo_ponta.Agosto = this.getIndex(element.PROPERTY_207);
            grupoA.consumo_ponta.Setembro = this.getIndex(element.PROPERTY_273);
            grupoA.consumo_ponta.Outubro = this.getIndex(element.PROPERTY_211);
            grupoA.consumo_ponta.Novembro = this.getIndex(element.PROPERTY_213);
            grupoA.consumo_ponta.Dezembro = this.getIndex(element.PROPERTY_215);
            grupoA.consumo_fora.Media = this.getIndex(element.PROPERTY_249);
            grupoA.consumo_fora.Janeiro = this.getIndex(element.PROPERTY_219);
            grupoA.consumo_fora.Fevereiro = this.getIndex(element.PROPERTY_221);
            grupoA.consumo_fora.Marco = this.getIndex(element.PROPERTY_223);
            grupoA.consumo_fora.Abril = this.getIndex(element.PROPERTY_225);
            grupoA.consumo_fora.Maio = this.getIndex(element.PROPERTY_227);
            grupoA.consumo_fora.Junho = this.getIndex(element.PROPERTY_229);
            grupoA.consumo_fora.Julho = this.getIndex(element.PROPERTY_231);
            grupoA.consumo_fora.Agosto = this.getIndex(element.PROPERTY_233);
            grupoA.consumo_fora.Setembro = this.getIndex(element.PROPERTY_235);
            grupoA.consumo_fora.Outubro = this.getIndex(element.PROPERTY_237);
            grupoA.consumo_fora.Novembro = this.getIndex(element.PROPERTY_239);
            grupoA.consumo_fora.Dezembro = this.getIndex(element.PROPERTY_241);

            return grupoA;
        },

        setEnderecoInstalacao() {
            this.step4.cep_instalacao = this.deal.UF_CRM_1571317714553;
            this.step4.logradouro_instalacao = this.deal.UF_CRM_1571317675905;
            this.step4.numero_instalacao = this.deal.UF_CRM_1571317740082;
            this.step4.bairro_instalacao = this.deal.UF_CRM_1571317752848;
            this.step4.complemento_instalacao = this.deal.UF_CRM_1592855066;
            this.step4.estado_instalacao = this.deal.UF_CRM_1592769596;
            this.step4.cidade_instalacao = this.deal.UF_CRM_1571317767945;
        },
        // CLEAR ADDRESS INPUTS ====================================================================================
        clear_cep_form() {
            this.loading = false;
        },
        //CALL BACK FUNCTION THAT WRITE THE ADDRESS VALUES ========================================================
        meu_callback(conteudo) {
            if (!("erro" in conteudo)) {
                //Atualiza os campos com os valores.
                this.step4.estado_instalacao = conteudo.uf;
                this.step4.logradouro_instalacao = conteudo.logradouro;
                this.step4.bairro_instalacao = conteudo.bairro;
                this.step4.cidade_instalacao = conteudo.localidade;
                this.loading = false;
                this.$v.$touch();
                return true;
            } else {
                //CEP não Encontrado.
                this.clear_cep_form();
                return false;
            }
        },
        // FUNCTION THAT MAKES A GET REQUEST FROM A GIVEN CEP ======================================================
        pesquisacep(value) {
            if (!value) return true;

            let cep = value.replace(/\D/g, ''); // LEAVE ONLY DIGITS ===============================================
            let validacep = /^[0-9]{8}$/; // TAKE CEP TO VALID FORMAT ==============================================

            if (validacep.test(cep)) { // IF VALID, SET LOADING TRUE ==================================
                this.loading = true;

                const request = new Request('https://viacep.com.br/ws/' + cep + '/json/');
                return fetch(request)
                    .then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            return false;
                        }
                    })
                    .then(response => {
                        return this.meu_callback(response);
                    }).catch(error => {
                        console.error(error);
                        return false;
                    });
            } else { // NOT VALID CEP ==============================================================================
                this.clear_cep_form();
                return false;
            }
        },
        //Converte imagens para base64 =================================================================================
        async updateImagens(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.step5.anexo_imagens_local = [];
            // this.anexo_imagens_local = [files[0].name, await toBase64(files[0])];
            for (let i = 0; i < files.length; i++) {
                this.step5.anexo_imagens_local.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },

        async updateAnexos(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.step5.anexo_fatura = [];
            // this.anexo_fatura = [files[0].name, await toBase64(files[0])];
            for (let i = 0; i < files.length; i++) {
                this.step5.anexo_fatura.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },
        //==========================================================================================================

        // CREATE A NEW SUB-GROUP AND PUSH TO THE ARRAY ============================================================
        createSubGrupoA() {
            if (this.step5.grupos_a.length >= 4) {
                alert('Número máximo de UCs atingido!');
            } else {
                alert('As unidades consumidoras para compensação precisam estar obrigatoriamente na mesma área de concessão')
                this.step5.grupos_a.push(getGrupoA());
            }
        },
        createSubGrupoB() {
            if (this.step5.grupos_b.length >= 10) {
                alert('Número máximo de UCs atingido!');
            } else {
                alert('As unidades consumidoras para compensação precisam estar obrigatoriamente na mesma área de concessão')
                this.step5.grupos_b.push(getGrupoB());
            }
        },
        // DELETE A NEW SUB-GROUP AND POP FROM THE ARRAY ============================================================
        deleteSubGrupoA(index) {
            if (this.step5.grupos_a.length > 1) {
                this.step5.grupos_a.splice(index, 1);
            }
        },
        deleteSubGrupoB(index) {
            if (this.step5.grupos_b.length > 1) {
                this.step5.grupos_b.splice(index, 1);
            }
        },

        //Set the sub_grupo that is going to be sent to bitrix (1: B, 2: A or 3: Construcao)
        setSubGrupo(sub_grupo) {
            this.step5.sub_grupo = sub_grupo;
        },

        async submit() {
            this.$v.$touch();
            let success = false;
            if (this.consumo) {
                if (!this.custos_adicionais_invalid) {
                    switch (this.step5.sub_grupo) {
                        case 1:
                            if (
                                !this.$v.step5.grupos_b.$invalid &&
                                !this.$v.step5.telhado.$invalid &&
                                !this.$v.step5.p_modulo.$invalid &&
                                !this.$v.step5.categoria_atendimento.$invalid &&
                                !this.$v.step5.tensao.$invalid &&
                                !this.$v.step5.concessionaria.$invalid &&
                                !this.$v.troca_titularidade.$invalid
                            ) {
                                this.$v.$reset();
                                success = true;
                            } else {
                                this.pushNotification('Existem campos obrigatórios', 'error', 2500);
                            }
                            break;
                        case 2:
                            if (
                                !this.$v.step5.grupos_a.$invalid &&
                                !this.$v.step5.demanda_contratada.$invalid &&
                                !this.$v.step5.tarifa_demanda.$invalid &&
                                !this.$v.step5.demanda_maxima.$invalid &&
                                !this.$v.step5.telhado.$invalid &&
                                !this.$v.step5.p_modulo.$invalid &&
                                !this.$v.step5.categoria_atendimento.$invalid &&
                                !this.$v.step5.concessionaria.$invalid &&
                                !this.$v.troca_titularidade.$invalid &&
                                !this.$v.step5.tensao.$invalid
                            ) {
                                this.$v.$reset();
                                success = true;
                            } else {
                                this.pushNotification('Existem campos obrigatórios', 'error', 2500);
                            }
                            break;
                        case 3:
                            if (!this.$v.step5.grupo_c.$invalid) {
                                this.$v.$reset();
                                this.steps.s5 = true;
                                success = true;
                            } else {
                                this.steps.s5 = false;
                            }
                            break;
                        case 4:
                            if (!this.$v.step5.especial.$invalid) {
                                this.$v.$reset();
                                this.steps.s5 = true;
                                success = true;
                            } else {
                                this.steps.s5 = false;
                            }
                            break;
                    }
                } else {
                    this.pushNotification('Existem campos obrigatórios', 'error', 2500);
                }
            } else {
                if (!this.$v.step4.$invalid) {
                    this.$v.$reset();
                    success = true;
                }
            }
            if (success === true) {
                this.loading_message = 'Atualizando proposta, por favor, aguarde...';
                this.loading_dialog = true;
                try {
                    await this.$http.put('proposta/' + this.id, this.$data).then(resp => {
                        this.loading_dialog = false;
                        if (resp.data.error) {
                            this.$toast.open({
                                message: resp.data.message,
                                type: "error",
                                duration: 2500,
                            });
                        } else {
                            this.$toast.open({
                                message: 'Proposta atualizada com sucesso!',
                                type: "success",
                                duration: 2500,
                            });
                            this.$router.push('/');
                        }
                    }).catch(function (error) {
                        throw error;
                    });
                } catch (error) {
                    this.loading_dialog = false;
                    this.pushNotification('Ops, não foi possível concluir a atualização da proposta, por favor, tente novamente!', 'error', 10000);
                }
            }
        },

        async getElements() {
            await this.$http.get('proposta/' + this.id + '/edit').then(resp => {
                if (!resp.data.error) {
                    this.elements = resp.data.message[0];
                } else {
                    this.pushNotification(resp.data.message, 'error', 2500);
                    this.$router.push('/');
                }
            });
        },

        async getDeal() {
            await this.$http.get('proposta/' + this.id + '/edit').then(resp => {
                if (!resp.data.error) {
                    this.deal = resp.data.message[1];
                    this.elements = resp.data.message[0];
                    this.consumo = true;
                } else {
                    this.pushNotification(resp.data.message, 'error', 2500);
                    this.$router.push('/');
                }
                if (this.deal.STAGE_ID === 'C8:19') {
                    this.novo_endereco = true;
                }
            });
        },

        getIndex(property) {
            if (property === undefined) {
                return ''
            } else {
                let index = [];
                for (let i in property) {
                    index.push(i);
                }
                index.sort(function (a, b) {
                    return a === b ? 0 : (a > b ? 1 : -1);
                });
                return property[index[0]];
            }
        },
    },

    computed: {
        cepInstalacaoErrors() {
            const errors = []
            if (!this.$v.step4.cep_instalacao.$dirty) return errors
            !this.$v.step4.cep_instalacao.required && errors.push('CEP é obrigatório.')
            !this.$v.step4.cep_instalacao.validarCep && errors.push('CEP precisa ser válido.')
            return errors
        },
        logradouroInstalacaoErrors() {
            const errors = []
            if (!this.$v.step4.logradouro_instalacao.$dirty) return errors
            !this.$v.step4.logradouro_instalacao.required && errors.push('Logradouro é obrigatório.')
            return errors
        },
        numeroInstalacaoErrors() {
            const errors = []
            if (!this.$v.step4.numero_instalacao.$dirty) return errors
            !this.$v.step4.numero_instalacao.required && errors.push('Número é obrigatório.')
            return errors
        },
        bairroInstalacaoErrors() {
            const errors = []
            if (!this.$v.step4.bairro_instalacao.$dirty) return errors
            !this.$v.step4.bairro_instalacao.required && errors.push('Bairro é obrigatório.')
            return errors
        },
        complementoInstalacaoErrors() {
            const errors = []
            if (!this.$v.step4.complemento_instalacao.$dirty) return errors
            !this.$v.step4.complemento_instalacao.required && errors.push('Complemento é obrigatório.')
            return errors
        },
        cidadeInstalacaoErrors() {
            const errors = []
            if (!this.$v.step4.cidade_instalacao.$dirty) return errors
            !this.$v.step4.cidade_instalacao.required && errors.push('Cidade é obrigatório.')
            return errors
        },
        estadoInstalacaoErrors() {
            const errors = []
            if (!this.$v.step4.estado_instalacao.$dirty) return errors;
            !this.$v.step4.estado_instalacao.required && errors.push('Estado é obrigatório.');
            !this.$v.step4.estado_instalacao.validaCidade;
            return errors
        },
        concessionariaErrors() {
            const errors = []
            if (!this.$v.step5.concessionaria.$dirty) return errors
            !this.$v.step5.concessionaria.required && errors.push('Obrigatório.')
            return errors
        },
    },

    validations: {
        troca_titularidade: {required},
        modalidade_consumo_geracao: {required},
        a_para_b: {required},

        step4: {
            cep_instalacao: {
                required: requiredIf(function (step4) {
                    return !step4.rural_instalacao;
                }),
                validarCep
            },
            logradouro_instalacao: {required},
            numero_instalacao: {required},
            bairro_instalacao: {required},
            complemento_instalacao: {required},
            estado_instalacao: {required},
            cidade_instalacao: {required},
        },
        step5: {
            concessionaria: {required},

            categoria_atendimento: {required},
            tensao: {required},
            p_modulo: {required},
            telhado: {required},

            tarifa_demanda: {required},
            demanda_maxima: {required},
            demanda_contratada: {required},

            grupos_b: {
                $each: {
                    unidade_consumidora: {required},
                    tarifa_anual: {required: validarReal},
                    consumo_anual: {
                        Media: {
                            required: function (value) {
                                return validaMedia(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                        Janeiro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                        Fevereiro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                        Marco: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                        Abril: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                        Maio: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                        Junho: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                        Julho: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                        Agosto: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                        Setembro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                        Outubro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                        Novembro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                        Dezembro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_b[0].simplificado);
                            }
                        },
                    },
                },
            },

            grupos_a: {
                $each: {
                    unidade_consumidora: {required},
                    valor_tarifa_ponta: {required: validarReal},
                    valor_tarifa_fora: {required: validarReal},
                    consumo_ponta: {
                        Media: {
                            required: function (value) {
                                return validaMedia(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Janeiro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Fevereiro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Marco: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Abril: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Maio: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Junho: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Julho: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Agosto: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Setembro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Outubro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Novembro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Dezembro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                    },
                    consumo_fora: {
                        Media: {
                            required: function (value) {
                                return validaMedia(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Janeiro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Fevereiro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Marco: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Abril: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Maio: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Junho: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Julho: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Agosto: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Setembro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Outubro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Novembro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                        Dezembro: {
                            required: function (value) {
                                return validaMes(value, this.step5.grupos_a[0].simplificado);
                            }
                        },
                    },
                },
            },

            grupo_c: {
                telhado: {required}
            },

            especial: {
                ambiente_livre: {required},
                anexo_fatura: {required},
                consumo_total_parcial: {required}
            }
        },
    },
}
</script>
