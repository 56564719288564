/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import App from "./components/App";

require('./bootstrap');

window.Vue = require('vue');

import '../plugins/vuelidate';
import '../plugins/v-money';
import '../plugins/vue-the-mask';
import '../plugins/di-vue-mask';
import '../plugins/axios';
import '../plugins/VueToast';
import './components/GlobalMixin';
import vuetify from "../plugins/vuetify";
import router from "./router";


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
// Vue.component('proposta-form', require('./components/PropostaForm.vue').default);
// Vue.component('dashboard', require('./components/Dashboard.vue').default);
// // Vue.component('notifications', require('./components/Notifications/Notifications.vue').default);
// Vue.component('app', require('./components/App.vue').default);
// Vue.component('detalhes', require('./components/Dialogs/Detalhes').default);
// Vue.component('aceite-cliente', require('./components/Dialogs/AceiteCliente').default);
// Vue.component('readequacao', require('./components/Dialogs/Readequacao').default);
// Vue.component('desconto', require('./components/Dialogs/Desconto').default);
// Vue.component('ListaKanban', require('./components/Dialogs/ListaKanban').default);
// // Vue.component('SnackFications', require('./components/Notifications/SnackFications').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

new Vue({
    el: "#app",
    router, vuetify,
});
