<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.mobile" @keydown.esc="setDialog(false)" @click:outside="setDialog(false)"
              v-model.lazy="dialog" max-width="600">
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-overlay :value="loading_dialog">
            <v-dialog v-model.lazy="loading_dialog" hide-overlay persistent width="400">
                <v-card color="primary" dark>
                    <v-card-text class="pt-2">
                        {{ loading_message }}
                        <v-progress-linear indeterminate color="white" class="mt-6 mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-overlay>
        <!--Dialog ---------------------------------------------------------------------------------------------------->

        <v-card class="pl-4 pr-4">
            <v-card-title class="headline">Solicitação de desconto</v-card-title>
            <v-card-text>
                <v-row no-gutters justify="center">
                    <v-radio-group v-model.lazy="radio" row>
                        <v-radio
                            label="Em Real"
                            value="real"
                        ></v-radio>
                        <v-radio
                            label="Em Porcentagem"
                            value="porcentagem"
                        ></v-radio>
                    </v-radio-group>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="4" class="ma-2">
                        <v-text-field
                            v-if="radio === 'porcentagem'"
                            label="Valor"
                            append-icon="mdi-money"
                            outlined v-money="porcentagem"
                            v-model.lazy="$v.desconto_solicitado.$model"
                            @blur="$v.desconto_solicitado.$touch()"
                            :error-messages="itemErrors($v.desconto_solicitado)"
                        ></v-text-field>
                        <v-text-field
                            v-if="radio === 'real'"
                            label="Valor"
                            append-icon="mdi-money"
                            outlined v-money="money"
                            v-model.lazy="$v.desconto_solicitado.$model"
                            @blur="$v.desconto_solicitado.$touch()"
                            :error-messages="itemErrors($v.desconto_solicitado)"
                        ></v-text-field>
                    </v-col>
                    <v-col class="ma-2">
                        <v-file-input
                            label="Proposta da concorrência"
                            outlined
                            prepend-icon=""
                            append-icon="mdi-paperclip"
                            multiple
                            @change="updateAnexos"
                        ></v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="red darken-1" text @click="setDialog(false)">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="submit">Enviar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {required} from "vuelidate/lib/validators";

export default {
    name: "Desconto",

    props: {
        desconto: {
            type: Boolean,
            required: true
        },

        id: {
            required: true
        }
    },

    data() {
        return {
            dialog: false,
            loading_dialog: null,
            loading_message: null,
            radio: 'porcentagem',
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: true
            },
            porcentagem: {
                decimal: '',
                thousands: '',
                prefix: '',
                suffix: '%',
                precision: 0,
                masked: true
            },

            desconto_solicitado: null,
            anexo_proposta_concorrencia: [],
        }
    },

    watch: {
        desconto: function () {
            this.dialog = this.desconto;
        }
    },

    methods: {
        setLoading(state, message) {
            this.loading_dialog = state;
            this.loading_message = message;
        },

        async submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                await this.setLoading(true, 'Um momento...');
                await this.$http.post('proposta/solicitar_desconto', [this.$data, this.id]).then(resp => {
                    this.setDialog(false);
                    this.setLoading(false, '');
                    this.pushNotification('Desconto solicitado com sucesso', 'error', 5000);
                    document.location.reload(true);
                }).catch(error => {
                    this.setDialog(false);
                    this.setLoading(false, '');
                    this.pushNotification(error.response.data, 'error', 7500);
                });
            }
        },

        itemErrors(item) {
            const errors = []
            if (!item.$dirty) return errors
            !item.required && errors.push('Obrigatório.')
            return errors
        },

        async updateAnexos(e) {
            if (e) {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = error => reject(error);
                });

                let files = e;
                this.anexo_proposta_concorrencia = [];
                for (let i = 0; i < files.length; i++) {
                    this.anexo_proposta_concorrencia.push({
                        'fileData': [files[i].name, await toBase64(files[i])]
                    });
                }
            }
        },

        setDialog(state) {
            this.dialog = state;
            this.$emit("update-dialog", state);
        },
    },

    mounted() {
        this.dialog = this.desconto;
    },

    validations: {
        desconto_solicitado: {required},
    },
}
</script>
