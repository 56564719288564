<template>
    <v-app style="background-color: #f8fafc !important;">
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-overlay :value="loading_dialog">
            <v-dialog v-model.lazy="loading_dialog" hide-overlay persistent width="400">
                <v-card color="primary" dark>
                    <v-card-text class="pt-2">
                        {{ loading_message }}
                        <v-progress-linear indeterminate color="white" class="mt-6 mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-overlay>
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-sheet outlined flat class="ma-0" color="#F8FAFC" height="auto">
            <v-sheet ref="form" flat height="auto" outlined>
                <v-tabs class="flat-tab" fixed-tabs grow background-color="primary">
                    <v-tab>
                        <v-icon left>mdi-flash</v-icon>
                        Revisão Técnica - {{ this.deal.TITLE }}
                    </v-tab>
                    <v-tab-item>
                        <v-card flat>
                            <v-container>
                                <v-layout justify-center>
                                    <v-form ref="form" class="text-xs-center">
                                        <v-row no-gutters justify="start">
                                            <h5>Alterar Status</h5>
                                        </v-row>
                                        <v-row no-gutters class="ml-12 mr-12">
                                            <v-col class="mr-1 ml-1">
                                                <v-select
                                                    label="Tensão de alimentação"
                                                    v-model.lazy="tensao"
                                                    :items="lista_tensao"
                                                    menu-props="auto"
                                                    append-icon="mdi-power-socket-fr"
                                                    clearable
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row no-gutters justify="start">
                                            <h5>Características do Gerador Fotovoltaico</h5>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col class="mr-1 ml-1">
                                                <v-text-field
                                                    label="Produção Anual - Solergo"
                                                    v-money="kwh_ano"
                                                    v-model.lazy="producao_anual"
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-text-field>
                                                <v-select
                                                    label="Será necessário reforço na estrutura?"
                                                    v-model.lazy="$v.reforco_estrutura.$model"
                                                    :items="lista_reforco_estrutura"
                                                    menu-props="auto"
                                                    append-icon="mdi-shield-sun-outline"
                                                    clearable
                                                    @blur="$v.reforco_estrutura.$touch()"
                                                    :error-messages="itemErrors($v.reforco_estrutura)"
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-select>
                                                <v-file-input
                                                    append-icon="mdi-paperclip"
                                                    prepend-icon=""
                                                    class="mt-2"
                                                    label="Relatório de Sombreamento"
                                                    outlined dense
                                                    @change="updateAnexos"
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-file-input>
                                            </v-col>
                                            <v-col class="mr-1 ml-1">
                                                <v-select
                                                    label="Monitoramento"
                                                    v-model.lazy="monitoramento"
                                                    :items="['SolarView', 'Gateway IoT WEG', 'SmartLogger3000A']"
                                                    menu-props="auto"
                                                    append-icon="mdi-radio-tower"
                                                    clearable
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-select>
                                                <v-select
                                                    v-if="username.tipo_contato === '7427' || username.tipo_contato === '7429'"
                                                    label="Estrutura de Fixação"
                                                    v-model.lazy="telhados_adm"
                                                    :items="telhados"
                                                    menu-props="auto"
                                                    append-icon="mdi-home"
                                                    class="mb-n3"
                                                    clearable
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-select>
                                                <v-select
                                                    v-else
                                                    label="Estrutura de Fixação"
                                                    v-model.lazy="telhado"
                                                    :items="telhados"
                                                    menu-props="auto"
                                                    append-icon="mdi-home"
                                                    class="mb-n3"
                                                    clearable
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-select>
                                                <v-row>
                                                    <v-col>
                                                        <v-select
                                                            label="Posto de Transformação ou Solicitação de disponibilidade?"
                                                            hint="Existe a necessidade de posto de Transformação ou solicitação de disponibilidade?"
                                                            v-model.lazy="$v.posto_transformacao.$model"
                                                            :items="lista_posto_transformacao"
                                                            menu-props="auto"
                                                            append-icon="mdi-transmission-tower"
                                                            @blur="$v.posto_transformacao.$touch()"
                                                            :error-messages="itemErrors($v.posto_transformacao)"
                                                            :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                        ></v-select>
                                                    </v-col>
                                                    <v-col>
                                                        <v-select
                                                            label="Necessidade de estudo de estrutura?"
                                                            hint="Existe a necessidade de estudo de estrutura?"
                                                            v-model.lazy="$v.estudo_estrutura.$model"
                                                            :items="lista_estudo_estrutura"
                                                            menu-props="auto"
                                                            append-icon="mdi-book-open-page-variant"
                                                            @blur="$v.estudo_estrutura.$touch()"
                                                            :error-messages="itemErrors($v.estudo_estrutura)"
                                                            :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                        ></v-select>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                        <v-row no-gutters justify="start">
                                            <h5>Dados Técnicos dos Módulos</h5>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col class="mr-1 ml-1">
                                                <v-select
                                                    label="Modelo"
                                                    v-model.lazy="modulo_modelo"
                                                    :items="modelos_modulos_adm_only"
                                                    menu-props="auto"
                                                    append-icon="mdi-solar-power"
                                                    clearable
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-select>
                                            </v-col>
                                            <v-col class="mr-1 ml-1">
                                                <v-text-field
                                                    label="Número de Módulos"
                                                    v-model.lazy="p_modulo_num"
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-divider/>
                                        <v-row no-gutters justify="start">
                                            <h5>Dados Técnicos dos Inversores</h5>
                                        </v-row>
                                        <v-row justify="center" align="center" no-gutters>
                                            <v-col align="start" cols="2" class="mr-1 ml-1">
                                                <p>Inversor 1</p>
                                            </v-col>
                                            <v-col class="mr-1 ml-1">
                                                <v-select
                                                    label="Modelo"
                                                    v-model.lazy="inversor_1_modelo"
                                                    :items="modelos_inversores_adm_only"
                                                    menu-props="auto"
                                                    append-icon="mdi-solar-power"
                                                    clearable
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="2" class="mr-1 ml-1">
                                                <v-text-field
                                                    label="Quantidade"
                                                    v-model.lazy="inversor_1_qntd"
                                                    :disabled="!inversor_1_modelo || this.deal.STAGE_ID === 'C8:15'"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2" class="mr-1 ml-1">
                                                <v-text-field
                                                    label="Potência"
                                                    disabled
                                                    v-model.lazy="inversor_1_potencia"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center" no-gutters>
                                            <v-col align="start" cols="2" class="mr-1 ml-1">
                                                <p>Inversor 2</p>
                                            </v-col>
                                            <v-col class="mr-1 ml-1">
                                                <v-select
                                                    label="Modelo"
                                                    v-model.lazy="inversor_2_modelo"
                                                    :items="modelos_inversores_adm_only"
                                                    menu-props="auto"
                                                    append-icon="mdi-solar-power"
                                                    clearable
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="2" class="mr-1 ml-1">
                                                <v-text-field
                                                    label="Quantidade"
                                                    v-model.lazy="inversor_2_qntd"
                                                    :disabled="!inversor_2_modelo || this.deal.STAGE_ID === 'C8:15'"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2" class="mr-1 ml-1">
                                                <v-text-field
                                                    label="Potência"
                                                    disabled
                                                    v-model.lazy="inversor_2_potencia || this.deal.STAGE_ID === 'C8:15'"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" align="center" no-gutters>
                                            <v-col align="start" cols="2" class="mr-1 ml-1">
                                                <p>Inversor 3</p>
                                            </v-col>
                                            <v-col class="mr-1 ml-1">
                                                <v-select
                                                    label="Modelo"
                                                    v-model.lazy="inversor_3_modelo"
                                                    :items="modelos_inversores_adm_only"
                                                    menu-props="auto"
                                                    append-icon="mdi-solar-power"
                                                    clearable
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-select>
                                            </v-col>
                                            <v-col cols="2" class="mr-1 ml-1">
                                                <v-text-field
                                                    label="Quantidade"
                                                    v-model.lazy="inversor_3_qntd"
                                                    :disabled="!inversor_3_modelo || this.deal.STAGE_ID === 'C8:15'"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2" class="mr-1 ml-1">
                                                <v-text-field
                                                    label="Potência"
                                                    disabled
                                                    v-model.lazy="inversor_3_potencia || this.deal.STAGE_ID === 'C8:15'"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="ml-10 mr-10">
                                                <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                <v-textarea
                                                    label="Observações pós visita técnica"
                                                    v-model.lazy="comentario_pos_visita"
                                                    outlined
                                                    :disabled="this.deal.STAGE_ID === 'C8:15'"
                                                ></v-textarea>
                                                <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                            </v-col>
                                        </v-row>
                                        <v-divider/>
                                        <custos-adicionais
                                            :disabled="revisao"
                                            :data="setCustosAdicionais"
                                            @getData="getCustosAdicionais"
                                            @invalid="setCustosAdicionaisInvalid">
                                        </custos-adicionais>
                                        <v-divider/>
                                        <v-row>
                                            <v-col class="ml-10 mr-10">
                                                <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                <v-textarea
                                                    label="Observações sobre a proposta"
                                                    v-model.lazy="observacoes"
                                                    outlined
                                                    :disabled="revisao"
                                                ></v-textarea>
                                                <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                            </v-col>
                                        </v-row>
                                        <!----------------------------------------------------------------------------->
                                    </v-form>
                                </v-layout>
                            </v-container>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-sheet>
        </v-sheet>
        <v-col v-if="this.deal.STAGE_ID !== 'C8:15'">
            <v-row justify="end" align="end">
                <v-col class="d-flex ma-2">
                    <v-btn class="ml-auto mr-2" color="orange" dark @click="reset">Limpar formulário</v-btn>
                    <v-btn color="primary" @click="submit">Enviar</v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-app>
</template>

<script>
import {required, requiredIf} from 'vuelidate/lib/validators';
import {
    padroes_entrada,
    transformadores,
    subestacoes,
    cabines,
    m_inversor,
    categorias,
    lista_tensao,
    p_modulos,
    telhados,
    responsabilidades_cabine,
    responsabilidades_extensao,
    responsabilidades_infraestrutura,
    responsabilidades_padrao_entrada,
    responsabilidades_subestacao,
    responsabilidades_transformador,
    lista_estudo_estrutura,
    lista_posto_transformacao,
    lista_reforco_estrutura,
    lista_troca_titularidade, lista_a_para_b, lista_cadastro_compensacao, telhados_adm,
} from '../data/SelectData';
import CustosAdicionais from "./CustosAdicionais";

export default {
    name: "RevisaoTecnica",
    components: {CustosAdicionais},
    props: ['username', 'id'],

    data() {
        return {
            deal: [],
            loading_message: null,
            loading_dialog: null,
            revisao: false,
            //==========================================================================================================
            lista_posto_transformacao: lista_posto_transformacao,
            lista_estudo_estrutura: lista_estudo_estrutura,
            padroes_entrada: padroes_entrada,
            transformadores: transformadores,
            subestacoes: subestacoes,
            cabines: cabines,
            m_inversor: m_inversor,
            categorias: categorias,
            lista_tensao: lista_tensao,
            p_modulos: p_modulos,
            telhados: telhados,
            telhados_adm: telhados_adm,
            responsabilidades_cabine: responsabilidades_cabine,
            responsabilidades_extensao: responsabilidades_extensao,
            responsabilidades_infraestrutura: responsabilidades_infraestrutura,
            responsabilidades_padrao_entrada: responsabilidades_padrao_entrada,
            responsabilidades_subestacao: responsabilidades_subestacao,
            responsabilidades_transformador: responsabilidades_transformador,
            lista_reforco_estrutura: lista_reforco_estrutura,
            lista_troca_titularidade: lista_troca_titularidade,
            lista_a_para_b: lista_a_para_b,
            lista_cadastro_compensacao: lista_cadastro_compensacao,
            //==========================================================================================================
            money: null,
            kwh_ano: null,
            // END - Settings ==========================================================================================
            tensao: null,
            modulo_modelo: null, p_modulo_num: null,
            telhado: null,
            producao_anual: null,
            reforco_estrutura: null,

            inversor_1_modelo: null, inversor_1_qntd: null, inversor_1_potencia: null,
            inversor_2_modelo: null, inversor_2_qntd: null, inversor_2_potencia: null,
            inversor_3_modelo: null, inversor_3_qntd: null, inversor_3_potencia: null,

            comentario_pos_visita: null,
            observacoes: null,

            monitoramento: null,
            relatorio_anexo: [],
            posto_transformacao: null,
            estudo_estrutura: null,
            //==========================================================================================================
        }
    },

    async created() {
        await this.getInversoresModulos();
        this.setLoading(true, 'Carregando...')
        await this.setDeal();
        if (await this.checkPermissao()) {
            await this.checkFase();
            await this.setFormData();
            await this.setMasks();
            this.setLoading(false, '');
        } else {
            this.setLoading(false, '');
            await this.$router.push('/');
        }
    },

    methods: {
        setLoading(state, message) {
            this.loading_dialog = state;
            this.loading_message = message;
        },

        toastNotificate(message, type, duration) {
            return this.$toast.open({
                message: message,
                type: type,
                duration: duration,
            });
        },

        async checkPermissao() {
            if (this.username.bitrix_id === this.deal['ASSIGNED_BY_ID'] || this.username.tipo_contato === '7429' || this.username.tipo_contato === '7427') {
                return true;
            } else {
                this.toastNotificate('Você não tem permissão para acessar esta página!', 'error', 2500)
                return false;
            }
        },

        async checkFase() {
            if (this.deal['STAGE_ID'] === 'C8:1' || this.deal['STAGE_ID'] === 'C8:FINAL_INVOICE' || this.deal['STAGE_ID'] === 'C8:26') {
                return this.toastNotificate('Atenção! Altere apenas o necessário...', 'warning', 10000)
            } else if (this.deal['STAGE_ID'] === 'C8:15') {
                this.revisao = true;
                return this.toastNotificate('Após revisão, continue o aceite pelo CRM.', 'info', 10000)
            } else {
                this.toastNotificate('A proposta ainda não pode ser readequada...', 'error', 2500)
                this.loading_dialog = false;
                return this.$router.push('/');
            }
        },

        setMasks() {
            this.kwh_ano = {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' kWh/ano',
                precision: 2,
                masked: false
            };
            this.money = {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            };
        }
        ,

        async setDeal() {
            await this.$http.get('proposta/' + this.id + '/edit').then(resp => {
                if (!resp.data.error) {
                    this.deal = resp.data.message[1];
                } else {
                    this.pushNotification(resp.data.message, 'error', 2500);
                    return this.$router.push('/');
                }
            });
        },

        setFormData() {
            this.observacoes = this.deal.UF_CRM_1600354174;

            this.tensao = this.deal.UF_CRM_1572643635176;
            this.producao_anual = this.deal.UF_CRM_1572532923059;

            this.reforco_estrutura = this.deal.UF_CRM_1598650125278;
            this.posto_transformacao = this.deal.UF_CRM_1557498879150;
            this.estudo_estrutura = this.deal.UF_CRM_1591820049394;

            this.monitoramento = this.deal.UF_CRM_1598620560;
            this.telhado = this.deal.UF_CRM_1594641266;
            this.modulo_modelo = this.deal.UF_CRM_1594641325;
            this.p_modulo_num = this.deal.UF_CRM_1594641197;
            if (this.deal.UF_CRM_1599225522 !== '') {
                this.inversor_1_modelo = this.deal.UF_CRM_1599225522;
                this.inversor_1_qntd = this.deal.UF_CRM_1599225542;
            }
            if (this.deal.UF_CRM_1599225621 !== '') {
                this.inversor_2_modelo = this.deal.UF_CRM_1599225621;
                this.inversor_2_qntd = this.deal.UF_CRM_1599225646;
            }
            if (this.deal.UF_CRM_1599225711 !== '') {
                this.inversor_3_modelo = this.deal.UF_CRM_1599225711;
                this.inversor_3_qntd = this.deal.UF_CRM_1599225739;
            }
            this.comentario_pos_visita = this.deal.UF_CRM_1599030965;
        },

        async reset() {
            await this.$refs.form.reset();
            this.$v.$reset();
        },

        atribuirPotencia(inversor, qntd) {
            for (const modulo of this.inversores) {
                if (inversor === modulo.modelo) {
                    return qntd * modulo.potencia;
                }
            }
            return 0;
        },

        async updateAnexos(e) {
            if (e) {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = error => reject(error);
                });

                let files = e;
                this.relatorio_anexo = [];
                for (let i = 0; i < files.length; i++) {
                    this.relatorio_anexo.push({
                        'fileData': [files[i].name, await toBase64(files[i])]
                    });
                }
            }
        },

        async submit() {
            this.$v.$touch();
            if (this.$v.$invalid || this.custos_adicionais_invalid) {
                return this.$toast.open({
                    message: 'Existem campos obrigatórios...',
                    type: "error",
                    duration: 5000,
                });
            } else {
                await this.setLoading(true, 'Cadastrando readequação, por favor, aguarde...');
                await this.$http.post('/proposta/revisao_tecnica/' + this.id, [this.$data, this.custos_adicionais]).then(resp => {
                    this.setLoading(false, '');
                    this.pushNotification('Revisão técnica enviada com sucesso', 'success', 5000);
                    this.$router.push('/');
                }).catch(error => {
                    this.setDialog(false);
                    this.setLoading(false, '');
                    this.pushNotification(error.response.data, 'error', 7500);
                });
            }
        },

        itemErrors(item) {
            const errors = []
            if (!item.$dirty) return errors
            !item.required && errors.push('Obrigatório.')
            return errors
        },
    },

    watch: {
        //==============================================================================================================
        'inversor_1_modelo': function () {
            if (this.inversor_1_qntd > 0 && this.inversor_1_modelo !== undefined) {
                this.inversor_1_potencia = this.atribuirPotencia(this.inversor_1_modelo, this.inversor_1_qntd);
            } else if (this.inversor_1_qntd <= 0 && this.inversor_1_modelo !== undefined) {
                this.inversor_1_qntd = 1;
                this.inversor_1_potencia = this.atribuirPotencia(this.inversor_1_modelo, this.inversor_1_qntd);
            } else {
                this.inversor_1_modelo = this.inversor_1_qntd = this.inversor_1_potencia = null;
            }
        },
        'inversor_2_modelo': function () {
            if (this.inversor_2_qntd > 0 && this.inversor_2_modelo !== undefined) {
                this.inversor_2_potencia = this.atribuirPotencia(this.inversor_2_modelo, this.inversor_2_qntd);
            } else if (this.inversor_2_qntd <= 0 && this.inversor_2_modelo !== undefined) {
                this.inversor_2_qntd = 1;
                this.inversor_2_potencia = this.atribuirPotencia(this.inversor_2_modelo, this.inversor_2_qntd);
            } else {
                this.inversor_2_modelo = this.inversor_2_qntd = this.inversor_2_potencia = null;
            }
        },
        'inversor_3_modelo': function () {
            if (this.inversor_3_qntd > 0 && this.inversor_3_modelo !== undefined) {
                this.inversor_3_potencia = this.atribuirPotencia(this.inversor_3_modelo, this.inversor_3_qntd);
            } else if (this.inversor_3_qntd <= 0 && this.inversor_3_modelo !== undefined) {
                this.inversor_3_qntd = 1;
                this.inversor_3_potencia = this.atribuirPotencia(this.inversor_3_modelo, this.inversor_3_qntd);
            } else {
                this.inversor_3_modelo = this.inversor_3_qntd = this.inversor_3_potencia = null;
            }
        },
        //==============================================================================================================
        'inversor_1_qntd': function () {
            if (this.inversor_1_qntd > 0) {
                this.inversor_1_potencia = this.atribuirPotencia(this.inversor_1_modelo, this.inversor_1_qntd);
            } else if (this.inversor_1_qntd <= 0 && this.inversor_1_modelo !== undefined) {
                this.inversor_1_qntd = 1;
                this.inversor_1_potencia = this.atribuirPotencia(this.inversor_1_modelo, this.inversor_1_qntd);
            }
        },

        'inversor_2_qntd': function () {
            if (this.inversor_2_qntd > 0) {
                this.inversor_2_potencia = this.atribuirPotencia(this.inversor_2_modelo, this.inversor_2_qntd);
            } else if (this.inversor_2_qntd <= 0 && this.inversor_2_modelo !== undefined) {
                this.inversor_2_qntd = 1;
                this.inversor_2_potencia = this.atribuirPotencia(this.inversor_2_modelo, this.inversor_2_qntd);
            }
        },

        'inversor_3_qntd': function () {
            if (this.inversor_3_qntd > 0) {
                this.inversor_3_potencia = this.atribuirPotencia(this.inversor_3_modelo, this.inversor_3_qntd);
            } else if (this.inversor_3_qntd <= 0 && this.inversor_3_modelo !== undefined) {
                this.inversor_3_qntd = 1;
                this.inversor_3_potencia = this.atribuirPotencia(this.inversor_3_modelo, this.inversor_3_qntd);
            }
        },
    },

    validations: {
        reforco_estrutura: {required},
        posto_transformacao: {required},
        estudo_estrutura: {required},
    },
}
</script>

<style scoped>
.my-text-style >>> .v-text-field__slot input {
    color: #385d7a;
}
</style>
