<template>
    <v-container>
        <v-row no-gutters justify="start">
            <h5>Custos Adicionais</h5>
        </v-row>
        <v-row no-gutters>
            <v-col cols="4" class="mr-8">
                <v-text-field
                    :disabled="disabled"
                    label="Cabo AC/DC e Conectores (R$)"
                    v-money="money"
                    v-model.lazy="cabo"
                ></v-text-field>
            </v-col>
            <v-col cols="2" class="ml-8">
                <v-select
                    label="Responsabilidade"
                    v-model.lazy="$v.responsabilidade_cabine.$model"
                    :items="responsabilidades_cabine"
                    menu-props="auto"
                    @blur="$v.responsabilidade_cabine.$touch()"
                    :error-messages="itemErrors($v.responsabilidade_cabine)"
                    :disabled="!cabine || disabled"
                ></v-select>
            </v-col>
            <v-col class="ml-1">
                <v-select
                    :disabled="disabled"
                    label="Cabine de Medição"
                    v-model.lazy="cabine"
                    :items="cabines"
                    menu-props="auto"
                    clearable
                ></v-select>
            </v-col>
            <v-col cols="1" class="mr-1 ml-1">
                <v-text-field
                    label="Qtd (Und)"
                    v-model.lazy="$v.qntd_cabine.$model"
                    @blur="$v.qntd_cabine.$touch()"
                    :error-messages="itemErrors($v.qntd_cabine)"
                    :disabled="!cabine || disabled"
                ></v-text-field>
            </v-col>
        </v-row>
        <!----------------------------------------------------------------------------->
        <v-row no-gutters>
            <v-col cols="4" class="mr-8">
                <v-text-field
                    :disabled="disabled"
                    label="Serviço e Materiais Gerais (R$)"
                    v-money="money"
                    v-model.lazy="servico"
                ></v-text-field>
            </v-col>
            <v-col cols="2" class="ml-8">
                <v-select
                    label="Responsabilidade"
                    :items="responsabilidades_subestacao"
                    menu-props="auto"
                    v-model.lazy="$v.responsabilidade_subestacao_1.$model"
                    @blur="$v.responsabilidade_subestacao_1.$touch()"
                    :error-messages="itemErrors($v.responsabilidade_subestacao_1)"
                    :disabled="(!subestacao_1 && !subestacao_2) || disabled"
                ></v-select>
            </v-col>
            <v-col class="ml-1">
                <v-select
                    :disabled="disabled"
                    label="Subestação 1"
                    v-model.lazy="subestacao_1"
                    :items="subestacoes"
                    menu-props="auto"
                    clearable
                ></v-select>
                <v-select
                    :disabled="disabled"
                    label="Subestação 2"
                    v-model.lazy="subestacao_2"
                    :items="subestacoes"
                    menu-props="auto"
                    clearable
                ></v-select>
            </v-col>
            <v-col cols="1" class="mr-1 ml-1">
                <v-text-field
                    label="Qtd (Und) 1"
                    v-model.lazy="$v.qntd_subestacao_1.$model"
                    @blur="$v.qntd_subestacao_1.$touch()"
                    :error-messages="itemErrors($v.qntd_subestacao_1)"
                    :disabled="!subestacao_1 || disabled"
                ></v-text-field>
                <v-text-field
                    label="Qtd (Und) 2"
                    v-model.lazy="$v.qntd_subestacao_2.$model"
                    @blur="$v.qntd_subestacao_2.$touch()"
                    :error-messages="itemErrors($v.qntd_subestacao_2)"
                    :disabled="!subestacao_2 || disabled"
                ></v-text-field>
            </v-col>
        </v-row>
        <!----------------------------------------------------------------------------->
        <v-row no-gutters>
            <v-col cols="2" class="ml-1 mr-1">
                <v-select
                    label="Responsabilidade"
                    :items="responsabilidades_infraestrutura"
                    menu-props="auto"
                    v-model.lazy="$v.responsabilidade_infraestrutura.$model"
                    @blur="$v.responsabilidade_infraestrutura.$touch()"
                    :error-messages="itemErrors($v.responsabilidade_infraestrutura)"
                    :disabled="infraestrutura === 'R$ 0,00' || disabled"
                ></v-select>
            </v-col>
            <v-col cols="2" class="mr-6 pr-2">
                <v-text-field
                    :disabled="disabled"
                    label="Infraestrutura e Serviços P. Civil (R$)"
                    v-money="money"
                    v-model.lazy="infraestrutura"
                ></v-text-field>
            </v-col>
            <v-col cols="2" class="ml-8">
                <v-select
                    label="Responsabilidade"
                    :items="responsabilidades_transformador"
                    menu-props="auto"
                    v-model.lazy="$v.responsabilidade_transformador_1.$model"
                    @blur="$v.responsabilidade_transformador_1.$touch()"
                    :error-messages="itemErrors($v.responsabilidade_transformador_1)"
                    :disabled="(!transformador_1 && !transformador_2) || disabled"
                ></v-select>
            </v-col>
            <v-col class="ml-1">
                <v-select
                    :disabled="disabled"
                    label="Transformador de Acoplamento 1"
                    v-model.lazy="transformador_1"
                    :items="transformadores"
                    menu-props="auto"
                    clearable
                ></v-select>
                <v-select
                    :disabled="disabled"
                    label="Transformador de Acoplamento 2"
                    v-model.lazy="transformador_2"
                    :items="transformadores"
                    menu-props="auto"
                    clearable
                ></v-select>
            </v-col>
            <v-col cols="1" class="mr-1 ml-1">
                <v-text-field
                    label="Qtd (Und) 1"
                    v-model.lazy="$v.qntd_transformador_1.$model"
                    @blur="$v.qntd_transformador_1.$touch()"
                    :error-messages="itemErrors($v.qntd_transformador_1)"
                    :disabled="!transformador_1 || disabled"
                ></v-text-field>
                <v-text-field
                    label="Qtd (Und) 2"
                    v-model.lazy="$v.qntd_transformador_2.$model"
                    @blur="$v.qntd_transformador_2.$touch()"
                    :error-messages="itemErrors($v.qntd_transformador_2)"
                    :disabled="!transformador_2 || disabled"
                ></v-text-field>
            </v-col>
        </v-row>
        <!----------------------------------------------------------------------------->
        <v-row no-gutters>
            <v-col cols="2" class="ml-1 mr-1">
                <v-select
                    label="Responsabilidade"
                    :items="responsabilidades_extensao"
                    menu-props="auto"
                    v-model.lazy="$v.responsabilidade_extensao.$model"
                    @blur="$v.responsabilidade_extensao.$touch()"
                    :error-messages="itemErrors($v.responsabilidade_extensao)"
                    :disabled="extensao === 'R$ 0,00' || disabled"
                ></v-select>
            </v-col>
            <v-col cols="2" class="mr-6 pr-2">
                <v-text-field
                    :disabled="disabled"
                    label="Extensão de Rede (R$)"
                    v-money="money"
                    v-model.lazy="extensao"
                ></v-text-field>
            </v-col>
            <v-col cols="2" class="ml-8">
                <v-select
                    label="Responsabilidade"
                    :items="responsabilidades_padrao_entrada"
                    menu-props="auto"
                    v-model.lazy="$v.responsabilidade_padrao_entrada.$model"
                    @blur="$v.responsabilidade_padrao_entrada.$touch()"
                    :error-messages="itemErrors($v.responsabilidade_padrao_entrada)"
                    :disabled="!padrao_entrada || disabled"
                ></v-select>
            </v-col>
            <v-col class="ml-1">
                <v-select
                    :disabled="disabled"
                    label="Padrão de Entrada"
                    v-model.lazy="padrao_entrada"
                    :items="padroes_entrada"
                    menu-props="auto"
                    clearable
                ></v-select>
            </v-col>
            <v-col cols="1" class="mr-1 ml-1">
                <v-text-field
                    label="Qtd (Und)"
                    v-model.lazy="$v.qntd_padrao_entrada.$model"
                    @blur="$v.qntd_padrao_entrada.$touch()"
                    :error-messages="itemErrors($v.qntd_padrao_entrada)"
                    :disabled="!padrao_entrada || disabled"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="ml-10 mr-10">
                <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                <v-textarea
                    label="Comentário sobre os custos adicionais"
                    v-model.lazy="comentario_custos"
                    outlined
                    :disabled="disabled"
                ></v-textarea>
                <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";

export default {
    name: "CustosAdicionais",

    props: ['data', 'disabled', 'proposta'],

    data() {
        return {
            index: 0,

            cabo: 'R$ 0,00', qntd_cabo: null,
            servico: 'R$ 0,00', qntd_servico: null,
            infraestrutura: 'R$ 0,00', qntd_infraestrutura: null,
            extensao: 'R$ 0,00', qntd_extensao: null,

            responsabilidade_cabine: '',
            responsabilidade_subestacao_1: '',
            responsabilidade_transformador_1: '',
            responsabilidade_padrao_entrada: '',
            responsabilidade_infraestrutura: '',
            responsabilidade_extensao: '',

            cabine: '', qntd_cabine: '',
            subestacao_1: '', qntd_subestacao_1: '',
            subestacao_2: '', qntd_subestacao_2: '',
            transformador_1: '', qntd_transformador_1: '',
            transformador_2: '', qntd_transformador_2: '',
            padrao_entrada: '', qntd_padrao_entrada: '',
            comentario_custos: '',
        }
    },

    methods: {
        setData(value) {
            this.cabo = value.cabo;
            this.qntd_cabo = value.qntd_cabo;
            this.servico = value.servico;
            this.qntd_servico = value.qntd_servico;
            this.infraestrutura = value.infraestrutura;
            this.qntd_infraestrutura = value.qntd_infraestrutura;
            this.extensao = value.extensao;
            this.qntd_extensao = value.qntd_extensao;

            this.responsabilidade_cabine = value.responsabilidade_cabine;
            this.responsabilidade_subestacao_1 = value.responsabilidade_subestacao_1;
            this.responsabilidade_transformador_1 = value.responsabilidade_transformador_1;
            this.responsabilidade_padrao_entrada = value.responsabilidade_padrao_entrada;
            this.responsabilidade_infraestrutura = value.responsabilidade_infraestrutura;
            this.responsabilidade_extensao = value.responsabilidade_extensao;

            this.cabine = value.cabine;
            this.subestacao_1 = value.subestacao_1;
            this.subestacao_2 = value.subestacao_2;
            this.transformador_1 = value.transformador_1;
            this.transformador_2 = value.transformador_2;
            this.padrao_entrada = value.padrao_entrada;

            this.qntd_cabine = value.qntd_cabine;
            this.qntd_subestacao_1 = value.qntd_subestacao_1;
            this.qntd_subestacao_2 = value.qntd_subestacao_2;
            this.qntd_transformador_1 = value.qntd_transformador_1;
            this.qntd_transformador_2 = value.qntd_transformador_2;
            this.qntd_padrao_entrada = value.qntd_padrao_entrada;

            this.comentario_custos = value.comentario_custos;
        },

        emitData() {
            this.$emit("getData", this.$data);
            this.$emit("invalid", this.$v.$invalid);
        },
    },

    created() {
        if (this.proposta !== true) {
            this.setData(this.data);
        }
    },

    watch: {
        '$data': {
            handler: function () {
                this.emitData();
            },
            deep: true
        },

        //Custos Adicionais ============================================================================================
        'cabine': function () {
            if (!this.cabine) {
                this.cabine = this.responsabilidade_cabine = this.qntd_cabine = '';
            }
        },
        'subestacao_1': function () {
            if (!this.subestacao_1) {
                this.subestacao_1 = this.responsabilidade_subestacao_1 = this.qntd_subestacao_1 = '';
            }
        },
        'subestacao_2': function () {
            if (!this.subestacao_2) {
                this.subestacao_2 = this.responsabilidade_subestacao_1 = this.qntd_subestacao_2 = '';
            }
        },
        'transformador_1': function () {
            if (!this.transformador_1) {
                this.transformador_1 = this.responsabilidade_transformador_1 = this.qntd_transformador_1 = '';
            }
        },
        'transformador_2': function () {
            if (!this.transformador_2) {
                this.transformador_2 = this.responsabilidade_transformador_1 = this.qntd_transformador_2 = '';
            }
        },
        'padrao_entrada': function () {
            if (!this.padrao_entrada) {
                this.padrao_entrada = this.responsabilidade_padrao_entrada = this.qntd_padrao_entrada = '';
            }
        },
        'infraestrutura': function () {
            if (this.infraestrutura === 'R$ 0,00') {
                this.responsabilidade_infraestrutura = this.qntd_infraestrutura = '';
            }
        },
        'extensao': function () {
            if (this.extensao === 'R$ 0,00') {
                this.responsabilidade_extensao = this.qntd_extensao = '';
            }
        },
    },

    validations: {
        responsabilidade_cabine: {
            required: requiredIf(function (object) {
                return object.cabine;
            })
        },
        qntd_cabine: {
            required: requiredIf(function (object) {
                return object.cabine;
            })
        },

        responsabilidade_subestacao_1: {
            required: requiredIf(function (object) {
                return (object.subestacao_1 || object.subestacao_2);
            })
        },
        qntd_subestacao_1: {
            required: requiredIf(function (object) {
                return object.subestacao_1;
            })
        },

        qntd_subestacao_2: {
            required: requiredIf(function (object) {
                return object.subestacao_2;
            })
        },

        responsabilidade_transformador_1: {
            required: requiredIf(function (object) {
                return (object.transformador_1 || object.transformador_2);
            })
        },
        qntd_transformador_1: {
            required: requiredIf(function (object) {
                return object.transformador_1;
            })
        },

        qntd_transformador_2: {
            required: requiredIf(function (object) {
                return object.transformador_2;
            })
        },

        responsabilidade_padrao_entrada: {
            required: requiredIf(function (object) {
                return object.padrao_entrada;
            })
        },
        qntd_padrao_entrada: {
            required: requiredIf(function (object) {
                return object.padrao_entrada;
            })
        },

        responsabilidade_infraestrutura: {
            required: requiredIf(function (object) {
                return object.infraestrutura !== 'R$ 0,00';
            })
        },

        responsabilidade_extensao: {
            required: requiredIf(function (object) {
                return object.extensao !== 'R$ 0,00';
            })
        },
    },
}
</script>

<style scoped>

</style>
