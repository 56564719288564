import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from "./components/Dashboard";
import PropostaForm from "./components/PropostaForm";
import EditDeal from "./components/EditDeal";
import RevisaoTecnica from "./components/RevisaoTecnica";
import RevisaoFinanceira from "./components/RevisaoFinanceira";
import Inativo from "./components/Inativo";

Vue.use(Router)

export default new Router({
    mode: 'hash',
    routes: [{
        path: '/',
        component: Dashboard,
        props: true,
    }, {
        path: '/proposta',
        component: PropostaForm,
        props: true,
    }, {
        path: '/proposta/:id/edit',
        component: EditDeal,
        props: true,
    }, {
        path: '/proposta/:id/rev_tecnica',
        component: RevisaoTecnica,
        props: true,
    }, {
        path: '/proposta/:id/rev_financeira',
        component: RevisaoFinanceira,
        props: true,
    }]
})
