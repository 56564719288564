<template>
    <v-app style="height: 0;" class="mx-4">
        <v-responsive class="overflow-x-auto">
            <v-overlay :value="loading_dialog">
                <v-dialog v-model.lazy="loading_dialog" hide-overlay persistent width="400">
                    <v-card color="primary" dark>
                        <v-card-text class="pt-2">
                            {{ loading_message }}
                            <v-progress-linear indeterminate color="white" class="mt-6 mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-overlay>

            <v-stepper class="v-sheet--outlined elevation-0 container-fluid pa-0 my-2"
                       style="min-width: 1100px; max-width: 2000px"
                       v-model.lazy="step">
                <!----------Header ---------------------------------------------------------------------------------------->
                <v-stepper-header>
                    <v-stepper-step :complete="step > 1" step="1">Tipo de cadastro</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 2" :rules="[() => steps.s2]" step="2">Dados de cadastro
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 3" :rules="[() => steps.s3]" step="3">Endereço</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 4" :rules="[() => steps.s4]" step="4">Endereço de instalação
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 5" :rules="[() => steps.s5]" step="5">Sub-Grupo tarifário
                    </v-stepper-step>
                </v-stepper-header>
                <!----------Stepper Body ---------------------------------------------------------------------------------->
                <v-stepper-items>
                    <!------STEP 1 ---------------------------------------------------------------------------------------->
                    <v-stepper-content step="1">
                        <v-card height="400" outlined flat class="mb-12 ml-10 mr-10" color="grey lighten-3">
                            <v-container class="fill-height">
                                <v-layout row wrap align-center>
                                    <v-flex class="text-xs-center">
                                        <v-row justify="center" class="mb-10 font-weight-thin display-1">TIPO DE CLIENTE
                                        </v-row>
                                        <v-row>
                                            <v-col align="right">
                                                <v-btn @click="set_p_type('p1')"
                                                       x-large width="240px"
                                                       color="warning"
                                                       dark>
                                                    <v-icon class="pr-4 ml-n4">mdi-account</v-icon>
                                                    Pessoa fisica
                                                </v-btn>
                                            </v-col>
                                            <v-col align="left">
                                                <v-btn @click="set_p_type('p2')"
                                                       x-large width="240px"
                                                       color="success" dark>
                                                    <v-icon class="pr-4">mdi-office-building</v-icon>
                                                    Pessoa juridica
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card>
                        <div class="d-flex justify-space-between mb-6">
                            <v-btn text @click="$router.push('/')">Anterior</v-btn>
                        </div>
                    </v-stepper-content>
                    <!------STEP 2 ---------------------------------------------------------------------------------------->
                    <v-stepper-content step="2">
                        <v-form>
                            <v-card min-height="400" outlined flat class="mb-12 ml-10 mr-10" color="grey lighten-3">
                                <v-container class="fill-height">
                                    <v-layout row wrap align-center>
                                        <v-flex class="text-xs-center">
                                            <!------CASO PESSOA FISICA----------------------------------------------------->
                                            <div v-if="form_data.p_type === 1">
                                                <v-row>
                                                    <v-col></v-col>
                                                    <v-col align="center">
                                                        <v-text-field
                                                            v-model.lazy="form_data.name"
                                                            :error-messages="nameErrors"
                                                            :counter="50"
                                                            label="Nome"
                                                            required
                                                            @input="$v.form_data.name.$touch()"
                                                            @blur="$v.form_data.name.$touch()"
                                                        ></v-text-field>
                                                        <v-text-field
                                                            v-model.lazy="form_data.email"
                                                            label="E-mail"
                                                            :error-messages="emailErrors"
                                                            @input="$v.form_data.email.$touch()"
                                                            @blur="$v.form_data.email.$touch()"
                                                        ></v-text-field>
                                                        <v-text-field
                                                            v-mask-cpf
                                                            v-model.lazy="form_data.cpf"
                                                            :error-messages="cpfErrors"
                                                            label="CPF"
                                                            @blur="$v.form_data.cpf.$touch()"
                                                        ></v-text-field>
                                                        <v-text-field
                                                            v-mask-phone.br
                                                            v-model.lazy="form_data.phone"
                                                            :error-messages="phoneErrors"
                                                            label="Telefone"
                                                            @blur="$v.form_data.phone.$touch()"
                                                            @keypress.enter="submit"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-checkbox
                                                            v-if="username.tipo_contato === '286'"
                                                            label="Deseja tornar-se Consultor"
                                                            v-model.lazy="form_data.step3.consultor"
                                                        ></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <!------CASO PESSOA JURIDICA--------------------------------------------------->
                                            <div v-if="form_data.p_type === 2">
                                                <v-row>
                                                    <v-col></v-col>
                                                    <v-col align="center">
                                                        <v-text-field
                                                            v-model.lazy="form_data.name"
                                                            :error-messages="nameErrors"
                                                            :counter="50"
                                                            label="Nome"
                                                            required
                                                            @input="$v.form_data.name.$touch()"
                                                            @blur="$v.form_data.name.$touch()"
                                                        ></v-text-field>
                                                        <v-text-field
                                                            v-model.lazy="form_data.email"
                                                            :error-messages="emailErrors"
                                                            label="E-mail"
                                                            required
                                                            @input="$v.form_data.email.$touch()"
                                                            @blur="$v.form_data.email.$touch()"
                                                        ></v-text-field>
                                                        <v-text-field
                                                            v-mask-cpf
                                                            v-model.lazy="form_data.cpf"
                                                            :error-messages="cpfErrors"
                                                            label="CPF"
                                                        ></v-text-field>
                                                        <v-text-field
                                                            v-mask-phone.br
                                                            v-model.lazy="form_data.phone"
                                                            :error-messages="phoneErrors"
                                                            label="Telefone"
                                                            @blur="$v.form_data.phone.$touch()"
                                                            @keypress.enter="submit"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col align="center">
                                                        <v-text-field
                                                            v-model.lazy="form_data.razao"
                                                            :error-messages="razaoErrors"
                                                            :counter="50"
                                                            label="Razão social"
                                                            @blur="$v.form_data.razao.$touch()"
                                                        ></v-text-field>
                                                        <v-text-field
                                                            v-mask-cnpj
                                                            v-model.lazy="form_data.cnpj"
                                                            :error-messages="cnpjErrors"
                                                            label="CNPJ"
                                                            @blur="$v.form_data.cnpj.$touch()"
                                                            @keypress.enter="submit"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col></v-col>
                                                </v-row>
                                            </div>
                                            <!----------------------------------------------------------------------------->
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-card>
                            <div class="d-flex justify-space-between mb-6">
                                <v-btn text @click="step--">Anterior</v-btn>
                                <v-btn style="margin-right: 80px" color="primary" @keypress.enter="submit"
                                       @click="submit">Próximo
                                </v-btn>
                            </div>
                        </v-form>
                    </v-stepper-content>
                    <!------STEP 3----------------------------------------------------------------------------------------->
                    <v-stepper-content step="3">
                        <v-card height="600" outlined flat class="mb-12 ml-10 mr-10" color="grey lighten-3">
                            <v-container class="fill-height">
                                <v-layout row wrap align-center>
                                    <v-flex class="text-xs-center">
                                        <v-row>
                                            <v-col></v-col>
                                            <v-col align="center">
                                                <v-text-field
                                                    v-if="!form_data.step3.rural"
                                                    label="CEP"
                                                    v-mask-cep.br
                                                    v-model.lazy="$v.form_data.step3.cep.$model"
                                                    :error-messages="cepErrors"
                                                    @blur="$v.form_data.step3.cep.$touch()"
                                                ></v-text-field>
                                                <v-text-field
                                                    :loading="loading"
                                                    label="Logradouro"
                                                    v-model.lazy="$v.form_data.step3.logradouro.$model"
                                                    :error-messages="logradouroErrors"
                                                    @blur="$v.form_data.step3.logradouro.$touch()"
                                                ></v-text-field>
                                                <v-text-field
                                                    :loading="loading"
                                                    label="Número"
                                                    v-model.lazy="$v.form_data.step3.numero.$model"
                                                    :error-messages="numeroErrors"
                                                    @blur="$v.form_data.step3.numero.$touch()"
                                                    @keypress.enter="submit"
                                                ></v-text-field>
                                                <v-text-field
                                                    :loading="loading"
                                                    label="Bairro"
                                                    v-model.lazy="$v.form_data.step3.bairro.$model"
                                                    :error-messages="bairroErrors"
                                                    @blur="$v.form_data.step3.bairro.$touch()"
                                                ></v-text-field>
                                                <v-text-field
                                                    :loading="loading"
                                                    label="Complemento"
                                                    v-model.lazy="$v.form_data.step3.complemento.$model"
                                                    :error-messages="complementoErrors"
                                                    @blur="$v.form_data.step3.complemento.$touch()"
                                                    @keypress.enter="submit"
                                                ></v-text-field>
                                                <v-select
                                                    :menu-props="{auto: true, attach: true, allowOverflow: true}"
                                                    :loading="loading"
                                                    label="Estado"
                                                    v-model.lazy="$v.form_data.step3.estado.$model"
                                                    :items="estados"
                                                    :error-messages="estadoErrors"
                                                    append-icon="mdi-map"
                                                    @blur="$v.form_data.step3.estado.$touch()"
                                                ></v-select>
                                                <v-select
                                                    menu-props="auto"
                                                    :loading="loading"
                                                    label="Cidade"
                                                    v-model.lazy="$v.form_data.step3.cidade.$model"
                                                    :items="cidades"
                                                    append-icon="mdi-map"
                                                    :error-messages="cidadeErrors"
                                                    @blur="$v.form_data.step3.cidade.$touch()"
                                                    @keypress.enter="submit"
                                                ></v-select>
                                            </v-col>
                                            <v-col align="center">
                                                <v-checkbox
                                                    v-if="!$v.form_data.step3.cep.validarCepStep3 || form_data.step3.invalido"
                                                    label="Permitir CEP inválido"
                                                    v-model.lazy="form_data.step3.invalido"
                                                ></v-checkbox>
                                                <v-checkbox
                                                    label="Endereço Rural"
                                                    v-model.lazy="form_data.step3.rural"
                                                ></v-checkbox>
                                                <v-switch
                                                    v-model.lazy="instalacaoIgual"
                                                    label="End. de Instalação é o mesmo.">
                                                </v-switch>
                                            </v-col>
                                        </v-row>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card>
                        <div class="d-flex justify-space-between mb-6" v-if="">
                            <v-btn text @click="step--">Anterior</v-btn>
                            <v-btn style="margin-right: 80px" color="primary" @keypress.enter="submit" @click="submit">
                                Próximo
                            </v-btn>
                        </div>
                    </v-stepper-content>
                    <!------STEP 4----------------------------------------------------------------------------------------->
                    <v-stepper-content step="4">
                        <v-card height="600" outlined flat class="mb-12 ml-10 mr-10" color="grey lighten-3">
                            <v-container class="fill-height">
                                <v-layout row wrap align-center>
                                    <v-flex class="text-xs-center">
                                        <v-row>
                                            <v-col align="center">
                                                <p justify="center"
                                                   class="font-weight-bold subtitle-2">
                                                    ENDEREÇO DE INSTALAÇÃO DA UNIDADE GERADORA
                                                </p>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col></v-col>
                                            <v-col align="center">
                                                <v-text-field
                                                    label="CEP"
                                                    v-if="!form_data.step4.rural_instalacao"
                                                    v-mask-cep
                                                    v-model.lazy="$v.form_data.step4.cep_instalacao.$model"
                                                    :error-messages="cepInstalacaoErrors"
                                                    @blur="$v.form_data.step4.cep_instalacao.$touch()"
                                                ></v-text-field>
                                                <v-text-field
                                                    :loading="loading"
                                                    label="Logradouro"
                                                    v-model.lazy="$v.form_data.step4.logradouro_instalacao.$model"
                                                    :error-messages="logradouroInstalacaoErrors"
                                                    @blur="$v.form_data.step4.logradouro_instalacao.$touch()"
                                                ></v-text-field>
                                                <v-text-field
                                                    :loading="loading"
                                                    label="Número"
                                                    v-model.lazy="$v.form_data.step4.numero_instalacao.$model"
                                                    :error-messages="numeroInstalacaoErrors"
                                                    @blur="$v.form_data.step4.numero_instalacao.$touch()"
                                                    @keypress.enter="submit"
                                                ></v-text-field>
                                                <v-text-field
                                                    :loading="loading"
                                                    label="Bairro"
                                                    v-model.lazy="$v.form_data.step4.bairro_instalacao.$model"
                                                    :error-messages="bairroInstalacaoErrors"
                                                    @blur="$v.form_data.step4.bairro_instalacao.$touch()"
                                                ></v-text-field>
                                                <v-text-field
                                                    :loading="loading"
                                                    label="Complemento"
                                                    v-model.lazy="$v.form_data.step4.complemento_instalacao.$model"
                                                    :error-messages="complementoInstalacaoErrors"
                                                    @blur="$v.form_data.step4.complemento_instalacao.$touch()"
                                                    @keypress.enter="submit"
                                                ></v-text-field>
                                                <v-select
                                                    :loading="loading"
                                                    label="Estado"
                                                    v-model.lazy="$v.form_data.step4.estado_instalacao.$model"
                                                    :items="estados"
                                                    :error-messages="estadoInstalacaoErrors"
                                                    @blur="$v.form_data.step4.estado_instalacao.$touch()"
                                                    menu-props="auto"
                                                    append-icon="mdi-map"
                                                ></v-select>
                                                <v-select
                                                    :loading="loading"
                                                    label="Cidade"
                                                    v-model.lazy="$v.form_data.step4.cidade_instalacao.$model"
                                                    :items="cidades_instalacao"
                                                    :error-messages="cidadeInstalacaoErrors"
                                                    @blur="$v.form_data.step4.cidade_instalacao.$touch()"
                                                    menu-props="auto"
                                                    append-icon="mdi-map"
                                                    @keypress.enter="submit"
                                                ></v-select>
                                            </v-col>
                                            <v-col align="center">
                                                <v-checkbox
                                                    v-if="!$v.form_data.step4.cep_instalacao.validarCepStep4 || form_data.step4.invalido"
                                                    label="Permitir CEP inválido"
                                                    v-model.lazy="form_data.step4.invalido"
                                                ></v-checkbox>
                                                <v-checkbox
                                                    v-model.lazy="form_data.step4.rural_instalacao"
                                                    label="Endereço Rural"
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card>
                        <div class="d-flex justify-space-between mb-6">
                            <v-btn text @click="step--">Anterior</v-btn>
                            <v-btn style="margin-right: 80px" color="primary" @keypress.enter="submit" @click="submit">
                                Próximo
                            </v-btn>
                        </div>
                    </v-stepper-content>
                    <!------STEP 5----------------------------------------------------------------------------------------->
                    <v-stepper-content step="5" class="pa-0">
                        <v-card height="auto" ref="form" flat class="ma-0 pa-0">
                            <v-tabs class="flat-tab" fixed-tabs background-color="primary">
                                <!-- TABS HEADER -------------------------------------------------------------------------->
                                <v-tab @click="setSubGrupo(1)">
                                    <v-icon left>mdi-flash</v-icon>
                                    Sub-Grupo B
                                </v-tab>
                                <v-tab @click="setSubGrupo(2)">
                                    <v-icon left>mdi-flash</v-icon>
                                    Sub-Grupo A
                                </v-tab>
                                <v-tab @click="setSubGrupo(3)">
                                    <v-icon left>mdi-wrench</v-icon>
                                    Em construção
                                </v-tab>
                                <v-tab @click="setSubGrupo(4)">
                                    <v-icon left>mdi-star</v-icon>
                                    Projetos Especiais
                                </v-tab>
                                <!-- TABS HEADER -------------------------------------------------------------------------->

                                <!-- Sub-Grupo B -------------------------------------------------------------------------->
                                <v-tab-item>
                                    <v-tabs color="blue" v-model.lazy="b_sub_tab" dark height="30px">
                                        <v-tab v-for="(grupo_b, i) in form_data.step5.grupos_b" :key="i">
                                            {{ i + 1 }}
                                        </v-tab>
                                        <v-btn class="pb-1" icon @click="createSubGrupoB">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                        <v-tabs-items v-model.lazy="b_sub_tab">
                                            <v-tab-item
                                                v-for="(grupo_b, index) in $v.form_data.step5.grupos_b.$each.$iter"
                                                :key="index">
                                                <v-card flat>
                                                    <v-container class="fill-height">
                                                        <v-layout row wrap align-center>
                                                            <v-flex class="text-xs-center">
                                                                <v-row>
                                                                    <v-col align="center">
                                                                        <p v-if="index == 0"
                                                                           justify="center"
                                                                           class="font-weight-bold subtitle-2">
                                                                            UNIDADE CONSUMIDORA GERADORA
                                                                        </p>
                                                                        <p v-else
                                                                           justify="center"
                                                                           class="font-weight-bold subtitle-2">
                                                                            UNIDADE CONSUMIDORA PARA COMPENSAÇÃO
                                                                        </p>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row class="ml-12 mr-12">
                                                                    <v-col>
                                                                        <v-text-field
                                                                            style="width: 90%"
                                                                            label="Unidade consumidora"
                                                                            v-model.lazy="grupo_b.unidade_consumidora.$model"
                                                                            @blur="grupo_b.unidade_consumidora.$touch()"
                                                                            :error-messages="itemErrors(grupo_b.unidade_consumidora)"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col>
                                                                        <v-select
                                                                            v-if="index == 0"
                                                                            style="width: 90%"
                                                                            label="Concessionária"
                                                                            v-model.lazy="$v.form_data.step5.concessionaria.$model"
                                                                            :items="concessionarias"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.concessionaria.$touch()"
                                                                            :error-messages="concessionariaErrors"
                                                                        ></v-select>
                                                                        <v-select
                                                                            v-else
                                                                            disabled
                                                                            style="width: 90%"
                                                                            :label="form_data.step5.concessionaria"
                                                                            menu-props="auto"
                                                                        ></v-select>
                                                                    </v-col>
                                                                    <v-col align="right">
                                                                        <v-row>
                                                                            <v-btn class="ml-3 pl-7 pr-7 mb-2"
                                                                                   rounded
                                                                                   color="success" dark
                                                                                   @click="createSubGrupoB">
                                                                                <v-icon class="mr-2">mdi-card-plus
                                                                                </v-icon>
                                                                                Nova UC
                                                                            </v-btn>
                                                                            <v-btn class="ml-3" rounded
                                                                                   color="primary"
                                                                                   dark
                                                                                   @click="deleteSubGrupoB(index)">
                                                                                <v-icon class="mr-2">mdi-delete
                                                                                </v-icon>
                                                                                Deletar UC
                                                                            </v-btn>
                                                                        </v-row>
                                                                        <v-row>
                                                                            <v-col align="center">
                                                                                <v-switch
                                                                                    v-if="index == 0"
                                                                                    v-model.lazy="form_data.step5.grupos_b[0].simplificado"
                                                                                    label="Modo Simplificado">
                                                                                </v-switch>
                                                                                <v-switch
                                                                                    v-else
                                                                                    v-model.lazy="form_data.step5.grupos_b[0].simplificado"
                                                                                    label="Modo Simplificado"
                                                                                    disabled>
                                                                                </v-switch>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-divider></v-divider>
                                                                <v-row>
                                                                    <v-col></v-col>
                                                                    <!-- LEFT COLUMN ----------------------------------------------------->
                                                                    <v-col align="center">
                                                                        <div
                                                                            v-if="form_data.step5.grupos_b[0].simplificado">
                                                                            <v-text-field
                                                                                label="Consumo Médio"
                                                                                v-money="kwh_ano"
                                                                                v-model.lazy="form_data.step5.grupos_b[index].consumo_anual.Media"
                                                                                @blur="grupo_b.consumo_anual.Media.$touch()"
                                                                                :error-messages="itemErrors(grupo_b.consumo_anual.Media)"
                                                                            ></v-text-field>
                                                                        </div>
                                                                        <div v-else>
                                                                            <v-text-field
                                                                                v-for="(value, key, j) in grupo_b.consumo_anual.$model"
                                                                                v-if="j > 0"
                                                                                :key="j"
                                                                                :label="key"
                                                                                v-model.lazy="form_data.step5.grupos_b[index].consumo_anual[key]"
                                                                                v-money="kwh_mes"
                                                                                @blur="grupo_b.consumo_anual[key].$touch()"
                                                                                :error-messages="itemErrors(grupo_b.consumo_anual[key])"
                                                                            ></v-text-field>
                                                                        </div>
                                                                    </v-col>
                                                                    <!-- RIGHT COLUMN ----------------------------------------------------->
                                                                    <v-col align="center">
                                                                        <v-text-field
                                                                            label="Tarifa com tributos"
                                                                            v-money="money"
                                                                            v-model.lazy="form_data.step5.grupos_b[index].tarifa_anual"
                                                                            @blur="grupo_b.tarifa_anual.$touch()"
                                                                            :error-messages="itemErrors(grupo_b.tarifa_anual)"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col></v-col>
                                                                </v-row>
                                                                <v-divider></v-divider>
                                                                <v-row v-if="index == 0">
                                                                    <v-col></v-col>
                                                                    <v-col class="mt-5">
                                                                        <v-text-field
                                                                            label="Geração desejada"
                                                                            v-money="kwh_mes"
                                                                            v-model.lazy="form_data.step5.consumo_desejado"
                                                                        ></v-text-field>
                                                                        <v-select
                                                                            v-if="username.tipo_contato === '7427' || username.tipo_contato === '7429'"
                                                                            label="Qual tipo de telhado?"
                                                                            v-model.lazy="$v.form_data.step5.telhado.$model"
                                                                            :items="telhados_adm"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.telhado.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.telhado)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            v-else
                                                                            label="Qual tipo de telhado?"
                                                                            v-model.lazy="$v.form_data.step5.telhado.$model"
                                                                            :items="telhados"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.telhado.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.telhado)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            label="Categoria de atendimento"
                                                                            v-model.lazy="$v.form_data.step5.categoria_atendimento.$model"
                                                                            :items="categorias"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.categoria_atendimento.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.categoria_atendimento)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            label="Haverá troca da titularidade?"
                                                                            v-model.lazy="$v.troca_titularidade.$model"
                                                                            :items="lista_troca_titularidade"
                                                                            menu-props="auto"
                                                                            @blur="$v.troca_titularidade.$touch()"
                                                                            :error-messages="itemErrors($v.troca_titularidade)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            v-if="username.tipo_contato === '7427' || username.tipo_contato === '7429'"
                                                                            label="Passará de Grupo A para B?"
                                                                            v-model.lazy="$v.a_para_b.$model"
                                                                            :items="lista_a_para_b"
                                                                            menu-props="auto"
                                                                            @blur="$v.a_para_b.$touch()"
                                                                            :error-messages="itemErrors($v.a_para_b)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            v-if="username.tipo_contato === '7427' || username.tipo_contato === '7429'"
                                                                            label="Modalidade de venda"
                                                                            v-model.lazy="modalidade"
                                                                            :items="lista_modalidade"
                                                                            menu-props="auto"
                                                                        ></v-select>
                                                                    </v-col>
                                                                    <v-col class="mt-5">
                                                                        <v-select
                                                                            label="Tensão de alimentação"
                                                                            v-model.lazy="$v.form_data.step5.tensao.$model"
                                                                            :items="lista_tensao"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.tensao.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.tensao)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            v-if="username.tipo_contato === '7427' || username.tipo_contato === '7429'"
                                                                            label="Potência do módulo"
                                                                            v-model.lazy="$v.form_data.step5.p_modulo.$model"
                                                                            :items="modelos_modulos_adm_only"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.p_modulo.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.p_modulo)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            v-if="username.tipo_contato !== '7427' && username.tipo_contato !== '7429'"
                                                                            label="Potência do módulo"
                                                                            v-model.lazy="$v.form_data.step5.p_modulo.$model"
                                                                            :items="modelos_modulos"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.p_modulo.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.p_modulo)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            label="Qual a modalidade?"
                                                                            v-model.lazy="$v.modalidade_consumo_geracao.$model"
                                                                            :items="['Auto-Consumo','Geração Remota']"
                                                                            menu-props="auto"
                                                                            @blur="$v.modalidade_consumo_geracao.$touch()"
                                                                            :error-messages="itemErrors($v.modalidade_consumo_geracao)"
                                                                        ></v-select>
                                                                        <v-file-input
                                                                            class="mt-2"
                                                                            label="Imagens do local"
                                                                            outlined dense
                                                                            multiple
                                                                            @change="updateImagens"
                                                                        ></v-file-input>
                                                                        <v-file-input
                                                                            label="Anexo de fatura"
                                                                            multiple
                                                                            outlined dense
                                                                            class="mt-5"
                                                                            @change="updateAnexos"
                                                                            @input="$v.form_data.step5.anexo_fatura.$touch()"
                                                                            @blur="$v.form_data.step5.anexo_fatura.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.anexo_fatura)"
                                                                        ></v-file-input>
                                                                    </v-col>
                                                                    <v-col></v-col>
                                                                </v-row>

                                                                <div v-if="index == 0 &&
                                                            (username.tipo_contato === '7427'
                                                            || username.tipo_contato === '7429')">
                                                                    <v-divider/>
                                                                    <custos-adicionais
                                                                        :proposta="true"
                                                                        @getData="getCustosAdicionais"
                                                                        @invalid="setCustosAdicionaisInvalid">
                                                                    </custos-adicionais>
                                                                    <v-divider/>
                                                                </div>

                                                                <v-row>
                                                                    <v-col class="ml-10 mr-10">
                                                                        <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                                        <v-textarea
                                                                            label="Comentário sobre a proposta"
                                                                            v-model.lazy="form_data.step5.comentario"
                                                                            outlined
                                                                        ></v-textarea>
                                                                        <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row
                                                                    v-if="(username.tipo_contato === '7427' || username.tipo_contato === '7429')">
                                                                    <v-col class="ml-10 mr-10">
                                                                        <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                                        <v-textarea
                                                                            label="Observações sobre a proposta"
                                                                            v-model.lazy="form_data.step5.observacoes"
                                                                            outlined
                                                                        ></v-textarea>
                                                                        <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                                    </v-col>
                                                                </v-row>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-container>
                                                </v-card>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-tabs>
                                </v-tab-item>
                                <!-- Sub-Grupo B -------------------------------------------------------------------------->

                                <!-- Sub-Grupo A -------------------------------------------------------------------------->
                                <v-tab-item>
                                    <v-tabs color="blue" v-model.lazy="a_sub_tab" dark height="30px">
                                        <!-- UNIDADES CONSUMIDORAS -------------------------------------------------------->
                                        <v-tab v-for="(grupo_a, i) in form_data.step5.grupos_a" :key="i">
                                            {{ i + 1 }}
                                        </v-tab>
                                        <v-btn class="pb-1" icon @click="createSubGrupoA">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                        <!--------------------------------------------------------------------------------->
                                        <v-tabs-items v-model.lazy="a_sub_tab">
                                            <v-tab-item
                                                v-for="(grupo_a, index) in $v.form_data.step5.grupos_a.$each.$iter"
                                                :key="index">
                                                <v-card min-width="900" flat>
                                                    <v-container class="fill-height">
                                                        <v-layout row wrap align-center>
                                                            <v-flex class="text-xs-center">
                                                                <v-row>
                                                                    <v-col align="center">
                                                                        <p v-if="index == 0"
                                                                           justify="center"
                                                                           class="font-weight-bold subtitle-2">
                                                                            UNIDADE CONSUMIDORA GERADORA
                                                                        </p>
                                                                        <p v-else
                                                                           justify="center"
                                                                           class="font-weight-bold subtitle-2">
                                                                            UNIDADE CONSUMIDORA PARA COMPENSAÇÃO
                                                                        </p>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row class="ml-12 mr-12">
                                                                    <v-col>
                                                                        <v-text-field
                                                                            label="Unidade consumidora"
                                                                            v-model.lazy="grupo_a.unidade_consumidora.$model"
                                                                            @blur="grupo_a.unidade_consumidora.$touch()"
                                                                            :error-messages="itemErrors(grupo_a.unidade_consumidora)"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <v-col>
                                                                        <v-select
                                                                            v-if="index == 0"
                                                                            style="width: 90%"
                                                                            label="Concessionária"
                                                                            v-model.lazy="$v.form_data.step5.concessionaria.$model"
                                                                            :items="concessionarias"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.concessionaria.$touch()"
                                                                            :error-messages="concessionariaErrors"
                                                                        ></v-select>
                                                                        <v-select
                                                                            v-else
                                                                            disabled
                                                                            style="width: 90%"
                                                                            :label="form_data.step5.concessionaria"
                                                                            menu-props="auto"
                                                                        ></v-select>
                                                                    </v-col>
                                                                    <v-col align="right">
                                                                        <v-row>
                                                                            <v-btn class="ml-3 pl-7 pr-7 mb-2"
                                                                                   rounded
                                                                                   color="success" dark
                                                                                   @click="createSubGrupoA">
                                                                                <v-icon class="mr-2">mdi-card-plus
                                                                                </v-icon>
                                                                                Nova UC
                                                                            </v-btn>
                                                                            <v-btn class="ml-3" rounded
                                                                                   color="primary"
                                                                                   dark
                                                                                   @click="deleteSubGrupoA(index)">
                                                                                <v-icon class="mr-2">mdi-delete
                                                                                </v-icon>
                                                                                Deletar UC
                                                                            </v-btn>
                                                                        </v-row>
                                                                        <v-row>
                                                                            <v-col align="center">
                                                                                <v-switch
                                                                                    v-if="index == 0"
                                                                                    v-model.lazy="form_data.step5.grupos_a[0].simplificado"
                                                                                    label="Modo Simplificado">
                                                                                </v-switch>
                                                                                <v-switch
                                                                                    v-else
                                                                                    v-model.lazy="form_data.step5.grupos_a[0].simplificado"
                                                                                    label="Modo Simplificado"
                                                                                    disabled>
                                                                                </v-switch>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-divider></v-divider>
                                                                <v-row>
                                                                    <!-- SIMPLIFICADO ------------------------------------->
                                                                    <v-col
                                                                        v-if="form_data.step5.grupos_a[0].simplificado"
                                                                        align="center"
                                                                        class="ml-12">
                                                                        <p justify="center"
                                                                           class="font-weight-bold subtitle-2">
                                                                            Consumo de ponta em kWh
                                                                        </p>
                                                                        <v-text-field
                                                                            label="Média"
                                                                            v-model.lazy="form_data.step5.grupos_a[index].consumo_ponta.Media"
                                                                            v-money="kwh_ano"
                                                                            @blur="grupo_a.consumo_ponta.Media.$touch()"
                                                                            :error-messages="itemErrors(grupo_a.consumo_ponta.Media)"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <!-- SIMPLIFICADO ------------------------------------->
                                                                    <!-- DETALHADO ---------------------------------------->
                                                                    <v-col v-else align="center" class="ml-12">
                                                                        <p justify="center"
                                                                           class="font-weight-bold subtitle-2">
                                                                            Consumo de ponta em kWh
                                                                        </p>
                                                                        <v-text-field
                                                                            v-for="(value, key, j) in grupo_a.consumo_ponta.$model"
                                                                            v-if="j > 0"
                                                                            :key="j"
                                                                            :label="key"
                                                                            v-model.lazy="form_data.step5.grupos_a[index].consumo_ponta[key]"
                                                                            v-money="kwh_mes"
                                                                            @blur="grupo_a.consumo_ponta[key].$touch()"
                                                                            :error-messages="itemErrors(grupo_a.consumo_ponta[key])"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <!-- LEFT COLUMN -------------------------------------->
                                                                    <v-col align="center">
                                                                        <p justify="center"
                                                                           class="font-weight-bold subtitle-2">
                                                                            Valor de tarifa ponta com tributos
                                                                        </p>
                                                                        <v-text-field
                                                                            label="Tarifa ponta"
                                                                            v-model.lazy="form_data.step5.grupos_a[index].valor_tarifa_ponta"
                                                                            v-money="money"
                                                                            @blur="grupo_a.valor_tarifa_ponta.$touch()"
                                                                            :error-messages="itemErrors(grupo_a.valor_tarifa_ponta)"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                    <!-- RIGHT COLUMN ------------------------------------->
                                                                    <v-col align="center">
                                                                        <p justify="center"
                                                                           class="font-weight-bold subtitle-2">
                                                                            Consumo fora de ponta em kWh
                                                                        </p>
                                                                        <!-- SIMPLIFICADO --------------------------------->
                                                                        <div
                                                                            v-if="form_data.step5.grupos_a[0].simplificado">
                                                                            <v-text-field
                                                                                label="Média"
                                                                                v-model.lazy="form_data.step5.grupos_a[index].consumo_fora.Media"
                                                                                v-money="kwh_ano"
                                                                                @blur="grupo_a.consumo_fora.Media.$touch()"
                                                                                :error-messages="itemErrors(grupo_a.consumo_fora.Media)"
                                                                            ></v-text-field>
                                                                        </div>
                                                                        <!-- SIMPLIFICADO --------------------------------->
                                                                        <!-- DETALHADO ------------------------------------>
                                                                        <div v-else>
                                                                            <v-text-field
                                                                                v-for="(value, key, j) in grupo_a.consumo_fora.$model"
                                                                                v-if="j > 0"
                                                                                :key="j"
                                                                                :label="key"
                                                                                v-model.lazy="form_data.step5.grupos_a[index].consumo_fora[key]"
                                                                                v-money="kwh_mes"
                                                                                @blur="grupo_a.consumo_fora[key].$touch()"
                                                                                :error-messages="itemErrors(grupo_a.consumo_fora[key])"
                                                                            ></v-text-field>
                                                                        </div>
                                                                        <!-- DETALHADO ------------------------------------>
                                                                    </v-col>
                                                                    <v-col align="center" class="mr-12">
                                                                        <p justify="center"
                                                                           class="font-weight-bold subtitle-2">
                                                                            Valor de tarifa fora ponta com tributos
                                                                        </p>
                                                                        <v-text-field
                                                                            label="Tarifa fora ponta"
                                                                            v-model.lazy="form_data.step5.grupos_a[index].valor_tarifa_fora"
                                                                            v-money="money"
                                                                            @blur="grupo_a.valor_tarifa_fora.$touch()"
                                                                            :error-messages="itemErrors(grupo_a.valor_tarifa_fora)"
                                                                        ></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <!-- 3 LINHA ---------------------------------------------->
                                                                <v-divider></v-divider>
                                                                <v-row v-if="index == 0">
                                                                    <v-col></v-col>
                                                                    <v-col>
                                                                        <v-text-field
                                                                            label="Demanda contratada em kWh"
                                                                            v-model.lazy="form_data.step5.demanda_contratada"
                                                                            v-mask-decimal.br="2"
                                                                            @blur="$v.form_data.step5.demanda_contratada.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.demanda_contratada)"
                                                                        ></v-text-field>
                                                                        <v-select
                                                                            v-if="username.tipo_contato === '7427' || username.tipo_contato === '7429'"
                                                                            label="Potência do módulo"
                                                                            v-model.lazy="$v.form_data.step5.p_modulo.$model"
                                                                            :items="modelos_modulos_adm_only"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.p_modulo.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.p_modulo)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            v-if="username.tipo_contato !== '7427' && username.tipo_contato !== '7429'"
                                                                            label="Potência do módulo"
                                                                            v-model.lazy="$v.form_data.step5.p_modulo.$model"
                                                                            :items="modelos_modulos"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.p_modulo.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.p_modulo)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            label="Tensão de alimentação"
                                                                            v-model.lazy="$v.form_data.step5.tensao.$model"
                                                                            :items="lista_tensao"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.tensao.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.tensao)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            v-if="username.tipo_contato === '7427' || username.tipo_contato === '7429'"
                                                                            label="Qual tipo de telhado?"
                                                                            v-model.lazy="$v.form_data.step5.telhado.$model"
                                                                            :items="telhados_adm"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.telhado.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.telhado)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            v-else
                                                                            label="Qual tipo de telhado?"
                                                                            v-model.lazy="$v.form_data.step5.telhado.$model"
                                                                            :items="telhados"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.telhado.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.telhado)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            label="Categoria de atendimento"
                                                                            v-model.lazy="$v.form_data.step5.categoria_atendimento.$model"
                                                                            :items="categorias"
                                                                            menu-props="auto"
                                                                            @blur="$v.form_data.step5.categoria_atendimento.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.categoria_atendimento)"
                                                                        ></v-select>
                                                                        <v-select
                                                                            label="Haverá troca da titularidade?"
                                                                            v-model.lazy="$v.troca_titularidade.$model"
                                                                            :items="lista_troca_titularidade"
                                                                            menu-props="auto"
                                                                            @blur="$v.troca_titularidade.$touch()"
                                                                            :error-messages="itemErrors($v.troca_titularidade)"
                                                                        ></v-select>
                                                                    </v-col>
                                                                    <v-col>
                                                                        <v-text-field
                                                                            label="Tarifa da demanda contratada"
                                                                            v-money="money"
                                                                            v-model.lazy="$v.form_data.step5.tarifa_demanda.$model"
                                                                            @blur="$v.form_data.step5.tarifa_demanda.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.tarifa_demanda)"
                                                                        ></v-text-field>
                                                                        <v-text-field
                                                                            label="Demanda máxima registrada em kWh"
                                                                            v-mask-decimal.br="2"
                                                                            v-model.lazy="form_data.step5.demanda_maxima"
                                                                            @blur="$v.form_data.step5.demanda_maxima.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.demanda_maxima)"
                                                                        ></v-text-field>
                                                                        <v-select
                                                                            label="Qual a modalidade?"
                                                                            v-model.lazy="$v.modalidade_consumo_geracao.$model"
                                                                            :items="['Auto-Consumo','Geração Remota']"
                                                                            menu-props="auto"
                                                                            @blur="$v.modalidade_consumo_geracao.$touch()"
                                                                            :error-messages="itemErrors($v.modalidade_consumo_geracao)"
                                                                        ></v-select>
                                                                        <v-file-input
                                                                            class="mt-2"
                                                                            label="Imagens do local"
                                                                            outlined dense
                                                                            multiple
                                                                            @change="updateImagens"
                                                                        ></v-file-input>
                                                                        <v-file-input
                                                                            label="Anexo de fatura"
                                                                            multiple
                                                                            outlined dense
                                                                            class="mt-1"
                                                                            @change="updateAnexos"
                                                                            @input="$v.form_data.step5.anexo_fatura.$touch()"
                                                                            @blur="$v.form_data.step5.anexo_fatura.$touch()"
                                                                            :error-messages="itemErrors($v.form_data.step5.anexo_fatura)"
                                                                        ></v-file-input>
                                                                    </v-col>
                                                                    <v-col></v-col>
                                                                </v-row>

                                                                <div v-if="index == 0 &&
                                                            (username.tipo_contato === '7427'
                                                            || username.tipo_contato === '7429')">
                                                                    <v-divider/>
                                                                    <custos-adicionais
                                                                        :proposta="true"
                                                                        @getData="getCustosAdicionais"
                                                                        @invalid="setCustosAdicionaisInvalid"></custos-adicionais>
                                                                    <v-divider/>
                                                                </div>
                                                                <v-row>
                                                                    <v-col class="ml-10 mr-10">
                                                                        <!-- COMMENT AREA (SHOULD BE BIG) ----------------->
                                                                        <v-textarea
                                                                            label="Comentário sobre a proposta"
                                                                            v-model.lazy="form_data.step5.comentario"
                                                                            outlined
                                                                        ></v-textarea>
                                                                        <!-- COMMENT AREA (SHOULD BE BIG) ----------------->
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row
                                                                    v-if="(username.tipo_contato === '7427' || username.tipo_contato === '7429')">
                                                                    <v-col class="ml-10 mr-10">
                                                                        <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                                        <v-textarea
                                                                            label="Observações sobre a proposta"
                                                                            v-model.lazy="form_data.step5.observacoes"
                                                                            outlined
                                                                        ></v-textarea>
                                                                        <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                                    </v-col>
                                                                </v-row>
                                                                <!--------------------------------------------------------->
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-container>
                                                </v-card>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-tabs>
                                </v-tab-item>
                                <!-- Sub-Grupo A -------------------------------------------------------------------------->

                                <!-- Unidade consumidora em construção ---------------------------------------------------->
                                <v-tab-item>
                                    <v-card min-width="900" flat>
                                        <v-container class="fill-height">
                                            <v-layout row wrap align-center>
                                                <v-flex class="text-xs-center">
                                                    <v-row class="ml-12 mr-12">
                                                        <v-col>
                                                            <v-text-field
                                                                label="Unidade consumidora"
                                                                v-model.lazy="form_data.step5.grupo_c.unidade_consumidora"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col>
                                                            <v-select
                                                                label="Concessionária"
                                                                v-model.lazy="$v.form_data.step5.concessionaria.$model"
                                                                :items="concessionarias"
                                                                menu-props="auto"
                                                                @blur="$v.form_data.step5.concessionaria.$touch()"
                                                                :error-messages="concessionariaErrors"
                                                            ></v-select>
                                                        </v-col>
                                                    </v-row>
                                                    <v-divider></v-divider>
                                                    <v-row>
                                                        <v-col></v-col>
                                                        <!-- LEFT COLUMN ----------------------------------------------------->
                                                        <v-col>
                                                            <!-- BLANK SPACE -------------------------------------------------->
                                                            <v-row style="height: 50px;"></v-row>
                                                            <!-- BLANK SPACE -------------------------------------------------->
                                                            <v-select
                                                                v-if="username.tipo_contato === '7427' || username.tipo_contato === '7429'"
                                                                label="Qual tipo de telhado?"
                                                                v-model.lazy="$v.form_data.step5.grupo_c.telhado.$model"
                                                                :items="telhados_adm"
                                                                menu-props="auto"
                                                                @blur="$v.form_data.step5.grupo_c.telhado.$touch()"
                                                                :error-messages="itemErrors($v.form_data.step5.grupo_c.telhado)"
                                                            ></v-select>
                                                            <v-select
                                                                v-else
                                                                label="Qual tipo de telhado?"
                                                                v-model.lazy="$v.form_data.step5.telhado.$model"
                                                                :items="telhados"
                                                                menu-props="auto"
                                                                @blur="$v.form_data.step5.telhado.$touch()"
                                                                :error-messages="itemErrors($v.form_data.step5.telhado)"
                                                            ></v-select>
                                                        </v-col>
                                                        <!-- RIGHT COLUMN ----------------------------------------------------->
                                                        <v-col align="center">
                                                            <p justify="center"
                                                               class="font-weight-bold subtitle-2">
                                                                Anexar projetos arquitetônico e elétrico para
                                                                uma estimativa de consumo
                                                            </p>
                                                            <v-file-input
                                                                label="Anexo de fatura"
                                                                multiple
                                                                outlined dense
                                                                @change="updateAnexos"
                                                                @input="$v.form_data.step5.anexo_fatura.$touch()"
                                                                @blur="$v.form_data.step5.anexo_fatura.$touch()"
                                                                :error-messages="itemErrors($v.form_data.step5.anexo_fatura)"
                                                            ></v-file-input>
                                                            <!-- BLANK SPACE -------------------------------------------------->
                                                            <v-row style="height: 50px;"></v-row>
                                                            <!-- BLANK SPACE -------------------------------------------------->
                                                            <v-file-input
                                                                label="Imagens do local"
                                                                outlined dense
                                                                multiple
                                                                @change="updateImagens"
                                                            ></v-file-input>
                                                        </v-col>
                                                        <v-col></v-col>
                                                    </v-row>

                                                    <div
                                                        v-if="(username.tipo_contato === '7427' || username.tipo_contato === '7429')">
                                                        <v-divider/>
                                                        <custos-adicionais
                                                            :proposta="true"
                                                            @getData="getCustosAdicionais"
                                                            @invalid="setCustosAdicionaisInvalid"></custos-adicionais>
                                                        <v-divider/>
                                                    </div>

                                                    <v-row>
                                                        <v-col class="ml-10 mr-10">
                                                            <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                            <v-textarea
                                                                label="Comentário sobre a proposta"
                                                                v-model.lazy="form_data.step5.comentario"
                                                                outlined
                                                            ></v-textarea>
                                                            <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                        </v-col>
                                                    </v-row>
                                                    <v-row
                                                        v-if="(username.tipo_contato === '7427' || username.tipo_contato === '7429')">
                                                        <v-col class="ml-10 mr-10">
                                                            <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                            <v-textarea
                                                                label="Observações sobre a proposta"
                                                                v-model.lazy="form_data.step5.observacoes"
                                                                outlined
                                                            ></v-textarea>
                                                            <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                        </v-col>
                                                    </v-row>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-card>
                                </v-tab-item>
                                <!-- Projetos Especiais ------------------------------------------------------------------->
                                <v-tab-item>
                                    <v-card min-width="900" flat>
                                        <v-container class="fill-height">
                                            <v-layout row wrap align-center>
                                                <v-flex class="text-xs-center">
                                                    <v-row class="ml-12 mr-12">
                                                        <v-col>
                                                            <v-select
                                                                label="Ambiente livre?"
                                                                :items="['Sim', 'Não']"
                                                                menu-props="auto"
                                                                v-model.lazy="$v.form_data.step5.especial.ambiente_livre.$model"
                                                                @blur="$v.form_data.step5.especial.ambiente_livre.$touch()"
                                                                :error-messages="itemErrors($v.form_data.step5.especial.ambiente_livre)"
                                                            ></v-select>
                                                            <v-file-input
                                                                label="Anexo da fatura"
                                                                multiple
                                                                outlined dense
                                                                class="mt-1"
                                                                @change="updateAnexosEspecial"
                                                                @input="$v.form_data.step5.especial.anexo_fatura.$touch()"
                                                                @blur="$v.form_data.step5.especial.anexo_fatura.$touch()"
                                                                :error-messages="itemErrors($v.form_data.step5.especial.anexo_fatura)"
                                                            ></v-file-input>
                                                            <v-file-input
                                                                v-if="form_data.step5.especial.ambiente_livre === 'Sim'"
                                                                label="Fatura da compra de energia"
                                                                multiple
                                                                outlined dense
                                                                class="mt-1"
                                                                @change="updateAnexosCompraEnergia"
                                                            ></v-file-input>
                                                        </v-col>
                                                        <v-col>
                                                            <v-select
                                                                label="Sistema para consumo Parcial ou Total?"
                                                                hint="O sistema solicitado pelo cliente é para atender totalmente ou parcialmente o consumo?"
                                                                v-model.lazy="$v.form_data.step5.especial.consumo_total_parcial.$model"
                                                                :items="['Total', 'Parcial']"
                                                                menu-props="auto"
                                                                @blur="$v.form_data.step5.especial.consumo_total_parcial.$touch()"
                                                                :error-messages="itemErrors($v.form_data.step5.especial.consumo_total_parcial)"
                                                            ></v-select>
                                                            <v-select
                                                                v-if="form_data.step5.especial.consumo_total_parcial === 'Parcial'"
                                                                label="Limite geração"
                                                                hint="Limitar a uma geração mensal ou a uma demanda contratada?"
                                                                v-model.lazy="form_data.step5.especial.limite_geracao"
                                                                :items="['Geração mensal', 'Demanda contratada']"
                                                                menu-props="auto"
                                                            ></v-select>
                                                            <v-text-field
                                                                v-if="form_data.step5.especial.limite_geracao === 'Geração mensal'"
                                                                label="Geração mensal"
                                                                v-model.lazy="form_data.step5.especial.geracao_mensal"
                                                                v-mask-decimal.br="2"
                                                            ></v-text-field>
                                                            <v-text-field
                                                                v-if="form_data.step5.especial.limite_geracao === 'Demanda contratada'"
                                                                label="Demanda contratada"
                                                                v-model.lazy="form_data.step5.especial.demanda_contratada"
                                                                v-mask-decimal.br="2"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>

                                                    <div
                                                        v-if="(username.tipo_contato === '7427' || username.tipo_contato === '7429')">
                                                        <v-divider/>
                                                        <custos-adicionais
                                                            :proposta="true"
                                                            @getData="getCustosAdicionais"
                                                            @invalid="setCustosAdicionaisInvalid"></custos-adicionais>
                                                        <v-divider/>
                                                    </div>

                                                    <v-row>
                                                        <v-col class="ml-10 mr-10">
                                                            <!-- COMENT AREA (SHOULD BE BIG) ------------------------------>
                                                            <v-textarea
                                                                label="Comentário sobre a proposta"
                                                                v-model.lazy="form_data.step5.comentario"
                                                                outlined
                                                            ></v-textarea>
                                                            <!-- COMENT AREA (SHOULD BE BIG) ------------------------------>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row
                                                        v-if="(username.tipo_contato === '7427' || username.tipo_contato === '7429')">
                                                        <v-col class="ml-10 mr-10">
                                                            <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                            <v-textarea
                                                                label="Observações sobre a proposta"
                                                                v-model.lazy="form_data.step5.observacoes"
                                                                outlined
                                                            ></v-textarea>
                                                            <!-- COMENT AREA (SHOULD BE BIG) -------------------------------------->
                                                        </v-col>
                                                    </v-row>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs>
                            <div class="d-flex justify-space-between mb-6 ml-12 mr-12">
                                <v-btn text @click="step--">Anterior</v-btn>
                                <v-btn style="margin-right: 80px" color="success" @keypress.enter="submit"
                                       @click="submit">
                                    Enviar
                                </v-btn>
                            </div>
                        </v-card>
                    </v-stepper-content>
                    <!----------------------------------------------------------------------------------------------------->
                </v-stepper-items>
            </v-stepper>
        </v-responsive>
    </v-app>
</template>

<script>
import {required, requiredIf, maxLength, email, or} from 'vuelidate/lib/validators'
import {estados} from "../data/estados";
import {concessionarias} from "../data/concessionarias";
import {
    cabines,
    categorias, lista_estudo_estrutura, lista_posto_transformacao, lista_reforco_estrutura, lista_tensao,
    m_inversor, p_modulos, padroes_entrada, responsabilidades_cabine, responsabilidades_extensao,
    responsabilidades_infraestrutura, responsabilidades_padrao_entrada, responsabilidades_subestacao,
    responsabilidades_transformador, subestacoes, telhados, transformadores, lista_troca_titularidade,
    lista_a_para_b, lista_cadastro_compensacao, lista_modalidade, telhados_adm,
} from "../data/SelectData";

import CustosAdicionais from "./CustosAdicionais";

function validaMedia(value, simplificado) {
    if (simplificado) {
        return validarKwhAno(value);
    } else {
        return true;
    }
}

function validaMes(value, simplificado) {
    if (!simplificado) {
        return validarKwhMes(value);
    } else {
        return true;
    }
}

function validarReal(value) {
    return value !== "R$ 0,00";
}

function validarKwhAno(value) {
    return value !== "0,00 kWh/ano";
}

function validarKwhMes(value) {
    return value !== "0,00 kWh/mês";
}

function validarCpf(value) {
    const BrV = require('br-validations');
    return BrV.cpf.validate(value);
}

function validarCnpj(value) {
    const BrV = require('br-validations');
    return BrV.cnpj.validate(value);
}

function validarCepStep3(value) {
    if (this.form_data.step3.invalido) {
        return true;
    } else {
        return this.pesquisacep(value);
    }
}

function validarCepStep4(value) {
    if (this.form_data.step4.invalido) {
        return true;
    } else {
        return this.pesquisacep(value);
    }
}

function validaCidade() {
    return this.estado_state;
}

function validaCidadeInstalacao() {
    return this.estado_instalacao_state;
}

function getGrupoB() {
    return {
        unidade_consumidora: null,
        tarifa_anual: 0, //tarifa com tributos
        simplificado: true,

        consumo_anual: {
            Media: null,
            Janeiro: null,
            Fevereiro: null,
            Marco: null,
            Abril: null,
            Maio: null,
            Junho: null,
            Julho: null,
            Agosto: null,
            Setembro: null,
            Outubro: null,
            Novembro: null,
            Dezembro: null,
        },
    }
}

function getGrupoA() {
    return {
        unidade_consumidora: null,
        valor_tarifa_ponta: 0,
        valor_tarifa_fora: 0,
        simplificado: true,

        consumo_ponta: {
            Media: null,
            Janeiro: null,
            Fevereiro: null,
            Marco: null,
            Abril: null,
            Maio: null,
            Junho: null,
            Julho: null,
            Agosto: null,
            Setembro: null,
            Outubro: null,
            Novembro: null,
            Dezembro: null,
        },
        consumo_fora: {
            Media: null,
            Janeiro: null,
            Fevereiro: null,
            Marco: null,
            Abril: null,
            Maio: null,
            Junho: null,
            Julho: null,
            Agosto: null,
            Setembro: null,
            Outubro: null,
            Novembro: null,
            Dezembro: null,
        },
    }
}

function getGrupoC() {
    return {
        unidade_consumidora: null,

        telhado: null,
    }

}

// BEGIN SCRIPT ====================================================================================================
export default {
    components: {CustosAdicionais},
    props: ['username'],

    data() {
        return {
            // FORM CONFIGS ========================================================================================
            instalacaoIgual: false,
            a_sub_tab: null,
            b_sub_tab: null,
            c_sub_tab: null,
            loading: false,
            loading_dialog: false,
            loading_message: '',
            // START STEP
            step: 1,
            // STEPS VALID STATUS
            steps: {
                s2: true,
                s3: true,
                s4: true,
                s5: true,
            },
            //======================================================================================================
            estados: estados,
            estado_state: true,
            estado_instalacao_state: true,

            cidades: [{value: '', text: "Selecione um estado"}],
            cidades_instalacao: [{value: '', text: "Selecione um estado"}],
            //======================================================================================================
            lista_posto_transformacao: lista_posto_transformacao,
            lista_estudo_estrutura: lista_estudo_estrutura,
            padroes_entrada: padroes_entrada,
            transformadores: transformadores,
            subestacoes: subestacoes,
            cabines: cabines,
            m_inversor: m_inversor,
            categorias: categorias,
            lista_tensao: lista_tensao,
            p_modulos: p_modulos,
            telhados: telhados,
            telhados_adm: telhados_adm,
            lista_reforco_estrutura: lista_reforco_estrutura,
            lista_troca_titularidade: lista_troca_titularidade,
            lista_a_para_b: lista_a_para_b,
            lista_cadastro_compensacao: lista_cadastro_compensacao,
            lista_modalidade: lista_modalidade,
            //======================================================================================================
            concessionarias: [{value: '', text: "Selecione um estado"}],
            //======================================================================================================
            troca_titularidade: '',
            modalidade_consumo_geracao: '',
            a_para_b: '',
            modalidade: '7327',

            custos_adicionais: {
                cabo: 'R$ 0,00', qntd_cabo: null,
                servico: 'R$ 0,00', qntd_servico: null,
                infraestrutura: 'R$ 0,00', qntd_infraestrutura: null,
                extensao: 'R$ 0,00', qntd_extensao: null,

                responsabilidade_cabine: '',
                responsabilidade_subestacao_1: '',
                responsabilidade_transformador_1: '',
                responsabilidade_padrao_entrada: '',
                responsabilidade_infraestrutura: '',
                responsabilidade_extensao: '',

                cabine: '', qntd_cabine: '',
                subestacao_1: '', qntd_subestacao_1: '',
                subestacao_2: '', qntd_subestacao_2: '',
                transformador_1: '', qntd_transformador_1: '',
                transformador_2: '', qntd_transformador_2: '',
                padrao_entrada: '', qntd_padrao_entrada: '',
                comentario_custos: '',
            },
            // ALL THE DATA FROM THE FORM ==========================================================================
            form_data: {
                p_type: null,
                // Case p_type = 1 =================================================================================
                cpf: '',
                name: '',
                email: '',
                phone: '',
                // Case p_type = 2 =================================================================================
                cnpj: '',
                razao: '',
                //==================================================================================================
                // ADDRESS =========================================================================================
                step3: {
                    cep: '',
                    logradouro: '',
                    numero: '',
                    bairro: '',
                    complemento: '',
                    cidade: '',
                    estado: '',
                    invalido: false,
                    rural: false,
                    consultor: false,
                },
                // INSTALATION ADDRESS =============================================================================
                step4: {
                    cep_instalacao: '',
                    logradouro_instalacao: '',
                    numero_instalacao: '',
                    bairro_instalacao: '',
                    complemento_instalacao: '',
                    cidade_instalacao: '',
                    estado_instalacao: '',
                    invalido: false,
                    rural_instalacao: false,
                },
                step5: {
                    sub_grupo: 1, //1, 2, 3 or 4
                    // SUB-GRUPO A =================================================================================
                    grupos_a: [getGrupoA()],
                    // SUB-GRUPO B =================================================================================
                    grupos_b: [getGrupoB()],
                    // EM CONSTRUCAO ===============================================================================
                    grupo_c: {
                        unidade_consumidora: null,
                        telhado: null,
                    },
                    especial: {
                        anexo_fatura: [],
                        anexo_compra_energia: [],

                        ambiente_livre: null, //Sim, Nao
                        consumo_total_parcial: null, //Total, Parcial
                        limite_geracao: null, //Geracao mensal, Demanda contratada

                        geracao_mensal: null, //Number
                        demanda_contratada: null, //Number
                    },

                    categoria_atendimento: null,
                    tensao: '220-127V',
                    p_modulo: null,
                    consumo_desejado: null,

                    anexo_fatura: [],
                    anexo_imagens_local: [],

                    b_resumido: false,

                    tarifa_demanda: 0,
                    demanda_maxima: null,
                    demanda_contratada: null,

                    telhado: null,
                    comentario: null,
                    observacoes: null,
                    concessionaria: null,
                },
                cliente: {
                    id: null,
                    tipo_contato: null,
                }
            },
        }
    },

    watch: {
        'form_data.step4.estado_instalacao': function (value) {
            // var request = new Request('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'
            //     + this.form_data.step4.estado_instalacao + '/municipios/');
            // this.estado_state = fetch(request)
            //     .then(response => {
            //         if (response.status === 200) {
            //             return response.json();
            //         } else {
            //             console.log('API de localidades fora do ar no momento!')
            //             this.pushNotification('API de localidades fora do ar no momento!', 'error', 5000);
            //             return false;
            //         }
            //     })
            //     .then(response => {
            //         let aux = response.map(function (aux2) {
            //             return aux2.nome
            //         });
            //         this.cidades_instalacao = aux;
            //         return true;
            //     }).catch(error => {
            //         console.error(error);
            //         return false;
            //     });

            for (const municipio of this.municipios) {
                if (municipio['value'] === value) {
                    this.cidades_instalacao = municipio['children'].map(function (aux) {
                        return aux.label;
                    });
                }
            }

            //Atualiza os estados das concessionarias
            this.concessionarias = [];
            for (let i = 0; i < concessionarias.length; i++) {
                if (concessionarias[i].UF === this.form_data.step4.estado_instalacao) {
                    this.concessionarias.push(concessionarias[i].concessionaria);
                }
            }
            if (this.concessionarias.length === 1) {
                this.form_data.step5.concessionaria = this.concessionarias[0];
            }
        },

        'form_data.step3.estado': function (value) {
            for (const municipio of this.municipios) {
                if (municipio['value'] === value) {
                    this.cidades = municipio['children'].map(function (aux) {
                        return aux.label;
                    });
                    return true;
                }
            }

            // var request = new Request('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'
            //     + this.form_data.step3.estado + '/municipios/');
            //
            // this.estado_state = fetch(request)
            //     .then(response => {
            //         if (response.status === 200) {
            //             return response.json();
            //         } else {
            //             console.log('API de localidades fora do ar no momento!')
            //             this.pushNotification('API de localidades fora do ar no momento!', 'error', 5000);
            //             return false;
            //         }
            //     })
            //     .then(response => {
            //         let aux = response.map(function (aux2) {
            //             return aux2.nome
            //         });
            //         this.cidades = aux;
            //         return true;
            //     }).catch(error => {
            //         console.error(error);
            //         return false;
            //     });
        },
    },
    // METHODS =====================================================================================================
    methods: {
        getCustosAdicionais(e) {
            this.custos_adicionais = e;
        },

        // VALIDATE EACH STEP OF THE FORM AND SET THE NEXT STEP ================================================
        async submit() {
            this.$v.$touch();
            switch (this.step) {
                case 2:
                    if (this.is_p_valid()) {
                        this.$v.$reset();
                        this.loading_message = 'Validando informações, por favor, aguarde...';
                        this.loading_dialog = true;
                        try {
                            await this.$http.post('auth.step.2', this.form_data).then(resp => {
                                this.loading_dialog = false;
                                if (resp.data.error) {
                                    this.pushNotification(resp.data.message, 'error', 10000);
                                    this.steps.s2 = false;
                                    this.step = 1;
                                } else {
                                    this.form_data.cliente.id = resp.data.message;
                                    this.steps.s2 = true;
                                    this.step = 3;
                                }
                            }).catch(error => {
                                throw error
                            });
                        } catch (error) {
                            this.loading_dialog = false;
                            this.pushNotification('Ops, parece ter algum problema com sua conexão...', 'error', 10000);
                        }
                    } else {
                        this.steps.s2 = false;
                    }
                    break;
                case 3:
                    if (!this.$v.form_data.step3.$invalid) {
                        this.$v.$reset();
                        if (this.instalacaoIgual) {
                            this.sameInstalation();
                            this.steps.s3 = true;
                            this.step = 4;
                        } else {
                            this.steps.s3 = true;
                            this.step = 4;
                        }
                    } else {
                        this.steps.s3 = false;
                    }
                    break;
                case 4:
                    if (!this.$v.form_data.step4.$invalid) {
                        this.$v.$reset();
                        this.steps.s4 = true;
                        this.step = 5;
                    } else {
                        this.steps.s4 = false;
                    }
                    break;
                case 5:
                    let success = false;
                    this.checkResumido();
                    if (!this.custos_adicionais_invalid) {
                        switch (this.form_data.step5.sub_grupo) {
                            case 1:
                                if (
                                    !this.$v.form_data.step5.grupos_b.$invalid &&
                                    !this.$v.form_data.step5.telhado.$invalid &&
                                    !this.$v.form_data.step5.p_modulo.$invalid &&
                                    !this.$v.form_data.step5.categoria_atendimento.$invalid &&
                                    !this.$v.form_data.step5.tensao.$invalid &&
                                    !this.$v.form_data.step5.concessionaria.$invalid &&
                                    !this.$v.troca_titularidade.$invalid &&
                                    !this.$v.form_data.step5.anexo_fatura.$invalid
                                ) {
                                    this.$v.$reset();
                                    this.steps.s5 = true;
                                    success = true;
                                } else {
                                    this.steps.s5 = false;
                                }
                                break;
                            case 2:
                                if (
                                    !this.$v.form_data.step5.grupos_a.$invalid &&
                                    !this.$v.form_data.step5.demanda_contratada.$invalid &&
                                    !this.$v.form_data.step5.tarifa_demanda.$invalid &&
                                    !this.$v.form_data.step5.demanda_maxima.$invalid &&
                                    !this.$v.form_data.step5.telhado.$invalid &&
                                    !this.$v.form_data.step5.p_modulo.$invalid &&
                                    !this.$v.form_data.step5.categoria_atendimento.$invalid &&
                                    !this.$v.form_data.step5.anexo_fatura.$invalid &&
                                    !this.$v.form_data.step5.concessionaria.$invalid &&
                                    !this.$v.troca_titularidade.$invalid &&
                                    !this.$v.form_data.step5.tensao.$invalid
                                ) {
                                    this.$v.$reset();
                                    this.steps.s5 = true;
                                    success = true;
                                } else {
                                    this.steps.s5 = false;
                                }
                                break;
                            case 3:
                                if (!this.$v.form_data.step5.grupo_c.$invalid) {
                                    this.$v.$reset();
                                    this.steps.s5 = true;
                                    success = true;
                                } else {
                                    this.steps.s5 = false;
                                }
                                break;
                            case 4:
                                if (!this.$v.form_data.step5.especial.$invalid) {
                                    this.$v.$reset();
                                    this.steps.s5 = true;
                                    success = true;
                                } else {
                                    this.steps.s5 = false;
                                }
                                break;
                        }
                    } else {
                        this.pushNotification('Por favor, revise os custos adicionais.', 'error', 5000);
                        this.steps.s5 = false;
                    }
                    if (success === true) {
                        this.loading_message = 'Registrando proposta, por favor, aguarde...';
                        this.loading_dialog = true;
                        await this.$http.post('proposta', this.$data).then(resp => {
                            this.loading_dialog = false;
                            if (resp.data.error) {
                                alert(resp.data.message);
                                this.$router.push('/');
                            } else if (resp.data['results'][0]['error'] || resp.data['results'][1]['error']) {
                                alert(resp.data['results'][0]['error_description']);
                                this.$router.push('/');
                            } else if (resp.data['results'][3] !== undefined) {
                                if (resp.data['results'][3]['Erro']) {
                                    alert(resp.data['results'][3]['Mensagem']);
                                    this.$router.push('/');
                                } else {
                                    alert(resp.data['results'][3]['Mensagem']);
                                }
                            }
                            this.pushNotification(resp.data.message, 'success', 5000);
                            this.$router.push('/');
                        }).catch(error => {
                            this.loading_dialog = false;
                            alert(error.data);
                            this.$router.push('/');
                        });
                    }
            }
        },

        checkResumido() {
            this.form_data.step5.b_resumido = this.form_data.step5.grupos_b[0].simplificado;
        },

        async updateImagens(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.form_data.step5.anexo_imagens_local = [];
            for (let i = 0; i < files.length; i++) {
                this.form_data.step5.anexo_imagens_local.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },

        async updateAnexos(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.form_data.step5.anexo_fatura = [];
            for (let i = 0; i < files.length; i++) {
                this.form_data.step5.anexo_fatura.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },

        async updateAnexosEspecial(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.form_data.step5.especial.anexo_fatura = [];
            for (let i = 0; i < files.length; i++) {
                this.form_data.step5.especial.anexo_fatura.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },

        async updateAnexosFatura(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.form_data.step5.especial.anexo_fatura = [];
            for (let i = 0; i < files.length; i++) {
                this.form_data.step5.especial.anexo_fatura.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },

        async updateAnexosCompraEnergia(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.form_data.step5.especial.anexo_compra_energia = [];
            for (let i = 0; i < files.length; i++) {
                this.form_data.step5.especial.anexo_compra_energia.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },
        //==========================================================================================================
        // SET PERSON TYPE (FISICA OU JURIDICA) ====================================================================
        set_p_type(type) {
            this.clear();
            if (type === 'p1') {
                this.form_data.p_type = 1
            } else {
                this.form_data.p_type = 2
            }
            this.step = 2;
        },
        //==========================================================================================================

        // CLEAR THE FORM ==========================================================================================
        clear() {
            this.$v.$reset()
            this.form_data.cpf = ''
            this.form_data.cnpj = ''
            this.form_data.name = ''
            this.form_data.razao = ''
            this.form_data.email = ''
            this.form_data.phone = ''
        },
        //==========================================================================================================
        sameInstalation() {
            this.form_data.step4.cep_instalacao = this.form_data.step3.cep;
            this.form_data.step4.numero_instalacao = this.form_data.step3.numero;
            this.form_data.step4.estado_instalacao = this.form_data.step3.estado;
            this.form_data.step4.logradouro_instalacao = this.form_data.step3.logradouro;
            this.form_data.step4.complemento_instalacao = this.form_data.step3.complemento;
            this.form_data.step4.bairro_instalacao = this.form_data.step3.bairro;
            this.form_data.step4.cidade_instalacao = this.form_data.step3.cidade;
            this.form_data.step4.rural_instalacao = this.form_data.step3.rural;
        },
        // VERIFIES IF THE ACTUAL PERSON DATA IS VALID =============================================================
        is_p_valid() {
            if (this.form_data.p_type === 1) {
                return !(
                    this.$v.form_data.name.$error ||
                    this.$v.form_data.email.$error ||
                    this.$v.form_data.cpf.$error ||
                    this.$v.form_data.phone.$error);
            } else {
                return !(
                    this.$v.form_data.razao.$error ||
                    this.$v.form_data.email.$error ||
                    this.$v.form_data.cnpj.$error ||
                    this.$v.form_data.phone.$error);
            }
        }
        ,
        //==========================================================================================================

        // CLEAR ADDRESS INPUTS ====================================================================================
        clear_cep_form() {
            this.loading = false;
        }
        ,
        //==========================================================================================================

        //CALL BACK FUNCTION THAT WRITE THE ADDRESS VALUES ========================================================
        meu_callback(conteudo) {
            if (!("erro" in conteudo)) {
                //Atualiza os campos com os valores.
                if (this.step === 3) {
                    this.form_data.step3.estado = conteudo.uf;
                    this.form_data.step3.logradouro = conteudo.logradouro;
                    this.form_data.step3.bairro = conteudo.bairro;
                    this.form_data.step3.cidade = conteudo.localidade;
                } else {
                    this.form_data.step4.estado_instalacao = conteudo.uf;
                    this.form_data.step4.logradouro_instalacao = conteudo.logradouro;
                    this.form_data.step4.bairro_instalacao = conteudo.bairro;
                    this.form_data.step4.cidade_instalacao = conteudo.localidade;
                }
                this.loading = false;
                this.$v.$touch();
                return true;
            } else {
                //CEP não Encontrado.
                this.clear_cep_form();
                return false;
            }
        }
        ,
        //==========================================================================================================

        // FUNCTION THAT MAKES A GET REQUEST FROM A GIVEN CEP ======================================================
        pesquisacep(value) {
            if (!value) return true;

            let cep = value.replace(/\D/g, ''); // LEAVE ONLY DIGITS ===============================================
            let validacep = /^[0-9]{8}$/; // TAKE CEP TO VALID FORMAT ==============================================

            if (validacep.test(cep)) { // IF VALID, SET LOADING TRUE ==================================
                this.loading = true;

                const request = new Request('https://viacep.com.br/ws/' + cep + '/json/');
                return fetch(request)
                    .then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            return false;
                        }
                    })
                    .then(response => {
                        return this.meu_callback(response);
                    }).catch(error => {
                        console.error(error);
                        return false;
                    });
            } else { // NOT VALID CEP ==============================================================================
                this.clear_cep_form();
                return false;
            }
        }, //=======================================================================================================

        // CREATE A NEW SUB-GROUP AND PUSH TO THE ARRAY ============================================================
        createSubGrupoA() {
            if (this.form_data.step5.grupos_a.length >= 4) {
                alert('Número máximo de UCs atingido!');
            } else {
                alert('As unidades consumidoras para compensação precisam estar obrigatoriamente na mesma área de concessão')
                this.form_data.step5.grupos_a.push(getGrupoA());
            }
        }
        ,
        createSubGrupoB() {
            if (this.form_data.step5.grupos_b.length >= 10) {
                alert('Número máximo de UCs atingido!');
            } else {
                alert('As unidades consumidoras para compensação precisam estar obrigatoriamente na mesma área de concessão')
                this.form_data.step5.grupos_b.push(getGrupoB());
            }
        }
        ,
        // DELETE A NEW SUB-GROUP AND POP FROM THE ARRAY ============================================================
        deleteSubGrupoA(index) {
            if (this.form_data.step5.grupos_a.length > 1) {
                this.form_data.step5.grupos_a.splice(index, 1);
            }
        }
        ,
        deleteSubGrupoB(index) {
            if (this.form_data.step5.grupos_b.length > 1) {
                this.form_data.step5.grupos_b.splice(index, 1);
            }
        }
        ,

        //Set the sub_grupo that is going to be sent to bitrix (1: B, 2: A or 3: Construcao)
        setSubGrupo(sub_grupo) {
            this.form_data.step5.sub_grupo = sub_grupo;
        },

        // ERRORS FUNCTIONS ============================================================================================
        itemErrors(item) {
            const errors = []
            if (!item.$dirty) return errors
            !item.required && errors.push('Obrigatório.')
            return errors
        }
        ,

    }
    ,
    // END METHODS =================================================================================================
    validations: {
        troca_titularidade: {required},
        modalidade_consumo_geracao: {required},
        a_para_b: {required},

        form_data: {
            name: {required, maxLength: maxLength(50)},
            email: {
                required: requiredIf(function (form_data) {
                    return this.form_data.p_type === 2;
                }), email
            },
            razao: {required, maxLength: maxLength(50)},
            cpf: {
                required: requiredIf(function (form_data) {
                    return form_data.cnpj === '';
                }), validarCpf
            },
            cnpj: {required, validarCnpj},
            phone: {required},

            step3: {
                cep: {
                    required: requiredIf(function (step3) {
                        return !step3.rural;
                    }),
                    validarCepStep3
                },
                logradouro: {required},
                numero: {required},
                bairro: {required},
                complemento: {required},
                estado: {required, validaCidade},
                cidade: {required},
            },
            step4: {
                cep_instalacao: {
                    required: requiredIf(function (step4) {
                        return !step4.rural_instalacao;
                    }),
                    validarCepStep4
                },
                logradouro_instalacao: {required},
                numero_instalacao: {required},
                bairro_instalacao: {required},
                complemento_instalacao: {required},
                estado_instalacao: {required},
                cidade_instalacao: {required},
            },
            step5: {
                concessionaria: {required},

                categoria_atendimento: {required},
                tensao: {required},
                p_modulo: {required},
                telhado: {required},

                tarifa_demanda: {required},
                demanda_maxima: {required},
                demanda_contratada: {required},

                anexo_fatura: {required},

                grupos_b: {
                    $each: {
                        unidade_consumidora: {required},
                        tarifa_anual: {required: validarReal},
                        consumo_anual: {
                            Media: {
                                required: function (value) {
                                    return validaMedia(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                            Janeiro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                            Fevereiro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                            Marco: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                            Abril: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                            Maio: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                            Junho: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                            Julho: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                            Agosto: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                            Setembro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                            Outubro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                            Novembro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                            Dezembro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_b[0].simplificado);
                                }
                            },
                        },
                    },
                },

                grupos_a: {
                    $each: {
                        unidade_consumidora: {required},
                        valor_tarifa_ponta: {required: validarReal},
                        valor_tarifa_fora: {required: validarReal},
                        consumo_ponta: {
                            Media: {
                                required: function (value) {
                                    return validaMedia(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Janeiro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Fevereiro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Marco: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Abril: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Maio: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Junho: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Julho: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Agosto: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Setembro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Outubro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Novembro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Dezembro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                        },
                        consumo_fora: {
                            Media: {
                                required: function (value) {
                                    return validaMedia(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Janeiro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Fevereiro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Marco: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Abril: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Maio: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Junho: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Julho: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Agosto: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Setembro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Outubro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Novembro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                            Dezembro: {
                                required: function (value) {
                                    return validaMes(value, this.form_data.step5.grupos_a[0].simplificado);
                                }
                            },
                        },
                    },
                },

                grupo_c: {
                    telhado: {required}
                },

                especial: {
                    ambiente_livre: {required},
                    anexo_fatura: {required},
                    consumo_total_parcial: {required}
                }
            },
        },
    },

    async created() {
        await this.getInversoresModulos();
    },

    // ERRORS FUNCTIONS ============================================================================================
    computed: {
        nameErrors() {
            const errors = []
            if (!this.$v.form_data.name.$dirty) return errors
            !this.$v.form_data.name.maxLength && errors.push('Nome pode ter no máximo: 50 caracteres')
            !this.$v.form_data.name.required && errors.push('Nome é obrigatório.')
            return errors
        }
        ,
        razaoErrors() {
            const errors = []
            if (!this.$v.form_data.razao.$dirty) return errors
            !this.$v.form_data.razao.maxLength && errors.push('Razão social pode ter no máximo: 50 caracteres')
            !this.$v.form_data.razao.required && errors.push('Razão social é obrigatório.')
            return errors
        }
        ,
        emailErrors() {
            const errors = []
            if (!this.$v.form_data.email.$dirty) return errors
            !this.$v.form_data.email.email && errors.push('E-mail precisa ser válido.')
            !this.$v.form_data.email.required && errors.push('E-mail é obrigatório.')
            return errors
        }
        ,
        emailJurErrors() {
            const errors = []
            if (!this.$v.form_data.email_jur.$dirty) return errors
            !this.$v.form_data.email_jur && errors.push('E-mail precisa ser válido.')
            !this.$v.form_data.email_jur.required && errors.push('E-mail é obrigatório.')
            return errors
        }
        ,
        cpfErrors() {
            const errors = []
            if (!this.$v.form_data.cpf.$dirty) return errors
            !this.$v.form_data.cpf.required && errors.push('CPF é obrigatório.')
            !this.$v.form_data.cpf.validarCpf && errors.push('CPF precisa ser válido.')
            return errors
        }
        ,
        cnpjErrors() {
            const errors = []
            if (!this.$v.form_data.cnpj.$dirty) return errors
            !this.$v.form_data.cnpj.required && errors.push('CNPJ é obrigatório.')
            !this.$v.form_data.cnpj.validarCnpj && errors.push('CNPJ precisa ser válido.')
            return errors
        }
        ,
        phoneErrors() {
            const errors = []
            if (!this.$v.form_data.phone.$dirty) return errors
            !this.$v.form_data.phone.required && errors.push('Telefone é obrigatório.')
            return errors
        }
        ,
        phoneJurErrors() {
            const errors = []
            if (!this.$v.form_data.phone_jur.$dirty) return errors
            !this.$v.form_data.phone_jur.required && errors.push('Telefone é obrigatório.')
            return errors
        }
        ,
        cepErrors() {
            const errors = []
            if (!this.$v.form_data.step3.cep.$dirty) return errors
            !this.$v.form_data.step3.cep.required && errors.push('CEP é obrigatório.')
            !this.$v.form_data.step3.cep.validarCepStep3 && errors.push('CEP precisa ser válido.')
            return errors
        }
        ,
        logradouroErrors() {
            const errors = []
            if (!this.$v.form_data.step3.logradouro.$dirty) return errors
            !this.$v.form_data.step3.logradouro.required && errors.push('Logradouro é obrigatório.')
            return errors
        }
        ,
        numeroErrors() {
            const errors = []
            if (!this.$v.form_data.step3.numero.$dirty) return errors
            !this.$v.form_data.step3.numero.required && errors.push('Número é obrigatório.')
            return errors
        }
        ,
        bairroErrors() {
            const errors = []
            if (!this.$v.form_data.step3.bairro.$dirty) return errors
            !this.$v.form_data.step3.bairro.required && errors.push('Bairro é obrigatório.')
            return errors
        }
        ,
        complementoErrors() {
            const errors = []
            if (!this.$v.form_data.step3.complemento.$dirty) return errors
            !this.$v.form_data.step3.complemento.required && errors.push('Complemento é obrigatório.')
            return errors
        }
        ,
        cidadeErrors() {
            const errors = []
            if (!this.$v.form_data.step3.cidade.$dirty) return errors
            !this.$v.form_data.step3.cidade.required && errors.push('Cidade é obrigatório.')
            return errors
        }
        ,
        estadoErrors() {
            const errors = []
            if (!this.$v.form_data.step3.estado.$dirty) return errors
            !this.$v.form_data.step3.estado.required && errors.push('Estado é obrigatório.')
            !this.$v.form_data.step3.estado.validaCidade
            return errors
        }
        ,
        cepInstalacaoErrors() {
            const errors = []
            if (!this.$v.form_data.step4.cep_instalacao.$dirty) return errors
            !this.$v.form_data.step4.cep_instalacao.required && errors.push('CEP é obrigatório.')
            !this.$v.form_data.step4.cep_instalacao.validarCepStep4 && errors.push('CEP precisa ser válido.')
            return errors
        }
        ,
        logradouroInstalacaoErrors() {
            const errors = []
            if (!this.$v.form_data.step4.logradouro_instalacao.$dirty) return errors
            !this.$v.form_data.step4.logradouro_instalacao.required && errors.push('Logradouro é obrigatório.')
            return errors
        }
        ,
        numeroInstalacaoErrors() {
            const errors = []
            if (!this.$v.form_data.step4.numero_instalacao.$dirty) return errors
            !this.$v.form_data.step4.numero_instalacao.required && errors.push('Número é obrigatório.')
            return errors
        }
        ,
        bairroInstalacaoErrors() {
            const errors = []
            if (!this.$v.form_data.step4.bairro_instalacao.$dirty) return errors
            !this.$v.form_data.step4.bairro_instalacao.required && errors.push('Bairro é obrigatório.')
            return errors
        }
        ,
        complementoInstalacaoErrors() {
            const errors = []
            if (!this.$v.form_data.step4.complemento_instalacao.$dirty) return errors
            !this.$v.form_data.step4.complemento_instalacao.required && errors.push('Complemento é obrigatório.')
            return errors
        }
        ,
        cidadeInstalacaoErrors() {
            const errors = []
            if (!this.$v.form_data.step4.cidade_instalacao.$dirty) return errors
            !this.$v.form_data.step4.cidade_instalacao.required && errors.push('Cidade é obrigatório.')
            return errors
        }
        ,
        estadoInstalacaoErrors() {
            const errors = []
            if (!this.$v.form_data.step4.estado_instalacao.$dirty) return errors
            !this.$v.form_data.step4.estado_instalacao.required && errors.push('Estado é obrigatório.')
            !this.$v.form_data.step4.estado_instalacao.validaCidade
            return errors
        }
        ,
        mConsumoPontaErrors() {
            const errors = []
            if (!this.$v.form_data.media_consumo_ponta.$dirty) return errors
            !this.$v.form_data.media_consumo_ponta.required && errors.push('Média de consumo de ponta é obrigatório.')
            return errors
        }
        ,
        concessionariaErrors() {
            const errors = []
            if (!this.$v.form_data.step5.concessionaria.$dirty) return errors
            !this.$v.form_data.step5.concessionaria.required && errors.push('Obrigatório.')
            return errors
        }
        ,
        comentarioErrors() {
            const errors = []
            if (!this.$v.form_data.step5.comentario.$dirty) return errors
            !this.$v.form_data.step5.comentario.required && errors.push('Obrigatório.')
            return errors
        }
        ,
        dmandamErrors() {
            const errors = []
            if (!this.$v.form_data.demanda_maxima.$dirty) return errors
            !this.$v.form_data.demanda_maxima.required && errors.push('Demanda máxima é obrigatório.')
            return errors
        }
        ,
        vtarifapErrors() {
            const errors = []
            if (!this.$v.form_data.valor_tarifa_ponta.$dirty) return errors
            !this.$v.form_data.valor_tarifa_ponta.required && errors.push('Valor da tarifa ponta é obrigatório.')
            return errors
        }
        ,
        vtarifafErrors() {
            const errors = []
            if (!this.$v.form_data.valor_tarifa_fora.$dirty) return errors
            !this.$v.form_data.valor_tarifa_fora.required && errors.push('Valor da tarifa fora ponta é obrigatório.')
            return errors
        }
        ,
        mAnualErrors() {
            const errors = []
            if (!this.$v.form_data.media_anual.$dirty) return errors
            !this.$v.form_data.media_anual.required && errors.push('Média anual é obrigatório.')
            return errors
        }
        ,
        vTarifaAnualErrors() {
            const errors = []
            if (!this.$v.form_data.tarifa_anual.$dirty) return errors
            !this.$v.form_data.tarifa_anual.required && errors.push('Tarifa anual é obrigatório.')
            return errors
        }
        ,
        telhadoErrors() {
            const errors = []
            if (!this.$v.form_data.telhado.$dirty) return errors
            !this.$v.form_data.telhado.required && errors.push('Telhado é obrigatório.')
            return errors
        }
        ,
        UCErrorsB() {
            const errors = []
            if (!this.$v.form_data.step5.grupos_b.$each.$iter[0].unidade_consumidora.$dirty) return errors
            !this.$v.form_data.step5.grupos_b.$each.$iter[0].unidade_consumidora.required && errors.push('UC é obrigatório.')
            return errors
        }
        ,
    }
    ,
    // END ERRORS FUNCTIONS ============================================================================================

}
// END SCRIPT ====================================================================================================
</script>

<style>
.flat-tab {
    border-radius: 0px !important;
}
</style>

