//Settings =================================================================================================
export const lista_modalidade = [
    {value: '7327', text: 'Venda Direta - Enerzee'},
    {value: '7329', text: 'Convênio - Cocari'},
];
//======================================================================================================
export const lista_troca_titularidade = [
    {value: '7589', text: 'Sim'},
    {value: '7591', text: 'Não'},
];
//======================================================================================================
export const lista_a_para_b = [
    {value: '7597', text: 'Sim'},
    {value: '7599', text: 'Não'},
];
//======================================================================================================
export const lista_cadastro_compensacao = [
    {value: '7593', text: 'Sim'},
    {value: '7595', text: 'Não'},
];
//======================================================================================================
export const lista_reforco_estrutura = [
    {value: '7549', text: 'Sim'},
    {value: '7551', text: 'Não'},
];
//======================================================================================================
export const lista_posto_transformacao = [
    {value: '51', text: 'Sim'},
    {value: '53', text: 'Não'},
];
//======================================================================================================
export const lista_estudo_estrutura = [
    {value: '7149', text: 'Sim'},
    {value: '7151', text: 'Não'},
];
//======================================================================================================
export const responsabilidades_cabine = [
    {value: '7433', text: 'Enerzee'},
    {value: '7431', text: 'Cliente'},
];
//======================================================================================================
export const responsabilidades_subestacao = [
    {value: '7437', text: 'Enerzee'},
    {value: '7435', text: 'Cliente'},
];
//======================================================================================================
export const responsabilidades_transformador = [
    {value: '7441', text: 'Enerzee'},
    {value: '7439', text: 'Cliente'},
];
//======================================================================================================
export const responsabilidades_padrao_entrada = [
    {value: '7445', text: 'Enerzee'},
    {value: '7443', text: 'Cliente'},
];
//======================================================================================================
export const responsabilidades_infraestrutura = [
    {value: '7449', text: 'Enerzee'},
    {value: '7447', text: 'Cliente'},
];
//======================================================================================================
export const responsabilidades_extensao = [
    {value: '7453', text: 'Enerzee'},
    {value: '7451', text: 'Cliente'},
];
//======================================================================================================
export const telhados = [
    {value: "Ao Solo", text: "Ao Solo"},
    {value: "Cerâmico", text: "Cerâmico"},
    {value: "CarPort", text: "CarPort"},
    {value: "Fibrocimento (Metálico)", text: "Fibrocimento"},
    {value: "Metálico", text: "Metálico"},
    {value: "Laje", text: "Laje"},
    {value: "Tracker", text: "Tracker"},
];
//======================================================================================================
export const telhados_adm = [
    "Ao Solo",
    "Cerâmico",
    "CarPort",
    "Fibrocimento (Madeira)",
    "Fibrocimento (Metálico)",
    "Metálico",
    "Laje",
    "Tracker",
];
//======================================================================================================
export const categorias = [
    "Monofásico",
    "Bifásico",
    "Trifásico",
];
//======================================================================================================
export const lista_tensao = [
    "380-220V",
    "220-127V",
    "254-127V",
];
//======================================================================================================
export const p_modulos = [
    {value: 'TSM-PE15H-345W', enabled: true, adm_only: false},
    {value: '405 Wp - TRINA', enabled: true, adm_only: false},
    {value: '400 Wp - TRINA', enabled: true, adm_only: false},
    {value: '350 Wp - TRINA', enabled: true, adm_only: false},
    {value: '345 Wp - TRINA', enabled: true, adm_only: false},
    {value: '390 Wp (FINAME) - BYD', enabled: true, adm_only: false},
    {value: '370 Wp (FINAME) - BYD', enabled: true, adm_only: false},
    {value: '335 Wp (FINAME) - BYD', enabled: true, adm_only: false},
];
//======================================================================================================
export const m_inversor = [
    {value: 'SIW300H M020', enabled: true, adm_only: false},
    {value: 'SIW300H M030', enabled: true, adm_only: false},
    {value: 'SIW300H M040', enabled: true, adm_only: false},
    {value: 'SIW300H M050', enabled: true, adm_only: false},
    {value: 'SIW500H ST012', enabled: true, adm_only: false},
    {value: 'SIW500H ST015', enabled: true, adm_only: false},
    {value: 'SIW500H ST020', enabled: true, adm_only: false},
    {value: 'SIW500H ST030', enabled: true, adm_only: false},
    {value: 'SIW500H ST040', enabled: true, adm_only: false},
    {value: 'SIW500H ST060', enabled: true, adm_only: false},
    {value: 'SIW500H ST100 HV', enabled: true, adm_only: false},
    {value: 'SIW200 M085', enabled: true, adm_only: false},
    {value: 'SIW200 M050', enabled: true, adm_only: false},
    {value: 'SIW200 M030', enabled: true, adm_only: false},
    {value: 'SIW600 T011-22', enabled: true, adm_only: false},
    {value: 'SIW600 T010-40', enabled: true, adm_only: false},
    {value: 'SIW600 T020-44', enabled: true, adm_only: false},
];
export const cabines = [
    'Cabine Metálica | 380-220V | 2000kVA',
    'Cabine Metálica | 380-220V | 5000kVA',
];
export const subestacoes = [
    'Subestação | 220-127V | 112,5kVA',
    'Subestação | 220-127V | 225kVA',
    'Subestação | 220-127V | 300kVA',
    'Subestação | 254-127V | 15kVA',
    'Subestação | 254-127V | 25kVA',
    'Subestação | 380-220V | 112,5kVA',
    'Subestação | 380-220V | 225kVA',
    'Subestação | 380-220V | 300kVA',
];
export const transformadores = [
    'Transformador de Acoplamento | 380-220V | 300kVA',
    'Transformador de Acoplamento | 380-220V | 500kVA',
];
export const padroes_entrada = [
    'Padrão Bifásico | 220-127V | 40A | Até 10,1kW',
    'Padrão Bifásico | 220-127V | 50A | Até 12,7kW',
    'Padrão Bifásico | 220-127V | 70A | Até 17,7kW',
    'Padrão Trifásico | 220-127V | 40A | Até 14kW',
    'Padrão Trifásico | 220-127V | 50A | Até 17,5kW',
    'Padrão Trifásico | 220-127V | 80A | Até 24,5kW',
    'Padrão Trifásico | 220-127V | 100A | Até 35,1kW',
    'Padrão Trifásico | 220-127V | 150A | Até 52,5kW',
    'Padrão Trifásico | 220-127V | 200A | Até 75kW',
];
