//Settings =================================================================================================
export const lista_metodos = [
    {value: '105', text: 'À Vista'},
    {value: '107', text: 'Financiamento'},
    {value: '7553', text: 'Customizado'},
];
//======================================================================================================
export const lista_financiamento = [
    {value: '113', text: 'Aprovar o projeto na concessionária'},
    {value: '497', text: 'Protocolar o projeto na concessionária'},
    {value: '167', text: 'Nenhuma'},
];
//======================================================================================================
