<template>
    <v-app>
        <v-menu transition="slide-x-reverse-transition" fixed nudge-bottom="60" left max-height="500" min-width="300">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="readNotifications" icon>
                    <v-badge left :content="new_notifications" :value="new_notifications" color="green" overlap>
                        <v-icon>mdi-bell</v-icon>
                    </v-badge>
                </v-btn>
            </template>
            <v-list shaped>
                <v-list-item-group v-model="item" color="green">
                    <v-list-item disabled v-if="notifications.length < 1" color="primary">
                        <v-list-item-title v-text="no_notification_message"></v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>mdi-emoticon-happy-outline</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                    <v-list-item disabled v-if="notifications.length > 0" v-for="n in notifications.length" :key="n" link>
                        <v-list-item-title @click="openDetalhes(notifications[n-1][1])"
                                           v-text="notifications[n-1][0]"
                        ></v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="notifications.length > 0" @click="clearNotifications">
                        <v-list-item-title>{{ 'Limpar todas as notificações' }}</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>mdi-notification-clear-all</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </v-app>
</template>

<script>
import Vue from "vue";
import Fases from "../Detalhes/Fases";

export default {
    name: "notifications",

    props: {
        user_id: {
            type: Number,
            required: true,
        }
    },

    watch: {
        item: function () {
            this.item = this.notifications.length;
        },

        notification: function () {
            this.setNotifications();
        },
    },

    created() {
        this.listenToNotification();
        this.setNotifications();
    },

    data() {
        return {
            myDialog: false,
            notification: [],
            notifications: [],
            no_notification_message: 'Você não tem notificações!',
            new_notifications: 0,
            item: 0,
        }
    }
    ,

    methods: {
        openDetalhes(id) {
            this.$emit("open_detalhes", id);
        },

        async setNotifications() {
            //get all the notifications
            await this.$http.get('notification').then(resp => {
                this.notifications = resp.data;
                this.item = this.notifications.length;
            });
            //get unread notifications count
            await this.setUnreadNotifications();
        },

        listenToNotification() {
            // Echo.private('App.User.' + this.user_id).notification((notification) => {
            //         //Add +1 to the notifications count
            //         this.new_notifications += 1;
            //         //Show up the notification
            //         this.notification = notification;
            //         this.showNotification(notification[0], notification[1]);
            //     });
        },

        showNotification(message, type) {
            this.$toast.open({
                message: message,
                type: type,
                duration: 5000,
            });
        },

        setUnreadNotifications() {
            this.$http.get('notifications/unread').then(resp => {
                if (resp.data > 99) {
                    this.new_notifications = '99+';
                } else {
                    this.new_notifications = resp.data;
                }
            });
        },

        readNotifications() {
            this.$http.get('notifications/read').then(resp => {
                this.new_notifications = 0;
            });
        },

        clearNotifications() {
            this.notifications = [];
            this.new_notifications = 0;
            this.$http.get('notifications/clear');
        },
    },

}
</script>
