<template>
    <v-card class="ma-2" min-width="200" height="auto">
        <div class="card-header">Chat</div>
        <div id="chat-body" class="card-body overflow-y-auto"
             style="height: 450px; scroll-behavior: smooth !important;">
            <ChatMessages :user="this.user" :messages="messages"></ChatMessages>
        </div>
        <ChatForm @messagesent="sendMessage" :user="this.user" :loading="loading"
                  class="mr-4 ml-4 mt-4"></ChatForm>
    </v-card>
</template>

<script>
import ChatForm from "./ChatForm";
import ChatMessages from "./ChatMessages";

export default {
    name: "ChatCard",
    props: ['messages', 'user', 'loading'],

    components: {
        ChatForm,
        ChatMessages,
    },

    methods: {
        sendMessage(newMessage) {
            this.$emit('messagesent', newMessage);
        }
    }

}
</script>

<style scoped>

</style>
