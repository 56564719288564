<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.mobile" @keydown.esc="setDialog(false)" @click:outside="setDialog(false)" v-model="dialog" max-width="800">
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-overlay :value="loading_dialog">
            <v-dialog v-model="loading_dialog" hide-overlay persistent width="400">
                <v-card color="primary" dark>
                    <v-card-text class="pt-2">
                        {{ loading_message }}
                        <v-progress-linear indeterminate color="white" class="mt-6 mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-overlay>
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-card min-width="700" class="pa-2">
            <v-card-title class="headline">Informe os dados para financiamento</v-card-title>
            <v-card-text>
                Escolha um dos métodos de financiamento abaixo.

                <v-row>
                    <v-col v-if="anexo_st.length !== 0">
                        <v-card :color="color_st" class="ma-2" @click="setColor(2)">
                            <v-img contain :src="require('../imagens/logo-santander-icon-2048.png')"
                                   height="200px">
                            </v-img>
                            <v-card-title class="title justify-content-center">
                                Financiamento Santander
                            </v-card-title>
                        </v-card>
                        <v-btn
                            @click="showUrl(anexo_st)" class="mb-4" block color="success">
                            BAIXAR ANEXO
                            <v-icon class="ml-2">mdi-download</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col v-if="anexo_bv.length !== 0">
                        <v-card :color="color_bv" class="ma-2" @click="setColor(1)">
                            <v-img contain :src="require('../imagens/logo-bv-financeira-1536.png')"
                                   height="200px">
                            </v-img>
                            <v-card-title class="title justify-content-center">
                                Financiamento BV
                            </v-card-title>
                        </v-card>
                        <v-btn
                            @click="showUrl(anexo_bv)" class="mb-4" block color="success">
                            BAIXAR ANEXO
                            <v-icon class="ml-2">mdi-download</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-file-input
                        label="Comprovante de Endereço"
                        @change="updateAnexosEndereco"
                        class="ma-4"
                        outlined
                        multiple
                        @input="$v.comprovante_endereco.$touch()"
                        @blur="$v.comprovante_endereco.$touch()"
                        :error-messages="itemErrors($v.comprovante_endereco)"
                    ></v-file-input>
                    <v-file-input
                        v-if="proposta.COMPANY_ID != 0"
                        label="Relação dos últimos 12 faturamentos"
                        @change="updateAnexosFaturamento"
                        class="ma-4"
                        outlined
                        multiple
                    ></v-file-input>
                    <v-file-input
                        label="Comprovante de renda"
                        @change="updateAnexosRenda"
                        class="ma-4"
                        outlined
                        multiple
                        @input="$v.comprovante_renda.$touch()"
                        @blur="$v.comprovante_renda.$touch()"
                        :error-messages="itemErrors($v.comprovante_renda)"
                    ></v-file-input>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="red darken-1" text @click="setDialog(false)">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="submit">Aceitar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {lista_financiamento, lista_metodos} from "../../data/metodo_pagamento";
import {required} from 'vuelidate/lib/validators';

export default {
    name: "Financiamento",

    props: {
        state: {
            type: Boolean,
            required: true
        },
        proposta: {
            required: true
        },
        anexo_bv: {
            type: Array,
            required: false
        },
        anexo_st: {
            type: Array,
            required: false
        },
    },

    data() {
        return {
            dialog: false,
            loading_dialog: false,
            loading_message: null,

            color_bv: null,
            color_st: null,
            financiamento: null,

            comprovante_endereco: [],
            relacao_faturamento: [],
            comprovante_renda: [],
        }
    },

    watch: {
        state: function () {
            this.dialog = this.state;
        }
    },

    methods: {
        itemErrors(item) {
            const errors = []
            if (!item.$dirty) return errors
            !item.required && errors.push('Obrigatório.')
            return errors
        },

        showUrl(url) {
            window.open(url);
        },

        setLoading(state, message) {
            this.loading_dialog = state;
            this.loading_message = message;
        },

        setColor(choice) {
            switch (choice) {
                case 1:
                    this.color_bv = '#B6D6F7';
                    this.financiamento = 7159;
                    this.color_st = '';
                    break;
                case 2:
                    this.color_st = '#B6D6F7';
                    this.financiamento = 7161;
                    this.color_bv = '';
                    break;
            }
        },

        async updateAnexosEndereco(e) {
            if (e) {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = error => reject(error);
                });

                let files = e;
                this.comprovante_endereco = [];
                for (let i = 0; i < files.length; i++) {
                    this.comprovante_endereco.push({
                        'fileData': [files[i].name, await toBase64(files[i])]
                    });
                }
            }
        },

        async updateAnexosFaturamento(e) {
            if (e) {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = error => reject(error);
                });

                let files = e;
                this.relacao_faturamento = [];
                for (let i = 0; i < files.length; i++) {
                    this.relacao_faturamento.push({
                        'fileData': [files[i].name, await toBase64(files[i])]
                    });
                }
            }
        },

        async updateAnexosRenda(e) {
            if (e) {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = error => reject(error);
                });

                let files = e;
                this.comprovante_renda = [];
                for (let i = 0; i < files.length; i++) {
                    this.comprovante_renda.push({
                        'fileData': [files[i].name, await toBase64(files[i])]
                    });
                }
            }
        },

        async submit() {
            this.$v.$touch();
            if ((this.color_bv || this.color_st) && !this.$v.$invalid) {
                await this.setLoading(true, 'Um momento...');
                await this.$http.post('proposta/financiamento', [this.$data, this.proposta.ID]).then(resp => {
                    this.setDialog(false);
                    this.setLoading(false, '');
                    this.$toast.open({
                        message: 'Financiamento cadastrado com sucesso!',
                        type: "success",
                        duration: 2500,
                    });
                    document.location.reload(true);
                });
            }
        },

        setDialog(state) {
            this.dialog = state;
            this.$emit("update-dialog", state);
        },
    },

    mounted() {
        // console.log([this.anexo_bv, this.anexo_st])
        this.dialog = this.state;
    },

    validations: {
        comprovante_endereco: {required},
        comprovante_renda: {required}
    },
}
</script>

<style scoped>

</style>
