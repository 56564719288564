<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.mobile" @keydown.esc="setDialog(false)" @click:outside="setDialog(false)" v-model="dialog" max-width="500">
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-overlay :value="loading_dialog">
            <v-dialog v-model="loading_dialog" hide-overlay persistent width="400">
                <v-card color="primary" dark>
                    <v-card-text class="pt-2">
                        {{ loading_message }}
                        <v-progress-linear indeterminate color="white" class="mt-6 mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-overlay>
        <!--Dialog ---------------------------------------------------------------------------------------------------->

        <v-card class="pl-4 pr-4">
            <v-card-title class="headline">Informe o motivo da readequação</v-card-title>
            <v-card-text>
                <v-textarea
                    label="Motivo da readequação"
                    outlined
                    v-model="$v.motivo.$model"
                    @blur="$v.motivo.$touch()"
                    :error-messages="itemErrors($v.motivo)"
                ></v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-btn color="red darken-1" text @click="setDialog(false)">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn class="ml-auto" color="green darken-1" text @click="submit">Enviar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {required} from "vuelidate/lib/validators";

export default {
    name: "Readequacao",

    props: {
        readequacao: {
            type: Boolean,
            required: true
        },
        id: {
            required: true
        },
    },

    watch: {
        readequacao: function () {
            this.dialog = this.readequacao;
        }
    },

    methods: {
        setLoading(state, message) {
            this.loading_dialog = state;
            this.loading_message = message;
        },

        async submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                await this.setLoading(true, 'Um momento...');
                await this.$http.post('proposta/solicitar_readequacao', [this.$data, this.id]).then(resp => {
                    this.setDialog(false);
                    this.setLoading(false, '');
                    this.$toast.open({
                        message: 'Sua readequação foi solicitada!',
                        type: "success",
                        duration: 2500,
                    });
                    document.location.reload(true);
                });
            }
        },

        setDialog(state) {
            this.dialog = state;
            this.$emit("update-dialog", state);
        },

        itemErrors(item) {
            const errors = []
            if (!item.$dirty) return errors
            !item.required && errors.push('Obrigatório.')
            return errors
        },
    },

    mounted() {
        this.dialog = this.readequacao;
    },

    data() {
        return {
            dialog: false,
            loading_dialog: null,
            loading_message: null,

            motivo: '',
        }
    },

    validations: {
        motivo: {required},
    }
}
</script>
