export const concessionarias = [
    {
        "concessionaria": "EBO",
        "UF": "PB"
    },
    {
        "concessionaria": "Ceprag",
        "UF": "SC"
    },
    {
        "concessionaria": "COOPERNORTE",
        "UF": "RS"
    },
    {
        "concessionaria": "COOPERSUL",
        "UF": "RS"
    },
    {
        "concessionaria": "Ceron",
        "UF": "RO"
    },
    {
        "concessionaria": "Eletroacre",
        "UF": "AC"
    },
    {
        "concessionaria": "CEA",
        "UF": "AP"
    },
    {
        "concessionaria": "Cepisa",
        "UF": "PI"
    },
    {
        "concessionaria": "CERMC",
        "UF": "SP"
    },
    {
        "concessionaria": "CEEE-D",
        "UF": "RS"
    },
    {
        "concessionaria": "Chesp",
        "UF": "GO"
    },
    {
        "concessionaria": "DMED",
        "UF": "MG"
    },
    {
        "concessionaria": "AmE",
        "UF": "AM"
    },
    {
        "concessionaria": "Roraima Energia",
        "UF": "RR"
    },
    {
        "concessionaria": "Ceral Anitápolis",
        "UF": "SC"
    },
    {
        "concessionaria": "Cerim",
        "UF": "SP"
    },
    {
        "concessionaria": "Cetril",
        "UF": "SP"
    },
    {
        "concessionaria": "CPFL Piratininga",
        "UF": "SP"
    },
    {
        "concessionaria": "EDP SP",
        "UF": "SP"
    },
    {
        "concessionaria": "CEB-DIS",
        "UF": "DF"
    },
    {
        "concessionaria": "Celg-D",
        "UF": "GO"
    },
    {
        "concessionaria": "Cedri",
        "UF": "SP"
    },
    {
        "concessionaria": "CEGERO",
        "UF": "SC"
    },
    {
        "concessionaria": "Cejama",
        "UF": "SC"
    },
    {
        "concessionaria": "Ceraça",
        "UF": "SC"
    },
    {
        "concessionaria": "Cerbranorte",
        "UF": "SC"
    },
    {
        "concessionaria": "Cerej",
        "UF": "SC"
    },
    {
        "concessionaria": "Cergal",
        "UF": "SC"
    },
    {
        "concessionaria": "Cergapa",
        "UF": "SC"
    },
    {
        "concessionaria": "Cergral",
        "UF": "SC"
    },
    {
        "concessionaria": "Cermoful",
        "UF": "SC"
    },
    {
        "concessionaria": "Cerpalo",
        "UF": "SC"
    },
    {
        "concessionaria": "CERSAD",
        "UF": "SC"
    },
    {
        "concessionaria": "Cersul",
        "UF": "SC"
    },
    {
        "concessionaria": "Certrel",
        "UF": "SC"
    },
    {
        "concessionaria": "CERVAM",
        "UF": "SP"
    },
    {
        "concessionaria": "CODESAM",
        "UF": "SC"
    },
    {
        "concessionaria": "Coopera",
        "UF": "SC"
    },
    {
        "concessionaria": "Coopercocal",
        "UF": "SC"
    },
    {
        "concessionaria": "Coopermila",
        "UF": "SC"
    },
    {
        "concessionaria": "COOPERZEM",
        "UF": "SC"
    },
    {
        "concessionaria": "Coorsel",
        "UF": "SC"
    },
    {
        "concessionaria": "Cedrap",
        "UF": "SP"
    },
    {
        "concessionaria": "Cooperaliança",
        "UF": "SC"
    },
    {
        "concessionaria": "EFLJC",
        "UF": "SC"
    },
    {
        "concessionaria": "Eflul",
        "UF": "SC"
    },
    {
        "concessionaria": "Ienergia",
        "UF": "SC"
    },
    {
        "concessionaria": "Cemar",
        "UF": "MA"
    },
    {
        "concessionaria": "EPB",
        "UF": "PB"
    },
    {
        "concessionaria": "Elektro",
        "UF": "SP"
    },
    {
        "concessionaria": "Forcel",
        "UF": "PR"
    },
    {
        "concessionaria": "Celesc-DIS",
        "UF": "SC"
    },
    {
        "concessionaria": "ELFSM",
        "UF": "ES"
    },
    {
        "concessionaria": "Celpa",
        "UF": "PA"
    },
    {
        "concessionaria": "EDP ES",
        "UF": "ES"
    },
    {
        "concessionaria": "CASTRO - DIS",
        "UF": "PR"
    },
    {
        "concessionaria": "Ceral DIS",
        "UF": "PR"
    },
    {
        "concessionaria": "CERFOX",
        "UF": "RS"
    },
    {
        "concessionaria": "Ceriluz",
        "UF": "RS"
    },
    {
        "concessionaria": "Cermissões",
        "UF": "RS"
    },
    {
        "concessionaria": "Certel",
        "UF": "RS"
    },
    {
        "concessionaria": "CERTHIL",
        "UF": "RS"
    },
    {
        "concessionaria": "Cooperluz",
        "UF": "RS"
    },
    {
        "concessionaria": "Coprel",
        "UF": "RS"
    },
    {
        "concessionaria": "Creluz-D",
        "UF": "RS"
    },
    {
        "concessionaria": "Creral",
        "UF": "RS"
    },
    {
        "concessionaria": "Demei",
        "UF": "RS"
    },
    {
        "concessionaria": "Eletrocar",
        "UF": "RS"
    },
    {
        "concessionaria": "Hidropan",
        "UF": "RS"
    },
    {
        "concessionaria": "MuxEnergia",
        "UF": "RS"
    },
    {
        "concessionaria": "Sul Sudeste",
        "UF": "SP"
    },
    {
        "concessionaria": "Ceris",
        "UF": "SP"
    },
    {
        "concessionaria": "Eletropaulo",
        "UF": "SP"
    },
    {
        "concessionaria": "ETO",
        "UF": "TO"
    },
    {
        "concessionaria": "Cocel",
        "UF": "PR"
    },
    {
        "concessionaria": "Copel-DIS",
        "UF": "PR"
    },
    {
        "concessionaria": "EMG",
        "UF": "MG"
    },
    {
        "concessionaria": "ENF",
        "UF": "RJ"
    },
    {
        "concessionaria": "RGE",
        "UF": "RS"
    },
    {
        "concessionaria": "Cemirim",
        "UF": "SP"
    },
    {
        "concessionaria": "Cerpro",
        "UF": "SP"
    },
    {
        "concessionaria": "Certaja",
        "UF": "RS"
    },
    {
        "concessionaria": "Cemig-D",
        "UF": "MG"
    },
    {
        "concessionaria": "Sulgipe",
        "UF": "SE"
    },
    {
        "concessionaria": "Uhenpal",
        "UF": "RS"
    },
    {
        "concessionaria": "Cernhe",
        "UF": "SP"
    },
    {
        "concessionaria": "Ceal",
        "UF": "AL"
    },
    {
        "concessionaria": "Celpe",
        "UF": "PE"
    },
    {
        "concessionaria": "CERAL ARARUAMA",
        "UF": "RJ"
    },
    {
        "concessionaria": "CERCI",
        "UF": "RJ"
    },
    {
        "concessionaria": "Cercos",
        "UF": "SE"
    },
    {
        "concessionaria": "Ceres",
        "UF": "RJ"
    },
    {
        "concessionaria": "Ceripa",
        "UF": "SP"
    },
    {
        "concessionaria": "Coelba",
        "UF": "BA"
    },
    {
        "concessionaria": "Cosern",
        "UF": "RN"
    },
    {
        "concessionaria": "Enel CE",
        "UF": "CE"
    },
    {
        "concessionaria": "ESE",
        "UF": "SE"
    },
    {
        "concessionaria": "CERRP",
        "UF": "SP"
    },
    {
        "concessionaria": "CPFL Paulista",
        "UF": "SP"
    },
    {
        "concessionaria": "EMS",
        "UF": "MS"
    },
    {
        "concessionaria": "EMT",
        "UF": "MT"
    },
    {
        "concessionaria": "Enel RJ",
        "UF": "RJ"
    },
    {
        "concessionaria": "Light",
        "UF": "RJ"
    },
    {
        "concessionaria": "CPFL Santa Cruz (nova)",
        "UF": "SP"
    }
]
