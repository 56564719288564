<template>
    <ChatCard @messagesent="sendMessage" :loading="loading" :user="user" :messages="messages"></ChatCard>
</template>

<script>
import Dashboard from "../Dashboard";
import ChatCard from "../Chat/ChatCard";

export default {
    name: "Fases",

    components: {
        ChatCard,
    },

    props: {
        stage_id: {
            type: String,
            required: true,
        },

        user: {
            type: String,
            required: true,
        },

        deal: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            messages: [],
            loading: false,
            fase: 0,
        }
    },

    watch: {
        deal: function () {
            this.fetchMessages();
        },
        messages: function () {
            //Scroll back to bottom
            this.scrollTop();
        },
        stage_id: function () {
            this.setFase();
        },
    },

    created() {
        this.fetchMessages();
    },

    mounted() {
        this.setFase();
    },

    methods: {
        setFase() {
            let fases = ['Prospecção', 'Gerando proposta', 'Elaborando proposta', 'Aprovação da diretoria', 'Aprovação do cliente', 'Viabilidade técnica', 'Valide as informações da visita realizada', 'Readequando proposta', 'Método de pagamento', 'Geração de Contrato', 'Validação do Contrato', 'Confirmação financeira', 'Negócio fechado!'];
            for (const key in fases) {
                if (fases[key] === this.stage_id) {
                    this.fase = Number(key) + Number(1);
                }
            }
        },

        clearMessages() {
            this.messages = [];
        },

        listenToNotification() {
            Echo.private('App.User.' + this.user_id)
                .notification((notification) => {
                    //Add +1 to the notifications count
                    this.new_notifications += 1;
                    //Show up the notification
                    this.notification = notification;
                    this.$toast.open({
                        message: notification[0],
                        type: "info",
                        duration: 5000,
                    });
                });
        },

        scrollTop() {
            if (this.messages) {
                let div = document.getElementById("chat-body");
                div.scrollTo(0, 0);
            }
        },

        async fetchMessages() {
            await this.$http.get('chat/messages/' + this.deal).then(response => {
                this.messages = response.data;
                for (let index in this.messages) {
                    //Converte as datas do padrao ISO em UTC para String local =============================================
                    let monName = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
                    let CREATED = new Date(this.messages[index]['CREATED']);
                    this.messages[index]['CREATED'] = CREATED.getUTCDate() + ' de ' + monName[CREATED.getMonth()] + ' de ' + CREATED.getFullYear();
                }
            }).catch(function (error) {
                this.$router.push('home');
            });
        },

        getIndex(property) {
            let index = [];
            for (let i in property) {
                index.push(i);
            }
            index.sort(function (a, b) {
                return a === b ? 0 : (a > b ? 1 : -1);
            });
            return property[index];
        },

        async sendMessage(message) {
            this.loading = true;
            await this.$http.post('chat/messages', {
                message: message,
                deal: this.deal,
                author: this.user
            }).then((response) => {
                this.fetchMessages();
            });
            this.loading = false;
        }
    },
}
</script>

<style scoped>

</style>
