<template>
    <div v-if="!$vuetify.breakpoint.xs">
        <v-app class="body">
            <v-card flat outlined>
                <v-toolbar flat color="primary" dark>
                    <v-app-bar-nav-icon @click="toggleHidden"></v-app-bar-nav-icon>
                    <v-toolbar-title>Configurações</v-toolbar-title>
                </v-toolbar>
                <v-tabs vertical>
                    <v-tab :hidden="configs.breakpoint_xs">
                        <v-icon left>
                            mdi-current-ac
                        </v-icon>
                        Inversores
                    </v-tab>
                    <v-tab :hidden="configs.breakpoint_xs">
                        <v-icon left>
                            mdi-solar-power
                        </v-icon>
                        Módulos
                    </v-tab>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-container fluid>
                                    <div v-for="(model, index) in this.$v.m_inversor.$each.$iter">
                                        <v-row>
                                            <v-col cols="2" class="d-inline-flex justify-center align-center">
                                                <v-checkbox
                                                    label="Habilitado"
                                                    v-model.lazy="m_inversor[index].habilitado"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="4" class="flex justify-center align-center">
                                                <v-text-field
                                                    label="Modelo de inversor"
                                                    v-model.lazy="model.modelo.$model"
                                                    @blur="model.modelo.$touch()"
                                                    :error-messages="itemErrors(model.modelo)"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="2" class="flex justify-center align-center">
                                                <v-text-field
                                                    label="Potência"
                                                    v-money="configs.potencia"
                                                    v-model.lazy="m_inversor[index].potencia"
                                                    @blur="model.potencia.$touch()"
                                                    :error-messages="itemErrors(model.potencia)"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="d-inline-flex justify-center align-center">
                                                <v-checkbox
                                                    label="Operadores e Administradores apenas"
                                                    v-model.lazy="m_inversor[index].adm_only"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="1" class="d-inline-flex justify-center align-center">
                                                <v-btn icon @click="deleteInversor(index)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                    </div>
                                </v-container>
                            </v-card-text>
                            <div class="d-flex mb-6 ml-12 mr-12">
                                <v-btn color="primary" @click="createNewInversor">
                                    Novo Inversor
                                </v-btn>
                                <v-btn class="ml-auto" color="success" @click="submit">
                                    Salvar
                                </v-btn>
                            </div>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-container fluid>
                                    <div v-for="(model, index) in this.$v.p_modulos.$each.$iter">
                                        <v-row no-gutters>
                                            <v-col cols="2" class="d-inline-flex justify-center align-center">
                                                <v-checkbox
                                                    label="Habilitado"
                                                    v-model.lazy="p_modulos[index].habilitado"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col class="flex justify-center align-center">
                                                <v-text-field
                                                    label="Modelo do Módulo"
                                                    v-model.lazy="model.modelo.$model"
                                                    @blur="model.modelo.$touch()"
                                                    :error-messages="itemErrors(model.modelo)"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="d-inline-flex justify-center align-center">
                                                <v-checkbox
                                                    label="Operadores e Administradores apenas"
                                                    v-model.lazy="p_modulos[index].adm_only"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="1" class="d-inline-flex justify-center align-center">
                                                <v-btn icon @click="deleteModulo(index)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                    </div>
                                </v-container>
                            </v-card-text>
                            <div class="d-flex mb-6 ml-12 mr-12">
                                <v-btn color="primary" @click="createNewModulo">
                                    Novo Módulo
                                </v-btn>
                                <v-btn class="ml-auto" color="success" @click="submit">
                                    Salvar
                                </v-btn>
                            </div>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>

                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-app>
    </div>


    <div v-else-if="$vuetify.breakpoint.xs">
        <v-app class="body">
            <v-card flat outlined>
                <v-toolbar flat color="primary" dark>
                    <v-app-bar-nav-icon @click="toggleHidden"></v-app-bar-nav-icon>
                    <v-toolbar-title>Configurações</v-toolbar-title>
                </v-toolbar>
                <v-tabs vertical>
                    <v-tab :hidden="configs.breakpoint_xs">
                        <v-icon left>
                            mdi-current-ac
                        </v-icon>
                        Inversores
                    </v-tab>
                    <v-tab :hidden="configs.breakpoint_xs">
                        <v-icon left>
                            mdi-solar-power
                        </v-icon>
                        Módulos
                    </v-tab>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-container fluid>
                                    <div v-for="(model, index) in this.$v.m_inversor.$each.$iter">
                                        <v-row>
                                            <v-col class="d-inline-flex justify-center align-center">
                                                <v-checkbox
                                                    label="Habilitado"
                                                    v-model.lazy="m_inversor[index].habilitado"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col class="d-inline-flex justify-center align-center">
                                                <v-btn icon @click="deleteInversor(index)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-col class="flex justify-center align-center">
                                            <v-text-field
                                                label="Modelo de inversor"
                                                v-model.lazy="model.modelo.$model"
                                                @blur="model.modelo.$touch()"
                                                :error-messages="itemErrors(model.modelo)"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col class="flex justify-center align-center">
                                            <v-text-field
                                                label="Potência"
                                                v-money="configs.potencia"
                                                v-model.lazy="m_inversor[index].potencia"
                                                @blur="model.potencia.$touch()"
                                                :error-messages="itemErrors(model.potencia)"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col class="d-inline-flex justify-center align-center">
                                            <v-checkbox
                                                label="Operadores e Administradores apenas"
                                                v-model.lazy="m_inversor[index].adm_only"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-divider></v-divider>
                                    </div>
                                </v-container>
                                <div class="d-flex ma-4">
                                    <v-btn color="primary" @click="createNewInversor">
                                        Novo Inversor
                                    </v-btn>
                                    <v-btn class="ml-auto" color="success" @click="submit">
                                        Salvar
                                    </v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <v-container fluid>
                                    <div v-for="(model, index) in this.$v.p_modulos.$each.$iter">
                                        <v-row>
                                            <v-col class="d-inline-flex justify-center align-center">
                                                <v-checkbox
                                                    label="Habilitado"
                                                    v-model.lazy="p_modulos[index].habilitado"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col class="d-inline-flex justify-center align-center">
                                                <v-btn icon @click="deleteModulo(index)">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-col class="flex justify-center align-center">
                                            <v-text-field
                                                label="Modelo de inversor"
                                                v-model.lazy="model.modelo.$model"
                                                @blur="model.modelo.$touch()"
                                                :error-messages="itemErrors(model.modelo)"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col class="d-inline-flex justify-center align-center">
                                            <v-checkbox
                                                label="Operadores e Administradores apenas"
                                                v-model.lazy="p_modulos[index].adm_only"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-divider></v-divider>
                                    </div>
                                </v-container>
                                <div class="d-flex ma-4">
                                    <v-btn color="primary" @click="createNewModulo">
                                        Novo Módulo
                                    </v-btn>
                                    <v-btn class="ml-auto" color="success" @click="submit">
                                        Salvar
                                    </v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>

                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-app>
    </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import LazyHydrate from 'vue-lazy-hydration';

export default {
    name: "Modulos_Inversores_CRUD",
    props: ['user'],

    components: {
        LazyHydrate
    },

    data() {
        return {
            configs: {
                breakpoint_xs: false,
                potencia: {
                    thousands: '',
                    prefix: '',
                    suffix: '',
                    decimal: '.',
                    precision: 2,
                    masked: false
                },
            },
            m_inversor: [],
            p_modulos: [],
        }
    },

    async created() {
        this.setBreakpoint_xs();
        await this.getInversoresModulos();
        this.m_inversor = this.inversores;
        this.p_modulos = this.modulos;
    },

    computed: {
        breakpoint_xs() {
            return this.$vuetify.breakpoint.xs;
        }
    },

    watch: {
        breakpoint_xs() {
            this.configs.breakpoint_xs = this.$vuetify.breakpoint.xs;
        }
    },

    methods: {
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.setInversoresModulos();
            } else {
                this.pushNotification('Os campos precisam ser preenchidos!', 'error', 5000);
            }
        },

        setBreakpoint_xs() {
            this.configs.breakpoint_xs = this.$vuetify.breakpoint.xs;
        },

        toggleHidden() {
            this.configs.breakpoint_xs = !this.configs.breakpoint_xs;
        }
    },

    validations: {
        p_modulos: {
            $each: {
                modelo: {required},
            }
        },
        m_inversor: {
            $each: {
                modelo: {required},
                potencia: {required: validaPotencia},
            }
        }
    },
}

function validaPotencia(value) {
    return value !== "0.00";
}

</script>

<style scoped>

</style>
