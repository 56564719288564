<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.mobile" @keydown.esc="setDialog(false)" @click:outside="setDialog(false)"
              v-model="dialog" max-width="500">
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-overlay :value="loading_dialog">
            <v-dialog v-model="loading_dialog" hide-overlay persistent width="400">
                <v-card color="primary" dark>
                    <v-card-text class="pt-2">
                        {{ loading_message }}
                        <v-progress-linear indeterminate color="white" class="mt-6 mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-overlay>
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-card class="pl-4 pr-4">
            <v-card-title class="headline">Solicitação Sim. de Financiamento</v-card-title>
            <v-card-text>
                <v-file-input
                    v-if="proposta.COMPANY_ID != 0"
                    class="ma-4"
                    label="Contrato social ou Requerimento de empresário"
                    outlined
                    multiple
                    @change="updateAnexosContrato"
                    @input="$v.anexos_contrato.$touch()"
                    @blur="$v.anexos_contrato.$touch()"
                    :error-messages="itemErrors($v.anexos_contrato)"
                ></v-file-input>
                <v-file-input
                    class="ma-4"
                    label="Documento"
                    outlined
                    multiple
                    @change="updateAnexos"
                    @input="$v.anexos_documento.$touch()"
                    @blur="$v.anexos_documento.$touch()"
                    :error-messages="itemErrors($v.anexos_documento)"
                ></v-file-input>
                Documento com foto (CNH, RG ou qualquer outro que seja válido, Não pode estar vencida).
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="submit">Enviar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {required, requiredIf} from "vuelidate/lib/validators";

export default {
    name: "SolicitarFinanciamento",

    props: {
        state: {
            type: Boolean,
            required: true
        },
        proposta: {
            required: true
        },
    },

    data() {
        return {
            dialog: false,
            anexos_documento: [],
            anexos_contrato: [],

            loading_dialog: false,
            loading_message: null,
        }
    },

    watch: {
        state: function () {
            this.dialog = this.state;
        }
    },

    methods: {
        itemErrors(item) {
            const errors = []
            if (!item.$dirty) return errors
            !item.required && errors.push('Obrigatório.')
            return errors
        },

        setLoading(state, message) {
            this.loading_dialog = state;
            this.loading_message = message;
        },

        async submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                await this.setLoading(true, 'Um momento...');
                await this.$http.post('proposta/solicitar_financiamento', [this.$data, this.proposta.ID]).then(resp => {
                    this.setDialog(false);
                    this.setLoading(false, '');
                    this.pushNotification('Financiamento solicitado com sucesso!', 'success', 5000);
                    document.location.reload(true);
                }).catch(error => {
                    this.setDialog(false);
                    this.setLoading(false, '');
                    this.pushNotification(error.response.data, 'error', 7500);
                });
            }
        },

        async updateAnexos(e) {
            if (e) {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = error => reject(error);
                });

                let files = e;
                this.anexos_documento = [];
                for (let i = 0; i < files.length; i++) {
                    this.anexos_documento.push({
                        'fileData': [files[i].name, await toBase64(files[i])]
                    });
                }
            }
        },

        async updateAnexosContrato(e) {
            if (e) {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = error => reject(error);
                });

                let files = e;
                this.anexos_contrato = [];
                for (let i = 0; i < files.length; i++) {
                    this.anexos_contrato.push({
                        'fileData': [files[i].name, await toBase64(files[i])]
                    });
                }
            }
        },

        setDialog(state) {
            this.dialog = state;
            this.$emit("update-dialog", state);
        },
    },

    mounted() {
        // console.log(this.proposta);
        this.dialog = this.state;
    },

    validations: {
        anexos_contrato: {
            required: requiredIf(function () {
                return this.proposta.COMPANY_ID != 0;
            })
        },
        anexos_documento: {required}
    }
}
</script>
