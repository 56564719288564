<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.mobile" @keydown.esc="setDialog(false)" @click:outside="setDialog(false)" v-model="dialog"
              max-width="600">
        <!--overlay ---------------------------------------------------------------------------------------------------->
        <v-overlay :value="loading_dialog">
            <v-dialog v-model="loading_dialog" hide-overlay persistent width="400">
                <v-card color="primary" dark>
                    <v-card-text class="pt-2">
                        {{ loading_message }}
                        <v-progress-linear indeterminate color="white" class="mt-6 mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-overlay>
        <!--overlay ---------------------------------------------------------------------------------------------------->

        <v-card min-width="400">
            <v-card-title class="headline">Atenção!</v-card-title>
            <v-card-text>
                <div v-if="numero_modulos && numero_modulos < 16">
                    Seu sistema possui menos de 16 módulos! Caso tenha em mãos imagens do local, poderá anexar antes de
                    prosseguir para a análise de viabilidade.
                    <v-switch
                        v-model="anexar"
                        label="Anexar arquivos">
                    </v-switch>
                    <div v-if="anexar">
                        <v-file-input
                            label="Fotos do telhado"
                            v-model="validate_fotos_telhado"
                            multiple
                            outlined
                            dense
                            class="mt-5"
                            @change="updateAnexos"
                            @input.native="$v.validate_fotos_telhado.$touch()"
                            @blur="$v.validate_fotos_telhado.$touch()"
                            :error-messages="itemErrors($v.validate_fotos_telhado)"
                        ></v-file-input>
                        <v-file-input
                            label="Imagens da estrutura do telhado"
                            v-model="validate_imagens_estrutura"
                            multiple
                            outlined
                            dense
                            class="mt-5"
                            @change="updateAnexos_2"
                            @input.native="$v.validate_imagens_estrutura.$touch()"
                            @blur="$v.validate_imagens_estrutura.$touch()"
                            :error-messages="itemErrors($v.validate_imagens_estrutura)"
                        ></v-file-input>
                        <v-file-input
                            label="Fotos do padrão"
                            v-model="validate_fotos_padrao"
                            multiple
                            outlined
                            dense
                            class="mt-5"
                            @change="updateAnexos_3"
                            @input.native="$v.validate_fotos_padrao.$touch()"
                            @blur="$v.validate_fotos_padrao.$touch()"
                            :error-messages="itemErrors($v.validate_fotos_padrao)"
                        ></v-file-input>
                        <v-file-input
                            label="Fotos do disjuntor"
                            v-model="validate_fotos_disjuntor"
                            multiple
                            outlined
                            dense
                            class="mt-5"
                            @change="updateAnexos_4"
                            @input.native="$v.validate_fotos_disjuntor.$touch()"
                            @blur="$v.validate_fotos_disjuntor.$touch()"
                            :error-messages="itemErrors($v.validate_fotos_disjuntor)"
                        ></v-file-input>
                        <v-file-input
                            label="Fotos do local de instalação dos inversores"
                            v-model="validate_fotos_local"
                            multiple
                            outlined
                            dense
                            class="mt-5"
                            @change="updateAnexos_5"
                            @input.native="$v.validate_fotos_local.$touch()"
                            @blur="$v.validate_fotos_local.$touch()"
                            :error-messages="itemErrors($v.validate_fotos_local)"
                        ></v-file-input>
                    </div>
                </div>
                O informe de aceite do cliente fará com que o negócio avance
                para a análise de viabilidade.
                <br><br>
                Tenha certeza de que esta é a proposta que o cliente deu o
                aceite!
            </v-card-text>
            <v-card-actions>
                <v-btn color="red darken-1" text @click="setDialog(false)">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="updateStage(2)">Aceitar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {requiredIf} from "vuelidate/lib/validators";

export default {
    name: "AceiteCliente",

    props: {
        aceite: {
            type: Boolean,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        numero_modulos: {
            required: true
        },
        stage_id: {
            required: true
        },
    },

    data() {
        return {
            dialog: false,
            loading_dialog: null,
            loading_message: null,

            anexar: true,
            validate_fotos_telhado: [],
            validate_imagens_estrutura: [],
            validate_fotos_padrao: [],
            validate_fotos_disjuntor: [],
            validate_fotos_local: [],

            fotos_telhado: [],
            imagens_estrutura: [],
            fotos_padrao: [],
            fotos_disjuntor: [],
            fotos_local: [],
        }
    },

    watch: {
        aceite: function () {
            this.dialog = this.aceite;
        },
    },

    methods: {
        async updateAnexos(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.fotos_telhado = [];
            for (let i = 0; i < files.length; i++) {
                this.fotos_telhado.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },
        //==========================================================================================================
        async updateAnexos_2(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.imagens_estrutura = [];
            for (let i = 0; i < files.length; i++) {
                this.imagens_estrutura.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },
        //==========================================================================================================
        async updateAnexos_3(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.fotos_padrao = [];
            for (let i = 0; i < files.length; i++) {
                this.fotos_padrao.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },
        //==========================================================================================================
        async updateAnexos_4(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.fotos_disjuntor = [];
            for (let i = 0; i < files.length; i++) {
                this.fotos_disjuntor.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },
        //==========================================================================================================
        async updateAnexos_5(e) {
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(',')[1]);
                reader.onerror = error => reject(error);
            });

            let files = e;
            this.fotos_local = [];
            for (let i = 0; i < files.length; i++) {
                this.fotos_local.push({
                    'fileData': [files[i].name, await toBase64(files[i])]
                });
            }
        },
        //==========================================================================================================

        async updateStage(value) {
            this.$v.$touch();
            if ((this.$v.$invalid && this.numero_modulos >= 16) || !this.$v.$invalid || (this.$v.$invalid && !this.anexar)) {
                await this.setLoading(true, 'Um momento, por favor...');
                await this.$http.put('proposta/' + this.id, {
                    event: 'aceite_cliente',
                    anexos: this.$data,
                    numero_modulos: this.numero_modulos,
                    stage_id: this.stage_id
                }).then(resp => {
                    this.setDialog(false);
                    document.location.reload(true);
                });
                await this.setLoading(false, '');
            }
        },

        setLoading(state, message) {
            this.loading_dialog = state;
            this.loading_message = message;
        },

        setDialog(state) {
            this.dialog = state;
            this.$emit("update-dialog", state);
        },

        itemErrors(item) {
            const errors = []
            if (!item.$dirty) return errors
            !item.required && errors.push('Obrigatório.')
            return errors
        },
    },

    mounted() {
        this.dialog = this.aceite;
    },

    validations: {
        validate_fotos_telhado: {
            required: requiredIf(function (object) {
                return object.anexar;
            }),
        },
        validate_imagens_estrutura: {
            required: requiredIf(function (object) {
                return object.anexar;
            }),
        },
        validate_fotos_padrao: {
            required: requiredIf(function (object) {
                return object.anexar;
            }),
        },
        validate_fotos_disjuntor: {
            required: requiredIf(function (object) {
                return object.anexar;
            }),
        },
        validate_fotos_local: {
            required: requiredIf(function (object) {
                return object.anexar;
            }),
        },
    }

}
</script>

<style scoped>

</style>
