<template>
    <v-dialog @keydown.esc="setDetails(false)" @click:outside="setDetails(false)" v-model="myDetails"
              transition="scale-transition" max-width="1200" scrollable :fullscreen="$vuetify.breakpoint.mobile">
        <v-card>
            <v-card-title class="pa-0">
                <v-toolbar flat dark color="primary">
                    <v-toolbar-title v-if="propostas_cliente[index_page]">{{ propostas_cliente[index_page].TITLE }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Detalhes</v-toolbar-title>
                    <v-btn icon dark @click="setDetails(false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
            </v-card-title>
            <v-card-text flat style="background-color: #fafafa;" class="pl-10 pr-10"
                         v-if="propostas_cliente[index_page]">
                <v-responsive min-width="1024">
                    <v-row align="center" no-gutters class="d-flex justify-center">
                        <v-pagination
                            class="mt-4"
                            v-model="page"
                            :length="propostasLength"
                        ></v-pagination>
                    </v-row>
                    <v-row align="center">
                        <v-divider></v-divider>
                    </v-row>
                    <v-row align="start" no-gutters>
                        <!-- Tabela com dados da proposta ------------------------------------------------------------->
                        <v-col sm="auto">
                            <v-row align="center">
                                <v-container>
                                    <v-row align="center" no-gutters>
                                        <v-card style="background-color: #fafafa;" class="h5" flat tile>
                                            Dados da proposta:
                                        </v-card>
                                    </v-row>
                                    <v-row align="center" no-gutters>
                                        <v-col cols="auto" sm="auto" class="font-weight-bold">
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                ID da proposta
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Valor à vista
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Vencimento
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Tempo restante
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Potência pico do sistema
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Número de módulos
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Potência dos módulos
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Tipo de telhado (Estrutura de fixação)
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Consumo anual considerado
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Produção Anual de Energia
                                            </v-card>
                                        </v-col>
                                        <v-col cols="auto" sm="auto">
                                            <v-card class="pa-4" outlined tile>
                                                {{ propostas_cliente[index_page].ID }}
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div
                                                    v-if="propostas_cliente[index_page].UF_CRM_1594641774 === 'Aguardando orçamento'">
                                                    Sem informações
                                                </div>
                                                <div v-else>
                                                    {{ propostas_cliente[index_page].UF_CRM_1594641774 }}
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="propostas_cliente[index_page].UF_CRM_1601920651">
                                                    {{ propostas_cliente[index_page].UF_CRM_1601920651 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="propostas_cliente[index_page].AVAILABLE">
                                                    {{ propostas_cliente[index_page].AVAILABLE }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="propostas_cliente[index_page].UF_CRM_1594640613">
                                                    {{ propostas_cliente[index_page].UF_CRM_1594640613 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="propostas_cliente[index_page].UF_CRM_1594641197">
                                                    {{ propostas_cliente[index_page].UF_CRM_1594641197 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="propostas_cliente[index_page].UF_CRM_1594641341">
                                                    {{ propostas_cliente[index_page].UF_CRM_1594641341 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="propostas_cliente[index_page].UF_CRM_1594641266">
                                                    {{ propostas_cliente[index_page].UF_CRM_1594641266 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="propostas_cliente[index_page].UF_CRM_1594640657">
                                                    {{ propostas_cliente[index_page].UF_CRM_1594640657 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="propostas_cliente[index_page].UF_CRM_1594641231">
                                                    {{ propostas_cliente[index_page].UF_CRM_1594641231 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-row>
                        </v-col>
                        <!-- Tabela com dados da proposta ------------------------------------------------------------->

                        <!-- Botoes de gerenciamento ------------------------------------------------------------------>
                        <v-col sm="auto" style="min-width: 30%" class="ml-auto mr-2">
                            <v-row align="center">
                                <v-container>
                                    <v-row align="center" no-gutters justify="start">
                                        <h5 style="color: #1976D2">Situação da proposta:</h5>
                                        <v-divider class="ml-2"></v-divider>
                                    </v-row>
                                    <v-row align="center" no-gutters justify="center" class="ma-2"
                                           v-if="propostas_cliente[index_page].AVAILABLE !== 'Vencida'">
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Proposta incompleta'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Proposta incompleta</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'C8:LOSE'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-close</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Proposta perdida</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Prospecção'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-clock-start</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Prospecção</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-cogs</v-icon>
                                                        <h5>Gerando Proposta</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Gerando proposta'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-cogs</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Gerando Proposta</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-wrench</v-icon>
                                                        <h5>Elaborando proposta</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Elaborando proposta'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-wrench</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Elaborando proposta</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-check-decagram</v-icon>
                                                        <h5>Aprovação da diretoria</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Aprovação da diretoria'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-check-decagram</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Aprovação da diretoria</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-account-check</v-icon>
                                                        <h5>Aprovação do cliente</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Aprovação do cliente'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-account-check</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Aprovação do cliente</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-beaker-question</v-icon>
                                                        <h5>Viabilidade técnica</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Viabilidade técnica'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-beaker-question</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Viabilidade técnica</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-beaker-question</v-icon>
                                                        <h5>Validação da visita</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Validação da visita'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-beaker-question</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Validação da visita</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-beaker-question</v-icon>
                                                        <h5>Readequando proposta</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Aguardando novo endereço'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-map-clock</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Aguardando novo endereço</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-beaker-question</v-icon>
                                                        <h5>Viabilidade técnica</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Readequando proposta'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-beaker-question</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Readequando proposta</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-cash-multiple</v-icon>
                                                        <h5>Método de pagamento</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Método de pagamento'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-cash-multiple</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Método de pagamento</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-refresh</v-icon>
                                                        <h5>Validação Método de Pagamento</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Validação Método de Pagamento'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-refresh</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Validação Método de Pagamento</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-file</v-icon>
                                                        <h5>Geração de Contrato</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Geração de Contrato'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-file</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Geração de Contrato</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-book-check</v-icon>
                                                        <h5>Validação do Contrato</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Validação do Contrato'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-book-check</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Validação do Contrato</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-book-check</v-icon>
                                                        <h5>Validação da Readequação</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Validação da Readequação'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-book-check</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Validação da Readequação</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-cash-check</v-icon>
                                                        <h5>Confirmação financeira</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Confirmação financeira'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-cash-check</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Confirmação financeira</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-check-bold</v-icon>
                                                        <h5>Negócio fechado!</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="propostas_cliente[index_page].STAGE_ID === 'Negócio fechado!'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-check-bold</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Negócio fechado!</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else-if="propostas_cliente[index_page].AVAILABLE === 'Vencida'">
                                        <v-col justify="center">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-timer-off</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Proposta vencida!</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mb-4"></v-divider>

                                    <!-- Função de reemissão da proposta desativada temporariamente -->
                                    <!--                                    <div v-if="(propostas_cliente[index_page].AVAILABLE === '0 Dia(s)'-->
                                    <!--                                   || propostas_cliente[index_page].AVAILABLE === '1 Dia(s)'-->
                                    <!--                                   || propostas_cliente[index_page].AVAILABLE === 'Vencida'-->
                                    <!--                                   || propostas_cliente[index_page].AVAILABLE === '2 Dia(s)')-->
                                    <!--                                   && (propostas_cliente[index_page].UF_CRM_1602771188 > '0' && reemissao[index_page] !== true)"-->
                                    <!--                                    >-->
                                    <!--                                        <v-btn-->
                                    <!--                                            @click="submitReemissao(propostas_cliente[index_page].ID, propostas_cliente[index_page].UF_CRM_1602771188)"-->
                                    <!--                                            class="ma-2" block color="error">-->
                                    <!--                                            Reemitir proposta-->
                                    <!--                                            <v-icon class="ml-2">mdi-alert-circle</v-icon>-->
                                    <!--                                        </v-btn>-->
                                    <!--                                        <v-divider class="mb-4"></v-divider>-->
                                    <!--                                    </div>-->

                                    <v-btn v-if="['Aprovação do cliente'].includes(propostas_cliente[index_page].STAGE_ID) || propostas_cliente[index_page].AVAILABLE === 'Vencida'"
                                           @click="readequacao=true" class="ma-2" block color="warning">
                                        Solicitar readequação da proposta
                                        <v-icon class="ml-2">mdi-account-edit</v-icon>
                                    </v-btn>
                                    <Readequacao :readequacao="readequacao" :id="propostas_cliente[index_page].ID" @update-dialog="(state) => this.readequacao = state"></Readequacao>

                                    <div v-if="propostas_cliente[index_page].AVAILABLE !== 'Vencida' || [
                                            'Viabilidade técnica',
                                            'Validação da visita',
                                            'Aguardando novo endereço',
                                            'Readequando proposta',
                                            'Método de pagamento',
                                            'Validação Método de Pagamento',
                                            'Geração de Contrato',
                                            'Validação do Contrato',
                                            'Validação da Readequação',
                                            'Confirmação financeira',
                                            'Negócio fechado!',
                                        ].includes(propostas_cliente[index_page].STAGE_ID)">
                                        <v-row class="mt-5" v-if="[
                                            'Prospecção',
                                            'Gerando proposta',
                                            'Elaborando proposta',
                                            'Aprovação da diretoria',
                                            'Viabilidade técnica',
                                            'Validação da visita',
                                            'Readequando proposta',
                                            'Validação Método de Pagamento',
                                            'Geração de Contrato',
                                            'Validação do Contrato',
                                            'Confirmação financeira',
                                            'Negócio fechado!',
                                        ].includes(propostas_cliente[index_page].STAGE_ID)">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-alert-circle-outline
                                                        </v-icon>
                                                        <h5>Nenhuma ação necessária.<br>Aguarde as próximas etapas!</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-row>

                                        <v-btn
                                            v-if="propostas_cliente[index_page].STAGE_ID === 'Aguardando novo endereço'"
                                            @click="$router.push('/proposta/' + propostas_cliente[index_page].ID + '/edit')"
                                            class="ma-2" block color="success">
                                            Informar novo endereço
                                            <v-icon class="ml-2">mdi-map-marker-plus</v-icon>
                                        </v-btn>

                                        <v-btn
                                            v-if="propostas_cliente[index_page].STAGE_ID === 'Aprovação do cliente'"
                                            @click="aceite=true" class="ma-2" block color="success">
                                            Informar aceite do cliente
                                            <v-icon class="ml-2">mdi-account-check</v-icon>
                                        </v-btn>
                                        <AceiteCliente :id="propostas_cliente[index_page].ID" :aceite="aceite" :numero_modulos="propostas_cliente[index_page].UF_CRM_1594641197" @update-dialog="(state) => this.aceite = state" :stage_id="propostas_cliente[index_page].STAGE_ID"></AceiteCliente>

                                        <v-btn v-if="propostas_cliente[index_page].STAGE_ID === 'Aprovação do cliente'"
                                               @click="negacao=true" class="ma-2" block color="error">
                                            Informar recusa do cliente
                                            <v-icon class="ml-2">mdi-account-cancel</v-icon>
                                        </v-btn>
                                        <NegacaoCliente :deal_id="propostas_cliente[index_page].ID" :negacao="negacao" @update-dialog="(state) => this.negacao = state"></NegacaoCliente>

                                        <v-btn
                                            v-if="['Validação Método de Pagamento', 'Método de pagamento', 'Aprovação do cliente'].includes(propostas_cliente[index_page].STAGE_ID) && !propostas_cliente[index_page].UF_CRM_1600378114"
                                            @click="desconto=true" class="ma-2" block color="primary">
                                            Solicitar desconto
                                            <v-icon class="ml-2">mdi-account-cash</v-icon>
                                        </v-btn>
                                        <Desconto :id="propostas_cliente[index_page].ID" :desconto="desconto"
                                                  @update-dialog="(state) => this.desconto = state">
                                        </Desconto>

                                        <v-btn
                                            v-if="(propostas_cliente[index_page].STAGE_ID === 'Método de pagamento' || propostas_cliente[index_page].STAGE_ID === 'Aprovação do cliente') &&
                                        propostas_cliente[index_page].UF_CRM_1600112349 !== 'Financiamento Solicitado'"
                                            @click="$set(financiamento, index_page, false); sim_financiamento_dialog=true"
                                            class="ma-2"
                                            block color="primary">
                                            Solicitar sim. de financiamento
                                            <v-icon class="ml-2">mdi-clock</v-icon>
                                        </v-btn>
                                        <solicitar-financiamento
                                            @update-dialog="(state) => this.sim_financiamento_dialog = state"
                                            :proposta="propostas_cliente[index_page]"
                                            :state="sim_financiamento_dialog">
                                        </solicitar-financiamento>

                                        <v-btn
                                            v-if="status_consultor[index_page] !== true && user.tipo_contato === '286'"
                                            @click="submitConsultor( propostas_cliente[index_page].ID)"
                                            class="ma-2" block color="primary">
                                            Tornar consultor
                                            <v-icon class="ml-2">mdi-account</v-icon>
                                        </v-btn>

                                        <v-row align="center" class="mt-4 mb-2" no-gutters justify="start">
                                            <v-col v-if="![
                                            'Prospecção',
                                            'Gerando proposta',
                                            'Elaborando proposta',
                                            'Aprovação da diretoria',
                                            'Proposta Aprovada',
                                        ].includes(propostas_cliente[index_page].STAGE_ID)">
                                                <v-row align="center" no-gutters justify="start"
                                                       v-if="propostas_cliente[index_page].UF_CRM_1571148076689 || (propostas_cliente[index_page].UF_CRM_1591821000 === false || propostas_cliente[index_page].UF_CRM_1591821000.length === 0) && (propostas_cliente[index_page].UF_CRM_1591821184956.length !== 0 || propostas_cliente[index_page].UF_CRM_1591821198462.length !== 0)">
                                                    <h5 style="color: #FF5F5F">Novas informações:</h5>
                                                    <v-divider class="ml-2"></v-divider>
                                                </v-row>
                                                <v-row v-if="!propostas_cliente[index_page].UF_CRM_1571148076689">
                                                    <v-row align="center" class="d-flex">
                                                        <v-col class="ml-n6">
                                                            <v-row align="center" no-gutters justify="center">
                                                                <v-icon class="ma-2" size="50px">
                                                                    mdi-alert-circle-outline
                                                                </v-icon>
                                                                <h5>Nenhuma proposta anexada</h5>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-row>
                                                <v-row no-gutters align="center" justify="center">
                                                    <v-col>
                                                        <div class="justify-center"
                                                             v-if="propostas_cliente[index_page].UF_CRM_1571148076689">
                                                            <v-card class="ma-2" @click="pagamento_dialog=true">
                                                                <v-card-title class="title justify-content-center">
                                                                    Informações sobre o pagamento
                                                                </v-card-title>
                                                                <MetodoPagamento :id="propostas_cliente[index_page].ID"
                                                                                 :state="pagamento_dialog"
                                                                                 :anexo="propostas_cliente[index_page].UF_CRM_1617990320"
                                                                                 :stage_id="propostas_cliente[index_page].STAGE_ID"
                                                                                 @update-dialog="(state) => this.pagamento_dialog = state">
                                                                </MetodoPagamento>
                                                            </v-card>
                                                            <v-btn
                                                                @click="showUrl(propostas_cliente[index_page].UF_CRM_1617990320)"
                                                                class="mr-4 my-2" block color="success">
                                                                BAIXAR PROPOSTA
                                                                <v-icon class="ml-2">mdi-download</v-icon>
                                                            </v-btn>
                                                        </div>
                                                        <div>
                                                            <v-card class="ma-2" @click="financiamento_dialog=true"
                                                                    v-if="(propostas_cliente[index_page].UF_CRM_1591821000 === false
                                                    || propostas_cliente[index_page].UF_CRM_1591821000.length === 0)
                                                    && (propostas_cliente[index_page].UF_CRM_1591821184956.length !== 0
                                                    || propostas_cliente[index_page].UF_CRM_1591821198462.length !== 0)">
                                                                <v-card-title class="title justify-content-center">
                                                                    Simulações de financiamento
                                                                </v-card-title>
                                                                <financiamento :proposta="propostas_cliente[index_page]"
                                                                               :state="financiamento_dialog"
                                                                               :anexo_bv="propostas_cliente[index_page].UF_CRM_1614032735"
                                                                               :anexo_st="propostas_cliente[index_page].UF_CRM_1614032766"
                                                                               @update-dialog="(state) => this.financiamento_dialog = state">
                                                                </financiamento>
                                                            </v-card>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-container>
                            </v-row>
                        </v-col>
                        <!-- Botoes de gerenciamento ------------------------------------------------------------------>
                    </v-row>
                    <!--                    <v-divider></v-divider>-->
                    <!-- Chat ----------------------------------------------------------------------------------------->
                    <!--                    <v-row class="ma-5" align="center" no-gutters-->
                    <!--                           v-if="propostas_cliente[index_page].STAGE_ID !== 'C8:LOSE' && propostas_cliente[index_page].AVAILABLE !== 'Vencida'">-->
                    <!--                        <v-col>-->
                    <!--                            <Fases ref="fases" :user="'389'"-->
                    <!--                                   :deal="propostas_cliente[index_page].ID"-->
                    <!--                                   :stage_id="propostas_cliente[index_page].STAGE_ID"/>-->
                    <!--                        </v-col>-->
                    <!--                    </v-row>-->
                    <!-- Chat ----------------------------------------------------------------------------------------->
                </v-responsive>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import AceiteCliente from "../Dialogs/AceiteCliente";
import NegacaoCliente from "../Dialogs/NegacaoCliente";
import Readequacao from "../Dialogs/Readequacao";
import Desconto from "../Dialogs/Desconto";
import Fases from "./Fases";
import MetodoPagamento from "../Dialogs/MetodoPagamento";
import Financiamento from "../Dialogs/Financiamento";
import SolicitarFinanciamento from "../Dialogs/SolicitarFinanciamento";

export default {
    data() {
        return {
            index_page: 0,
            page: 0,
            myDetails: false,
            aceite: false,
            negacao: false,
            pagamento_dialog: false,
            financiamento_dialog: false,
            readequacao: false,
            desconto: false,
            sim_financiamento_dialog: false,

            financiamento: [],
            reemissao: [],
            status_consultor: [],
        }
    },

    name: "Detalhes",

    components: {
        SolicitarFinanciamento,
        Financiamento,
        MetodoPagamento,
        Fases,
        AceiteCliente,
        NegacaoCliente,
        Readequacao,
        Desconto,
    },

    props: {
        details: {
            type: Boolean,
            required: true
        },
        propostas_cliente: {
            type: Array,
            required: true
        },
        start_page: {
            type: Number,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
    },

    computed: {
        propostasLength() {
            return this.propostas_cliente.length;
        },

        getFinanciamento() {
            return this.financiamento[this.index_page];
        },
    },

    watch: {
        page: function () {
            this.index_page = this.page - 1;
        },

        details: function (val) {
            this.setup();
            this.setDetails(val);
        },

        myDetails: function (val) {
            if (val === false) {
                // this.$refs.fases.clearMessages();
            }
        }
    },

    methods: {
        submitReemissao(id, reemissoes) {
            Vue.set(this.reemissao, this.index_page, true);
            this.$http.post('proposta/solicitar_reemissao', {
                id: id,
                reemissoes: reemissoes
            }).then(resp => {
                if (!resp.data.error) {
                    this.pushNotification('Proposta reemitida com sucesso', 'success', 2500);
                } else {
                    this.pushNotification(resp.data.message, 'error', 2500);
                    // document.location.reload(true);
                }
            });
        },

        submitConsultor(id) {
            Vue.set(this.status_consultor, this.index_page, true);
            // this.$http.post('/proposta/solicitar_reemissao', {
            //     id: id,
            //     reemissoes: reemissoes
            // }).then(resp => {
            //     if (!resp.data.error) {
            //         this.pushNotification('Proposta reemitida com sucesso', 'success', 2500);
            //     } else {
            //         this.pushNotification(resp.data.message, 'error', 2500);
            //         // document.location.reload(true);
            //     }
            // });
        },

        setDetails(state) {
            this.myDetails = this.details;
            this.$emit("update-details", state);
        },

        showUrl(id) {
            this.$http.get('proposta/download/' + id).then(response => {
                window.open(response.data);
            }).catch(error => {
                this.pushNotification(error.response.data, 'error', 5000);
            });
        },

        setup() {
            this.page = this.start_page;
        },
    },

}
</script>
