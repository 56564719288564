<template>
    <v-dialog :fullscreen="$vuetify.breakpoint.mobile" @keydown.esc="setDialog(false)" @click:outside="setDialog(false)"
              v-model="dialog" max-width="600">
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-overlay :value="loading_dialog">
            <v-dialog v-model="loading_dialog" hide-overlay persistent width="400">
                <v-card color="primary" dark>
                    <v-card-text class="pt-2">
                        {{ loading_message }}
                        <v-progress-linear indeterminate color="white" class="mt-6 mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-overlay>
        <!--Dialog ---------------------------------------------------------------------------------------------------->

        <v-card min-width="300" class="pa-2">
            <v-card-title class="headline">Informe o método de pagamento</v-card-title>
            <v-card-text>
                <v-btn
                    @click="showUrl(anexo)" class="mb-4" block color="success">
                    BAIXAR PROPOSTA
                    <v-icon class="ml-2">mdi-download</v-icon>
                </v-btn>

                <v-select
                    :items="lista_metodos"
                    v-model="$v.metodo.$model"
                    filled
                    label="Método de Pagamento"
                    @blur="$v.metodo.$touch()"
                    :error-messages="itemErrors($v.metodo)"
                ></v-select>
                <v-select
                    v-if="metodo === '107'"
                    :items="lista_financiamento"
                    v-model="$v.financiamento.$model"
                    filled
                    label="Obrigatoriedades Financiamento"
                    @blur="$v.financiamento.$touch()"
                    :error-messages="itemErrors($v.financiamento)"
                ></v-select>
                <v-textarea
                    v-else-if="metodo === '7553'"
                    label="Método de pagamento solicitado pelo titular"
                    v-model="$v.pagamento_customizado.$model"
                    outlined
                    @blur="$v.pagamento_customizado.$touch()"
                    :error-messages="itemErrors($v.pagamento_customizado)"
                ></v-textarea>
                <v-file-input
                    class="mt-2"
                    label="Documentos do Cliente"
                    :rules="[v => !!v || 'Obrigatório']"
                    multiple
                    outlined
                    clearable
                    @change="updateAnexos_documento"
                ></v-file-input>
                <v-file-input
                    class="mt-2"
                    label="Comprovante de Endereço"
                    :rules="[v => !!v || 'Obrigatório']"
                    multiple
                    outlined
                    clearable
                    @change="updateAnexos_comprovante"
                ></v-file-input>
                <v-file-input
                    v-if="this.financiamento === '113' || this.financiamento === '497'"
                    class="mt-2"
                    label="Carta de Pré-Aprovação Financiamento"
                    :rules="[v => !!v || 'Obrigatório']"
                    multiple
                    outlined
                    clearable
                    @change="updateAnexos_preAprovacao"
                ></v-file-input>
                <v-textarea
                    label="Observações de Pagamento"
                    v-model="$v.observacoes_pagamento.$model"
                    outlined
                    @blur="$v.observacoes_pagamento.$touch()"
                    :error-messages="itemErrors($v.observacoes_pagamento)"
                ></v-textarea>


                Obs: A partir do momento que a proposta entra nesta fase, você tem apenas 48 horas para informar o
                método de
                pagamento!
            </v-card-text>
            <v-card-actions>
                <v-btn color="red darken-1" text @click="setDialog(false)">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="submit">Aceitar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import {lista_financiamento, lista_metodos} from "../../data/metodo_pagamento";

export default {
    name: "MetodoPagamento",

    props: {
        state: {
            type: Boolean,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        anexo: {
            type: String,
            required: true
        },
        stage_id: {
            required: true
        },
    },

    data() {
        return {
            dialog: false,
            loading_dialog: false,
            loading_message: null,
            stage: '',

            metodo: null,
            financiamento: null,
            pagamento_customizado: null,
            observacoes_pagamento: null,
            anexos_documento: [],
            anexos_comprovante: [],
            anexos_pre_aprovacao: [],

            lista_metodos: lista_metodos,
            lista_financiamento: lista_financiamento,
        }
    },

    watch: {
        state: function () {
            this.dialog = this.state;
        }
    },

    methods: {
        showUrl(id) {
            this.$http.get('proposta/download/' + id).then(response => {
                window.open(response.data);
            }).catch(error => {
                this.pushNotification(error.response.data, 'error', 5000);
            });
        },

        setLoading(state, message) {
            this.loading_dialog = state;
            this.loading_message = message;
        },

        async updateAnexos_documento(e) {
            if (e) {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = error => reject(error);
                });

                let files = e;
                this.anexos_documento = [];
                for (let i = 0; i < files.length; i++) {
                    this.anexos_documento.push({
                        'fileData': [files[i].name, await toBase64(files[i])]
                    });
                }
            }
        },

        async updateAnexos_comprovante(e) {
            if (e) {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = error => reject(error);
                });

                let files = e;
                this.anexos_comprovante = [];
                for (let i = 0; i < files.length; i++) {
                    this.anexos_comprovante.push({
                        'fileData': [files[i].name, await toBase64(files[i])]
                    });
                }
            }
        },

        async updateAnexos_preAprovacao(e) {
            if (e) {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result.split(',')[1]);
                    reader.onerror = error => reject(error);
                });

                let files = e;
                this.anexos_pre_aprovacao = [];
                for (let i = 0; i < files.length; i++) {
                    this.anexos_pre_aprovacao.push({
                        'fileData': [files[i].name, await toBase64(files[i])]
                    });
                }
            }
        },

        async submit() {
            if (this.stage_id === 'Método de pagamento') {
                this.stage = 'C8:5';
            }
            this.$v.$touch();
            if (!this.$v.$invalid) {
                await this.setLoading(true, 'Um momento...');
                await this.$http.post('proposta/metodo_pagamento', {
                    data: this.$data,
                    id: this.id,
                    stage_id: this.stage
                }).then(resp => {
                    if (resp.data.error) {
                        this.pushNotification(resp.data.message, 'error', 2500);
                    } else {
                        this.pushNotification('Metodo de pagamento cadastrado com sucesso!', 'success', 2500);
                        this.setDialog(false);
                        this.setLoading(false, '');
                        document.location.reload(true);
                    }
                }).catch(error => {
                    this.setDialog(false);
                    this.setLoading(false, '');
                    this.pushNotification(error.response.data, 'error', 7500);
                });
            }
        },

        setDialog(state) {
            this.dialog = state;
            this.$emit("update-dialog", state);
        },

        itemErrors(item) {
            const errors = []
            if (!item.$dirty) return errors
            !item.required && errors.push('Obrigatório.')
            return errors
        },
    },

    mounted() {
        this.dialog = this.state;
    },

    validations: {
        metodo: {required},
        financiamento: {
            required: requiredIf(function (object) {
                return object.metodo === '107';
            }),
        },
        pagamento_customizado: {
            required: requiredIf(function (object) {
                return object.metodo === '7553';
            }),
        },
        observacoes_pagamento: {required},
        anexos_documento: {required},
        anexos_comprovante: {required},
    },
}
</script>

<style scoped>

</style>
