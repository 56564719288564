import Vue from 'vue';

import {
    cabines,
    categorias, lista_estudo_estrutura, lista_posto_transformacao, lista_reforco_estrutura, lista_tensao,
    m_inversor, p_modulos, padroes_entrada, responsabilidades_cabine, responsabilidades_extensao,
    responsabilidades_infraestrutura, responsabilidades_padrao_entrada, responsabilidades_subestacao,
    responsabilidades_transformador, subestacoes, telhados, transformadores, lista_troca_titularidade,
    lista_a_para_b, lista_cadastro_compensacao, lista_modalidade,
} from "../data/SelectData";
import {municipios} from "../data/municipios";

Vue.mixin({
    data() {
        return {
            responsabilidades_cabine: responsabilidades_cabine,
            responsabilidades_extensao: responsabilidades_extensao,
            responsabilidades_infraestrutura: responsabilidades_infraestrutura,
            responsabilidades_padrao_entrada: responsabilidades_padrao_entrada,
            responsabilidades_subestacao: responsabilidades_subestacao,
            responsabilidades_transformador: responsabilidades_transformador,
            padroes_entrada: padroes_entrada,
            transformadores: transformadores,
            subestacoes: subestacoes,
            cabines: cabines,
            m_inversor: m_inversor,
            categorias: categorias,
            lista_tensao: lista_tensao,
            p_modulos: p_modulos,
            telhados: telhados,
            lista_posto_transformacao: lista_posto_transformacao,
            lista_estudo_estrutura: lista_estudo_estrutura,
            lista_reforco_estrutura: lista_reforco_estrutura,
            lista_troca_titularidade: lista_troca_titularidade,
            lista_a_para_b: lista_a_para_b,
            lista_cadastro_compensacao: lista_cadastro_compensacao,
            lista_modalidade: lista_modalidade,
            municipios: municipios,

            inversores: [],
            modulos: [],

            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            },
            kwh_ano: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' kWh/ano',
                precision: 2,
                masked: false
            },
            kwh_mes: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' kWh/mês',
                precision: 2,
                masked: false
            },

            custos_adicionais: {
                cabo: 'R$ 0,00', qntd_cabo: null,
                servico: 'R$ 0,00', qntd_servico: null,
                infraestrutura: 'R$ 0,00', qntd_infraestrutura: null,
                extensao: 'R$ 0,00', qntd_extensao: null,

                responsabilidade_cabine: '',
                responsabilidade_subestacao_1: '',
                responsabilidade_transformador_1: '',
                responsabilidade_padrao_entrada: '',
                responsabilidade_infraestrutura: '',
                responsabilidade_extensao: '',

                cabine: '', qntd_cabine: '',
                subestacao_1: '', qntd_subestacao_1: '',
                subestacao_2: '', qntd_subestacao_2: '',
                transformador_1: '', qntd_transformador_1: '',
                transformador_2: '', qntd_transformador_2: '',
                padrao_entrada: '', qntd_padrao_entrada: '',
                comentario_custos: '',
            },

            custos_adicionais_invalid: false,
        }
    },

    computed: {
        setCustosAdicionais(){
            // Vue.set(this.custos_adicionais, "comentario_custos", this.deal.UF_CRM_1598384576);
            this.custos_adicionais.comentario_custos = this.deal.UF_CRM_1598384576;
            this.custos_adicionais.cabo = this.deal.UF_CRM_1597156285;
            this.custos_adicionais.qntd_cabo = this.deal.UF_CRM_1597156340;
            this.custos_adicionais.servico = this.deal.UF_CRM_1597156356;
            this.custos_adicionais.qntd_servico = this.deal.UF_CRM_1597156375;
            this.custos_adicionais.infraestrutura = this.deal.UF_CRM_1597156399;
            this.custos_adicionais.qntd_infraestrutura = this.deal.UF_CRM_1597156413;
            this.custos_adicionais.extensao = this.deal.UF_CRM_1597156425;
            this.custos_adicionais.qntd_extensao = this.deal.UF_CRM_1597156439;

            this.custos_adicionais.cabine = this.deal.UF_CRM_1597156460;
            this.custos_adicionais.qntd_cabine = this.deal.UF_CRM_1597156483;
            this.custos_adicionais.subestacao_1 = this.deal.UF_CRM_1597156512;
            this.custos_adicionais.qntd_subestacao_1 = this.deal.UF_CRM_1597156538;
            this.custos_adicionais.subestacao_2 = this.deal.UF_CRM_1597156581;
            this.custos_adicionais.qntd_subestacao_2 = this.deal.UF_CRM_1597156600;
            this.custos_adicionais.transformador_1 = this.deal.UF_CRM_1597156631;
            this.custos_adicionais.qntd_transformador_1 = this.deal.UF_CRM_1597156649;
            this.custos_adicionais.transformador_2 = this.deal.UF_CRM_1597156681;
            this.custos_adicionais.qntd_transformador_2 = this.deal.UF_CRM_1597156708;
            this.custos_adicionais.padrao_entrada = this.deal.UF_CRM_1597156728;
            this.custos_adicionais.qntd_padrao_entrada = this.deal.UF_CRM_1597156745;

            if (this.deal.UF_CRM_1598384718) {
                this.custos_adicionais.responsabilidade_cabine = this.deal.UF_CRM_1598384718;
            }
            if (this.deal.UF_CRM_1598384792) {
                this.custos_adicionais.responsabilidade_subestacao_1 = this.deal.UF_CRM_1598384792;
            }
            if (this.deal.UF_CRM_1598384922) {
                this.custos_adicionais.responsabilidade_transformador_1 = this.deal.UF_CRM_1598384922;
            }
            if (this.deal.UF_CRM_1598385013) {
                this.custos_adicionais.responsabilidade_padrao_entrada = this.deal.UF_CRM_1598385013;
            }
            if (this.deal.UF_CRM_1597156399) {
                this.custos_adicionais.responsabilidade_infraestrutura = this.deal.UF_CRM_1598385178;
            }
            if (this.deal.UF_CRM_1597156425) {
                this.custos_adicionais.responsabilidade_extensao = this.deal.UF_CRM_1598385314;
            }

            return this.custos_adicionais;
        },

        modelos_modulos() {
            let modelos = [];
            for (const modulo of this.modulos) {
                if (!modulo.adm_only && modulo.habilitado) {
                    modelos.push(modulo.modelo);
                }
            }
            return modelos;
        },

        modelos_inversores() {
            let modelos = [];
            for (const modulo of this.inversores) {
                if (!modulo.adm_only && modulo.habilitado) {
                    modelos.push(modulo.modelo);
                }
            }
            return modelos;
        },

        modelos_inversores_adm_only() {
            let modelos = [];
            for (const modulo of this.inversores) {
                if (modulo.habilitado) {
                    modelos.push(modulo.modelo);
                }
            }
            return modelos;
        },

        modelos_modulos_adm_only() {
            let modelos = [];
            for (const modulo of this.modulos) {
                if (modulo.habilitado) {
                    modelos.push(modulo.modelo);
                }
            }
            return modelos;
        },
    },

    async created() {
    },

    methods: {
        getCustosAdicionais(e) {
            this.custos_adicionais = e;
        },

        setCustosAdicionaisInvalid(e) {
            this.custos_adicionais_invalid = e;
        },

        createNewInversor() {
            this.inversores.push({
                id: null,
                modelo: null,
                habilitado: true,
                adm_only: false,
                created_at: null,
                updated_at: null
            });
        },

        deleteInversor(index) {
            this.inversores.splice(index, 1);
        },

        createNewModulo() {
            this.modulos.push({
                id: null,
                modelo: null,
                habilitado: true,
                adm_only: false,
                created_at: null,
                updated_at: null
            });
        },

        deleteModulo(index) {
            this.modulos.splice(index, 1);
        },

        async getInversoresModulos() {
            await this.$http.get('configuracoes').then(response => {
                this.inversores = response.data[0];
                this.modulos = response.data[1]
            });
        },

        async setInversoresModulos() {
            await this.$http.post('configuracoes', {
                inversores: this.inversores,
                modulos: this.modulos,
            }).then(response => this.pushNotification('Salvo com sucesso!', 'success', 2500));
        },

        formatToDateString(date) {
            //Converte as datas do padrao ISO em UTC para String local
            let monName = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
            let partDate = new Date(date);
            return partDate.getUTCDate() + ' de ' + monName[partDate.getMonth()] + ' de ' + partDate.getFullYear();
        },

        formatToBrl(value) {
            // Create our number formatter.
            var formatter = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
            });
            return formatter.format(value);
        },

        itemErrors(item) {
            const errors = []
            if (!item.$dirty) return errors
            !item.required && errors.push('Obrigatório.')
            return errors
        },

        pushNotification(message, type, duration) {
            this.$toast.open({
                message: message,
                type: type,
                duration: duration,
            });
        },
    },
});


