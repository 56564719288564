export const municipios = [
    {
        "value": "AC",
        "label": "Acre",
        "children": [
            {
                "value": "Acrelândia",
                "label": "Acrelândia"
            },
            {
                "value": "Assis Brasil",
                "label": "Assis Brasil"
            },
            {
                "value": "Brasiléia",
                "label": "Brasiléia"
            },
            {
                "value": "Bujari",
                "label": "Bujari"
            },
            {
                "value": "Capixaba",
                "label": "Capixaba"
            },
            {
                "value": "Cruzeiro do Sul",
                "label": "Cruzeiro do Sul"
            },
            {
                "value": "Epitaciolândia",
                "label": "Epitaciolândia"
            },
            {
                "value": "Feijó",
                "label": "Feijó"
            },
            {
                "value": "Jordão",
                "label": "Jordão"
            },
            {
                "value": "Mâncio Lima",
                "label": "Mâncio Lima"
            },
            {
                "value": "Manoel Urbano",
                "label": "Manoel Urbano"
            },
            {
                "value": "Marechal Thaumaturgo",
                "label": "Marechal Thaumaturgo"
            },
            {
                "value": "Plácido de Castro",
                "label": "Plácido de Castro"
            },
            {
                "value": "Porto Walter",
                "label": "Porto Walter"
            },
            {
                "value": "Rio Branco",
                "label": "Rio Branco"
            },
            {
                "value": "Rodrigues Alves",
                "label": "Rodrigues Alves"
            },
            {
                "value": "Santa Rosa do Purus",
                "label": "Santa Rosa do Purus"
            },
            {
                "value": "Senador Guiomard",
                "label": "Senador Guiomard"
            },
            {
                "value": "Sena Madureira",
                "label": "Sena Madureira"
            },
            {
                "value": "Tarauacá",
                "label": "Tarauacá"
            },
            {
                "value": "Xapuri",
                "label": "Xapuri"
            },
            {
                "value": "Porto Acre",
                "label": "Porto Acre"
            }
        ]
    },
    {
        "value": "AL",
        "label": "Alagoas",
        "children": [
            {
                "value": "Água Branca",
                "label": "Água Branca"
            },
            {
                "value": "Anadia",
                "label": "Anadia"
            },
            {
                "value": "Arapiraca",
                "label": "Arapiraca"
            },
            {
                "value": "Atalaia",
                "label": "Atalaia"
            },
            {
                "value": "Barra de Santo Antônio",
                "label": "Barra de Santo Antônio"
            },
            {
                "value": "Barra de São Miguel",
                "label": "Barra de São Miguel"
            },
            {
                "value": "Batalha",
                "label": "Batalha"
            },
            {
                "value": "Belém",
                "label": "Belém"
            },
            {
                "value": "Belo Monte",
                "label": "Belo Monte"
            },
            {
                "value": "Boca da Mata",
                "label": "Boca da Mata"
            },
            {
                "value": "Branquinha",
                "label": "Branquinha"
            },
            {
                "value": "Cacimbinhas",
                "label": "Cacimbinhas"
            },
            {
                "value": "Cajueiro",
                "label": "Cajueiro"
            },
            {
                "value": "Campestre",
                "label": "Campestre"
            },
            {
                "value": "Campo Alegre",
                "label": "Campo Alegre"
            },
            {
                "value": "Campo Grande",
                "label": "Campo Grande"
            },
            {
                "value": "Canapi",
                "label": "Canapi"
            },
            {
                "value": "Capela",
                "label": "Capela"
            },
            {
                "value": "Carneiros",
                "label": "Carneiros"
            },
            {
                "value": "Chã Preta",
                "label": "Chã Preta"
            },
            {
                "value": "Coité do Nóia",
                "label": "Coité do Nóia"
            },
            {
                "value": "Colônia Leopoldina",
                "label": "Colônia Leopoldina"
            },
            {
                "value": "Coqueiro Seco",
                "label": "Coqueiro Seco"
            },
            {
                "value": "Coruripe",
                "label": "Coruripe"
            },
            {
                "value": "Craíbas",
                "label": "Craíbas"
            },
            {
                "value": "Delmiro Gouveia",
                "label": "Delmiro Gouveia"
            },
            {
                "value": "Dois Riachos",
                "label": "Dois Riachos"
            },
            {
                "value": "Estrela de Alagoas",
                "label": "Estrela de Alagoas"
            },
            {
                "value": "Feira Grande",
                "label": "Feira Grande"
            },
            {
                "value": "Feliz Deserto",
                "label": "Feliz Deserto"
            },
            {
                "value": "Flexeiras",
                "label": "Flexeiras"
            },
            {
                "value": "Girau do Ponciano",
                "label": "Girau do Ponciano"
            },
            {
                "value": "Ibateguara",
                "label": "Ibateguara"
            },
            {
                "value": "Igaci",
                "label": "Igaci"
            },
            {
                "value": "Igreja Nova",
                "label": "Igreja Nova"
            },
            {
                "value": "Inhapi",
                "label": "Inhapi"
            },
            {
                "value": "Jacaré dos Homens",
                "label": "Jacaré dos Homens"
            },
            {
                "value": "Jacuípe",
                "label": "Jacuípe"
            },
            {
                "value": "Japaratinga",
                "label": "Japaratinga"
            },
            {
                "value": "Jaramataia",
                "label": "Jaramataia"
            },
            {
                "value": "Jequiá da Praia",
                "label": "Jequiá da Praia"
            },
            {
                "value": "Joaquim Gomes",
                "label": "Joaquim Gomes"
            },
            {
                "value": "Jundiá",
                "label": "Jundiá"
            },
            {
                "value": "Junqueiro",
                "label": "Junqueiro"
            },
            {
                "value": "Lagoa da Canoa",
                "label": "Lagoa da Canoa"
            },
            {
                "value": "Limoeiro de Anadia",
                "label": "Limoeiro de Anadia"
            },
            {
                "value": "Maceió",
                "label": "Maceió"
            },
            {
                "value": "Major Isidoro",
                "label": "Major Isidoro"
            },
            {
                "value": "Maragogi",
                "label": "Maragogi"
            },
            {
                "value": "Maravilha",
                "label": "Maravilha"
            },
            {
                "value": "Marechal Deodoro",
                "label": "Marechal Deodoro"
            },
            {
                "value": "Maribondo",
                "label": "Maribondo"
            },
            {
                "value": "Mar Vermelho",
                "label": "Mar Vermelho"
            },
            {
                "value": "Mata Grande",
                "label": "Mata Grande"
            },
            {
                "value": "Matriz de Camaragibe",
                "label": "Matriz de Camaragibe"
            },
            {
                "value": "Messias",
                "label": "Messias"
            },
            {
                "value": "Minador do Negrão",
                "label": "Minador do Negrão"
            },
            {
                "value": "Monteirópolis",
                "label": "Monteirópolis"
            },
            {
                "value": "Murici",
                "label": "Murici"
            },
            {
                "value": "Novo Lino",
                "label": "Novo Lino"
            },
            {
                "value": "Olho d'Água das Flores",
                "label": "Olho d'Água das Flores"
            },
            {
                "value": "Olho d'Água do Casado",
                "label": "Olho d'Água do Casado"
            },
            {
                "value": "Olho d'Água Grande",
                "label": "Olho d'Água Grande"
            },
            {
                "value": "Olivença",
                "label": "Olivença"
            },
            {
                "value": "Ouro Branco",
                "label": "Ouro Branco"
            },
            {
                "value": "Palestina",
                "label": "Palestina"
            },
            {
                "value": "Palmeira dos Índios",
                "label": "Palmeira dos Índios"
            },
            {
                "value": "Pão de Açúcar",
                "label": "Pão de Açúcar"
            },
            {
                "value": "Pariconha",
                "label": "Pariconha"
            },
            {
                "value": "Paripueira",
                "label": "Paripueira"
            },
            {
                "value": "Passo de Camaragibe",
                "label": "Passo de Camaragibe"
            },
            {
                "value": "Paulo Jacinto",
                "label": "Paulo Jacinto"
            },
            {
                "value": "Penedo",
                "label": "Penedo"
            },
            {
                "value": "Piaçabuçu",
                "label": "Piaçabuçu"
            },
            {
                "value": "Pilar",
                "label": "Pilar"
            },
            {
                "value": "Pindoba",
                "label": "Pindoba"
            },
            {
                "value": "Piranhas",
                "label": "Piranhas"
            },
            {
                "value": "Poço das Trincheiras",
                "label": "Poço das Trincheiras"
            },
            {
                "value": "Porto Calvo",
                "label": "Porto Calvo"
            },
            {
                "value": "Porto de Pedras",
                "label": "Porto de Pedras"
            },
            {
                "value": "Porto Real do Colégio",
                "label": "Porto Real do Colégio"
            },
            {
                "value": "Quebrangulo",
                "label": "Quebrangulo"
            },
            {
                "value": "Rio Largo",
                "label": "Rio Largo"
            },
            {
                "value": "Roteiro",
                "label": "Roteiro"
            },
            {
                "value": "Santa Luzia do Norte",
                "label": "Santa Luzia do Norte"
            },
            {
                "value": "Santana do Ipanema",
                "label": "Santana do Ipanema"
            },
            {
                "value": "Santana do Mundaú",
                "label": "Santana do Mundaú"
            },
            {
                "value": "São Brás",
                "label": "São Brás"
            },
            {
                "value": "São José da Laje",
                "label": "São José da Laje"
            },
            {
                "value": "São José da Tapera",
                "label": "São José da Tapera"
            },
            {
                "value": "São Luís do Quitunde",
                "label": "São Luís do Quitunde"
            },
            {
                "value": "São Miguel dos Campos",
                "label": "São Miguel dos Campos"
            },
            {
                "value": "São Miguel dos Milagres",
                "label": "São Miguel dos Milagres"
            },
            {
                "value": "São Sebastião",
                "label": "São Sebastião"
            },
            {
                "value": "Satuba",
                "label": "Satuba"
            },
            {
                "value": "Senador Rui Palmeira",
                "label": "Senador Rui Palmeira"
            },
            {
                "value": "Tanque d'Arca",
                "label": "Tanque d'Arca"
            },
            {
                "value": "Taquarana",
                "label": "Taquarana"
            },
            {
                "value": "Teotônio Vilela",
                "label": "Teotônio Vilela"
            },
            {
                "value": "Traipu",
                "label": "Traipu"
            },
            {
                "value": "União dos Palmares",
                "label": "União dos Palmares"
            },
            {
                "value": "Viçosa",
                "label": "Viçosa"
            }
        ]
    },
    {
        "value": "AM",
        "label": "Amazonas",
        "children": [
            {
                "value": "Alvarães",
                "label": "Alvarães"
            },
            {
                "value": "Amaturá",
                "label": "Amaturá"
            },
            {
                "value": "Anamã",
                "label": "Anamã"
            },
            {
                "value": "Anori",
                "label": "Anori"
            },
            {
                "value": "Apuí",
                "label": "Apuí"
            },
            {
                "value": "Atalaia do Norte",
                "label": "Atalaia do Norte"
            },
            {
                "value": "Autazes",
                "label": "Autazes"
            },
            {
                "value": "Barcelos",
                "label": "Barcelos"
            },
            {
                "value": "Barreirinha",
                "label": "Barreirinha"
            },
            {
                "value": "Benjamin Constant",
                "label": "Benjamin Constant"
            },
            {
                "value": "Beruri",
                "label": "Beruri"
            },
            {
                "value": "Boa Vista do Ramos",
                "label": "Boa Vista do Ramos"
            },
            {
                "value": "Boca do Acre",
                "label": "Boca do Acre"
            },
            {
                "value": "Borba",
                "label": "Borba"
            },
            {
                "value": "Caapiranga",
                "label": "Caapiranga"
            },
            {
                "value": "Canutama",
                "label": "Canutama"
            },
            {
                "value": "Carauari",
                "label": "Carauari"
            },
            {
                "value": "Careiro",
                "label": "Careiro"
            },
            {
                "value": "Careiro da Várzea",
                "label": "Careiro da Várzea"
            },
            {
                "value": "Coari",
                "label": "Coari"
            },
            {
                "value": "Codajás",
                "label": "Codajás"
            },
            {
                "value": "Eirunepé",
                "label": "Eirunepé"
            },
            {
                "value": "Envira",
                "label": "Envira"
            },
            {
                "value": "Fonte Boa",
                "label": "Fonte Boa"
            },
            {
                "value": "Guajará",
                "label": "Guajará"
            },
            {
                "value": "Humaitá",
                "label": "Humaitá"
            },
            {
                "value": "Ipixuna",
                "label": "Ipixuna"
            },
            {
                "value": "Iranduba",
                "label": "Iranduba"
            },
            {
                "value": "Itacoatiara",
                "label": "Itacoatiara"
            },
            {
                "value": "Itamarati",
                "label": "Itamarati"
            },
            {
                "value": "Itapiranga",
                "label": "Itapiranga"
            },
            {
                "value": "Japurá",
                "label": "Japurá"
            },
            {
                "value": "Juruá",
                "label": "Juruá"
            },
            {
                "value": "Jutaí",
                "label": "Jutaí"
            },
            {
                "value": "Lábrea",
                "label": "Lábrea"
            },
            {
                "value": "Manacapuru",
                "label": "Manacapuru"
            },
            {
                "value": "Manaquiri",
                "label": "Manaquiri"
            },
            {
                "value": "Manaus",
                "label": "Manaus"
            },
            {
                "value": "Manicoré",
                "label": "Manicoré"
            },
            {
                "value": "Maraã",
                "label": "Maraã"
            },
            {
                "value": "Maués",
                "label": "Maués"
            },
            {
                "value": "Nhamundá",
                "label": "Nhamundá"
            },
            {
                "value": "Nova Olinda do Norte",
                "label": "Nova Olinda do Norte"
            },
            {
                "value": "Novo Airão",
                "label": "Novo Airão"
            },
            {
                "value": "Novo Aripuanã",
                "label": "Novo Aripuanã"
            },
            {
                "value": "Parintins",
                "label": "Parintins"
            },
            {
                "value": "Pauini",
                "label": "Pauini"
            },
            {
                "value": "Presidente Figueiredo",
                "label": "Presidente Figueiredo"
            },
            {
                "value": "Rio Preto da Eva",
                "label": "Rio Preto da Eva"
            },
            {
                "value": "Santa Isabel do Rio Negro",
                "label": "Santa Isabel do Rio Negro"
            },
            {
                "value": "Santo Antônio do Içá",
                "label": "Santo Antônio do Içá"
            },
            {
                "value": "São Gabriel da Cachoeira",
                "label": "São Gabriel da Cachoeira"
            },
            {
                "value": "São Paulo de Olivença",
                "label": "São Paulo de Olivença"
            },
            {
                "value": "São Sebastião do Uatumã",
                "label": "São Sebastião do Uatumã"
            },
            {
                "value": "Silves",
                "label": "Silves"
            },
            {
                "value": "Tabatinga",
                "label": "Tabatinga"
            },
            {
                "value": "Tapauá",
                "label": "Tapauá"
            },
            {
                "value": "Tefé",
                "label": "Tefé"
            },
            {
                "value": "Tonantins",
                "label": "Tonantins"
            },
            {
                "value": "Uarini",
                "label": "Uarini"
            },
            {
                "value": "Urucará",
                "label": "Urucará"
            },
            {
                "value": "Urucurituba",
                "label": "Urucurituba"
            }
        ]
    },
    {
        "value": "AP",
        "label": "Amapá",
        "children": [
            {
                "value": "Serra do Navio",
                "label": "Serra do Navio"
            },
            {
                "value": "Amapá",
                "label": "Amapá"
            },
            {
                "value": "Pedra Branca do Amapari",
                "label": "Pedra Branca do Amapari"
            },
            {
                "value": "Calçoene",
                "label": "Calçoene"
            },
            {
                "value": "Cutias",
                "label": "Cutias"
            },
            {
                "value": "Ferreira Gomes",
                "label": "Ferreira Gomes"
            },
            {
                "value": "Itaubal",
                "label": "Itaubal"
            },
            {
                "value": "Laranjal do Jari",
                "label": "Laranjal do Jari"
            },
            {
                "value": "Macapá",
                "label": "Macapá"
            },
            {
                "value": "Mazagão",
                "label": "Mazagão"
            },
            {
                "value": "Oiapoque",
                "label": "Oiapoque"
            },
            {
                "value": "Porto Grande",
                "label": "Porto Grande"
            },
            {
                "value": "Pracuúba",
                "label": "Pracuúba"
            },
            {
                "value": "Santana",
                "label": "Santana"
            },
            {
                "value": "Tartarugalzinho",
                "label": "Tartarugalzinho"
            },
            {
                "value": "Vitória do Jari",
                "label": "Vitória do Jari"
            }
        ]
    },
    {
        "value": "BA",
        "label": "Bahia",
        "children": [
            {
                "value": "Abaíra",
                "label": "Abaíra"
            },
            {
                "value": "Abaré",
                "label": "Abaré"
            },
            {
                "value": "Acajutiba",
                "label": "Acajutiba"
            },
            {
                "value": "Adustina",
                "label": "Adustina"
            },
            {
                "value": "Água Fria",
                "label": "Água Fria"
            },
            {
                "value": "Érico Cardoso",
                "label": "Érico Cardoso"
            },
            {
                "value": "Aiquara",
                "label": "Aiquara"
            },
            {
                "value": "Alagoinhas",
                "label": "Alagoinhas"
            },
            {
                "value": "Alcobaça",
                "label": "Alcobaça"
            },
            {
                "value": "Almadina",
                "label": "Almadina"
            },
            {
                "value": "Amargosa",
                "label": "Amargosa"
            },
            {
                "value": "Amélia Rodrigues",
                "label": "Amélia Rodrigues"
            },
            {
                "value": "América Dourada",
                "label": "América Dourada"
            },
            {
                "value": "Anagé",
                "label": "Anagé"
            },
            {
                "value": "Andaraí",
                "label": "Andaraí"
            },
            {
                "value": "Andorinha",
                "label": "Andorinha"
            },
            {
                "value": "Angical",
                "label": "Angical"
            },
            {
                "value": "Anguera",
                "label": "Anguera"
            },
            {
                "value": "Antas",
                "label": "Antas"
            },
            {
                "value": "Antônio Cardoso",
                "label": "Antônio Cardoso"
            },
            {
                "value": "Antônio Gonçalves",
                "label": "Antônio Gonçalves"
            },
            {
                "value": "Aporá",
                "label": "Aporá"
            },
            {
                "value": "Apuarema",
                "label": "Apuarema"
            },
            {
                "value": "Aracatu",
                "label": "Aracatu"
            },
            {
                "value": "Araçás",
                "label": "Araçás"
            },
            {
                "value": "Araci",
                "label": "Araci"
            },
            {
                "value": "Aramari",
                "label": "Aramari"
            },
            {
                "value": "Arataca",
                "label": "Arataca"
            },
            {
                "value": "Aratuípe",
                "label": "Aratuípe"
            },
            {
                "value": "Aurelino Leal",
                "label": "Aurelino Leal"
            },
            {
                "value": "Baianópolis",
                "label": "Baianópolis"
            },
            {
                "value": "Baixa Grande",
                "label": "Baixa Grande"
            },
            {
                "value": "Banzaê",
                "label": "Banzaê"
            },
            {
                "value": "Barra",
                "label": "Barra"
            },
            {
                "value": "Barra da Estiva",
                "label": "Barra da Estiva"
            },
            {
                "value": "Barra do Choça",
                "label": "Barra do Choça"
            },
            {
                "value": "Barra do Mendes",
                "label": "Barra do Mendes"
            },
            {
                "value": "Barra do Rocha",
                "label": "Barra do Rocha"
            },
            {
                "value": "Barreiras",
                "label": "Barreiras"
            },
            {
                "value": "Barro Alto",
                "label": "Barro Alto"
            },
            {
                "value": "Barrocas",
                "label": "Barrocas"
            },
            {
                "value": "Barro Preto",
                "label": "Barro Preto"
            },
            {
                "value": "Belmonte",
                "label": "Belmonte"
            },
            {
                "value": "Belo Campo",
                "label": "Belo Campo"
            },
            {
                "value": "Biritinga",
                "label": "Biritinga"
            },
            {
                "value": "Boa Nova",
                "label": "Boa Nova"
            },
            {
                "value": "Boa Vista do Tupim",
                "label": "Boa Vista do Tupim"
            },
            {
                "value": "Bom Jesus da Lapa",
                "label": "Bom Jesus da Lapa"
            },
            {
                "value": "Bom Jesus da Serra",
                "label": "Bom Jesus da Serra"
            },
            {
                "value": "Boninal",
                "label": "Boninal"
            },
            {
                "value": "Bonito",
                "label": "Bonito"
            },
            {
                "value": "Boquira",
                "label": "Boquira"
            },
            {
                "value": "Botuporã",
                "label": "Botuporã"
            },
            {
                "value": "Brejões",
                "label": "Brejões"
            },
            {
                "value": "Brejolândia",
                "label": "Brejolândia"
            },
            {
                "value": "Brotas de Macaúbas",
                "label": "Brotas de Macaúbas"
            },
            {
                "value": "Brumado",
                "label": "Brumado"
            },
            {
                "value": "Buerarema",
                "label": "Buerarema"
            },
            {
                "value": "Buritirama",
                "label": "Buritirama"
            },
            {
                "value": "Caatiba",
                "label": "Caatiba"
            },
            {
                "value": "Cabaceiras do Paraguaçu",
                "label": "Cabaceiras do Paraguaçu"
            },
            {
                "value": "Cachoeira",
                "label": "Cachoeira"
            },
            {
                "value": "Caculé",
                "label": "Caculé"
            },
            {
                "value": "Caém",
                "label": "Caém"
            },
            {
                "value": "Caetanos",
                "label": "Caetanos"
            },
            {
                "value": "Caetité",
                "label": "Caetité"
            },
            {
                "value": "Cafarnaum",
                "label": "Cafarnaum"
            },
            {
                "value": "Cairu",
                "label": "Cairu"
            },
            {
                "value": "Caldeirão Grande",
                "label": "Caldeirão Grande"
            },
            {
                "value": "Camacan",
                "label": "Camacan"
            },
            {
                "value": "Camaçari",
                "label": "Camaçari"
            },
            {
                "value": "Camamu",
                "label": "Camamu"
            },
            {
                "value": "Campo Alegre de Lourdes",
                "label": "Campo Alegre de Lourdes"
            },
            {
                "value": "Campo Formoso",
                "label": "Campo Formoso"
            },
            {
                "value": "Canápolis",
                "label": "Canápolis"
            },
            {
                "value": "Canarana",
                "label": "Canarana"
            },
            {
                "value": "Canavieiras",
                "label": "Canavieiras"
            },
            {
                "value": "Candeal",
                "label": "Candeal"
            },
            {
                "value": "Candeias",
                "label": "Candeias"
            },
            {
                "value": "Candiba",
                "label": "Candiba"
            },
            {
                "value": "Cândido Sales",
                "label": "Cândido Sales"
            },
            {
                "value": "Cansanção",
                "label": "Cansanção"
            },
            {
                "value": "Canudos",
                "label": "Canudos"
            },
            {
                "value": "Capela do Alto Alegre",
                "label": "Capela do Alto Alegre"
            },
            {
                "value": "Capim Grosso",
                "label": "Capim Grosso"
            },
            {
                "value": "Caraíbas",
                "label": "Caraíbas"
            },
            {
                "value": "Caravelas",
                "label": "Caravelas"
            },
            {
                "value": "Cardeal da Silva",
                "label": "Cardeal da Silva"
            },
            {
                "value": "Carinhanha",
                "label": "Carinhanha"
            },
            {
                "value": "Casa Nova",
                "label": "Casa Nova"
            },
            {
                "value": "Castro Alves",
                "label": "Castro Alves"
            },
            {
                "value": "Catolândia",
                "label": "Catolândia"
            },
            {
                "value": "Catu",
                "label": "Catu"
            },
            {
                "value": "Caturama",
                "label": "Caturama"
            },
            {
                "value": "Central",
                "label": "Central"
            },
            {
                "value": "Chorrochó",
                "label": "Chorrochó"
            },
            {
                "value": "Cícero Dantas",
                "label": "Cícero Dantas"
            },
            {
                "value": "Cipó",
                "label": "Cipó"
            },
            {
                "value": "Coaraci",
                "label": "Coaraci"
            },
            {
                "value": "Cocos",
                "label": "Cocos"
            },
            {
                "value": "Conceição da Feira",
                "label": "Conceição da Feira"
            },
            {
                "value": "Conceição do Almeida",
                "label": "Conceição do Almeida"
            },
            {
                "value": "Conceição do Coité",
                "label": "Conceição do Coité"
            },
            {
                "value": "Conceição do Jacuípe",
                "label": "Conceição do Jacuípe"
            },
            {
                "value": "Conde",
                "label": "Conde"
            },
            {
                "value": "Condeúba",
                "label": "Condeúba"
            },
            {
                "value": "Contendas do Sincorá",
                "label": "Contendas do Sincorá"
            },
            {
                "value": "Coração de Maria",
                "label": "Coração de Maria"
            },
            {
                "value": "Cordeiros",
                "label": "Cordeiros"
            },
            {
                "value": "Coribe",
                "label": "Coribe"
            },
            {
                "value": "Coronel João Sá",
                "label": "Coronel João Sá"
            },
            {
                "value": "Correntina",
                "label": "Correntina"
            },
            {
                "value": "Cotegipe",
                "label": "Cotegipe"
            },
            {
                "value": "Cravolândia",
                "label": "Cravolândia"
            },
            {
                "value": "Crisópolis",
                "label": "Crisópolis"
            },
            {
                "value": "Cristópolis",
                "label": "Cristópolis"
            },
            {
                "value": "Cruz das Almas",
                "label": "Cruz das Almas"
            },
            {
                "value": "Curaçá",
                "label": "Curaçá"
            },
            {
                "value": "Dário Meira",
                "label": "Dário Meira"
            },
            {
                "value": "Dias d'Ávila",
                "label": "Dias d'Ávila"
            },
            {
                "value": "Dom Basílio",
                "label": "Dom Basílio"
            },
            {
                "value": "Dom Macedo Costa",
                "label": "Dom Macedo Costa"
            },
            {
                "value": "Elísio Medrado",
                "label": "Elísio Medrado"
            },
            {
                "value": "Encruzilhada",
                "label": "Encruzilhada"
            },
            {
                "value": "Entre Rios",
                "label": "Entre Rios"
            },
            {
                "value": "Esplanada",
                "label": "Esplanada"
            },
            {
                "value": "Euclides da Cunha",
                "label": "Euclides da Cunha"
            },
            {
                "value": "Eunápolis",
                "label": "Eunápolis"
            },
            {
                "value": "Fátima",
                "label": "Fátima"
            },
            {
                "value": "Feira da Mata",
                "label": "Feira da Mata"
            },
            {
                "value": "Feira de Santana",
                "label": "Feira de Santana"
            },
            {
                "value": "Filadélfia",
                "label": "Filadélfia"
            },
            {
                "value": "Firmino Alves",
                "label": "Firmino Alves"
            },
            {
                "value": "Floresta Azul",
                "label": "Floresta Azul"
            },
            {
                "value": "Formosa do Rio Preto",
                "label": "Formosa do Rio Preto"
            },
            {
                "value": "Gandu",
                "label": "Gandu"
            },
            {
                "value": "Gavião",
                "label": "Gavião"
            },
            {
                "value": "Gentio do Ouro",
                "label": "Gentio do Ouro"
            },
            {
                "value": "Glória",
                "label": "Glória"
            },
            {
                "value": "Gongogi",
                "label": "Gongogi"
            },
            {
                "value": "Governador Mangabeira",
                "label": "Governador Mangabeira"
            },
            {
                "value": "Guajeru",
                "label": "Guajeru"
            },
            {
                "value": "Guanambi",
                "label": "Guanambi"
            },
            {
                "value": "Guaratinga",
                "label": "Guaratinga"
            },
            {
                "value": "Heliópolis",
                "label": "Heliópolis"
            },
            {
                "value": "Iaçu",
                "label": "Iaçu"
            },
            {
                "value": "Ibiassucê",
                "label": "Ibiassucê"
            },
            {
                "value": "Ibicaraí",
                "label": "Ibicaraí"
            },
            {
                "value": "Ibicoara",
                "label": "Ibicoara"
            },
            {
                "value": "Ibicuí",
                "label": "Ibicuí"
            },
            {
                "value": "Ibipeba",
                "label": "Ibipeba"
            },
            {
                "value": "Ibipitanga",
                "label": "Ibipitanga"
            },
            {
                "value": "Ibiquera",
                "label": "Ibiquera"
            },
            {
                "value": "Ibirapitanga",
                "label": "Ibirapitanga"
            },
            {
                "value": "Ibirapuã",
                "label": "Ibirapuã"
            },
            {
                "value": "Ibirataia",
                "label": "Ibirataia"
            },
            {
                "value": "Ibitiara",
                "label": "Ibitiara"
            },
            {
                "value": "Ibititá",
                "label": "Ibititá"
            },
            {
                "value": "Ibotirama",
                "label": "Ibotirama"
            },
            {
                "value": "Ichu",
                "label": "Ichu"
            },
            {
                "value": "Igaporã",
                "label": "Igaporã"
            },
            {
                "value": "Igrapiúna",
                "label": "Igrapiúna"
            },
            {
                "value": "Iguaí",
                "label": "Iguaí"
            },
            {
                "value": "Ilhéus",
                "label": "Ilhéus"
            },
            {
                "value": "Inhambupe",
                "label": "Inhambupe"
            },
            {
                "value": "Ipecaetá",
                "label": "Ipecaetá"
            },
            {
                "value": "Ipiaú",
                "label": "Ipiaú"
            },
            {
                "value": "Ipirá",
                "label": "Ipirá"
            },
            {
                "value": "Ipupiara",
                "label": "Ipupiara"
            },
            {
                "value": "Irajuba",
                "label": "Irajuba"
            },
            {
                "value": "Iramaia",
                "label": "Iramaia"
            },
            {
                "value": "Iraquara",
                "label": "Iraquara"
            },
            {
                "value": "Irará",
                "label": "Irará"
            },
            {
                "value": "Irecê",
                "label": "Irecê"
            },
            {
                "value": "Itabela",
                "label": "Itabela"
            },
            {
                "value": "Itaberaba",
                "label": "Itaberaba"
            },
            {
                "value": "Itabuna",
                "label": "Itabuna"
            },
            {
                "value": "Itacaré",
                "label": "Itacaré"
            },
            {
                "value": "Itaeté",
                "label": "Itaeté"
            },
            {
                "value": "Itagi",
                "label": "Itagi"
            },
            {
                "value": "Itagibá",
                "label": "Itagibá"
            },
            {
                "value": "Itagimirim",
                "label": "Itagimirim"
            },
            {
                "value": "Itaguaçu da Bahia",
                "label": "Itaguaçu da Bahia"
            },
            {
                "value": "Itaju do Colônia",
                "label": "Itaju do Colônia"
            },
            {
                "value": "Itajuípe",
                "label": "Itajuípe"
            },
            {
                "value": "Itamaraju",
                "label": "Itamaraju"
            },
            {
                "value": "Itamari",
                "label": "Itamari"
            },
            {
                "value": "Itambé",
                "label": "Itambé"
            },
            {
                "value": "Itanagra",
                "label": "Itanagra"
            },
            {
                "value": "Itanhém",
                "label": "Itanhém"
            },
            {
                "value": "Itaparica",
                "label": "Itaparica"
            },
            {
                "value": "Itapé",
                "label": "Itapé"
            },
            {
                "value": "Itapebi",
                "label": "Itapebi"
            },
            {
                "value": "Itapetinga",
                "label": "Itapetinga"
            },
            {
                "value": "Itapicuru",
                "label": "Itapicuru"
            },
            {
                "value": "Itapitanga",
                "label": "Itapitanga"
            },
            {
                "value": "Itaquara",
                "label": "Itaquara"
            },
            {
                "value": "Itarantim",
                "label": "Itarantim"
            },
            {
                "value": "Itatim",
                "label": "Itatim"
            },
            {
                "value": "Itiruçu",
                "label": "Itiruçu"
            },
            {
                "value": "Itiúba",
                "label": "Itiúba"
            },
            {
                "value": "Itororó",
                "label": "Itororó"
            },
            {
                "value": "Ituaçu",
                "label": "Ituaçu"
            },
            {
                "value": "Ituberá",
                "label": "Ituberá"
            },
            {
                "value": "Iuiu",
                "label": "Iuiu"
            },
            {
                "value": "Jaborandi",
                "label": "Jaborandi"
            },
            {
                "value": "Jacaraci",
                "label": "Jacaraci"
            },
            {
                "value": "Jacobina",
                "label": "Jacobina"
            },
            {
                "value": "Jaguaquara",
                "label": "Jaguaquara"
            },
            {
                "value": "Jaguarari",
                "label": "Jaguarari"
            },
            {
                "value": "Jaguaripe",
                "label": "Jaguaripe"
            },
            {
                "value": "Jandaíra",
                "label": "Jandaíra"
            },
            {
                "value": "Jequié",
                "label": "Jequié"
            },
            {
                "value": "Jeremoabo",
                "label": "Jeremoabo"
            },
            {
                "value": "Jiquiriçá",
                "label": "Jiquiriçá"
            },
            {
                "value": "Jitaúna",
                "label": "Jitaúna"
            },
            {
                "value": "João Dourado",
                "label": "João Dourado"
            },
            {
                "value": "Juazeiro",
                "label": "Juazeiro"
            },
            {
                "value": "Jucuruçu",
                "label": "Jucuruçu"
            },
            {
                "value": "Jussara",
                "label": "Jussara"
            },
            {
                "value": "Jussari",
                "label": "Jussari"
            },
            {
                "value": "Jussiape",
                "label": "Jussiape"
            },
            {
                "value": "Lafaiete Coutinho",
                "label": "Lafaiete Coutinho"
            },
            {
                "value": "Lagoa Real",
                "label": "Lagoa Real"
            },
            {
                "value": "Laje",
                "label": "Laje"
            },
            {
                "value": "Lajedão",
                "label": "Lajedão"
            },
            {
                "value": "Lajedinho",
                "label": "Lajedinho"
            },
            {
                "value": "Lajedo do Tabocal",
                "label": "Lajedo do Tabocal"
            },
            {
                "value": "Lamarão",
                "label": "Lamarão"
            },
            {
                "value": "Lapão",
                "label": "Lapão"
            },
            {
                "value": "Lauro de Freitas",
                "label": "Lauro de Freitas"
            },
            {
                "value": "Lençóis",
                "label": "Lençóis"
            },
            {
                "value": "Licínio de Almeida",
                "label": "Licínio de Almeida"
            },
            {
                "value": "Livramento de Nossa Senhora",
                "label": "Livramento de Nossa Senhora"
            },
            {
                "value": "Luís Eduardo Magalhães",
                "label": "Luís Eduardo Magalhães"
            },
            {
                "value": "Macajuba",
                "label": "Macajuba"
            },
            {
                "value": "Macarani",
                "label": "Macarani"
            },
            {
                "value": "Macaúbas",
                "label": "Macaúbas"
            },
            {
                "value": "Macururé",
                "label": "Macururé"
            },
            {
                "value": "Madre de Deus",
                "label": "Madre de Deus"
            },
            {
                "value": "Maetinga",
                "label": "Maetinga"
            },
            {
                "value": "Maiquinique",
                "label": "Maiquinique"
            },
            {
                "value": "Mairi",
                "label": "Mairi"
            },
            {
                "value": "Malhada",
                "label": "Malhada"
            },
            {
                "value": "Malhada de Pedras",
                "label": "Malhada de Pedras"
            },
            {
                "value": "Manoel Vitorino",
                "label": "Manoel Vitorino"
            },
            {
                "value": "Mansidão",
                "label": "Mansidão"
            },
            {
                "value": "Maracás",
                "label": "Maracás"
            },
            {
                "value": "Maragogipe",
                "label": "Maragogipe"
            },
            {
                "value": "Maraú",
                "label": "Maraú"
            },
            {
                "value": "Marcionílio Souza",
                "label": "Marcionílio Souza"
            },
            {
                "value": "Mascote",
                "label": "Mascote"
            },
            {
                "value": "Mata de São João",
                "label": "Mata de São João"
            },
            {
                "value": "Matina",
                "label": "Matina"
            },
            {
                "value": "Medeiros Neto",
                "label": "Medeiros Neto"
            },
            {
                "value": "Miguel Calmon",
                "label": "Miguel Calmon"
            },
            {
                "value": "Milagres",
                "label": "Milagres"
            },
            {
                "value": "Mirangaba",
                "label": "Mirangaba"
            },
            {
                "value": "Mirante",
                "label": "Mirante"
            },
            {
                "value": "Monte Santo",
                "label": "Monte Santo"
            },
            {
                "value": "Morpará",
                "label": "Morpará"
            },
            {
                "value": "Morro do Chapéu",
                "label": "Morro do Chapéu"
            },
            {
                "value": "Mortugaba",
                "label": "Mortugaba"
            },
            {
                "value": "Mucugê",
                "label": "Mucugê"
            },
            {
                "value": "Mucuri",
                "label": "Mucuri"
            },
            {
                "value": "Mulungu do Morro",
                "label": "Mulungu do Morro"
            },
            {
                "value": "Mundo Novo",
                "label": "Mundo Novo"
            },
            {
                "value": "Muniz Ferreira",
                "label": "Muniz Ferreira"
            },
            {
                "value": "Muquém do São Francisco",
                "label": "Muquém do São Francisco"
            },
            {
                "value": "Muritiba",
                "label": "Muritiba"
            },
            {
                "value": "Mutuípe",
                "label": "Mutuípe"
            },
            {
                "value": "Nazaré",
                "label": "Nazaré"
            },
            {
                "value": "Nilo Peçanha",
                "label": "Nilo Peçanha"
            },
            {
                "value": "Nordestina",
                "label": "Nordestina"
            },
            {
                "value": "Nova Canaã",
                "label": "Nova Canaã"
            },
            {
                "value": "Nova Fátima",
                "label": "Nova Fátima"
            },
            {
                "value": "Nova Ibiá",
                "label": "Nova Ibiá"
            },
            {
                "value": "Nova Itarana",
                "label": "Nova Itarana"
            },
            {
                "value": "Nova Redenção",
                "label": "Nova Redenção"
            },
            {
                "value": "Nova Soure",
                "label": "Nova Soure"
            },
            {
                "value": "Nova Viçosa",
                "label": "Nova Viçosa"
            },
            {
                "value": "Novo Horizonte",
                "label": "Novo Horizonte"
            },
            {
                "value": "Novo Triunfo",
                "label": "Novo Triunfo"
            },
            {
                "value": "Olindina",
                "label": "Olindina"
            },
            {
                "value": "Oliveira dos Brejinhos",
                "label": "Oliveira dos Brejinhos"
            },
            {
                "value": "Ouriçangas",
                "label": "Ouriçangas"
            },
            {
                "value": "Ourolândia",
                "label": "Ourolândia"
            },
            {
                "value": "Palmas de Monte Alto",
                "label": "Palmas de Monte Alto"
            },
            {
                "value": "Palmeiras",
                "label": "Palmeiras"
            },
            {
                "value": "Paramirim",
                "label": "Paramirim"
            },
            {
                "value": "Paratinga",
                "label": "Paratinga"
            },
            {
                "value": "Paripiranga",
                "label": "Paripiranga"
            },
            {
                "value": "Pau Brasil",
                "label": "Pau Brasil"
            },
            {
                "value": "Paulo Afonso",
                "label": "Paulo Afonso"
            },
            {
                "value": "Pé de Serra",
                "label": "Pé de Serra"
            },
            {
                "value": "Pedrão",
                "label": "Pedrão"
            },
            {
                "value": "Pedro Alexandre",
                "label": "Pedro Alexandre"
            },
            {
                "value": "Piatã",
                "label": "Piatã"
            },
            {
                "value": "Pilão Arcado",
                "label": "Pilão Arcado"
            },
            {
                "value": "Pindaí",
                "label": "Pindaí"
            },
            {
                "value": "Pindobaçu",
                "label": "Pindobaçu"
            },
            {
                "value": "Pintadas",
                "label": "Pintadas"
            },
            {
                "value": "Piraí do Norte",
                "label": "Piraí do Norte"
            },
            {
                "value": "Piripá",
                "label": "Piripá"
            },
            {
                "value": "Piritiba",
                "label": "Piritiba"
            },
            {
                "value": "Planaltino",
                "label": "Planaltino"
            },
            {
                "value": "Planalto",
                "label": "Planalto"
            },
            {
                "value": "Poções",
                "label": "Poções"
            },
            {
                "value": "Pojuca",
                "label": "Pojuca"
            },
            {
                "value": "Ponto Novo",
                "label": "Ponto Novo"
            },
            {
                "value": "Porto Seguro",
                "label": "Porto Seguro"
            },
            {
                "value": "Potiraguá",
                "label": "Potiraguá"
            },
            {
                "value": "Prado",
                "label": "Prado"
            },
            {
                "value": "Presidente Dutra",
                "label": "Presidente Dutra"
            },
            {
                "value": "Presidente Jânio Quadros",
                "label": "Presidente Jânio Quadros"
            },
            {
                "value": "Presidente Tancredo Neves",
                "label": "Presidente Tancredo Neves"
            },
            {
                "value": "Queimadas",
                "label": "Queimadas"
            },
            {
                "value": "Quijingue",
                "label": "Quijingue"
            },
            {
                "value": "Quixabeira",
                "label": "Quixabeira"
            },
            {
                "value": "Rafael Jambeiro",
                "label": "Rafael Jambeiro"
            },
            {
                "value": "Remanso",
                "label": "Remanso"
            },
            {
                "value": "Retirolândia",
                "label": "Retirolândia"
            },
            {
                "value": "Riachão das Neves",
                "label": "Riachão das Neves"
            },
            {
                "value": "Riachão do Jacuípe",
                "label": "Riachão do Jacuípe"
            },
            {
                "value": "Riacho de Santana",
                "label": "Riacho de Santana"
            },
            {
                "value": "Ribeira do Amparo",
                "label": "Ribeira do Amparo"
            },
            {
                "value": "Ribeira do Pombal",
                "label": "Ribeira do Pombal"
            },
            {
                "value": "Ribeirão do Largo",
                "label": "Ribeirão do Largo"
            },
            {
                "value": "Rio de Contas",
                "label": "Rio de Contas"
            },
            {
                "value": "Rio do Antônio",
                "label": "Rio do Antônio"
            },
            {
                "value": "Rio do Pires",
                "label": "Rio do Pires"
            },
            {
                "value": "Rio Real",
                "label": "Rio Real"
            },
            {
                "value": "Rodelas",
                "label": "Rodelas"
            },
            {
                "value": "Ruy Barbosa",
                "label": "Ruy Barbosa"
            },
            {
                "value": "Salinas da Margarida",
                "label": "Salinas da Margarida"
            },
            {
                "value": "Salvador",
                "label": "Salvador"
            },
            {
                "value": "Santa Bárbara",
                "label": "Santa Bárbara"
            },
            {
                "value": "Santa Brígida",
                "label": "Santa Brígida"
            },
            {
                "value": "Santa Cruz Cabrália",
                "label": "Santa Cruz Cabrália"
            },
            {
                "value": "Santa Cruz da Vitória",
                "label": "Santa Cruz da Vitória"
            },
            {
                "value": "Santa Inês",
                "label": "Santa Inês"
            },
            {
                "value": "Santaluz",
                "label": "Santaluz"
            },
            {
                "value": "Santa Luzia",
                "label": "Santa Luzia"
            },
            {
                "value": "Santa Maria da Vitória",
                "label": "Santa Maria da Vitória"
            },
            {
                "value": "Santana",
                "label": "Santana"
            },
            {
                "value": "Santanópolis",
                "label": "Santanópolis"
            },
            {
                "value": "Santa Rita de Cássia",
                "label": "Santa Rita de Cássia"
            },
            {
                "value": "Santa Terezinha",
                "label": "Santa Terezinha"
            },
            {
                "value": "Santo Amaro",
                "label": "Santo Amaro"
            },
            {
                "value": "Santo Antônio de Jesus",
                "label": "Santo Antônio de Jesus"
            },
            {
                "value": "Santo Estêvão",
                "label": "Santo Estêvão"
            },
            {
                "value": "São Desidério",
                "label": "São Desidério"
            },
            {
                "value": "São Domingos",
                "label": "São Domingos"
            },
            {
                "value": "São Félix",
                "label": "São Félix"
            },
            {
                "value": "São Félix do Coribe",
                "label": "São Félix do Coribe"
            },
            {
                "value": "São Felipe",
                "label": "São Felipe"
            },
            {
                "value": "São Francisco do Conde",
                "label": "São Francisco do Conde"
            },
            {
                "value": "São Gabriel",
                "label": "São Gabriel"
            },
            {
                "value": "São Gonçalo dos Campos",
                "label": "São Gonçalo dos Campos"
            },
            {
                "value": "São José da Vitória",
                "label": "São José da Vitória"
            },
            {
                "value": "São José do Jacuípe",
                "label": "São José do Jacuípe"
            },
            {
                "value": "São Miguel das Matas",
                "label": "São Miguel das Matas"
            },
            {
                "value": "São Sebastião do Passé",
                "label": "São Sebastião do Passé"
            },
            {
                "value": "Sapeaçu",
                "label": "Sapeaçu"
            },
            {
                "value": "Sátiro Dias",
                "label": "Sátiro Dias"
            },
            {
                "value": "Saubara",
                "label": "Saubara"
            },
            {
                "value": "Saúde",
                "label": "Saúde"
            },
            {
                "value": "Seabra",
                "label": "Seabra"
            },
            {
                "value": "Sebastião Laranjeiras",
                "label": "Sebastião Laranjeiras"
            },
            {
                "value": "Senhor do Bonfim",
                "label": "Senhor do Bonfim"
            },
            {
                "value": "Serra do Ramalho",
                "label": "Serra do Ramalho"
            },
            {
                "value": "Sento Sé",
                "label": "Sento Sé"
            },
            {
                "value": "Serra Dourada",
                "label": "Serra Dourada"
            },
            {
                "value": "Serra Preta",
                "label": "Serra Preta"
            },
            {
                "value": "Serrinha",
                "label": "Serrinha"
            },
            {
                "value": "Serrolândia",
                "label": "Serrolândia"
            },
            {
                "value": "Simões Filho",
                "label": "Simões Filho"
            },
            {
                "value": "Sítio do Mato",
                "label": "Sítio do Mato"
            },
            {
                "value": "Sítio do Quinto",
                "label": "Sítio do Quinto"
            },
            {
                "value": "Sobradinho",
                "label": "Sobradinho"
            },
            {
                "value": "Souto Soares",
                "label": "Souto Soares"
            },
            {
                "value": "Tabocas do Brejo Velho",
                "label": "Tabocas do Brejo Velho"
            },
            {
                "value": "Tanhaçu",
                "label": "Tanhaçu"
            },
            {
                "value": "Tanque Novo",
                "label": "Tanque Novo"
            },
            {
                "value": "Tanquinho",
                "label": "Tanquinho"
            },
            {
                "value": "Taperoá",
                "label": "Taperoá"
            },
            {
                "value": "Tapiramutá",
                "label": "Tapiramutá"
            },
            {
                "value": "Teixeira de Freitas",
                "label": "Teixeira de Freitas"
            },
            {
                "value": "Teodoro Sampaio",
                "label": "Teodoro Sampaio"
            },
            {
                "value": "Teofilândia",
                "label": "Teofilândia"
            },
            {
                "value": "Teolândia",
                "label": "Teolândia"
            },
            {
                "value": "Terra Nova",
                "label": "Terra Nova"
            },
            {
                "value": "Tremedal",
                "label": "Tremedal"
            },
            {
                "value": "Tucano",
                "label": "Tucano"
            },
            {
                "value": "Uauá",
                "label": "Uauá"
            },
            {
                "value": "Ubaíra",
                "label": "Ubaíra"
            },
            {
                "value": "Ubaitaba",
                "label": "Ubaitaba"
            },
            {
                "value": "Ubatã",
                "label": "Ubatã"
            },
            {
                "value": "Uibaí",
                "label": "Uibaí"
            },
            {
                "value": "Umburanas",
                "label": "Umburanas"
            },
            {
                "value": "Una",
                "label": "Una"
            },
            {
                "value": "Urandi",
                "label": "Urandi"
            },
            {
                "value": "Uruçuca",
                "label": "Uruçuca"
            },
            {
                "value": "Utinga",
                "label": "Utinga"
            },
            {
                "value": "Valença",
                "label": "Valença"
            },
            {
                "value": "Valente",
                "label": "Valente"
            },
            {
                "value": "Várzea da Roça",
                "label": "Várzea da Roça"
            },
            {
                "value": "Várzea do Poço",
                "label": "Várzea do Poço"
            },
            {
                "value": "Várzea Nova",
                "label": "Várzea Nova"
            },
            {
                "value": "Varzedo",
                "label": "Varzedo"
            },
            {
                "value": "Vera Cruz",
                "label": "Vera Cruz"
            },
            {
                "value": "Vereda",
                "label": "Vereda"
            },
            {
                "value": "Vitória da Conquista",
                "label": "Vitória da Conquista"
            },
            {
                "value": "Wagner",
                "label": "Wagner"
            },
            {
                "value": "Wanderley",
                "label": "Wanderley"
            },
            {
                "value": "Wenceslau Guimarães",
                "label": "Wenceslau Guimarães"
            },
            {
                "value": "Xique-Xique",
                "label": "Xique-Xique"
            }
        ]
    },
    {
        "value": "CE",
        "label": "Ceará",
        "children": [
            {
                "value": "Abaiara",
                "label": "Abaiara"
            },
            {
                "value": "Acarape",
                "label": "Acarape"
            },
            {
                "value": "Acaraú",
                "label": "Acaraú"
            },
            {
                "value": "Acopiara",
                "label": "Acopiara"
            },
            {
                "value": "Aiuaba",
                "label": "Aiuaba"
            },
            {
                "value": "Alcântaras",
                "label": "Alcântaras"
            },
            {
                "value": "Altaneira",
                "label": "Altaneira"
            },
            {
                "value": "Alto Santo",
                "label": "Alto Santo"
            },
            {
                "value": "Amontada",
                "label": "Amontada"
            },
            {
                "value": "Antonina do Norte",
                "label": "Antonina do Norte"
            },
            {
                "value": "Apuiarés",
                "label": "Apuiarés"
            },
            {
                "value": "Aquiraz",
                "label": "Aquiraz"
            },
            {
                "value": "Aracati",
                "label": "Aracati"
            },
            {
                "value": "Aracoiaba",
                "label": "Aracoiaba"
            },
            {
                "value": "Ararendá",
                "label": "Ararendá"
            },
            {
                "value": "Araripe",
                "label": "Araripe"
            },
            {
                "value": "Aratuba",
                "label": "Aratuba"
            },
            {
                "value": "Arneiroz",
                "label": "Arneiroz"
            },
            {
                "value": "Assaré",
                "label": "Assaré"
            },
            {
                "value": "Aurora",
                "label": "Aurora"
            },
            {
                "value": "Baixio",
                "label": "Baixio"
            },
            {
                "value": "Banabuiú",
                "label": "Banabuiú"
            },
            {
                "value": "Barbalha",
                "label": "Barbalha"
            },
            {
                "value": "Barreira",
                "label": "Barreira"
            },
            {
                "value": "Barro",
                "label": "Barro"
            },
            {
                "value": "Barroquinha",
                "label": "Barroquinha"
            },
            {
                "value": "Baturité",
                "label": "Baturité"
            },
            {
                "value": "Beberibe",
                "label": "Beberibe"
            },
            {
                "value": "Bela Cruz",
                "label": "Bela Cruz"
            },
            {
                "value": "Boa Viagem",
                "label": "Boa Viagem"
            },
            {
                "value": "Brejo Santo",
                "label": "Brejo Santo"
            },
            {
                "value": "Camocim",
                "label": "Camocim"
            },
            {
                "value": "Campos Sales",
                "label": "Campos Sales"
            },
            {
                "value": "Canindé",
                "label": "Canindé"
            },
            {
                "value": "Capistrano",
                "label": "Capistrano"
            },
            {
                "value": "Caridade",
                "label": "Caridade"
            },
            {
                "value": "Cariré",
                "label": "Cariré"
            },
            {
                "value": "Caririaçu",
                "label": "Caririaçu"
            },
            {
                "value": "Cariús",
                "label": "Cariús"
            },
            {
                "value": "Carnaubal",
                "label": "Carnaubal"
            },
            {
                "value": "Cascavel",
                "label": "Cascavel"
            },
            {
                "value": "Catarina",
                "label": "Catarina"
            },
            {
                "value": "Catunda",
                "label": "Catunda"
            },
            {
                "value": "Caucaia",
                "label": "Caucaia"
            },
            {
                "value": "Cedro",
                "label": "Cedro"
            },
            {
                "value": "Chaval",
                "label": "Chaval"
            },
            {
                "value": "Choró",
                "label": "Choró"
            },
            {
                "value": "Chorozinho",
                "label": "Chorozinho"
            },
            {
                "value": "Coreaú",
                "label": "Coreaú"
            },
            {
                "value": "Crateús",
                "label": "Crateús"
            },
            {
                "value": "Crato",
                "label": "Crato"
            },
            {
                "value": "Croatá",
                "label": "Croatá"
            },
            {
                "value": "Cruz",
                "label": "Cruz"
            },
            {
                "value": "Deputado Irapuan Pinheiro",
                "label": "Deputado Irapuan Pinheiro"
            },
            {
                "value": "Ereré",
                "label": "Ereré"
            },
            {
                "value": "Eusébio",
                "label": "Eusébio"
            },
            {
                "value": "Farias Brito",
                "label": "Farias Brito"
            },
            {
                "value": "Forquilha",
                "label": "Forquilha"
            },
            {
                "value": "Fortaleza",
                "label": "Fortaleza"
            },
            {
                "value": "Fortim",
                "label": "Fortim"
            },
            {
                "value": "Frecheirinha",
                "label": "Frecheirinha"
            },
            {
                "value": "General Sampaio",
                "label": "General Sampaio"
            },
            {
                "value": "Graça",
                "label": "Graça"
            },
            {
                "value": "Granja",
                "label": "Granja"
            },
            {
                "value": "Granjeiro",
                "label": "Granjeiro"
            },
            {
                "value": "Groaíras",
                "label": "Groaíras"
            },
            {
                "value": "Guaiúba",
                "label": "Guaiúba"
            },
            {
                "value": "Guaraciaba do Norte",
                "label": "Guaraciaba do Norte"
            },
            {
                "value": "Guaramiranga",
                "label": "Guaramiranga"
            },
            {
                "value": "Hidrolândia",
                "label": "Hidrolândia"
            },
            {
                "value": "Horizonte",
                "label": "Horizonte"
            },
            {
                "value": "Ibaretama",
                "label": "Ibaretama"
            },
            {
                "value": "Ibiapina",
                "label": "Ibiapina"
            },
            {
                "value": "Ibicuitinga",
                "label": "Ibicuitinga"
            },
            {
                "value": "Icapuí",
                "label": "Icapuí"
            },
            {
                "value": "Icó",
                "label": "Icó"
            },
            {
                "value": "Iguatu",
                "label": "Iguatu"
            },
            {
                "value": "Independência",
                "label": "Independência"
            },
            {
                "value": "Ipaporanga",
                "label": "Ipaporanga"
            },
            {
                "value": "Ipaumirim",
                "label": "Ipaumirim"
            },
            {
                "value": "Ipu",
                "label": "Ipu"
            },
            {
                "value": "Ipueiras",
                "label": "Ipueiras"
            },
            {
                "value": "Iracema",
                "label": "Iracema"
            },
            {
                "value": "Irauçuba",
                "label": "Irauçuba"
            },
            {
                "value": "Itaiçaba",
                "label": "Itaiçaba"
            },
            {
                "value": "Itaitinga",
                "label": "Itaitinga"
            },
            {
                "value": "Itapajé",
                "label": "Itapajé"
            },
            {
                "value": "Itapipoca",
                "label": "Itapipoca"
            },
            {
                "value": "Itapiúna",
                "label": "Itapiúna"
            },
            {
                "value": "Itarema",
                "label": "Itarema"
            },
            {
                "value": "Itatira",
                "label": "Itatira"
            },
            {
                "value": "Jaguaretama",
                "label": "Jaguaretama"
            },
            {
                "value": "Jaguaribara",
                "label": "Jaguaribara"
            },
            {
                "value": "Jaguaribe",
                "label": "Jaguaribe"
            },
            {
                "value": "Jaguaruana",
                "label": "Jaguaruana"
            },
            {
                "value": "Jardim",
                "label": "Jardim"
            },
            {
                "value": "Jati",
                "label": "Jati"
            },
            {
                "value": "Jijoca de Jericoacoara",
                "label": "Jijoca de Jericoacoara"
            },
            {
                "value": "Juazeiro do Norte",
                "label": "Juazeiro do Norte"
            },
            {
                "value": "Jucás",
                "label": "Jucás"
            },
            {
                "value": "Lavras da Mangabeira",
                "label": "Lavras da Mangabeira"
            },
            {
                "value": "Limoeiro do Norte",
                "label": "Limoeiro do Norte"
            },
            {
                "value": "Madalena",
                "label": "Madalena"
            },
            {
                "value": "Maracanaú",
                "label": "Maracanaú"
            },
            {
                "value": "Maranguape",
                "label": "Maranguape"
            },
            {
                "value": "Marco",
                "label": "Marco"
            },
            {
                "value": "Martinópole",
                "label": "Martinópole"
            },
            {
                "value": "Massapê",
                "label": "Massapê"
            },
            {
                "value": "Mauriti",
                "label": "Mauriti"
            },
            {
                "value": "Meruoca",
                "label": "Meruoca"
            },
            {
                "value": "Milagres",
                "label": "Milagres"
            },
            {
                "value": "Milhã",
                "label": "Milhã"
            },
            {
                "value": "Miraíma",
                "label": "Miraíma"
            },
            {
                "value": "Missão Velha",
                "label": "Missão Velha"
            },
            {
                "value": "Mombaça",
                "label": "Mombaça"
            },
            {
                "value": "Monsenhor Tabosa",
                "label": "Monsenhor Tabosa"
            },
            {
                "value": "Morada Nova",
                "label": "Morada Nova"
            },
            {
                "value": "Moraújo",
                "label": "Moraújo"
            },
            {
                "value": "Morrinhos",
                "label": "Morrinhos"
            },
            {
                "value": "Mucambo",
                "label": "Mucambo"
            },
            {
                "value": "Mulungu",
                "label": "Mulungu"
            },
            {
                "value": "Nova Olinda",
                "label": "Nova Olinda"
            },
            {
                "value": "Nova Russas",
                "label": "Nova Russas"
            },
            {
                "value": "Novo Oriente",
                "label": "Novo Oriente"
            },
            {
                "value": "Ocara",
                "label": "Ocara"
            },
            {
                "value": "Orós",
                "label": "Orós"
            },
            {
                "value": "Pacajus",
                "label": "Pacajus"
            },
            {
                "value": "Pacatuba",
                "label": "Pacatuba"
            },
            {
                "value": "Pacoti",
                "label": "Pacoti"
            },
            {
                "value": "Pacujá",
                "label": "Pacujá"
            },
            {
                "value": "Palhano",
                "label": "Palhano"
            },
            {
                "value": "Palmácia",
                "label": "Palmácia"
            },
            {
                "value": "Paracuru",
                "label": "Paracuru"
            },
            {
                "value": "Paraipaba",
                "label": "Paraipaba"
            },
            {
                "value": "Parambu",
                "label": "Parambu"
            },
            {
                "value": "Paramoti",
                "label": "Paramoti"
            },
            {
                "value": "Pedra Branca",
                "label": "Pedra Branca"
            },
            {
                "value": "Penaforte",
                "label": "Penaforte"
            },
            {
                "value": "Pentecoste",
                "label": "Pentecoste"
            },
            {
                "value": "Pereiro",
                "label": "Pereiro"
            },
            {
                "value": "Pindoretama",
                "label": "Pindoretama"
            },
            {
                "value": "Piquet Carneiro",
                "label": "Piquet Carneiro"
            },
            {
                "value": "Pires Ferreira",
                "label": "Pires Ferreira"
            },
            {
                "value": "Poranga",
                "label": "Poranga"
            },
            {
                "value": "Porteiras",
                "label": "Porteiras"
            },
            {
                "value": "Potengi",
                "label": "Potengi"
            },
            {
                "value": "Potiretama",
                "label": "Potiretama"
            },
            {
                "value": "Quiterianópolis",
                "label": "Quiterianópolis"
            },
            {
                "value": "Quixadá",
                "label": "Quixadá"
            },
            {
                "value": "Quixelô",
                "label": "Quixelô"
            },
            {
                "value": "Quixeramobim",
                "label": "Quixeramobim"
            },
            {
                "value": "Quixeré",
                "label": "Quixeré"
            },
            {
                "value": "Redenção",
                "label": "Redenção"
            },
            {
                "value": "Reriutaba",
                "label": "Reriutaba"
            },
            {
                "value": "Russas",
                "label": "Russas"
            },
            {
                "value": "Saboeiro",
                "label": "Saboeiro"
            },
            {
                "value": "Salitre",
                "label": "Salitre"
            },
            {
                "value": "Santana do Acaraú",
                "label": "Santana do Acaraú"
            },
            {
                "value": "Santana do Cariri",
                "label": "Santana do Cariri"
            },
            {
                "value": "Santa Quitéria",
                "label": "Santa Quitéria"
            },
            {
                "value": "São Benedito",
                "label": "São Benedito"
            },
            {
                "value": "São Gonçalo do Amarante",
                "label": "São Gonçalo do Amarante"
            },
            {
                "value": "São João do Jaguaribe",
                "label": "São João do Jaguaribe"
            },
            {
                "value": "São Luís do Curu",
                "label": "São Luís do Curu"
            },
            {
                "value": "Senador Pompeu",
                "label": "Senador Pompeu"
            },
            {
                "value": "Senador Sá",
                "label": "Senador Sá"
            },
            {
                "value": "Sobral",
                "label": "Sobral"
            },
            {
                "value": "Solonópole",
                "label": "Solonópole"
            },
            {
                "value": "Tabuleiro do Norte",
                "label": "Tabuleiro do Norte"
            },
            {
                "value": "Tamboril",
                "label": "Tamboril"
            },
            {
                "value": "Tarrafas",
                "label": "Tarrafas"
            },
            {
                "value": "Tauá",
                "label": "Tauá"
            },
            {
                "value": "Tejuçuoca",
                "label": "Tejuçuoca"
            },
            {
                "value": "Tianguá",
                "label": "Tianguá"
            },
            {
                "value": "Trairi",
                "label": "Trairi"
            },
            {
                "value": "Tururu",
                "label": "Tururu"
            },
            {
                "value": "Ubajara",
                "label": "Ubajara"
            },
            {
                "value": "Umari",
                "label": "Umari"
            },
            {
                "value": "Umirim",
                "label": "Umirim"
            },
            {
                "value": "Uruburetama",
                "label": "Uruburetama"
            },
            {
                "value": "Uruoca",
                "label": "Uruoca"
            },
            {
                "value": "Varjota",
                "label": "Varjota"
            },
            {
                "value": "Várzea Alegre",
                "label": "Várzea Alegre"
            },
            {
                "value": "Viçosa do Ceará",
                "label": "Viçosa do Ceará"
            }
        ]
    },
    {
        "value": "DF",
        "label": "Distrito Federal",
        "children": [
            {
                "value": "Brasília",
                "label": "Brasília"
            }
        ]
    },
    {
        "value": "ES",
        "label": "Espírito Santo",
        "children": [
            {
                "value": "Afonso Cláudio",
                "label": "Afonso Cláudio"
            },
            {
                "value": "Águia Branca",
                "label": "Águia Branca"
            },
            {
                "value": "Água Doce do Norte",
                "label": "Água Doce do Norte"
            },
            {
                "value": "Alegre",
                "label": "Alegre"
            },
            {
                "value": "Alfredo Chaves",
                "label": "Alfredo Chaves"
            },
            {
                "value": "Alto Rio Novo",
                "label": "Alto Rio Novo"
            },
            {
                "value": "Anchieta",
                "label": "Anchieta"
            },
            {
                "value": "Apiacá",
                "label": "Apiacá"
            },
            {
                "value": "Aracruz",
                "label": "Aracruz"
            },
            {
                "value": "Atílio Vivacqua",
                "label": "Atílio Vivacqua"
            },
            {
                "value": "Baixo Guandu",
                "label": "Baixo Guandu"
            },
            {
                "value": "Barra de São Francisco",
                "label": "Barra de São Francisco"
            },
            {
                "value": "Boa Esperança",
                "label": "Boa Esperança"
            },
            {
                "value": "Bom Jesus do Norte",
                "label": "Bom Jesus do Norte"
            },
            {
                "value": "Brejetuba",
                "label": "Brejetuba"
            },
            {
                "value": "Cachoeiro de Itapemirim",
                "label": "Cachoeiro de Itapemirim"
            },
            {
                "value": "Cariacica",
                "label": "Cariacica"
            },
            {
                "value": "Castelo",
                "label": "Castelo"
            },
            {
                "value": "Colatina",
                "label": "Colatina"
            },
            {
                "value": "Conceição da Barra",
                "label": "Conceição da Barra"
            },
            {
                "value": "Conceição do Castelo",
                "label": "Conceição do Castelo"
            },
            {
                "value": "Divino de São Lourenço",
                "label": "Divino de São Lourenço"
            },
            {
                "value": "Domingos Martins",
                "label": "Domingos Martins"
            },
            {
                "value": "Dores do Rio Preto",
                "label": "Dores do Rio Preto"
            },
            {
                "value": "Ecoporanga",
                "label": "Ecoporanga"
            },
            {
                "value": "Fundão",
                "label": "Fundão"
            },
            {
                "value": "Governador Lindenberg",
                "label": "Governador Lindenberg"
            },
            {
                "value": "Guaçuí",
                "label": "Guaçuí"
            },
            {
                "value": "Guarapari",
                "label": "Guarapari"
            },
            {
                "value": "Ibatiba",
                "label": "Ibatiba"
            },
            {
                "value": "Ibiraçu",
                "label": "Ibiraçu"
            },
            {
                "value": "Ibitirama",
                "label": "Ibitirama"
            },
            {
                "value": "Iconha",
                "label": "Iconha"
            },
            {
                "value": "Irupi",
                "label": "Irupi"
            },
            {
                "value": "Itaguaçu",
                "label": "Itaguaçu"
            },
            {
                "value": "Itapemirim",
                "label": "Itapemirim"
            },
            {
                "value": "Itarana",
                "label": "Itarana"
            },
            {
                "value": "Iúna",
                "label": "Iúna"
            },
            {
                "value": "Jaguaré",
                "label": "Jaguaré"
            },
            {
                "value": "Jerônimo Monteiro",
                "label": "Jerônimo Monteiro"
            },
            {
                "value": "João Neiva",
                "label": "João Neiva"
            },
            {
                "value": "Laranja da Terra",
                "label": "Laranja da Terra"
            },
            {
                "value": "Linhares",
                "label": "Linhares"
            },
            {
                "value": "Mantenópolis",
                "label": "Mantenópolis"
            },
            {
                "value": "Marataízes",
                "label": "Marataízes"
            },
            {
                "value": "Marechal Floriano",
                "label": "Marechal Floriano"
            },
            {
                "value": "Marilândia",
                "label": "Marilândia"
            },
            {
                "value": "Mimoso do Sul",
                "label": "Mimoso do Sul"
            },
            {
                "value": "Montanha",
                "label": "Montanha"
            },
            {
                "value": "Mucurici",
                "label": "Mucurici"
            },
            {
                "value": "Muniz Freire",
                "label": "Muniz Freire"
            },
            {
                "value": "Muqui",
                "label": "Muqui"
            },
            {
                "value": "Nova Venécia",
                "label": "Nova Venécia"
            },
            {
                "value": "Pancas",
                "label": "Pancas"
            },
            {
                "value": "Pedro Canário",
                "label": "Pedro Canário"
            },
            {
                "value": "Pinheiros",
                "label": "Pinheiros"
            },
            {
                "value": "Piúma",
                "label": "Piúma"
            },
            {
                "value": "Ponto Belo",
                "label": "Ponto Belo"
            },
            {
                "value": "Presidente Kennedy",
                "label": "Presidente Kennedy"
            },
            {
                "value": "Rio Bananal",
                "label": "Rio Bananal"
            },
            {
                "value": "Rio Novo do Sul",
                "label": "Rio Novo do Sul"
            },
            {
                "value": "Santa Leopoldina",
                "label": "Santa Leopoldina"
            },
            {
                "value": "Santa Maria de Jetibá",
                "label": "Santa Maria de Jetibá"
            },
            {
                "value": "Santa Teresa",
                "label": "Santa Teresa"
            },
            {
                "value": "São Domingos do Norte",
                "label": "São Domingos do Norte"
            },
            {
                "value": "São Gabriel da Palha",
                "label": "São Gabriel da Palha"
            },
            {
                "value": "São José do Calçado",
                "label": "São José do Calçado"
            },
            {
                "value": "São Mateus",
                "label": "São Mateus"
            },
            {
                "value": "São Roque do Canaã",
                "label": "São Roque do Canaã"
            },
            {
                "value": "Serra",
                "label": "Serra"
            },
            {
                "value": "Sooretama",
                "label": "Sooretama"
            },
            {
                "value": "Vargem Alta",
                "label": "Vargem Alta"
            },
            {
                "value": "Venda Nova do Imigrante",
                "label": "Venda Nova do Imigrante"
            },
            {
                "value": "Viana",
                "label": "Viana"
            },
            {
                "value": "Vila Pavão",
                "label": "Vila Pavão"
            },
            {
                "value": "Vila Valério",
                "label": "Vila Valério"
            },
            {
                "value": "Vila Velha",
                "label": "Vila Velha"
            },
            {
                "value": "Vitória",
                "label": "Vitória"
            }
        ]
    },
    {
        "value": "GO",
        "label": "Goiás",
        "children": [
            {
                "value": "Abadia de Goiás",
                "label": "Abadia de Goiás"
            },
            {
                "value": "Abadiânia",
                "label": "Abadiânia"
            },
            {
                "value": "Acreúna",
                "label": "Acreúna"
            },
            {
                "value": "Adelândia",
                "label": "Adelândia"
            },
            {
                "value": "Água Fria de Goiás",
                "label": "Água Fria de Goiás"
            },
            {
                "value": "Água Limpa",
                "label": "Água Limpa"
            },
            {
                "value": "Águas Lindas de Goiás",
                "label": "Águas Lindas de Goiás"
            },
            {
                "value": "Alexânia",
                "label": "Alexânia"
            },
            {
                "value": "Aloândia",
                "label": "Aloândia"
            },
            {
                "value": "Alto Horizonte",
                "label": "Alto Horizonte"
            },
            {
                "value": "Alto Paraíso de Goiás",
                "label": "Alto Paraíso de Goiás"
            },
            {
                "value": "Alvorada do Norte",
                "label": "Alvorada do Norte"
            },
            {
                "value": "Amaralina",
                "label": "Amaralina"
            },
            {
                "value": "Americano do Brasil",
                "label": "Americano do Brasil"
            },
            {
                "value": "Amorinópolis",
                "label": "Amorinópolis"
            },
            {
                "value": "Anápolis",
                "label": "Anápolis"
            },
            {
                "value": "Anhanguera",
                "label": "Anhanguera"
            },
            {
                "value": "Anicuns",
                "label": "Anicuns"
            },
            {
                "value": "Aparecida de Goiânia",
                "label": "Aparecida de Goiânia"
            },
            {
                "value": "Aparecida do Rio Doce",
                "label": "Aparecida do Rio Doce"
            },
            {
                "value": "Aporé",
                "label": "Aporé"
            },
            {
                "value": "Araçu",
                "label": "Araçu"
            },
            {
                "value": "Aragarças",
                "label": "Aragarças"
            },
            {
                "value": "Aragoiânia",
                "label": "Aragoiânia"
            },
            {
                "value": "Araguapaz",
                "label": "Araguapaz"
            },
            {
                "value": "Arenópolis",
                "label": "Arenópolis"
            },
            {
                "value": "Aruanã",
                "label": "Aruanã"
            },
            {
                "value": "Aurilândia",
                "label": "Aurilândia"
            },
            {
                "value": "Avelinópolis",
                "label": "Avelinópolis"
            },
            {
                "value": "Baliza",
                "label": "Baliza"
            },
            {
                "value": "Barro Alto",
                "label": "Barro Alto"
            },
            {
                "value": "Bela Vista de Goiás",
                "label": "Bela Vista de Goiás"
            },
            {
                "value": "Bom Jardim de Goiás",
                "label": "Bom Jardim de Goiás"
            },
            {
                "value": "Bom Jesus de Goiás",
                "label": "Bom Jesus de Goiás"
            },
            {
                "value": "Bonfinópolis",
                "label": "Bonfinópolis"
            },
            {
                "value": "Bonópolis",
                "label": "Bonópolis"
            },
            {
                "value": "Brazabrantes",
                "label": "Brazabrantes"
            },
            {
                "value": "Britânia",
                "label": "Britânia"
            },
            {
                "value": "Buriti Alegre",
                "label": "Buriti Alegre"
            },
            {
                "value": "Buriti de Goiás",
                "label": "Buriti de Goiás"
            },
            {
                "value": "Buritinópolis",
                "label": "Buritinópolis"
            },
            {
                "value": "Cabeceiras",
                "label": "Cabeceiras"
            },
            {
                "value": "Cachoeira Alta",
                "label": "Cachoeira Alta"
            },
            {
                "value": "Cachoeira de Goiás",
                "label": "Cachoeira de Goiás"
            },
            {
                "value": "Cachoeira Dourada",
                "label": "Cachoeira Dourada"
            },
            {
                "value": "Caçu",
                "label": "Caçu"
            },
            {
                "value": "Caiapônia",
                "label": "Caiapônia"
            },
            {
                "value": "Caldas Novas",
                "label": "Caldas Novas"
            },
            {
                "value": "Caldazinha",
                "label": "Caldazinha"
            },
            {
                "value": "Campestre de Goiás",
                "label": "Campestre de Goiás"
            },
            {
                "value": "Campinaçu",
                "label": "Campinaçu"
            },
            {
                "value": "Campinorte",
                "label": "Campinorte"
            },
            {
                "value": "Campo Alegre de Goiás",
                "label": "Campo Alegre de Goiás"
            },
            {
                "value": "Campo Limpo de Goiás",
                "label": "Campo Limpo de Goiás"
            },
            {
                "value": "Campos Belos",
                "label": "Campos Belos"
            },
            {
                "value": "Campos Verdes",
                "label": "Campos Verdes"
            },
            {
                "value": "Carmo do Rio Verde",
                "label": "Carmo do Rio Verde"
            },
            {
                "value": "Castelândia",
                "label": "Castelândia"
            },
            {
                "value": "Catalão",
                "label": "Catalão"
            },
            {
                "value": "Caturaí",
                "label": "Caturaí"
            },
            {
                "value": "Cavalcante",
                "label": "Cavalcante"
            },
            {
                "value": "Ceres",
                "label": "Ceres"
            },
            {
                "value": "Cezarina",
                "label": "Cezarina"
            },
            {
                "value": "Chapadão do Céu",
                "label": "Chapadão do Céu"
            },
            {
                "value": "Cidade Ocidental",
                "label": "Cidade Ocidental"
            },
            {
                "value": "Cocalzinho de Goiás",
                "label": "Cocalzinho de Goiás"
            },
            {
                "value": "Colinas do Sul",
                "label": "Colinas do Sul"
            },
            {
                "value": "Córrego do Ouro",
                "label": "Córrego do Ouro"
            },
            {
                "value": "Corumbá de Goiás",
                "label": "Corumbá de Goiás"
            },
            {
                "value": "Corumbaíba",
                "label": "Corumbaíba"
            },
            {
                "value": "Cristalina",
                "label": "Cristalina"
            },
            {
                "value": "Cristianópolis",
                "label": "Cristianópolis"
            },
            {
                "value": "Crixás",
                "label": "Crixás"
            },
            {
                "value": "Cromínia",
                "label": "Cromínia"
            },
            {
                "value": "Cumari",
                "label": "Cumari"
            },
            {
                "value": "Damianópolis",
                "label": "Damianópolis"
            },
            {
                "value": "Damolândia",
                "label": "Damolândia"
            },
            {
                "value": "Davinópolis",
                "label": "Davinópolis"
            },
            {
                "value": "Diorama",
                "label": "Diorama"
            },
            {
                "value": "Doverlândia",
                "label": "Doverlândia"
            },
            {
                "value": "Edealina",
                "label": "Edealina"
            },
            {
                "value": "Edéia",
                "label": "Edéia"
            },
            {
                "value": "Estrela do Norte",
                "label": "Estrela do Norte"
            },
            {
                "value": "Faina",
                "label": "Faina"
            },
            {
                "value": "Fazenda Nova",
                "label": "Fazenda Nova"
            },
            {
                "value": "Firminópolis",
                "label": "Firminópolis"
            },
            {
                "value": "Flores de Goiás",
                "label": "Flores de Goiás"
            },
            {
                "value": "Formosa",
                "label": "Formosa"
            },
            {
                "value": "Formoso",
                "label": "Formoso"
            },
            {
                "value": "Gameleira de Goiás",
                "label": "Gameleira de Goiás"
            },
            {
                "value": "Divinópolis de Goiás",
                "label": "Divinópolis de Goiás"
            },
            {
                "value": "Goianápolis",
                "label": "Goianápolis"
            },
            {
                "value": "Goiandira",
                "label": "Goiandira"
            },
            {
                "value": "Goianésia",
                "label": "Goianésia"
            },
            {
                "value": "Goiânia",
                "label": "Goiânia"
            },
            {
                "value": "Goianira",
                "label": "Goianira"
            },
            {
                "value": "Goiás",
                "label": "Goiás"
            },
            {
                "value": "Goiatuba",
                "label": "Goiatuba"
            },
            {
                "value": "Gouvelândia",
                "label": "Gouvelândia"
            },
            {
                "value": "Guapó",
                "label": "Guapó"
            },
            {
                "value": "Guaraíta",
                "label": "Guaraíta"
            },
            {
                "value": "Guarani de Goiás",
                "label": "Guarani de Goiás"
            },
            {
                "value": "Guarinos",
                "label": "Guarinos"
            },
            {
                "value": "Heitoraí",
                "label": "Heitoraí"
            },
            {
                "value": "Hidrolândia",
                "label": "Hidrolândia"
            },
            {
                "value": "Hidrolina",
                "label": "Hidrolina"
            },
            {
                "value": "Iaciara",
                "label": "Iaciara"
            },
            {
                "value": "Inaciolândia",
                "label": "Inaciolândia"
            },
            {
                "value": "Indiara",
                "label": "Indiara"
            },
            {
                "value": "Inhumas",
                "label": "Inhumas"
            },
            {
                "value": "Ipameri",
                "label": "Ipameri"
            },
            {
                "value": "Ipiranga de Goiás",
                "label": "Ipiranga de Goiás"
            },
            {
                "value": "Iporá",
                "label": "Iporá"
            },
            {
                "value": "Israelândia",
                "label": "Israelândia"
            },
            {
                "value": "Itaberaí",
                "label": "Itaberaí"
            },
            {
                "value": "Itaguari",
                "label": "Itaguari"
            },
            {
                "value": "Itaguaru",
                "label": "Itaguaru"
            },
            {
                "value": "Itajá",
                "label": "Itajá"
            },
            {
                "value": "Itapaci",
                "label": "Itapaci"
            },
            {
                "value": "Itapirapuã",
                "label": "Itapirapuã"
            },
            {
                "value": "Itapuranga",
                "label": "Itapuranga"
            },
            {
                "value": "Itarumã",
                "label": "Itarumã"
            },
            {
                "value": "Itauçu",
                "label": "Itauçu"
            },
            {
                "value": "Itumbiara",
                "label": "Itumbiara"
            },
            {
                "value": "Ivolândia",
                "label": "Ivolândia"
            },
            {
                "value": "Jandaia",
                "label": "Jandaia"
            },
            {
                "value": "Jaraguá",
                "label": "Jaraguá"
            },
            {
                "value": "Jataí",
                "label": "Jataí"
            },
            {
                "value": "Jaupaci",
                "label": "Jaupaci"
            },
            {
                "value": "Jesúpolis",
                "label": "Jesúpolis"
            },
            {
                "value": "Joviânia",
                "label": "Joviânia"
            },
            {
                "value": "Jussara",
                "label": "Jussara"
            },
            {
                "value": "Lagoa Santa",
                "label": "Lagoa Santa"
            },
            {
                "value": "Leopoldo de Bulhões",
                "label": "Leopoldo de Bulhões"
            },
            {
                "value": "Luziânia",
                "label": "Luziânia"
            },
            {
                "value": "Mairipotaba",
                "label": "Mairipotaba"
            },
            {
                "value": "Mambaí",
                "label": "Mambaí"
            },
            {
                "value": "Mara Rosa",
                "label": "Mara Rosa"
            },
            {
                "value": "Marzagão",
                "label": "Marzagão"
            },
            {
                "value": "Matrinchã",
                "label": "Matrinchã"
            },
            {
                "value": "Maurilândia",
                "label": "Maurilândia"
            },
            {
                "value": "Mimoso de Goiás",
                "label": "Mimoso de Goiás"
            },
            {
                "value": "Minaçu",
                "label": "Minaçu"
            },
            {
                "value": "Mineiros",
                "label": "Mineiros"
            },
            {
                "value": "Moiporá",
                "label": "Moiporá"
            },
            {
                "value": "Monte Alegre de Goiás",
                "label": "Monte Alegre de Goiás"
            },
            {
                "value": "Montes Claros de Goiás",
                "label": "Montes Claros de Goiás"
            },
            {
                "value": "Montividiu",
                "label": "Montividiu"
            },
            {
                "value": "Montividiu do Norte",
                "label": "Montividiu do Norte"
            },
            {
                "value": "Morrinhos",
                "label": "Morrinhos"
            },
            {
                "value": "Morro Agudo de Goiás",
                "label": "Morro Agudo de Goiás"
            },
            {
                "value": "Mossâmedes",
                "label": "Mossâmedes"
            },
            {
                "value": "Mozarlândia",
                "label": "Mozarlândia"
            },
            {
                "value": "Mundo Novo",
                "label": "Mundo Novo"
            },
            {
                "value": "Mutunópolis",
                "label": "Mutunópolis"
            },
            {
                "value": "Nazário",
                "label": "Nazário"
            },
            {
                "value": "Nerópolis",
                "label": "Nerópolis"
            },
            {
                "value": "Niquelândia",
                "label": "Niquelândia"
            },
            {
                "value": "Nova América",
                "label": "Nova América"
            },
            {
                "value": "Nova Aurora",
                "label": "Nova Aurora"
            },
            {
                "value": "Nova Crixás",
                "label": "Nova Crixás"
            },
            {
                "value": "Nova Glória",
                "label": "Nova Glória"
            },
            {
                "value": "Nova Iguaçu de Goiás",
                "label": "Nova Iguaçu de Goiás"
            },
            {
                "value": "Nova Roma",
                "label": "Nova Roma"
            },
            {
                "value": "Nova Veneza",
                "label": "Nova Veneza"
            },
            {
                "value": "Novo Brasil",
                "label": "Novo Brasil"
            },
            {
                "value": "Novo Gama",
                "label": "Novo Gama"
            },
            {
                "value": "Novo Planalto",
                "label": "Novo Planalto"
            },
            {
                "value": "Orizona",
                "label": "Orizona"
            },
            {
                "value": "Ouro Verde de Goiás",
                "label": "Ouro Verde de Goiás"
            },
            {
                "value": "Ouvidor",
                "label": "Ouvidor"
            },
            {
                "value": "Padre Bernardo",
                "label": "Padre Bernardo"
            },
            {
                "value": "Palestina de Goiás",
                "label": "Palestina de Goiás"
            },
            {
                "value": "Palmeiras de Goiás",
                "label": "Palmeiras de Goiás"
            },
            {
                "value": "Palmelo",
                "label": "Palmelo"
            },
            {
                "value": "Palminópolis",
                "label": "Palminópolis"
            },
            {
                "value": "Panamá",
                "label": "Panamá"
            },
            {
                "value": "Paranaiguara",
                "label": "Paranaiguara"
            },
            {
                "value": "Paraúna",
                "label": "Paraúna"
            },
            {
                "value": "Perolândia",
                "label": "Perolândia"
            },
            {
                "value": "Petrolina de Goiás",
                "label": "Petrolina de Goiás"
            },
            {
                "value": "Pilar de Goiás",
                "label": "Pilar de Goiás"
            },
            {
                "value": "Piracanjuba",
                "label": "Piracanjuba"
            },
            {
                "value": "Piranhas",
                "label": "Piranhas"
            },
            {
                "value": "Pirenópolis",
                "label": "Pirenópolis"
            },
            {
                "value": "Pires do Rio",
                "label": "Pires do Rio"
            },
            {
                "value": "Planaltina",
                "label": "Planaltina"
            },
            {
                "value": "Pontalina",
                "label": "Pontalina"
            },
            {
                "value": "Porangatu",
                "label": "Porangatu"
            },
            {
                "value": "Porteirão",
                "label": "Porteirão"
            },
            {
                "value": "Portelândia",
                "label": "Portelândia"
            },
            {
                "value": "Posse",
                "label": "Posse"
            },
            {
                "value": "Professor Jamil",
                "label": "Professor Jamil"
            },
            {
                "value": "Quirinópolis",
                "label": "Quirinópolis"
            },
            {
                "value": "Rialma",
                "label": "Rialma"
            },
            {
                "value": "Rianápolis",
                "label": "Rianápolis"
            },
            {
                "value": "Rio Quente",
                "label": "Rio Quente"
            },
            {
                "value": "Rio Verde",
                "label": "Rio Verde"
            },
            {
                "value": "Rubiataba",
                "label": "Rubiataba"
            },
            {
                "value": "Sanclerlândia",
                "label": "Sanclerlândia"
            },
            {
                "value": "Santa Bárbara de Goiás",
                "label": "Santa Bárbara de Goiás"
            },
            {
                "value": "Santa Cruz de Goiás",
                "label": "Santa Cruz de Goiás"
            },
            {
                "value": "Santa Fé de Goiás",
                "label": "Santa Fé de Goiás"
            },
            {
                "value": "Santa Helena de Goiás",
                "label": "Santa Helena de Goiás"
            },
            {
                "value": "Santa Isabel",
                "label": "Santa Isabel"
            },
            {
                "value": "Santa Rita do Araguaia",
                "label": "Santa Rita do Araguaia"
            },
            {
                "value": "Santa Rita do Novo Destino",
                "label": "Santa Rita do Novo Destino"
            },
            {
                "value": "Santa Rosa de Goiás",
                "label": "Santa Rosa de Goiás"
            },
            {
                "value": "Santa Tereza de Goiás",
                "label": "Santa Tereza de Goiás"
            },
            {
                "value": "Santa Terezinha de Goiás",
                "label": "Santa Terezinha de Goiás"
            },
            {
                "value": "Santo Antônio da Barra",
                "label": "Santo Antônio da Barra"
            },
            {
                "value": "Santo Antônio de Goiás",
                "label": "Santo Antônio de Goiás"
            },
            {
                "value": "Santo Antônio do Descoberto",
                "label": "Santo Antônio do Descoberto"
            },
            {
                "value": "São Domingos",
                "label": "São Domingos"
            },
            {
                "value": "São Francisco de Goiás",
                "label": "São Francisco de Goiás"
            },
            {
                "value": "São João d'Aliança",
                "label": "São João d'Aliança"
            },
            {
                "value": "São João da Paraúna",
                "label": "São João da Paraúna"
            },
            {
                "value": "São Luís de Montes Belos",
                "label": "São Luís de Montes Belos"
            },
            {
                "value": "São Luiz do Norte",
                "label": "São Luiz do Norte"
            },
            {
                "value": "São Miguel do Araguaia",
                "label": "São Miguel do Araguaia"
            },
            {
                "value": "São Miguel do Passa Quatro",
                "label": "São Miguel do Passa Quatro"
            },
            {
                "value": "São Patrício",
                "label": "São Patrício"
            },
            {
                "value": "São Simão",
                "label": "São Simão"
            },
            {
                "value": "Senador Canedo",
                "label": "Senador Canedo"
            },
            {
                "value": "Serranópolis",
                "label": "Serranópolis"
            },
            {
                "value": "Silvânia",
                "label": "Silvânia"
            },
            {
                "value": "Simolândia",
                "label": "Simolândia"
            },
            {
                "value": "Sítio d'Abadia",
                "label": "Sítio d'Abadia"
            },
            {
                "value": "Taquaral de Goiás",
                "label": "Taquaral de Goiás"
            },
            {
                "value": "Teresina de Goiás",
                "label": "Teresina de Goiás"
            },
            {
                "value": "Terezópolis de Goiás",
                "label": "Terezópolis de Goiás"
            },
            {
                "value": "Três Ranchos",
                "label": "Três Ranchos"
            },
            {
                "value": "Trindade",
                "label": "Trindade"
            },
            {
                "value": "Trombas",
                "label": "Trombas"
            },
            {
                "value": "Turvânia",
                "label": "Turvânia"
            },
            {
                "value": "Turvelândia",
                "label": "Turvelândia"
            },
            {
                "value": "Uirapuru",
                "label": "Uirapuru"
            },
            {
                "value": "Uruaçu",
                "label": "Uruaçu"
            },
            {
                "value": "Uruana",
                "label": "Uruana"
            },
            {
                "value": "Urutaí",
                "label": "Urutaí"
            },
            {
                "value": "Valparaíso de Goiás",
                "label": "Valparaíso de Goiás"
            },
            {
                "value": "Varjão",
                "label": "Varjão"
            },
            {
                "value": "Vianópolis",
                "label": "Vianópolis"
            },
            {
                "value": "Vicentinópolis",
                "label": "Vicentinópolis"
            },
            {
                "value": "Vila Boa",
                "label": "Vila Boa"
            },
            {
                "value": "Vila Propício",
                "label": "Vila Propício"
            }
        ]
    },
    {
        "value": "MA",
        "label": "Maranhão",
        "children": [
            {
                "value": "Açailândia",
                "label": "Açailândia"
            },
            {
                "value": "Afonso Cunha",
                "label": "Afonso Cunha"
            },
            {
                "value": "Água Doce do Maranhão",
                "label": "Água Doce do Maranhão"
            },
            {
                "value": "Alcântara",
                "label": "Alcântara"
            },
            {
                "value": "Aldeias Altas",
                "label": "Aldeias Altas"
            },
            {
                "value": "Altamira do Maranhão",
                "label": "Altamira do Maranhão"
            },
            {
                "value": "Alto Alegre do Maranhão",
                "label": "Alto Alegre do Maranhão"
            },
            {
                "value": "Alto Alegre do Pindaré",
                "label": "Alto Alegre do Pindaré"
            },
            {
                "value": "Alto Parnaíba",
                "label": "Alto Parnaíba"
            },
            {
                "value": "Amapá do Maranhão",
                "label": "Amapá do Maranhão"
            },
            {
                "value": "Amarante do Maranhão",
                "label": "Amarante do Maranhão"
            },
            {
                "value": "Anajatuba",
                "label": "Anajatuba"
            },
            {
                "value": "Anapurus",
                "label": "Anapurus"
            },
            {
                "value": "Apicum-Açu",
                "label": "Apicum-Açu"
            },
            {
                "value": "Araguanã",
                "label": "Araguanã"
            },
            {
                "value": "Araioses",
                "label": "Araioses"
            },
            {
                "value": "Arame",
                "label": "Arame"
            },
            {
                "value": "Arari",
                "label": "Arari"
            },
            {
                "value": "Axixá",
                "label": "Axixá"
            },
            {
                "value": "Bacabal",
                "label": "Bacabal"
            },
            {
                "value": "Bacabeira",
                "label": "Bacabeira"
            },
            {
                "value": "Bacuri",
                "label": "Bacuri"
            },
            {
                "value": "Bacurituba",
                "label": "Bacurituba"
            },
            {
                "value": "Balsas",
                "label": "Balsas"
            },
            {
                "value": "Barão de Grajaú",
                "label": "Barão de Grajaú"
            },
            {
                "value": "Barra do Corda",
                "label": "Barra do Corda"
            },
            {
                "value": "Barreirinhas",
                "label": "Barreirinhas"
            },
            {
                "value": "Belágua",
                "label": "Belágua"
            },
            {
                "value": "Bela Vista do Maranhão",
                "label": "Bela Vista do Maranhão"
            },
            {
                "value": "Benedito Leite",
                "label": "Benedito Leite"
            },
            {
                "value": "Bequimão",
                "label": "Bequimão"
            },
            {
                "value": "Bernardo do Mearim",
                "label": "Bernardo do Mearim"
            },
            {
                "value": "Boa Vista do Gurupi",
                "label": "Boa Vista do Gurupi"
            },
            {
                "value": "Bom Jardim",
                "label": "Bom Jardim"
            },
            {
                "value": "Bom Jesus das Selvas",
                "label": "Bom Jesus das Selvas"
            },
            {
                "value": "Bom Lugar",
                "label": "Bom Lugar"
            },
            {
                "value": "Brejo",
                "label": "Brejo"
            },
            {
                "value": "Brejo de Areia",
                "label": "Brejo de Areia"
            },
            {
                "value": "Buriti",
                "label": "Buriti"
            },
            {
                "value": "Buriti Bravo",
                "label": "Buriti Bravo"
            },
            {
                "value": "Buriticupu",
                "label": "Buriticupu"
            },
            {
                "value": "Buritirana",
                "label": "Buritirana"
            },
            {
                "value": "Cachoeira Grande",
                "label": "Cachoeira Grande"
            },
            {
                "value": "Cajapió",
                "label": "Cajapió"
            },
            {
                "value": "Cajari",
                "label": "Cajari"
            },
            {
                "value": "Campestre do Maranhão",
                "label": "Campestre do Maranhão"
            },
            {
                "value": "Cândido Mendes",
                "label": "Cândido Mendes"
            },
            {
                "value": "Cantanhede",
                "label": "Cantanhede"
            },
            {
                "value": "Capinzal do Norte",
                "label": "Capinzal do Norte"
            },
            {
                "value": "Carolina",
                "label": "Carolina"
            },
            {
                "value": "Carutapera",
                "label": "Carutapera"
            },
            {
                "value": "Caxias",
                "label": "Caxias"
            },
            {
                "value": "Cedral",
                "label": "Cedral"
            },
            {
                "value": "Central do Maranhão",
                "label": "Central do Maranhão"
            },
            {
                "value": "Centro do Guilherme",
                "label": "Centro do Guilherme"
            },
            {
                "value": "Centro Novo do Maranhão",
                "label": "Centro Novo do Maranhão"
            },
            {
                "value": "Chapadinha",
                "label": "Chapadinha"
            },
            {
                "value": "Cidelândia",
                "label": "Cidelândia"
            },
            {
                "value": "Codó",
                "label": "Codó"
            },
            {
                "value": "Coelho Neto",
                "label": "Coelho Neto"
            },
            {
                "value": "Colinas",
                "label": "Colinas"
            },
            {
                "value": "Conceição do Lago-Açu",
                "label": "Conceição do Lago-Açu"
            },
            {
                "value": "Coroatá",
                "label": "Coroatá"
            },
            {
                "value": "Cururupu",
                "label": "Cururupu"
            },
            {
                "value": "Davinópolis",
                "label": "Davinópolis"
            },
            {
                "value": "Dom Pedro",
                "label": "Dom Pedro"
            },
            {
                "value": "Duque Bacelar",
                "label": "Duque Bacelar"
            },
            {
                "value": "Esperantinópolis",
                "label": "Esperantinópolis"
            },
            {
                "value": "Estreito",
                "label": "Estreito"
            },
            {
                "value": "Feira Nova do Maranhão",
                "label": "Feira Nova do Maranhão"
            },
            {
                "value": "Fernando Falcão",
                "label": "Fernando Falcão"
            },
            {
                "value": "Formosa da Serra Negra",
                "label": "Formosa da Serra Negra"
            },
            {
                "value": "Fortaleza dos Nogueiras",
                "label": "Fortaleza dos Nogueiras"
            },
            {
                "value": "Fortuna",
                "label": "Fortuna"
            },
            {
                "value": "Godofredo Viana",
                "label": "Godofredo Viana"
            },
            {
                "value": "Gonçalves Dias",
                "label": "Gonçalves Dias"
            },
            {
                "value": "Governador Archer",
                "label": "Governador Archer"
            },
            {
                "value": "Governador Edison Lobão",
                "label": "Governador Edison Lobão"
            },
            {
                "value": "Governador Eugênio Barros",
                "label": "Governador Eugênio Barros"
            },
            {
                "value": "Governador Luiz Rocha",
                "label": "Governador Luiz Rocha"
            },
            {
                "value": "Governador Newton Bello",
                "label": "Governador Newton Bello"
            },
            {
                "value": "Governador Nunes Freire",
                "label": "Governador Nunes Freire"
            },
            {
                "value": "Graça Aranha",
                "label": "Graça Aranha"
            },
            {
                "value": "Grajaú",
                "label": "Grajaú"
            },
            {
                "value": "Guimarães",
                "label": "Guimarães"
            },
            {
                "value": "Humberto de Campos",
                "label": "Humberto de Campos"
            },
            {
                "value": "Icatu",
                "label": "Icatu"
            },
            {
                "value": "Igarapé do Meio",
                "label": "Igarapé do Meio"
            },
            {
                "value": "Igarapé Grande",
                "label": "Igarapé Grande"
            },
            {
                "value": "Imperatriz",
                "label": "Imperatriz"
            },
            {
                "value": "Itaipava do Grajaú",
                "label": "Itaipava do Grajaú"
            },
            {
                "value": "Itapecuru Mirim",
                "label": "Itapecuru Mirim"
            },
            {
                "value": "Itinga do Maranhão",
                "label": "Itinga do Maranhão"
            },
            {
                "value": "Jatobá",
                "label": "Jatobá"
            },
            {
                "value": "Jenipapo dos Vieiras",
                "label": "Jenipapo dos Vieiras"
            },
            {
                "value": "João Lisboa",
                "label": "João Lisboa"
            },
            {
                "value": "Joselândia",
                "label": "Joselândia"
            },
            {
                "value": "Junco do Maranhão",
                "label": "Junco do Maranhão"
            },
            {
                "value": "Lago da Pedra",
                "label": "Lago da Pedra"
            },
            {
                "value": "Lago do Junco",
                "label": "Lago do Junco"
            },
            {
                "value": "Lago Verde",
                "label": "Lago Verde"
            },
            {
                "value": "Lagoa do Mato",
                "label": "Lagoa do Mato"
            },
            {
                "value": "Lago dos Rodrigues",
                "label": "Lago dos Rodrigues"
            },
            {
                "value": "Lagoa Grande do Maranhão",
                "label": "Lagoa Grande do Maranhão"
            },
            {
                "value": "Lajeado Novo",
                "label": "Lajeado Novo"
            },
            {
                "value": "Lima Campos",
                "label": "Lima Campos"
            },
            {
                "value": "Loreto",
                "label": "Loreto"
            },
            {
                "value": "Luís Domingues",
                "label": "Luís Domingues"
            },
            {
                "value": "Magalhães de Almeida",
                "label": "Magalhães de Almeida"
            },
            {
                "value": "Maracaçumé",
                "label": "Maracaçumé"
            },
            {
                "value": "Marajá do Sena",
                "label": "Marajá do Sena"
            },
            {
                "value": "Maranhãozinho",
                "label": "Maranhãozinho"
            },
            {
                "value": "Mata Roma",
                "label": "Mata Roma"
            },
            {
                "value": "Matinha",
                "label": "Matinha"
            },
            {
                "value": "Matões",
                "label": "Matões"
            },
            {
                "value": "Matões do Norte",
                "label": "Matões do Norte"
            },
            {
                "value": "Milagres do Maranhão",
                "label": "Milagres do Maranhão"
            },
            {
                "value": "Mirador",
                "label": "Mirador"
            },
            {
                "value": "Miranda do Norte",
                "label": "Miranda do Norte"
            },
            {
                "value": "Mirinzal",
                "label": "Mirinzal"
            },
            {
                "value": "Monção",
                "label": "Monção"
            },
            {
                "value": "Montes Altos",
                "label": "Montes Altos"
            },
            {
                "value": "Morros",
                "label": "Morros"
            },
            {
                "value": "Nina Rodrigues",
                "label": "Nina Rodrigues"
            },
            {
                "value": "Nova Colinas",
                "label": "Nova Colinas"
            },
            {
                "value": "Nova Iorque",
                "label": "Nova Iorque"
            },
            {
                "value": "Nova Olinda do Maranhão",
                "label": "Nova Olinda do Maranhão"
            },
            {
                "value": "Olho d'Água das Cunhãs",
                "label": "Olho d'Água das Cunhãs"
            },
            {
                "value": "Olinda Nova do Maranhão",
                "label": "Olinda Nova do Maranhão"
            },
            {
                "value": "Paço do Lumiar",
                "label": "Paço do Lumiar"
            },
            {
                "value": "Palmeirândia",
                "label": "Palmeirândia"
            },
            {
                "value": "Paraibano",
                "label": "Paraibano"
            },
            {
                "value": "Parnarama",
                "label": "Parnarama"
            },
            {
                "value": "Passagem Franca",
                "label": "Passagem Franca"
            },
            {
                "value": "Pastos Bons",
                "label": "Pastos Bons"
            },
            {
                "value": "Paulino Neves",
                "label": "Paulino Neves"
            },
            {
                "value": "Paulo Ramos",
                "label": "Paulo Ramos"
            },
            {
                "value": "Pedreiras",
                "label": "Pedreiras"
            },
            {
                "value": "Pedro do Rosário",
                "label": "Pedro do Rosário"
            },
            {
                "value": "Penalva",
                "label": "Penalva"
            },
            {
                "value": "Peri Mirim",
                "label": "Peri Mirim"
            },
            {
                "value": "Peritoró",
                "label": "Peritoró"
            },
            {
                "value": "Pindaré-Mirim",
                "label": "Pindaré-Mirim"
            },
            {
                "value": "Pinheiro",
                "label": "Pinheiro"
            },
            {
                "value": "Pio XII",
                "label": "Pio XII"
            },
            {
                "value": "Pirapemas",
                "label": "Pirapemas"
            },
            {
                "value": "Poção de Pedras",
                "label": "Poção de Pedras"
            },
            {
                "value": "Porto Franco",
                "label": "Porto Franco"
            },
            {
                "value": "Porto Rico do Maranhão",
                "label": "Porto Rico do Maranhão"
            },
            {
                "value": "Presidente Dutra",
                "label": "Presidente Dutra"
            },
            {
                "value": "Presidente Juscelino",
                "label": "Presidente Juscelino"
            },
            {
                "value": "Presidente Médici",
                "label": "Presidente Médici"
            },
            {
                "value": "Presidente Sarney",
                "label": "Presidente Sarney"
            },
            {
                "value": "Presidente Vargas",
                "label": "Presidente Vargas"
            },
            {
                "value": "Primeira Cruz",
                "label": "Primeira Cruz"
            },
            {
                "value": "Raposa",
                "label": "Raposa"
            },
            {
                "value": "Riachão",
                "label": "Riachão"
            },
            {
                "value": "Ribamar Fiquene",
                "label": "Ribamar Fiquene"
            },
            {
                "value": "Rosário",
                "label": "Rosário"
            },
            {
                "value": "Sambaíba",
                "label": "Sambaíba"
            },
            {
                "value": "Santa Filomena do Maranhão",
                "label": "Santa Filomena do Maranhão"
            },
            {
                "value": "Santa Helena",
                "label": "Santa Helena"
            },
            {
                "value": "Santa Inês",
                "label": "Santa Inês"
            },
            {
                "value": "Santa Luzia",
                "label": "Santa Luzia"
            },
            {
                "value": "Santa Luzia do Paruá",
                "label": "Santa Luzia do Paruá"
            },
            {
                "value": "Santa Quitéria do Maranhão",
                "label": "Santa Quitéria do Maranhão"
            },
            {
                "value": "Santa Rita",
                "label": "Santa Rita"
            },
            {
                "value": "Santana do Maranhão",
                "label": "Santana do Maranhão"
            },
            {
                "value": "Santo Amaro do Maranhão",
                "label": "Santo Amaro do Maranhão"
            },
            {
                "value": "Santo Antônio dos Lopes",
                "label": "Santo Antônio dos Lopes"
            },
            {
                "value": "São Benedito do Rio Preto",
                "label": "São Benedito do Rio Preto"
            },
            {
                "value": "São Bento",
                "label": "São Bento"
            },
            {
                "value": "São Bernardo",
                "label": "São Bernardo"
            },
            {
                "value": "São Domingos do Azeitão",
                "label": "São Domingos do Azeitão"
            },
            {
                "value": "São Domingos do Maranhão",
                "label": "São Domingos do Maranhão"
            },
            {
                "value": "São Félix de Balsas",
                "label": "São Félix de Balsas"
            },
            {
                "value": "São Francisco do Brejão",
                "label": "São Francisco do Brejão"
            },
            {
                "value": "São Francisco do Maranhão",
                "label": "São Francisco do Maranhão"
            },
            {
                "value": "São João Batista",
                "label": "São João Batista"
            },
            {
                "value": "São João do Carú",
                "label": "São João do Carú"
            },
            {
                "value": "São João do Paraíso",
                "label": "São João do Paraíso"
            },
            {
                "value": "São João do Soter",
                "label": "São João do Soter"
            },
            {
                "value": "São João dos Patos",
                "label": "São João dos Patos"
            },
            {
                "value": "São José de Ribamar",
                "label": "São José de Ribamar"
            },
            {
                "value": "São José dos Basílios",
                "label": "São José dos Basílios"
            },
            {
                "value": "São Luís",
                "label": "São Luís"
            },
            {
                "value": "São Luís Gonzaga do Maranhão",
                "label": "São Luís Gonzaga do Maranhão"
            },
            {
                "value": "São Mateus do Maranhão",
                "label": "São Mateus do Maranhão"
            },
            {
                "value": "São Pedro da Água Branca",
                "label": "São Pedro da Água Branca"
            },
            {
                "value": "São Pedro dos Crentes",
                "label": "São Pedro dos Crentes"
            },
            {
                "value": "São Raimundo das Mangabeiras",
                "label": "São Raimundo das Mangabeiras"
            },
            {
                "value": "São Raimundo do Doca Bezerra",
                "label": "São Raimundo do Doca Bezerra"
            },
            {
                "value": "São Roberto",
                "label": "São Roberto"
            },
            {
                "value": "São Vicente Ferrer",
                "label": "São Vicente Ferrer"
            },
            {
                "value": "Satubinha",
                "label": "Satubinha"
            },
            {
                "value": "Senador Alexandre Costa",
                "label": "Senador Alexandre Costa"
            },
            {
                "value": "Senador La Rocque",
                "label": "Senador La Rocque"
            },
            {
                "value": "Serrano do Maranhão",
                "label": "Serrano do Maranhão"
            },
            {
                "value": "Sítio Novo",
                "label": "Sítio Novo"
            },
            {
                "value": "Sucupira do Norte",
                "label": "Sucupira do Norte"
            },
            {
                "value": "Sucupira do Riachão",
                "label": "Sucupira do Riachão"
            },
            {
                "value": "Tasso Fragoso",
                "label": "Tasso Fragoso"
            },
            {
                "value": "Timbiras",
                "label": "Timbiras"
            },
            {
                "value": "Timon",
                "label": "Timon"
            },
            {
                "value": "Trizidela do Vale",
                "label": "Trizidela do Vale"
            },
            {
                "value": "Tufilândia",
                "label": "Tufilândia"
            },
            {
                "value": "Tuntum",
                "label": "Tuntum"
            },
            {
                "value": "Turiaçu",
                "label": "Turiaçu"
            },
            {
                "value": "Turilândia",
                "label": "Turilândia"
            },
            {
                "value": "Tutóia",
                "label": "Tutóia"
            },
            {
                "value": "Urbano Santos",
                "label": "Urbano Santos"
            },
            {
                "value": "Vargem Grande",
                "label": "Vargem Grande"
            },
            {
                "value": "Viana",
                "label": "Viana"
            },
            {
                "value": "Vila Nova dos Martírios",
                "label": "Vila Nova dos Martírios"
            },
            {
                "value": "Vitória do Mearim",
                "label": "Vitória do Mearim"
            },
            {
                "value": "Vitorino Freire",
                "label": "Vitorino Freire"
            },
            {
                "value": "Zé Doca",
                "label": "Zé Doca"
            }
        ]
    },
    {
        "value": "MG",
        "label": "Minas Gerais",
        "children": [
            {
                "value": "Abadia dos Dourados",
                "label": "Abadia dos Dourados"
            },
            {
                "value": "Abaeté",
                "label": "Abaeté"
            },
            {
                "value": "Abre Campo",
                "label": "Abre Campo"
            },
            {
                "value": "Acaiaca",
                "label": "Acaiaca"
            },
            {
                "value": "Açucena",
                "label": "Açucena"
            },
            {
                "value": "Água Boa",
                "label": "Água Boa"
            },
            {
                "value": "Água Comprida",
                "label": "Água Comprida"
            },
            {
                "value": "Aguanil",
                "label": "Aguanil"
            },
            {
                "value": "Águas Formosas",
                "label": "Águas Formosas"
            },
            {
                "value": "Águas Vermelhas",
                "label": "Águas Vermelhas"
            },
            {
                "value": "Aimorés",
                "label": "Aimorés"
            },
            {
                "value": "Aiuruoca",
                "label": "Aiuruoca"
            },
            {
                "value": "Alagoa",
                "label": "Alagoa"
            },
            {
                "value": "Albertina",
                "label": "Albertina"
            },
            {
                "value": "Além Paraíba",
                "label": "Além Paraíba"
            },
            {
                "value": "Alfenas",
                "label": "Alfenas"
            },
            {
                "value": "Alfredo Vasconcelos",
                "label": "Alfredo Vasconcelos"
            },
            {
                "value": "Almenara",
                "label": "Almenara"
            },
            {
                "value": "Alpercata",
                "label": "Alpercata"
            },
            {
                "value": "Alpinópolis",
                "label": "Alpinópolis"
            },
            {
                "value": "Alterosa",
                "label": "Alterosa"
            },
            {
                "value": "Alto Caparaó",
                "label": "Alto Caparaó"
            },
            {
                "value": "Alto Rio Doce",
                "label": "Alto Rio Doce"
            },
            {
                "value": "Alvarenga",
                "label": "Alvarenga"
            },
            {
                "value": "Alvinópolis",
                "label": "Alvinópolis"
            },
            {
                "value": "Alvorada de Minas",
                "label": "Alvorada de Minas"
            },
            {
                "value": "Amparo do Serra",
                "label": "Amparo do Serra"
            },
            {
                "value": "Andradas",
                "label": "Andradas"
            },
            {
                "value": "Cachoeira de Pajeú",
                "label": "Cachoeira de Pajeú"
            },
            {
                "value": "Andrelândia",
                "label": "Andrelândia"
            },
            {
                "value": "Angelândia",
                "label": "Angelândia"
            },
            {
                "value": "Antônio Carlos",
                "label": "Antônio Carlos"
            },
            {
                "value": "Antônio Dias",
                "label": "Antônio Dias"
            },
            {
                "value": "Antônio Prado de Minas",
                "label": "Antônio Prado de Minas"
            },
            {
                "value": "Araçaí",
                "label": "Araçaí"
            },
            {
                "value": "Aracitaba",
                "label": "Aracitaba"
            },
            {
                "value": "Araçuaí",
                "label": "Araçuaí"
            },
            {
                "value": "Araguari",
                "label": "Araguari"
            },
            {
                "value": "Arantina",
                "label": "Arantina"
            },
            {
                "value": "Araponga",
                "label": "Araponga"
            },
            {
                "value": "Araporã",
                "label": "Araporã"
            },
            {
                "value": "Arapuá",
                "label": "Arapuá"
            },
            {
                "value": "Araújos",
                "label": "Araújos"
            },
            {
                "value": "Araxá",
                "label": "Araxá"
            },
            {
                "value": "Arceburgo",
                "label": "Arceburgo"
            },
            {
                "value": "Arcos",
                "label": "Arcos"
            },
            {
                "value": "Areado",
                "label": "Areado"
            },
            {
                "value": "Argirita",
                "label": "Argirita"
            },
            {
                "value": "Aricanduva",
                "label": "Aricanduva"
            },
            {
                "value": "Arinos",
                "label": "Arinos"
            },
            {
                "value": "Astolfo Dutra",
                "label": "Astolfo Dutra"
            },
            {
                "value": "Ataléia",
                "label": "Ataléia"
            },
            {
                "value": "Augusto de Lima",
                "label": "Augusto de Lima"
            },
            {
                "value": "Baependi",
                "label": "Baependi"
            },
            {
                "value": "Baldim",
                "label": "Baldim"
            },
            {
                "value": "Bambuí",
                "label": "Bambuí"
            },
            {
                "value": "Bandeira",
                "label": "Bandeira"
            },
            {
                "value": "Bandeira do Sul",
                "label": "Bandeira do Sul"
            },
            {
                "value": "Barão de Cocais",
                "label": "Barão de Cocais"
            },
            {
                "value": "Barão de Monte Alto",
                "label": "Barão de Monte Alto"
            },
            {
                "value": "Barbacena",
                "label": "Barbacena"
            },
            {
                "value": "Barra Longa",
                "label": "Barra Longa"
            },
            {
                "value": "Barroso",
                "label": "Barroso"
            },
            {
                "value": "Bela Vista de Minas",
                "label": "Bela Vista de Minas"
            },
            {
                "value": "Belmiro Braga",
                "label": "Belmiro Braga"
            },
            {
                "value": "Belo Horizonte",
                "label": "Belo Horizonte"
            },
            {
                "value": "Belo Oriente",
                "label": "Belo Oriente"
            },
            {
                "value": "Belo Vale",
                "label": "Belo Vale"
            },
            {
                "value": "Berilo",
                "label": "Berilo"
            },
            {
                "value": "Bertópolis",
                "label": "Bertópolis"
            },
            {
                "value": "Berizal",
                "label": "Berizal"
            },
            {
                "value": "Betim",
                "label": "Betim"
            },
            {
                "value": "Bias Fortes",
                "label": "Bias Fortes"
            },
            {
                "value": "Bicas",
                "label": "Bicas"
            },
            {
                "value": "Biquinhas",
                "label": "Biquinhas"
            },
            {
                "value": "Boa Esperança",
                "label": "Boa Esperança"
            },
            {
                "value": "Bocaina de Minas",
                "label": "Bocaina de Minas"
            },
            {
                "value": "Bocaiúva",
                "label": "Bocaiúva"
            },
            {
                "value": "Bom Despacho",
                "label": "Bom Despacho"
            },
            {
                "value": "Bom Jardim de Minas",
                "label": "Bom Jardim de Minas"
            },
            {
                "value": "Bom Jesus da Penha",
                "label": "Bom Jesus da Penha"
            },
            {
                "value": "Bom Jesus do Amparo",
                "label": "Bom Jesus do Amparo"
            },
            {
                "value": "Bom Jesus do Galho",
                "label": "Bom Jesus do Galho"
            },
            {
                "value": "Bom Repouso",
                "label": "Bom Repouso"
            },
            {
                "value": "Bom Sucesso",
                "label": "Bom Sucesso"
            },
            {
                "value": "Bonfim",
                "label": "Bonfim"
            },
            {
                "value": "Bonfinópolis de Minas",
                "label": "Bonfinópolis de Minas"
            },
            {
                "value": "Bonito de Minas",
                "label": "Bonito de Minas"
            },
            {
                "value": "Borda da Mata",
                "label": "Borda da Mata"
            },
            {
                "value": "Botelhos",
                "label": "Botelhos"
            },
            {
                "value": "Botumirim",
                "label": "Botumirim"
            },
            {
                "value": "Brasilândia de Minas",
                "label": "Brasilândia de Minas"
            },
            {
                "value": "Brasília de Minas",
                "label": "Brasília de Minas"
            },
            {
                "value": "Brás Pires",
                "label": "Brás Pires"
            },
            {
                "value": "Braúnas",
                "label": "Braúnas"
            },
            {
                "value": "Brazópolis",
                "label": "Brazópolis"
            },
            {
                "value": "Brumadinho",
                "label": "Brumadinho"
            },
            {
                "value": "Bueno Brandão",
                "label": "Bueno Brandão"
            },
            {
                "value": "Buenópolis",
                "label": "Buenópolis"
            },
            {
                "value": "Bugre",
                "label": "Bugre"
            },
            {
                "value": "Buritis",
                "label": "Buritis"
            },
            {
                "value": "Buritizeiro",
                "label": "Buritizeiro"
            },
            {
                "value": "Cabeceira Grande",
                "label": "Cabeceira Grande"
            },
            {
                "value": "Cabo Verde",
                "label": "Cabo Verde"
            },
            {
                "value": "Cachoeira da Prata",
                "label": "Cachoeira da Prata"
            },
            {
                "value": "Cachoeira de Minas",
                "label": "Cachoeira de Minas"
            },
            {
                "value": "Cachoeira Dourada",
                "label": "Cachoeira Dourada"
            },
            {
                "value": "Caetanópolis",
                "label": "Caetanópolis"
            },
            {
                "value": "Caeté",
                "label": "Caeté"
            },
            {
                "value": "Caiana",
                "label": "Caiana"
            },
            {
                "value": "Cajuri",
                "label": "Cajuri"
            },
            {
                "value": "Caldas",
                "label": "Caldas"
            },
            {
                "value": "Camacho",
                "label": "Camacho"
            },
            {
                "value": "Camanducaia",
                "label": "Camanducaia"
            },
            {
                "value": "Cambuí",
                "label": "Cambuí"
            },
            {
                "value": "Cambuquira",
                "label": "Cambuquira"
            },
            {
                "value": "Campanário",
                "label": "Campanário"
            },
            {
                "value": "Campanha",
                "label": "Campanha"
            },
            {
                "value": "Campestre",
                "label": "Campestre"
            },
            {
                "value": "Campina Verde",
                "label": "Campina Verde"
            },
            {
                "value": "Campo Azul",
                "label": "Campo Azul"
            },
            {
                "value": "Campo Belo",
                "label": "Campo Belo"
            },
            {
                "value": "Campo do Meio",
                "label": "Campo do Meio"
            },
            {
                "value": "Campo Florido",
                "label": "Campo Florido"
            },
            {
                "value": "Campos Altos",
                "label": "Campos Altos"
            },
            {
                "value": "Campos Gerais",
                "label": "Campos Gerais"
            },
            {
                "value": "Canaã",
                "label": "Canaã"
            },
            {
                "value": "Canápolis",
                "label": "Canápolis"
            },
            {
                "value": "Cana Verde",
                "label": "Cana Verde"
            },
            {
                "value": "Candeias",
                "label": "Candeias"
            },
            {
                "value": "Cantagalo",
                "label": "Cantagalo"
            },
            {
                "value": "Caparaó",
                "label": "Caparaó"
            },
            {
                "value": "Capela Nova",
                "label": "Capela Nova"
            },
            {
                "value": "Capelinha",
                "label": "Capelinha"
            },
            {
                "value": "Capetinga",
                "label": "Capetinga"
            },
            {
                "value": "Capim Branco",
                "label": "Capim Branco"
            },
            {
                "value": "Capinópolis",
                "label": "Capinópolis"
            },
            {
                "value": "Capitão Andrade",
                "label": "Capitão Andrade"
            },
            {
                "value": "Capitão Enéas",
                "label": "Capitão Enéas"
            },
            {
                "value": "Capitólio",
                "label": "Capitólio"
            },
            {
                "value": "Caputira",
                "label": "Caputira"
            },
            {
                "value": "Caraí",
                "label": "Caraí"
            },
            {
                "value": "Caranaíba",
                "label": "Caranaíba"
            },
            {
                "value": "Carandaí",
                "label": "Carandaí"
            },
            {
                "value": "Carangola",
                "label": "Carangola"
            },
            {
                "value": "Caratinga",
                "label": "Caratinga"
            },
            {
                "value": "Carbonita",
                "label": "Carbonita"
            },
            {
                "value": "Careaçu",
                "label": "Careaçu"
            },
            {
                "value": "Carlos Chagas",
                "label": "Carlos Chagas"
            },
            {
                "value": "Carmésia",
                "label": "Carmésia"
            },
            {
                "value": "Carmo da Cachoeira",
                "label": "Carmo da Cachoeira"
            },
            {
                "value": "Carmo da Mata",
                "label": "Carmo da Mata"
            },
            {
                "value": "Carmo de Minas",
                "label": "Carmo de Minas"
            },
            {
                "value": "Carmo do Cajuru",
                "label": "Carmo do Cajuru"
            },
            {
                "value": "Carmo do Paranaíba",
                "label": "Carmo do Paranaíba"
            },
            {
                "value": "Carmo do Rio Claro",
                "label": "Carmo do Rio Claro"
            },
            {
                "value": "Carmópolis de Minas",
                "label": "Carmópolis de Minas"
            },
            {
                "value": "Carneirinho",
                "label": "Carneirinho"
            },
            {
                "value": "Carrancas",
                "label": "Carrancas"
            },
            {
                "value": "Carvalhópolis",
                "label": "Carvalhópolis"
            },
            {
                "value": "Carvalhos",
                "label": "Carvalhos"
            },
            {
                "value": "Casa Grande",
                "label": "Casa Grande"
            },
            {
                "value": "Cascalho Rico",
                "label": "Cascalho Rico"
            },
            {
                "value": "Cássia",
                "label": "Cássia"
            },
            {
                "value": "Conceição da Barra de Minas",
                "label": "Conceição da Barra de Minas"
            },
            {
                "value": "Cataguases",
                "label": "Cataguases"
            },
            {
                "value": "Catas Altas",
                "label": "Catas Altas"
            },
            {
                "value": "Catas Altas da Noruega",
                "label": "Catas Altas da Noruega"
            },
            {
                "value": "Catuji",
                "label": "Catuji"
            },
            {
                "value": "Catuti",
                "label": "Catuti"
            },
            {
                "value": "Caxambu",
                "label": "Caxambu"
            },
            {
                "value": "Cedro do Abaeté",
                "label": "Cedro do Abaeté"
            },
            {
                "value": "Central de Minas",
                "label": "Central de Minas"
            },
            {
                "value": "Centralina",
                "label": "Centralina"
            },
            {
                "value": "Chácara",
                "label": "Chácara"
            },
            {
                "value": "Chalé",
                "label": "Chalé"
            },
            {
                "value": "Chapada do Norte",
                "label": "Chapada do Norte"
            },
            {
                "value": "Chapada Gaúcha",
                "label": "Chapada Gaúcha"
            },
            {
                "value": "Chiador",
                "label": "Chiador"
            },
            {
                "value": "Cipotânea",
                "label": "Cipotânea"
            },
            {
                "value": "Claraval",
                "label": "Claraval"
            },
            {
                "value": "Claro dos Poções",
                "label": "Claro dos Poções"
            },
            {
                "value": "Cláudio",
                "label": "Cláudio"
            },
            {
                "value": "Coimbra",
                "label": "Coimbra"
            },
            {
                "value": "Coluna",
                "label": "Coluna"
            },
            {
                "value": "Comendador Gomes",
                "label": "Comendador Gomes"
            },
            {
                "value": "Comercinho",
                "label": "Comercinho"
            },
            {
                "value": "Conceição da Aparecida",
                "label": "Conceição da Aparecida"
            },
            {
                "value": "Conceição das Pedras",
                "label": "Conceição das Pedras"
            },
            {
                "value": "Conceição das Alagoas",
                "label": "Conceição das Alagoas"
            },
            {
                "value": "Conceição de Ipanema",
                "label": "Conceição de Ipanema"
            },
            {
                "value": "Conceição do Mato Dentro",
                "label": "Conceição do Mato Dentro"
            },
            {
                "value": "Conceição do Pará",
                "label": "Conceição do Pará"
            },
            {
                "value": "Conceição do Rio Verde",
                "label": "Conceição do Rio Verde"
            },
            {
                "value": "Conceição dos Ouros",
                "label": "Conceição dos Ouros"
            },
            {
                "value": "Cônego Marinho",
                "label": "Cônego Marinho"
            },
            {
                "value": "Confins",
                "label": "Confins"
            },
            {
                "value": "Congonhal",
                "label": "Congonhal"
            },
            {
                "value": "Congonhas",
                "label": "Congonhas"
            },
            {
                "value": "Congonhas do Norte",
                "label": "Congonhas do Norte"
            },
            {
                "value": "Conquista",
                "label": "Conquista"
            },
            {
                "value": "Conselheiro Lafaiete",
                "label": "Conselheiro Lafaiete"
            },
            {
                "value": "Conselheiro Pena",
                "label": "Conselheiro Pena"
            },
            {
                "value": "Consolação",
                "label": "Consolação"
            },
            {
                "value": "Contagem",
                "label": "Contagem"
            },
            {
                "value": "Coqueiral",
                "label": "Coqueiral"
            },
            {
                "value": "Coração de Jesus",
                "label": "Coração de Jesus"
            },
            {
                "value": "Cordisburgo",
                "label": "Cordisburgo"
            },
            {
                "value": "Cordislândia",
                "label": "Cordislândia"
            },
            {
                "value": "Corinto",
                "label": "Corinto"
            },
            {
                "value": "Coroaci",
                "label": "Coroaci"
            },
            {
                "value": "Coromandel",
                "label": "Coromandel"
            },
            {
                "value": "Coronel Fabriciano",
                "label": "Coronel Fabriciano"
            },
            {
                "value": "Coronel Murta",
                "label": "Coronel Murta"
            },
            {
                "value": "Coronel Pacheco",
                "label": "Coronel Pacheco"
            },
            {
                "value": "Coronel Xavier Chaves",
                "label": "Coronel Xavier Chaves"
            },
            {
                "value": "Córrego Danta",
                "label": "Córrego Danta"
            },
            {
                "value": "Córrego do Bom Jesus",
                "label": "Córrego do Bom Jesus"
            },
            {
                "value": "Córrego Fundo",
                "label": "Córrego Fundo"
            },
            {
                "value": "Córrego Novo",
                "label": "Córrego Novo"
            },
            {
                "value": "Couto de Magalhães de Minas",
                "label": "Couto de Magalhães de Minas"
            },
            {
                "value": "Crisólita",
                "label": "Crisólita"
            },
            {
                "value": "Cristais",
                "label": "Cristais"
            },
            {
                "value": "Cristália",
                "label": "Cristália"
            },
            {
                "value": "Cristiano Otoni",
                "label": "Cristiano Otoni"
            },
            {
                "value": "Cristina",
                "label": "Cristina"
            },
            {
                "value": "Crucilândia",
                "label": "Crucilândia"
            },
            {
                "value": "Cruzeiro da Fortaleza",
                "label": "Cruzeiro da Fortaleza"
            },
            {
                "value": "Cruzília",
                "label": "Cruzília"
            },
            {
                "value": "Cuparaque",
                "label": "Cuparaque"
            },
            {
                "value": "Curral de Dentro",
                "label": "Curral de Dentro"
            },
            {
                "value": "Curvelo",
                "label": "Curvelo"
            },
            {
                "value": "Datas",
                "label": "Datas"
            },
            {
                "value": "Delfim Moreira",
                "label": "Delfim Moreira"
            },
            {
                "value": "Delfinópolis",
                "label": "Delfinópolis"
            },
            {
                "value": "Delta",
                "label": "Delta"
            },
            {
                "value": "Descoberto",
                "label": "Descoberto"
            },
            {
                "value": "Desterro de Entre Rios",
                "label": "Desterro de Entre Rios"
            },
            {
                "value": "Desterro do Melo",
                "label": "Desterro do Melo"
            },
            {
                "value": "Diamantina",
                "label": "Diamantina"
            },
            {
                "value": "Diogo de Vasconcelos",
                "label": "Diogo de Vasconcelos"
            },
            {
                "value": "Dionísio",
                "label": "Dionísio"
            },
            {
                "value": "Divinésia",
                "label": "Divinésia"
            },
            {
                "value": "Divino",
                "label": "Divino"
            },
            {
                "value": "Divino das Laranjeiras",
                "label": "Divino das Laranjeiras"
            },
            {
                "value": "Divinolândia de Minas",
                "label": "Divinolândia de Minas"
            },
            {
                "value": "Divinópolis",
                "label": "Divinópolis"
            },
            {
                "value": "Divisa Alegre",
                "label": "Divisa Alegre"
            },
            {
                "value": "Divisa Nova",
                "label": "Divisa Nova"
            },
            {
                "value": "Divisópolis",
                "label": "Divisópolis"
            },
            {
                "value": "Dom Bosco",
                "label": "Dom Bosco"
            },
            {
                "value": "Dom Cavati",
                "label": "Dom Cavati"
            },
            {
                "value": "Dom Joaquim",
                "label": "Dom Joaquim"
            },
            {
                "value": "Dom Silvério",
                "label": "Dom Silvério"
            },
            {
                "value": "Dom Viçoso",
                "label": "Dom Viçoso"
            },
            {
                "value": "Dona Euzébia",
                "label": "Dona Euzébia"
            },
            {
                "value": "Dores de Campos",
                "label": "Dores de Campos"
            },
            {
                "value": "Dores de Guanhães",
                "label": "Dores de Guanhães"
            },
            {
                "value": "Dores do Indaiá",
                "label": "Dores do Indaiá"
            },
            {
                "value": "Dores do Turvo",
                "label": "Dores do Turvo"
            },
            {
                "value": "Doresópolis",
                "label": "Doresópolis"
            },
            {
                "value": "Douradoquara",
                "label": "Douradoquara"
            },
            {
                "value": "Durandé",
                "label": "Durandé"
            },
            {
                "value": "Elói Mendes",
                "label": "Elói Mendes"
            },
            {
                "value": "Engenheiro Caldas",
                "label": "Engenheiro Caldas"
            },
            {
                "value": "Engenheiro Navarro",
                "label": "Engenheiro Navarro"
            },
            {
                "value": "Entre Folhas",
                "label": "Entre Folhas"
            },
            {
                "value": "Entre Rios de Minas",
                "label": "Entre Rios de Minas"
            },
            {
                "value": "Ervália",
                "label": "Ervália"
            },
            {
                "value": "Esmeraldas",
                "label": "Esmeraldas"
            },
            {
                "value": "Espera Feliz",
                "label": "Espera Feliz"
            },
            {
                "value": "Espinosa",
                "label": "Espinosa"
            },
            {
                "value": "Espírito Santo do Dourado",
                "label": "Espírito Santo do Dourado"
            },
            {
                "value": "Estiva",
                "label": "Estiva"
            },
            {
                "value": "Estrela Dalva",
                "label": "Estrela Dalva"
            },
            {
                "value": "Estrela do Indaiá",
                "label": "Estrela do Indaiá"
            },
            {
                "value": "Estrela do Sul",
                "label": "Estrela do Sul"
            },
            {
                "value": "Eugenópolis",
                "label": "Eugenópolis"
            },
            {
                "value": "Ewbank da Câmara",
                "label": "Ewbank da Câmara"
            },
            {
                "value": "Extrema",
                "label": "Extrema"
            },
            {
                "value": "Fama",
                "label": "Fama"
            },
            {
                "value": "Faria Lemos",
                "label": "Faria Lemos"
            },
            {
                "value": "Felício dos Santos",
                "label": "Felício dos Santos"
            },
            {
                "value": "São Gonçalo do Rio Preto",
                "label": "São Gonçalo do Rio Preto"
            },
            {
                "value": "Felisburgo",
                "label": "Felisburgo"
            },
            {
                "value": "Felixlândia",
                "label": "Felixlândia"
            },
            {
                "value": "Fernandes Tourinho",
                "label": "Fernandes Tourinho"
            },
            {
                "value": "Ferros",
                "label": "Ferros"
            },
            {
                "value": "Fervedouro",
                "label": "Fervedouro"
            },
            {
                "value": "Florestal",
                "label": "Florestal"
            },
            {
                "value": "Formiga",
                "label": "Formiga"
            },
            {
                "value": "Formoso",
                "label": "Formoso"
            },
            {
                "value": "Fortaleza de Minas",
                "label": "Fortaleza de Minas"
            },
            {
                "value": "Fortuna de Minas",
                "label": "Fortuna de Minas"
            },
            {
                "value": "Francisco Badaró",
                "label": "Francisco Badaró"
            },
            {
                "value": "Francisco Dumont",
                "label": "Francisco Dumont"
            },
            {
                "value": "Francisco Sá",
                "label": "Francisco Sá"
            },
            {
                "value": "Franciscópolis",
                "label": "Franciscópolis"
            },
            {
                "value": "Frei Gaspar",
                "label": "Frei Gaspar"
            },
            {
                "value": "Frei Inocêncio",
                "label": "Frei Inocêncio"
            },
            {
                "value": "Frei Lagonegro",
                "label": "Frei Lagonegro"
            },
            {
                "value": "Fronteira",
                "label": "Fronteira"
            },
            {
                "value": "Fronteira dos Vales",
                "label": "Fronteira dos Vales"
            },
            {
                "value": "Fruta de Leite",
                "label": "Fruta de Leite"
            },
            {
                "value": "Frutal",
                "label": "Frutal"
            },
            {
                "value": "Funilândia",
                "label": "Funilândia"
            },
            {
                "value": "Galiléia",
                "label": "Galiléia"
            },
            {
                "value": "Gameleiras",
                "label": "Gameleiras"
            },
            {
                "value": "Glaucilândia",
                "label": "Glaucilândia"
            },
            {
                "value": "Goiabeira",
                "label": "Goiabeira"
            },
            {
                "value": "Goianá",
                "label": "Goianá"
            },
            {
                "value": "Gonçalves",
                "label": "Gonçalves"
            },
            {
                "value": "Gonzaga",
                "label": "Gonzaga"
            },
            {
                "value": "Gouveia",
                "label": "Gouveia"
            },
            {
                "value": "Governador Valadares",
                "label": "Governador Valadares"
            },
            {
                "value": "Grão Mogol",
                "label": "Grão Mogol"
            },
            {
                "value": "Grupiara",
                "label": "Grupiara"
            },
            {
                "value": "Guanhães",
                "label": "Guanhães"
            },
            {
                "value": "Guapé",
                "label": "Guapé"
            },
            {
                "value": "Guaraciaba",
                "label": "Guaraciaba"
            },
            {
                "value": "Guaraciama",
                "label": "Guaraciama"
            },
            {
                "value": "Guaranésia",
                "label": "Guaranésia"
            },
            {
                "value": "Guarani",
                "label": "Guarani"
            },
            {
                "value": "Guarará",
                "label": "Guarará"
            },
            {
                "value": "Guarda-Mor",
                "label": "Guarda-Mor"
            },
            {
                "value": "Guaxupé",
                "label": "Guaxupé"
            },
            {
                "value": "Guidoval",
                "label": "Guidoval"
            },
            {
                "value": "Guimarânia",
                "label": "Guimarânia"
            },
            {
                "value": "Guiricema",
                "label": "Guiricema"
            },
            {
                "value": "Gurinhatã",
                "label": "Gurinhatã"
            },
            {
                "value": "Heliodora",
                "label": "Heliodora"
            },
            {
                "value": "Iapu",
                "label": "Iapu"
            },
            {
                "value": "Ibertioga",
                "label": "Ibertioga"
            },
            {
                "value": "Ibiá",
                "label": "Ibiá"
            },
            {
                "value": "Ibiaí",
                "label": "Ibiaí"
            },
            {
                "value": "Ibiracatu",
                "label": "Ibiracatu"
            },
            {
                "value": "Ibiraci",
                "label": "Ibiraci"
            },
            {
                "value": "Ibirité",
                "label": "Ibirité"
            },
            {
                "value": "Ibitiúra de Minas",
                "label": "Ibitiúra de Minas"
            },
            {
                "value": "Ibituruna",
                "label": "Ibituruna"
            },
            {
                "value": "Icaraí de Minas",
                "label": "Icaraí de Minas"
            },
            {
                "value": "Igarapé",
                "label": "Igarapé"
            },
            {
                "value": "Igaratinga",
                "label": "Igaratinga"
            },
            {
                "value": "Iguatama",
                "label": "Iguatama"
            },
            {
                "value": "Ijaci",
                "label": "Ijaci"
            },
            {
                "value": "Ilicínea",
                "label": "Ilicínea"
            },
            {
                "value": "Imbé de Minas",
                "label": "Imbé de Minas"
            },
            {
                "value": "Inconfidentes",
                "label": "Inconfidentes"
            },
            {
                "value": "Indaiabira",
                "label": "Indaiabira"
            },
            {
                "value": "Indianópolis",
                "label": "Indianópolis"
            },
            {
                "value": "Ingaí",
                "label": "Ingaí"
            },
            {
                "value": "Inhapim",
                "label": "Inhapim"
            },
            {
                "value": "Inhaúma",
                "label": "Inhaúma"
            },
            {
                "value": "Inimutaba",
                "label": "Inimutaba"
            },
            {
                "value": "Ipaba",
                "label": "Ipaba"
            },
            {
                "value": "Ipanema",
                "label": "Ipanema"
            },
            {
                "value": "Ipatinga",
                "label": "Ipatinga"
            },
            {
                "value": "Ipiaçu",
                "label": "Ipiaçu"
            },
            {
                "value": "Ipuiúna",
                "label": "Ipuiúna"
            },
            {
                "value": "Iraí de Minas",
                "label": "Iraí de Minas"
            },
            {
                "value": "Itabira",
                "label": "Itabira"
            },
            {
                "value": "Itabirinha",
                "label": "Itabirinha"
            },
            {
                "value": "Itabirito",
                "label": "Itabirito"
            },
            {
                "value": "Itacambira",
                "label": "Itacambira"
            },
            {
                "value": "Itacarambi",
                "label": "Itacarambi"
            },
            {
                "value": "Itaguara",
                "label": "Itaguara"
            },
            {
                "value": "Itaipé",
                "label": "Itaipé"
            },
            {
                "value": "Itajubá",
                "label": "Itajubá"
            },
            {
                "value": "Itamarandiba",
                "label": "Itamarandiba"
            },
            {
                "value": "Itamarati de Minas",
                "label": "Itamarati de Minas"
            },
            {
                "value": "Itambacuri",
                "label": "Itambacuri"
            },
            {
                "value": "Itambé do Mato Dentro",
                "label": "Itambé do Mato Dentro"
            },
            {
                "value": "Itamogi",
                "label": "Itamogi"
            },
            {
                "value": "Itamonte",
                "label": "Itamonte"
            },
            {
                "value": "Itanhandu",
                "label": "Itanhandu"
            },
            {
                "value": "Itanhomi",
                "label": "Itanhomi"
            },
            {
                "value": "Itaobim",
                "label": "Itaobim"
            },
            {
                "value": "Itapagipe",
                "label": "Itapagipe"
            },
            {
                "value": "Itapecerica",
                "label": "Itapecerica"
            },
            {
                "value": "Itapeva",
                "label": "Itapeva"
            },
            {
                "value": "Itatiaiuçu",
                "label": "Itatiaiuçu"
            },
            {
                "value": "Itaú de Minas",
                "label": "Itaú de Minas"
            },
            {
                "value": "Itaúna",
                "label": "Itaúna"
            },
            {
                "value": "Itaverava",
                "label": "Itaverava"
            },
            {
                "value": "Itinga",
                "label": "Itinga"
            },
            {
                "value": "Itueta",
                "label": "Itueta"
            },
            {
                "value": "Ituiutaba",
                "label": "Ituiutaba"
            },
            {
                "value": "Itumirim",
                "label": "Itumirim"
            },
            {
                "value": "Iturama",
                "label": "Iturama"
            },
            {
                "value": "Itutinga",
                "label": "Itutinga"
            },
            {
                "value": "Jaboticatubas",
                "label": "Jaboticatubas"
            },
            {
                "value": "Jacinto",
                "label": "Jacinto"
            },
            {
                "value": "Jacuí",
                "label": "Jacuí"
            },
            {
                "value": "Jacutinga",
                "label": "Jacutinga"
            },
            {
                "value": "Jaguaraçu",
                "label": "Jaguaraçu"
            },
            {
                "value": "Jaíba",
                "label": "Jaíba"
            },
            {
                "value": "Jampruca",
                "label": "Jampruca"
            },
            {
                "value": "Janaúba",
                "label": "Janaúba"
            },
            {
                "value": "Januária",
                "label": "Januária"
            },
            {
                "value": "Japaraíba",
                "label": "Japaraíba"
            },
            {
                "value": "Japonvar",
                "label": "Japonvar"
            },
            {
                "value": "Jeceaba",
                "label": "Jeceaba"
            },
            {
                "value": "Jenipapo de Minas",
                "label": "Jenipapo de Minas"
            },
            {
                "value": "Jequeri",
                "label": "Jequeri"
            },
            {
                "value": "Jequitaí",
                "label": "Jequitaí"
            },
            {
                "value": "Jequitibá",
                "label": "Jequitibá"
            },
            {
                "value": "Jequitinhonha",
                "label": "Jequitinhonha"
            },
            {
                "value": "Jesuânia",
                "label": "Jesuânia"
            },
            {
                "value": "Joaíma",
                "label": "Joaíma"
            },
            {
                "value": "Joanésia",
                "label": "Joanésia"
            },
            {
                "value": "João Monlevade",
                "label": "João Monlevade"
            },
            {
                "value": "João Pinheiro",
                "label": "João Pinheiro"
            },
            {
                "value": "Joaquim Felício",
                "label": "Joaquim Felício"
            },
            {
                "value": "Jordânia",
                "label": "Jordânia"
            },
            {
                "value": "José Gonçalves de Minas",
                "label": "José Gonçalves de Minas"
            },
            {
                "value": "José Raydan",
                "label": "José Raydan"
            },
            {
                "value": "Josenópolis",
                "label": "Josenópolis"
            },
            {
                "value": "Nova União",
                "label": "Nova União"
            },
            {
                "value": "Juatuba",
                "label": "Juatuba"
            },
            {
                "value": "Juiz de Fora",
                "label": "Juiz de Fora"
            },
            {
                "value": "Juramento",
                "label": "Juramento"
            },
            {
                "value": "Juruaia",
                "label": "Juruaia"
            },
            {
                "value": "Juvenília",
                "label": "Juvenília"
            },
            {
                "value": "Ladainha",
                "label": "Ladainha"
            },
            {
                "value": "Lagamar",
                "label": "Lagamar"
            },
            {
                "value": "Lagoa da Prata",
                "label": "Lagoa da Prata"
            },
            {
                "value": "Lagoa dos Patos",
                "label": "Lagoa dos Patos"
            },
            {
                "value": "Lagoa Dourada",
                "label": "Lagoa Dourada"
            },
            {
                "value": "Lagoa Formosa",
                "label": "Lagoa Formosa"
            },
            {
                "value": "Lagoa Grande",
                "label": "Lagoa Grande"
            },
            {
                "value": "Lagoa Santa",
                "label": "Lagoa Santa"
            },
            {
                "value": "Lajinha",
                "label": "Lajinha"
            },
            {
                "value": "Lambari",
                "label": "Lambari"
            },
            {
                "value": "Lamim",
                "label": "Lamim"
            },
            {
                "value": "Laranjal",
                "label": "Laranjal"
            },
            {
                "value": "Lassance",
                "label": "Lassance"
            },
            {
                "value": "Lavras",
                "label": "Lavras"
            },
            {
                "value": "Leandro Ferreira",
                "label": "Leandro Ferreira"
            },
            {
                "value": "Leme do Prado",
                "label": "Leme do Prado"
            },
            {
                "value": "Leopoldina",
                "label": "Leopoldina"
            },
            {
                "value": "Liberdade",
                "label": "Liberdade"
            },
            {
                "value": "Lima Duarte",
                "label": "Lima Duarte"
            },
            {
                "value": "Limeira do Oeste",
                "label": "Limeira do Oeste"
            },
            {
                "value": "Lontra",
                "label": "Lontra"
            },
            {
                "value": "Luisburgo",
                "label": "Luisburgo"
            },
            {
                "value": "Luislândia",
                "label": "Luislândia"
            },
            {
                "value": "Luminárias",
                "label": "Luminárias"
            },
            {
                "value": "Luz",
                "label": "Luz"
            },
            {
                "value": "Machacalis",
                "label": "Machacalis"
            },
            {
                "value": "Machado",
                "label": "Machado"
            },
            {
                "value": "Madre de Deus de Minas",
                "label": "Madre de Deus de Minas"
            },
            {
                "value": "Malacacheta",
                "label": "Malacacheta"
            },
            {
                "value": "Mamonas",
                "label": "Mamonas"
            },
            {
                "value": "Manga",
                "label": "Manga"
            },
            {
                "value": "Manhuaçu",
                "label": "Manhuaçu"
            },
            {
                "value": "Manhumirim",
                "label": "Manhumirim"
            },
            {
                "value": "Mantena",
                "label": "Mantena"
            },
            {
                "value": "Maravilhas",
                "label": "Maravilhas"
            },
            {
                "value": "Mar de Espanha",
                "label": "Mar de Espanha"
            },
            {
                "value": "Maria da Fé",
                "label": "Maria da Fé"
            },
            {
                "value": "Mariana",
                "label": "Mariana"
            },
            {
                "value": "Marilac",
                "label": "Marilac"
            },
            {
                "value": "Mário Campos",
                "label": "Mário Campos"
            },
            {
                "value": "Maripá de Minas",
                "label": "Maripá de Minas"
            },
            {
                "value": "Marliéria",
                "label": "Marliéria"
            },
            {
                "value": "Marmelópolis",
                "label": "Marmelópolis"
            },
            {
                "value": "Martinho Campos",
                "label": "Martinho Campos"
            },
            {
                "value": "Martins Soares",
                "label": "Martins Soares"
            },
            {
                "value": "Mata Verde",
                "label": "Mata Verde"
            },
            {
                "value": "Materlândia",
                "label": "Materlândia"
            },
            {
                "value": "Mateus Leme",
                "label": "Mateus Leme"
            },
            {
                "value": "Matias Barbosa",
                "label": "Matias Barbosa"
            },
            {
                "value": "Matias Cardoso",
                "label": "Matias Cardoso"
            },
            {
                "value": "Matipó",
                "label": "Matipó"
            },
            {
                "value": "Mato Verde",
                "label": "Mato Verde"
            },
            {
                "value": "Matozinhos",
                "label": "Matozinhos"
            },
            {
                "value": "Matutina",
                "label": "Matutina"
            },
            {
                "value": "Medeiros",
                "label": "Medeiros"
            },
            {
                "value": "Medina",
                "label": "Medina"
            },
            {
                "value": "Mendes Pimentel",
                "label": "Mendes Pimentel"
            },
            {
                "value": "Mercês",
                "label": "Mercês"
            },
            {
                "value": "Mesquita",
                "label": "Mesquita"
            },
            {
                "value": "Minas Novas",
                "label": "Minas Novas"
            },
            {
                "value": "Minduri",
                "label": "Minduri"
            },
            {
                "value": "Mirabela",
                "label": "Mirabela"
            },
            {
                "value": "Miradouro",
                "label": "Miradouro"
            },
            {
                "value": "Miraí",
                "label": "Miraí"
            },
            {
                "value": "Miravânia",
                "label": "Miravânia"
            },
            {
                "value": "Moeda",
                "label": "Moeda"
            },
            {
                "value": "Moema",
                "label": "Moema"
            },
            {
                "value": "Monjolos",
                "label": "Monjolos"
            },
            {
                "value": "Monsenhor Paulo",
                "label": "Monsenhor Paulo"
            },
            {
                "value": "Montalvânia",
                "label": "Montalvânia"
            },
            {
                "value": "Monte Alegre de Minas",
                "label": "Monte Alegre de Minas"
            },
            {
                "value": "Monte Azul",
                "label": "Monte Azul"
            },
            {
                "value": "Monte Belo",
                "label": "Monte Belo"
            },
            {
                "value": "Monte Carmelo",
                "label": "Monte Carmelo"
            },
            {
                "value": "Monte Formoso",
                "label": "Monte Formoso"
            },
            {
                "value": "Monte Santo de Minas",
                "label": "Monte Santo de Minas"
            },
            {
                "value": "Montes Claros",
                "label": "Montes Claros"
            },
            {
                "value": "Monte Sião",
                "label": "Monte Sião"
            },
            {
                "value": "Montezuma",
                "label": "Montezuma"
            },
            {
                "value": "Morada Nova de Minas",
                "label": "Morada Nova de Minas"
            },
            {
                "value": "Morro da Garça",
                "label": "Morro da Garça"
            },
            {
                "value": "Morro do Pilar",
                "label": "Morro do Pilar"
            },
            {
                "value": "Munhoz",
                "label": "Munhoz"
            },
            {
                "value": "Muriaé",
                "label": "Muriaé"
            },
            {
                "value": "Mutum",
                "label": "Mutum"
            },
            {
                "value": "Muzambinho",
                "label": "Muzambinho"
            },
            {
                "value": "Nacip Raydan",
                "label": "Nacip Raydan"
            },
            {
                "value": "Nanuque",
                "label": "Nanuque"
            },
            {
                "value": "Naque",
                "label": "Naque"
            },
            {
                "value": "Natalândia",
                "label": "Natalândia"
            },
            {
                "value": "Natércia",
                "label": "Natércia"
            },
            {
                "value": "Nazareno",
                "label": "Nazareno"
            },
            {
                "value": "Nepomuceno",
                "label": "Nepomuceno"
            },
            {
                "value": "Ninheira",
                "label": "Ninheira"
            },
            {
                "value": "Nova Belém",
                "label": "Nova Belém"
            },
            {
                "value": "Nova Era",
                "label": "Nova Era"
            },
            {
                "value": "Nova Lima",
                "label": "Nova Lima"
            },
            {
                "value": "Nova Módica",
                "label": "Nova Módica"
            },
            {
                "value": "Nova Ponte",
                "label": "Nova Ponte"
            },
            {
                "value": "Nova Porteirinha",
                "label": "Nova Porteirinha"
            },
            {
                "value": "Nova Resende",
                "label": "Nova Resende"
            },
            {
                "value": "Nova Serrana",
                "label": "Nova Serrana"
            },
            {
                "value": "Novo Cruzeiro",
                "label": "Novo Cruzeiro"
            },
            {
                "value": "Novo Oriente de Minas",
                "label": "Novo Oriente de Minas"
            },
            {
                "value": "Novorizonte",
                "label": "Novorizonte"
            },
            {
                "value": "Olaria",
                "label": "Olaria"
            },
            {
                "value": "Olhos-d'Água",
                "label": "Olhos-d'Água"
            },
            {
                "value": "Olímpio Noronha",
                "label": "Olímpio Noronha"
            },
            {
                "value": "Oliveira",
                "label": "Oliveira"
            },
            {
                "value": "Oliveira Fortes",
                "label": "Oliveira Fortes"
            },
            {
                "value": "Onça de Pitangui",
                "label": "Onça de Pitangui"
            },
            {
                "value": "Oratórios",
                "label": "Oratórios"
            },
            {
                "value": "Orizânia",
                "label": "Orizânia"
            },
            {
                "value": "Ouro Branco",
                "label": "Ouro Branco"
            },
            {
                "value": "Ouro Fino",
                "label": "Ouro Fino"
            },
            {
                "value": "Ouro Preto",
                "label": "Ouro Preto"
            },
            {
                "value": "Ouro Verde de Minas",
                "label": "Ouro Verde de Minas"
            },
            {
                "value": "Padre Carvalho",
                "label": "Padre Carvalho"
            },
            {
                "value": "Padre Paraíso",
                "label": "Padre Paraíso"
            },
            {
                "value": "Paineiras",
                "label": "Paineiras"
            },
            {
                "value": "Pains",
                "label": "Pains"
            },
            {
                "value": "Pai Pedro",
                "label": "Pai Pedro"
            },
            {
                "value": "Paiva",
                "label": "Paiva"
            },
            {
                "value": "Palma",
                "label": "Palma"
            },
            {
                "value": "Palmópolis",
                "label": "Palmópolis"
            },
            {
                "value": "Papagaios",
                "label": "Papagaios"
            },
            {
                "value": "Paracatu",
                "label": "Paracatu"
            },
            {
                "value": "Pará de Minas",
                "label": "Pará de Minas"
            },
            {
                "value": "Paraguaçu",
                "label": "Paraguaçu"
            },
            {
                "value": "Paraisópolis",
                "label": "Paraisópolis"
            },
            {
                "value": "Paraopeba",
                "label": "Paraopeba"
            },
            {
                "value": "Passabém",
                "label": "Passabém"
            },
            {
                "value": "Passa Quatro",
                "label": "Passa Quatro"
            },
            {
                "value": "Passa Tempo",
                "label": "Passa Tempo"
            },
            {
                "value": "Passa Vinte",
                "label": "Passa Vinte"
            },
            {
                "value": "Passos",
                "label": "Passos"
            },
            {
                "value": "Patis",
                "label": "Patis"
            },
            {
                "value": "Patos de Minas",
                "label": "Patos de Minas"
            },
            {
                "value": "Patrocínio",
                "label": "Patrocínio"
            },
            {
                "value": "Patrocínio do Muriaé",
                "label": "Patrocínio do Muriaé"
            },
            {
                "value": "Paula Cândido",
                "label": "Paula Cândido"
            },
            {
                "value": "Paulistas",
                "label": "Paulistas"
            },
            {
                "value": "Pavão",
                "label": "Pavão"
            },
            {
                "value": "Peçanha",
                "label": "Peçanha"
            },
            {
                "value": "Pedra Azul",
                "label": "Pedra Azul"
            },
            {
                "value": "Pedra Bonita",
                "label": "Pedra Bonita"
            },
            {
                "value": "Pedra do Anta",
                "label": "Pedra do Anta"
            },
            {
                "value": "Pedra do Indaiá",
                "label": "Pedra do Indaiá"
            },
            {
                "value": "Pedra Dourada",
                "label": "Pedra Dourada"
            },
            {
                "value": "Pedralva",
                "label": "Pedralva"
            },
            {
                "value": "Pedras de Maria da Cruz",
                "label": "Pedras de Maria da Cruz"
            },
            {
                "value": "Pedrinópolis",
                "label": "Pedrinópolis"
            },
            {
                "value": "Pedro Leopoldo",
                "label": "Pedro Leopoldo"
            },
            {
                "value": "Pedro Teixeira",
                "label": "Pedro Teixeira"
            },
            {
                "value": "Pequeri",
                "label": "Pequeri"
            },
            {
                "value": "Pequi",
                "label": "Pequi"
            },
            {
                "value": "Perdigão",
                "label": "Perdigão"
            },
            {
                "value": "Perdizes",
                "label": "Perdizes"
            },
            {
                "value": "Perdões",
                "label": "Perdões"
            },
            {
                "value": "Periquito",
                "label": "Periquito"
            },
            {
                "value": "Pescador",
                "label": "Pescador"
            },
            {
                "value": "Piau",
                "label": "Piau"
            },
            {
                "value": "Piedade de Caratinga",
                "label": "Piedade de Caratinga"
            },
            {
                "value": "Piedade de Ponte Nova",
                "label": "Piedade de Ponte Nova"
            },
            {
                "value": "Piedade do Rio Grande",
                "label": "Piedade do Rio Grande"
            },
            {
                "value": "Piedade dos Gerais",
                "label": "Piedade dos Gerais"
            },
            {
                "value": "Pimenta",
                "label": "Pimenta"
            },
            {
                "value": "Pingo d'Água",
                "label": "Pingo d'Água"
            },
            {
                "value": "Pintópolis",
                "label": "Pintópolis"
            },
            {
                "value": "Piracema",
                "label": "Piracema"
            },
            {
                "value": "Pirajuba",
                "label": "Pirajuba"
            },
            {
                "value": "Piranga",
                "label": "Piranga"
            },
            {
                "value": "Piranguçu",
                "label": "Piranguçu"
            },
            {
                "value": "Piranguinho",
                "label": "Piranguinho"
            },
            {
                "value": "Pirapetinga",
                "label": "Pirapetinga"
            },
            {
                "value": "Pirapora",
                "label": "Pirapora"
            },
            {
                "value": "Piraúba",
                "label": "Piraúba"
            },
            {
                "value": "Pitangui",
                "label": "Pitangui"
            },
            {
                "value": "Piumhi",
                "label": "Piumhi"
            },
            {
                "value": "Planura",
                "label": "Planura"
            },
            {
                "value": "Poço Fundo",
                "label": "Poço Fundo"
            },
            {
                "value": "Poços de Caldas",
                "label": "Poços de Caldas"
            },
            {
                "value": "Pocrane",
                "label": "Pocrane"
            },
            {
                "value": "Pompéu",
                "label": "Pompéu"
            },
            {
                "value": "Ponte Nova",
                "label": "Ponte Nova"
            },
            {
                "value": "Ponto Chique",
                "label": "Ponto Chique"
            },
            {
                "value": "Ponto dos Volantes",
                "label": "Ponto dos Volantes"
            },
            {
                "value": "Porteirinha",
                "label": "Porteirinha"
            },
            {
                "value": "Porto Firme",
                "label": "Porto Firme"
            },
            {
                "value": "Poté",
                "label": "Poté"
            },
            {
                "value": "Pouso Alegre",
                "label": "Pouso Alegre"
            },
            {
                "value": "Pouso Alto",
                "label": "Pouso Alto"
            },
            {
                "value": "Prados",
                "label": "Prados"
            },
            {
                "value": "Prata",
                "label": "Prata"
            },
            {
                "value": "Pratápolis",
                "label": "Pratápolis"
            },
            {
                "value": "Pratinha",
                "label": "Pratinha"
            },
            {
                "value": "Presidente Bernardes",
                "label": "Presidente Bernardes"
            },
            {
                "value": "Presidente Juscelino",
                "label": "Presidente Juscelino"
            },
            {
                "value": "Presidente Kubitschek",
                "label": "Presidente Kubitschek"
            },
            {
                "value": "Presidente Olegário",
                "label": "Presidente Olegário"
            },
            {
                "value": "Alto Jequitibá",
                "label": "Alto Jequitibá"
            },
            {
                "value": "Prudente de Morais",
                "label": "Prudente de Morais"
            },
            {
                "value": "Quartel Geral",
                "label": "Quartel Geral"
            },
            {
                "value": "Queluzito",
                "label": "Queluzito"
            },
            {
                "value": "Raposos",
                "label": "Raposos"
            },
            {
                "value": "Raul Soares",
                "label": "Raul Soares"
            },
            {
                "value": "Recreio",
                "label": "Recreio"
            },
            {
                "value": "Reduto",
                "label": "Reduto"
            },
            {
                "value": "Resende Costa",
                "label": "Resende Costa"
            },
            {
                "value": "Resplendor",
                "label": "Resplendor"
            },
            {
                "value": "Ressaquinha",
                "label": "Ressaquinha"
            },
            {
                "value": "Riachinho",
                "label": "Riachinho"
            },
            {
                "value": "Riacho dos Machados",
                "label": "Riacho dos Machados"
            },
            {
                "value": "Ribeirão das Neves",
                "label": "Ribeirão das Neves"
            },
            {
                "value": "Ribeirão Vermelho",
                "label": "Ribeirão Vermelho"
            },
            {
                "value": "Rio Acima",
                "label": "Rio Acima"
            },
            {
                "value": "Rio Casca",
                "label": "Rio Casca"
            },
            {
                "value": "Rio Doce",
                "label": "Rio Doce"
            },
            {
                "value": "Rio do Prado",
                "label": "Rio do Prado"
            },
            {
                "value": "Rio Espera",
                "label": "Rio Espera"
            },
            {
                "value": "Rio Manso",
                "label": "Rio Manso"
            },
            {
                "value": "Rio Novo",
                "label": "Rio Novo"
            },
            {
                "value": "Rio Paranaíba",
                "label": "Rio Paranaíba"
            },
            {
                "value": "Rio Pardo de Minas",
                "label": "Rio Pardo de Minas"
            },
            {
                "value": "Rio Piracicaba",
                "label": "Rio Piracicaba"
            },
            {
                "value": "Rio Pomba",
                "label": "Rio Pomba"
            },
            {
                "value": "Rio Preto",
                "label": "Rio Preto"
            },
            {
                "value": "Rio Vermelho",
                "label": "Rio Vermelho"
            },
            {
                "value": "Ritápolis",
                "label": "Ritápolis"
            },
            {
                "value": "Rochedo de Minas",
                "label": "Rochedo de Minas"
            },
            {
                "value": "Rodeiro",
                "label": "Rodeiro"
            },
            {
                "value": "Romaria",
                "label": "Romaria"
            },
            {
                "value": "Rosário da Limeira",
                "label": "Rosário da Limeira"
            },
            {
                "value": "Rubelita",
                "label": "Rubelita"
            },
            {
                "value": "Rubim",
                "label": "Rubim"
            },
            {
                "value": "Sabará",
                "label": "Sabará"
            },
            {
                "value": "Sabinópolis",
                "label": "Sabinópolis"
            },
            {
                "value": "Sacramento",
                "label": "Sacramento"
            },
            {
                "value": "Salinas",
                "label": "Salinas"
            },
            {
                "value": "Salto da Divisa",
                "label": "Salto da Divisa"
            },
            {
                "value": "Santa Bárbara",
                "label": "Santa Bárbara"
            },
            {
                "value": "Santa Bárbara do Leste",
                "label": "Santa Bárbara do Leste"
            },
            {
                "value": "Santa Bárbara do Monte Verde",
                "label": "Santa Bárbara do Monte Verde"
            },
            {
                "value": "Santa Bárbara do Tugúrio",
                "label": "Santa Bárbara do Tugúrio"
            },
            {
                "value": "Santa Cruz de Minas",
                "label": "Santa Cruz de Minas"
            },
            {
                "value": "Santa Cruz de Salinas",
                "label": "Santa Cruz de Salinas"
            },
            {
                "value": "Santa Cruz do Escalvado",
                "label": "Santa Cruz do Escalvado"
            },
            {
                "value": "Santa Efigênia de Minas",
                "label": "Santa Efigênia de Minas"
            },
            {
                "value": "Santa Fé de Minas",
                "label": "Santa Fé de Minas"
            },
            {
                "value": "Santa Helena de Minas",
                "label": "Santa Helena de Minas"
            },
            {
                "value": "Santa Juliana",
                "label": "Santa Juliana"
            },
            {
                "value": "Santa Luzia",
                "label": "Santa Luzia"
            },
            {
                "value": "Santa Margarida",
                "label": "Santa Margarida"
            },
            {
                "value": "Santa Maria de Itabira",
                "label": "Santa Maria de Itabira"
            },
            {
                "value": "Santa Maria do Salto",
                "label": "Santa Maria do Salto"
            },
            {
                "value": "Santa Maria do Suaçuí",
                "label": "Santa Maria do Suaçuí"
            },
            {
                "value": "Santana da Vargem",
                "label": "Santana da Vargem"
            },
            {
                "value": "Santana de Cataguases",
                "label": "Santana de Cataguases"
            },
            {
                "value": "Santana de Pirapama",
                "label": "Santana de Pirapama"
            },
            {
                "value": "Santana do Deserto",
                "label": "Santana do Deserto"
            },
            {
                "value": "Santana do Garambéu",
                "label": "Santana do Garambéu"
            },
            {
                "value": "Santana do Jacaré",
                "label": "Santana do Jacaré"
            },
            {
                "value": "Santana do Manhuaçu",
                "label": "Santana do Manhuaçu"
            },
            {
                "value": "Santana do Paraíso",
                "label": "Santana do Paraíso"
            },
            {
                "value": "Santana do Riacho",
                "label": "Santana do Riacho"
            },
            {
                "value": "Santana dos Montes",
                "label": "Santana dos Montes"
            },
            {
                "value": "Santa Rita de Caldas",
                "label": "Santa Rita de Caldas"
            },
            {
                "value": "Santa Rita de Jacutinga",
                "label": "Santa Rita de Jacutinga"
            },
            {
                "value": "Santa Rita de Minas",
                "label": "Santa Rita de Minas"
            },
            {
                "value": "Santa Rita de Ibitipoca",
                "label": "Santa Rita de Ibitipoca"
            },
            {
                "value": "Santa Rita do Itueto",
                "label": "Santa Rita do Itueto"
            },
            {
                "value": "Santa Rita do Sapucaí",
                "label": "Santa Rita do Sapucaí"
            },
            {
                "value": "Santa Rosa da Serra",
                "label": "Santa Rosa da Serra"
            },
            {
                "value": "Santa Vitória",
                "label": "Santa Vitória"
            },
            {
                "value": "Santo Antônio do Amparo",
                "label": "Santo Antônio do Amparo"
            },
            {
                "value": "Santo Antônio do Aventureiro",
                "label": "Santo Antônio do Aventureiro"
            },
            {
                "value": "Santo Antônio do Grama",
                "label": "Santo Antônio do Grama"
            },
            {
                "value": "Santo Antônio do Itambé",
                "label": "Santo Antônio do Itambé"
            },
            {
                "value": "Santo Antônio do Jacinto",
                "label": "Santo Antônio do Jacinto"
            },
            {
                "value": "Santo Antônio do Monte",
                "label": "Santo Antônio do Monte"
            },
            {
                "value": "Santo Antônio do Retiro",
                "label": "Santo Antônio do Retiro"
            },
            {
                "value": "Santo Antônio do Rio Abaixo",
                "label": "Santo Antônio do Rio Abaixo"
            },
            {
                "value": "Santo Hipólito",
                "label": "Santo Hipólito"
            },
            {
                "value": "Santos Dumont",
                "label": "Santos Dumont"
            },
            {
                "value": "São Bento Abade",
                "label": "São Bento Abade"
            },
            {
                "value": "São Brás do Suaçuí",
                "label": "São Brás do Suaçuí"
            },
            {
                "value": "São Domingos das Dores",
                "label": "São Domingos das Dores"
            },
            {
                "value": "São Domingos do Prata",
                "label": "São Domingos do Prata"
            },
            {
                "value": "São Félix de Minas",
                "label": "São Félix de Minas"
            },
            {
                "value": "São Francisco",
                "label": "São Francisco"
            },
            {
                "value": "São Francisco de Paula",
                "label": "São Francisco de Paula"
            },
            {
                "value": "São Francisco de Sales",
                "label": "São Francisco de Sales"
            },
            {
                "value": "São Francisco do Glória",
                "label": "São Francisco do Glória"
            },
            {
                "value": "São Geraldo",
                "label": "São Geraldo"
            },
            {
                "value": "São Geraldo da Piedade",
                "label": "São Geraldo da Piedade"
            },
            {
                "value": "São Geraldo do Baixio",
                "label": "São Geraldo do Baixio"
            },
            {
                "value": "São Gonçalo do Abaeté",
                "label": "São Gonçalo do Abaeté"
            },
            {
                "value": "São Gonçalo do Pará",
                "label": "São Gonçalo do Pará"
            },
            {
                "value": "São Gonçalo do Rio Abaixo",
                "label": "São Gonçalo do Rio Abaixo"
            },
            {
                "value": "São Gonçalo do Sapucaí",
                "label": "São Gonçalo do Sapucaí"
            },
            {
                "value": "São Gotardo",
                "label": "São Gotardo"
            },
            {
                "value": "São João Batista do Glória",
                "label": "São João Batista do Glória"
            },
            {
                "value": "São João da Lagoa",
                "label": "São João da Lagoa"
            },
            {
                "value": "São João da Mata",
                "label": "São João da Mata"
            },
            {
                "value": "São João da Ponte",
                "label": "São João da Ponte"
            },
            {
                "value": "São João das Missões",
                "label": "São João das Missões"
            },
            {
                "value": "São João del Rei",
                "label": "São João del Rei"
            },
            {
                "value": "São João do Manhuaçu",
                "label": "São João do Manhuaçu"
            },
            {
                "value": "São João do Manteninha",
                "label": "São João do Manteninha"
            },
            {
                "value": "São João do Oriente",
                "label": "São João do Oriente"
            },
            {
                "value": "São João do Pacuí",
                "label": "São João do Pacuí"
            },
            {
                "value": "São João do Paraíso",
                "label": "São João do Paraíso"
            },
            {
                "value": "São João Evangelista",
                "label": "São João Evangelista"
            },
            {
                "value": "São João Nepomuceno",
                "label": "São João Nepomuceno"
            },
            {
                "value": "São Joaquim de Bicas",
                "label": "São Joaquim de Bicas"
            },
            {
                "value": "São José da Barra",
                "label": "São José da Barra"
            },
            {
                "value": "São José da Lapa",
                "label": "São José da Lapa"
            },
            {
                "value": "São José da Safira",
                "label": "São José da Safira"
            },
            {
                "value": "São José da Varginha",
                "label": "São José da Varginha"
            },
            {
                "value": "São José do Alegre",
                "label": "São José do Alegre"
            },
            {
                "value": "São José do Divino",
                "label": "São José do Divino"
            },
            {
                "value": "São José do Goiabal",
                "label": "São José do Goiabal"
            },
            {
                "value": "São José do Jacuri",
                "label": "São José do Jacuri"
            },
            {
                "value": "São José do Mantimento",
                "label": "São José do Mantimento"
            },
            {
                "value": "São Lourenço",
                "label": "São Lourenço"
            },
            {
                "value": "São Miguel do Anta",
                "label": "São Miguel do Anta"
            },
            {
                "value": "São Pedro da União",
                "label": "São Pedro da União"
            },
            {
                "value": "São Pedro dos Ferros",
                "label": "São Pedro dos Ferros"
            },
            {
                "value": "São Pedro do Suaçuí",
                "label": "São Pedro do Suaçuí"
            },
            {
                "value": "São Romão",
                "label": "São Romão"
            },
            {
                "value": "São Roque de Minas",
                "label": "São Roque de Minas"
            },
            {
                "value": "São Sebastião da Bela Vista",
                "label": "São Sebastião da Bela Vista"
            },
            {
                "value": "São Sebastião da Vargem Alegre",
                "label": "São Sebastião da Vargem Alegre"
            },
            {
                "value": "São Sebastião do Anta",
                "label": "São Sebastião do Anta"
            },
            {
                "value": "São Sebastião do Maranhão",
                "label": "São Sebastião do Maranhão"
            },
            {
                "value": "São Sebastião do Oeste",
                "label": "São Sebastião do Oeste"
            },
            {
                "value": "São Sebastião do Paraíso",
                "label": "São Sebastião do Paraíso"
            },
            {
                "value": "São Sebastião do Rio Preto",
                "label": "São Sebastião do Rio Preto"
            },
            {
                "value": "São Sebastião do Rio Verde",
                "label": "São Sebastião do Rio Verde"
            },
            {
                "value": "São Tiago",
                "label": "São Tiago"
            },
            {
                "value": "São Tomás de Aquino",
                "label": "São Tomás de Aquino"
            },
            {
                "value": "São Tomé das Letras",
                "label": "São Tomé das Letras"
            },
            {
                "value": "São Vicente de Minas",
                "label": "São Vicente de Minas"
            },
            {
                "value": "Sapucaí-Mirim",
                "label": "Sapucaí-Mirim"
            },
            {
                "value": "Sardoá",
                "label": "Sardoá"
            },
            {
                "value": "Sarzedo",
                "label": "Sarzedo"
            },
            {
                "value": "Setubinha",
                "label": "Setubinha"
            },
            {
                "value": "Sem-Peixe",
                "label": "Sem-Peixe"
            },
            {
                "value": "Senador Amaral",
                "label": "Senador Amaral"
            },
            {
                "value": "Senador Cortes",
                "label": "Senador Cortes"
            },
            {
                "value": "Senador Firmino",
                "label": "Senador Firmino"
            },
            {
                "value": "Senador José Bento",
                "label": "Senador José Bento"
            },
            {
                "value": "Senador Modestino Gonçalves",
                "label": "Senador Modestino Gonçalves"
            },
            {
                "value": "Senhora de Oliveira",
                "label": "Senhora de Oliveira"
            },
            {
                "value": "Senhora do Porto",
                "label": "Senhora do Porto"
            },
            {
                "value": "Senhora dos Remédios",
                "label": "Senhora dos Remédios"
            },
            {
                "value": "Sericita",
                "label": "Sericita"
            },
            {
                "value": "Seritinga",
                "label": "Seritinga"
            },
            {
                "value": "Serra Azul de Minas",
                "label": "Serra Azul de Minas"
            },
            {
                "value": "Serra da Saudade",
                "label": "Serra da Saudade"
            },
            {
                "value": "Serra dos Aimorés",
                "label": "Serra dos Aimorés"
            },
            {
                "value": "Serra do Salitre",
                "label": "Serra do Salitre"
            },
            {
                "value": "Serrania",
                "label": "Serrania"
            },
            {
                "value": "Serranópolis de Minas",
                "label": "Serranópolis de Minas"
            },
            {
                "value": "Serranos",
                "label": "Serranos"
            },
            {
                "value": "Serro",
                "label": "Serro"
            },
            {
                "value": "Sete Lagoas",
                "label": "Sete Lagoas"
            },
            {
                "value": "Silveirânia",
                "label": "Silveirânia"
            },
            {
                "value": "Silvianópolis",
                "label": "Silvianópolis"
            },
            {
                "value": "Simão Pereira",
                "label": "Simão Pereira"
            },
            {
                "value": "Simonésia",
                "label": "Simonésia"
            },
            {
                "value": "Sobrália",
                "label": "Sobrália"
            },
            {
                "value": "Soledade de Minas",
                "label": "Soledade de Minas"
            },
            {
                "value": "Tabuleiro",
                "label": "Tabuleiro"
            },
            {
                "value": "Taiobeiras",
                "label": "Taiobeiras"
            },
            {
                "value": "Taparuba",
                "label": "Taparuba"
            },
            {
                "value": "Tapira",
                "label": "Tapira"
            },
            {
                "value": "Tapiraí",
                "label": "Tapiraí"
            },
            {
                "value": "Taquaraçu de Minas",
                "label": "Taquaraçu de Minas"
            },
            {
                "value": "Tarumirim",
                "label": "Tarumirim"
            },
            {
                "value": "Teixeiras",
                "label": "Teixeiras"
            },
            {
                "value": "Teófilo Otoni",
                "label": "Teófilo Otoni"
            },
            {
                "value": "Timóteo",
                "label": "Timóteo"
            },
            {
                "value": "Tiradentes",
                "label": "Tiradentes"
            },
            {
                "value": "Tiros",
                "label": "Tiros"
            },
            {
                "value": "Tocantins",
                "label": "Tocantins"
            },
            {
                "value": "Tocos do Moji",
                "label": "Tocos do Moji"
            },
            {
                "value": "Toledo",
                "label": "Toledo"
            },
            {
                "value": "Tombos",
                "label": "Tombos"
            },
            {
                "value": "Três Corações",
                "label": "Três Corações"
            },
            {
                "value": "Três Marias",
                "label": "Três Marias"
            },
            {
                "value": "Três Pontas",
                "label": "Três Pontas"
            },
            {
                "value": "Tumiritinga",
                "label": "Tumiritinga"
            },
            {
                "value": "Tupaciguara",
                "label": "Tupaciguara"
            },
            {
                "value": "Turmalina",
                "label": "Turmalina"
            },
            {
                "value": "Turvolândia",
                "label": "Turvolândia"
            },
            {
                "value": "Ubá",
                "label": "Ubá"
            },
            {
                "value": "Ubaí",
                "label": "Ubaí"
            },
            {
                "value": "Ubaporanga",
                "label": "Ubaporanga"
            },
            {
                "value": "Uberaba",
                "label": "Uberaba"
            },
            {
                "value": "Uberlândia",
                "label": "Uberlândia"
            },
            {
                "value": "Umburatiba",
                "label": "Umburatiba"
            },
            {
                "value": "Unaí",
                "label": "Unaí"
            },
            {
                "value": "União de Minas",
                "label": "União de Minas"
            },
            {
                "value": "Uruana de Minas",
                "label": "Uruana de Minas"
            },
            {
                "value": "Urucânia",
                "label": "Urucânia"
            },
            {
                "value": "Urucuia",
                "label": "Urucuia"
            },
            {
                "value": "Vargem Alegre",
                "label": "Vargem Alegre"
            },
            {
                "value": "Vargem Bonita",
                "label": "Vargem Bonita"
            },
            {
                "value": "Vargem Grande do Rio Pardo",
                "label": "Vargem Grande do Rio Pardo"
            },
            {
                "value": "Varginha",
                "label": "Varginha"
            },
            {
                "value": "Varjão de Minas",
                "label": "Varjão de Minas"
            },
            {
                "value": "Várzea da Palma",
                "label": "Várzea da Palma"
            },
            {
                "value": "Varzelândia",
                "label": "Varzelândia"
            },
            {
                "value": "Vazante",
                "label": "Vazante"
            },
            {
                "value": "Verdelândia",
                "label": "Verdelândia"
            },
            {
                "value": "Veredinha",
                "label": "Veredinha"
            },
            {
                "value": "Veríssimo",
                "label": "Veríssimo"
            },
            {
                "value": "Vermelho Novo",
                "label": "Vermelho Novo"
            },
            {
                "value": "Vespasiano",
                "label": "Vespasiano"
            },
            {
                "value": "Viçosa",
                "label": "Viçosa"
            },
            {
                "value": "Vieiras",
                "label": "Vieiras"
            },
            {
                "value": "Mathias Lobato",
                "label": "Mathias Lobato"
            },
            {
                "value": "Virgem da Lapa",
                "label": "Virgem da Lapa"
            },
            {
                "value": "Virgínia",
                "label": "Virgínia"
            },
            {
                "value": "Virginópolis",
                "label": "Virginópolis"
            },
            {
                "value": "Virgolândia",
                "label": "Virgolândia"
            },
            {
                "value": "Visconde do Rio Branco",
                "label": "Visconde do Rio Branco"
            },
            {
                "value": "Volta Grande",
                "label": "Volta Grande"
            },
            {
                "value": "Wenceslau Braz",
                "label": "Wenceslau Braz"
            }
        ]
    },
    {
        "value": "MS",
        "label": "Mato Grosso do Sul",
        "children": [
            {
                "value": "Água Clara",
                "label": "Água Clara"
            },
            {
                "value": "Alcinópolis",
                "label": "Alcinópolis"
            },
            {
                "value": "Amambai",
                "label": "Amambai"
            },
            {
                "value": "Anastácio",
                "label": "Anastácio"
            },
            {
                "value": "Anaurilândia",
                "label": "Anaurilândia"
            },
            {
                "value": "Angélica",
                "label": "Angélica"
            },
            {
                "value": "Antônio João",
                "label": "Antônio João"
            },
            {
                "value": "Aparecida do Taboado",
                "label": "Aparecida do Taboado"
            },
            {
                "value": "Aquidauana",
                "label": "Aquidauana"
            },
            {
                "value": "Aral Moreira",
                "label": "Aral Moreira"
            },
            {
                "value": "Bandeirantes",
                "label": "Bandeirantes"
            },
            {
                "value": "Bataguassu",
                "label": "Bataguassu"
            },
            {
                "value": "Batayporã",
                "label": "Batayporã"
            },
            {
                "value": "Bela Vista",
                "label": "Bela Vista"
            },
            {
                "value": "Bodoquena",
                "label": "Bodoquena"
            },
            {
                "value": "Bonito",
                "label": "Bonito"
            },
            {
                "value": "Brasilândia",
                "label": "Brasilândia"
            },
            {
                "value": "Caarapó",
                "label": "Caarapó"
            },
            {
                "value": "Camapuã",
                "label": "Camapuã"
            },
            {
                "value": "Campo Grande",
                "label": "Campo Grande"
            },
            {
                "value": "Caracol",
                "label": "Caracol"
            },
            {
                "value": "Cassilândia",
                "label": "Cassilândia"
            },
            {
                "value": "Chapadão do Sul",
                "label": "Chapadão do Sul"
            },
            {
                "value": "Corguinho",
                "label": "Corguinho"
            },
            {
                "value": "Coronel Sapucaia",
                "label": "Coronel Sapucaia"
            },
            {
                "value": "Corumbá",
                "label": "Corumbá"
            },
            {
                "value": "Costa Rica",
                "label": "Costa Rica"
            },
            {
                "value": "Coxim",
                "label": "Coxim"
            },
            {
                "value": "Deodápolis",
                "label": "Deodápolis"
            },
            {
                "value": "Dois Irmãos do Buriti",
                "label": "Dois Irmãos do Buriti"
            },
            {
                "value": "Douradina",
                "label": "Douradina"
            },
            {
                "value": "Dourados",
                "label": "Dourados"
            },
            {
                "value": "Eldorado",
                "label": "Eldorado"
            },
            {
                "value": "Fátima do Sul",
                "label": "Fátima do Sul"
            },
            {
                "value": "Figueirão",
                "label": "Figueirão"
            },
            {
                "value": "Glória de Dourados",
                "label": "Glória de Dourados"
            },
            {
                "value": "Guia Lopes da Laguna",
                "label": "Guia Lopes da Laguna"
            },
            {
                "value": "Iguatemi",
                "label": "Iguatemi"
            },
            {
                "value": "Inocência",
                "label": "Inocência"
            },
            {
                "value": "Itaporã",
                "label": "Itaporã"
            },
            {
                "value": "Itaquiraí",
                "label": "Itaquiraí"
            },
            {
                "value": "Ivinhema",
                "label": "Ivinhema"
            },
            {
                "value": "Japorã",
                "label": "Japorã"
            },
            {
                "value": "Jaraguari",
                "label": "Jaraguari"
            },
            {
                "value": "Jardim",
                "label": "Jardim"
            },
            {
                "value": "Jateí",
                "label": "Jateí"
            },
            {
                "value": "Juti",
                "label": "Juti"
            },
            {
                "value": "Ladário",
                "label": "Ladário"
            },
            {
                "value": "Laguna Carapã",
                "label": "Laguna Carapã"
            },
            {
                "value": "Maracaju",
                "label": "Maracaju"
            },
            {
                "value": "Miranda",
                "label": "Miranda"
            },
            {
                "value": "Mundo Novo",
                "label": "Mundo Novo"
            },
            {
                "value": "Naviraí",
                "label": "Naviraí"
            },
            {
                "value": "Nioaque",
                "label": "Nioaque"
            },
            {
                "value": "Nova Alvorada do Sul",
                "label": "Nova Alvorada do Sul"
            },
            {
                "value": "Nova Andradina",
                "label": "Nova Andradina"
            },
            {
                "value": "Novo Horizonte do Sul",
                "label": "Novo Horizonte do Sul"
            },
            {
                "value": "Paraíso das Águas",
                "label": "Paraíso das Águas"
            },
            {
                "value": "Paranaíba",
                "label": "Paranaíba"
            },
            {
                "value": "Paranhos",
                "label": "Paranhos"
            },
            {
                "value": "Pedro Gomes",
                "label": "Pedro Gomes"
            },
            {
                "value": "Ponta Porã",
                "label": "Ponta Porã"
            },
            {
                "value": "Porto Murtinho",
                "label": "Porto Murtinho"
            },
            {
                "value": "Ribas do Rio Pardo",
                "label": "Ribas do Rio Pardo"
            },
            {
                "value": "Rio Brilhante",
                "label": "Rio Brilhante"
            },
            {
                "value": "Rio Negro",
                "label": "Rio Negro"
            },
            {
                "value": "Rio Verde de Mato Grosso",
                "label": "Rio Verde de Mato Grosso"
            },
            {
                "value": "Rochedo",
                "label": "Rochedo"
            },
            {
                "value": "Santa Rita do Pardo",
                "label": "Santa Rita do Pardo"
            },
            {
                "value": "São Gabriel do Oeste",
                "label": "São Gabriel do Oeste"
            },
            {
                "value": "Sete Quedas",
                "label": "Sete Quedas"
            },
            {
                "value": "Selvíria",
                "label": "Selvíria"
            },
            {
                "value": "Sidrolândia",
                "label": "Sidrolândia"
            },
            {
                "value": "Sonora",
                "label": "Sonora"
            },
            {
                "value": "Tacuru",
                "label": "Tacuru"
            },
            {
                "value": "Taquarussu",
                "label": "Taquarussu"
            },
            {
                "value": "Terenos",
                "label": "Terenos"
            },
            {
                "value": "Três Lagoas",
                "label": "Três Lagoas"
            },
            {
                "value": "Vicentina",
                "label": "Vicentina"
            }
        ]
    },
    {
        "value": "MT",
        "label": "Mato Grosso",
        "children": [
            {
                "value": "Acorizal",
                "label": "Acorizal"
            },
            {
                "value": "Água Boa",
                "label": "Água Boa"
            },
            {
                "value": "Alta Floresta",
                "label": "Alta Floresta"
            },
            {
                "value": "Alto Araguaia",
                "label": "Alto Araguaia"
            },
            {
                "value": "Alto Boa Vista",
                "label": "Alto Boa Vista"
            },
            {
                "value": "Alto Garças",
                "label": "Alto Garças"
            },
            {
                "value": "Alto Paraguai",
                "label": "Alto Paraguai"
            },
            {
                "value": "Alto Taquari",
                "label": "Alto Taquari"
            },
            {
                "value": "Apiacás",
                "label": "Apiacás"
            },
            {
                "value": "Araguaiana",
                "label": "Araguaiana"
            },
            {
                "value": "Araguainha",
                "label": "Araguainha"
            },
            {
                "value": "Araputanga",
                "label": "Araputanga"
            },
            {
                "value": "Arenápolis",
                "label": "Arenápolis"
            },
            {
                "value": "Aripuanã",
                "label": "Aripuanã"
            },
            {
                "value": "Barão de Melgaço",
                "label": "Barão de Melgaço"
            },
            {
                "value": "Barra do Bugres",
                "label": "Barra do Bugres"
            },
            {
                "value": "Barra do Garças",
                "label": "Barra do Garças"
            },
            {
                "value": "Bom Jesus do Araguaia",
                "label": "Bom Jesus do Araguaia"
            },
            {
                "value": "Brasnorte",
                "label": "Brasnorte"
            },
            {
                "value": "Cáceres",
                "label": "Cáceres"
            },
            {
                "value": "Campinápolis",
                "label": "Campinápolis"
            },
            {
                "value": "Campo Novo do Parecis",
                "label": "Campo Novo do Parecis"
            },
            {
                "value": "Campo Verde",
                "label": "Campo Verde"
            },
            {
                "value": "Campos de Júlio",
                "label": "Campos de Júlio"
            },
            {
                "value": "Canabrava do Norte",
                "label": "Canabrava do Norte"
            },
            {
                "value": "Canarana",
                "label": "Canarana"
            },
            {
                "value": "Carlinda",
                "label": "Carlinda"
            },
            {
                "value": "Castanheira",
                "label": "Castanheira"
            },
            {
                "value": "Chapada dos Guimarães",
                "label": "Chapada dos Guimarães"
            },
            {
                "value": "Cláudia",
                "label": "Cláudia"
            },
            {
                "value": "Cocalinho",
                "label": "Cocalinho"
            },
            {
                "value": "Colíder",
                "label": "Colíder"
            },
            {
                "value": "Colniza",
                "label": "Colniza"
            },
            {
                "value": "Comodoro",
                "label": "Comodoro"
            },
            {
                "value": "Confresa",
                "label": "Confresa"
            },
            {
                "value": "Conquista D'Oeste",
                "label": "Conquista D'Oeste"
            },
            {
                "value": "Cotriguaçu",
                "label": "Cotriguaçu"
            },
            {
                "value": "Cuiabá",
                "label": "Cuiabá"
            },
            {
                "value": "Curvelândia",
                "label": "Curvelândia"
            },
            {
                "value": "Denise",
                "label": "Denise"
            },
            {
                "value": "Diamantino",
                "label": "Diamantino"
            },
            {
                "value": "Dom Aquino",
                "label": "Dom Aquino"
            },
            {
                "value": "Feliz Natal",
                "label": "Feliz Natal"
            },
            {
                "value": "Figueirópolis D'Oeste",
                "label": "Figueirópolis D'Oeste"
            },
            {
                "value": "Gaúcha do Norte",
                "label": "Gaúcha do Norte"
            },
            {
                "value": "General Carneiro",
                "label": "General Carneiro"
            },
            {
                "value": "Glória D'Oeste",
                "label": "Glória D'Oeste"
            },
            {
                "value": "Guarantã do Norte",
                "label": "Guarantã do Norte"
            },
            {
                "value": "Guiratinga",
                "label": "Guiratinga"
            },
            {
                "value": "Indiavaí",
                "label": "Indiavaí"
            },
            {
                "value": "Ipiranga do Norte",
                "label": "Ipiranga do Norte"
            },
            {
                "value": "Itanhangá",
                "label": "Itanhangá"
            },
            {
                "value": "Itaúba",
                "label": "Itaúba"
            },
            {
                "value": "Itiquira",
                "label": "Itiquira"
            },
            {
                "value": "Jaciara",
                "label": "Jaciara"
            },
            {
                "value": "Jangada",
                "label": "Jangada"
            },
            {
                "value": "Jauru",
                "label": "Jauru"
            },
            {
                "value": "Juara",
                "label": "Juara"
            },
            {
                "value": "Juína",
                "label": "Juína"
            },
            {
                "value": "Juruena",
                "label": "Juruena"
            },
            {
                "value": "Juscimeira",
                "label": "Juscimeira"
            },
            {
                "value": "Lambari D'Oeste",
                "label": "Lambari D'Oeste"
            },
            {
                "value": "Lucas do Rio Verde",
                "label": "Lucas do Rio Verde"
            },
            {
                "value": "Luciara",
                "label": "Luciara"
            },
            {
                "value": "Vila Bela da Santíssima Trindade",
                "label": "Vila Bela da Santíssima Trindade"
            },
            {
                "value": "Marcelândia",
                "label": "Marcelândia"
            },
            {
                "value": "Matupá",
                "label": "Matupá"
            },
            {
                "value": "Mirassol d'Oeste",
                "label": "Mirassol d'Oeste"
            },
            {
                "value": "Nobres",
                "label": "Nobres"
            },
            {
                "value": "Nortelândia",
                "label": "Nortelândia"
            },
            {
                "value": "Nossa Senhora do Livramento",
                "label": "Nossa Senhora do Livramento"
            },
            {
                "value": "Nova Bandeirantes",
                "label": "Nova Bandeirantes"
            },
            {
                "value": "Nova Nazaré",
                "label": "Nova Nazaré"
            },
            {
                "value": "Nova Lacerda",
                "label": "Nova Lacerda"
            },
            {
                "value": "Nova Santa Helena",
                "label": "Nova Santa Helena"
            },
            {
                "value": "Nova Brasilândia",
                "label": "Nova Brasilândia"
            },
            {
                "value": "Nova Canaã do Norte",
                "label": "Nova Canaã do Norte"
            },
            {
                "value": "Nova Mutum",
                "label": "Nova Mutum"
            },
            {
                "value": "Nova Olímpia",
                "label": "Nova Olímpia"
            },
            {
                "value": "Nova Ubiratã",
                "label": "Nova Ubiratã"
            },
            {
                "value": "Nova Xavantina",
                "label": "Nova Xavantina"
            },
            {
                "value": "Novo Mundo",
                "label": "Novo Mundo"
            },
            {
                "value": "Novo Horizonte do Norte",
                "label": "Novo Horizonte do Norte"
            },
            {
                "value": "Novo São Joaquim",
                "label": "Novo São Joaquim"
            },
            {
                "value": "Paranaíta",
                "label": "Paranaíta"
            },
            {
                "value": "Paranatinga",
                "label": "Paranatinga"
            },
            {
                "value": "Novo Santo Antônio",
                "label": "Novo Santo Antônio"
            },
            {
                "value": "Pedra Preta",
                "label": "Pedra Preta"
            },
            {
                "value": "Peixoto de Azevedo",
                "label": "Peixoto de Azevedo"
            },
            {
                "value": "Planalto da Serra",
                "label": "Planalto da Serra"
            },
            {
                "value": "Poconé",
                "label": "Poconé"
            },
            {
                "value": "Pontal do Araguaia",
                "label": "Pontal do Araguaia"
            },
            {
                "value": "Ponte Branca",
                "label": "Ponte Branca"
            },
            {
                "value": "Pontes e Lacerda",
                "label": "Pontes e Lacerda"
            },
            {
                "value": "Porto Alegre do Norte",
                "label": "Porto Alegre do Norte"
            },
            {
                "value": "Porto dos Gaúchos",
                "label": "Porto dos Gaúchos"
            },
            {
                "value": "Porto Esperidião",
                "label": "Porto Esperidião"
            },
            {
                "value": "Porto Estrela",
                "label": "Porto Estrela"
            },
            {
                "value": "Poxoréu",
                "label": "Poxoréu"
            },
            {
                "value": "Primavera do Leste",
                "label": "Primavera do Leste"
            },
            {
                "value": "Querência",
                "label": "Querência"
            },
            {
                "value": "São José dos Quatro Marcos",
                "label": "São José dos Quatro Marcos"
            },
            {
                "value": "Reserva do Cabaçal",
                "label": "Reserva do Cabaçal"
            },
            {
                "value": "Ribeirão Cascalheira",
                "label": "Ribeirão Cascalheira"
            },
            {
                "value": "Ribeirãozinho",
                "label": "Ribeirãozinho"
            },
            {
                "value": "Rio Branco",
                "label": "Rio Branco"
            },
            {
                "value": "Santa Carmem",
                "label": "Santa Carmem"
            },
            {
                "value": "Santo Afonso",
                "label": "Santo Afonso"
            },
            {
                "value": "São José do Povo",
                "label": "São José do Povo"
            },
            {
                "value": "São José do Rio Claro",
                "label": "São José do Rio Claro"
            },
            {
                "value": "São José do Xingu",
                "label": "São José do Xingu"
            },
            {
                "value": "São Pedro da Cipa",
                "label": "São Pedro da Cipa"
            },
            {
                "value": "Rondolândia",
                "label": "Rondolândia"
            },
            {
                "value": "Rondonópolis",
                "label": "Rondonópolis"
            },
            {
                "value": "Rosário Oeste",
                "label": "Rosário Oeste"
            },
            {
                "value": "Santa Cruz do Xingu",
                "label": "Santa Cruz do Xingu"
            },
            {
                "value": "Salto do Céu",
                "label": "Salto do Céu"
            },
            {
                "value": "Santa Rita do Trivelato",
                "label": "Santa Rita do Trivelato"
            },
            {
                "value": "Santa Terezinha",
                "label": "Santa Terezinha"
            },
            {
                "value": "Santo Antônio do Leste",
                "label": "Santo Antônio do Leste"
            },
            {
                "value": "Santo Antônio do Leverger",
                "label": "Santo Antônio do Leverger"
            },
            {
                "value": "São Félix do Araguaia",
                "label": "São Félix do Araguaia"
            },
            {
                "value": "Sapezal",
                "label": "Sapezal"
            },
            {
                "value": "Serra Nova Dourada",
                "label": "Serra Nova Dourada"
            },
            {
                "value": "Sinop",
                "label": "Sinop"
            },
            {
                "value": "Sorriso",
                "label": "Sorriso"
            },
            {
                "value": "Tabaporã",
                "label": "Tabaporã"
            },
            {
                "value": "Tangará da Serra",
                "label": "Tangará da Serra"
            },
            {
                "value": "Tapurah",
                "label": "Tapurah"
            },
            {
                "value": "Terra Nova do Norte",
                "label": "Terra Nova do Norte"
            },
            {
                "value": "Tesouro",
                "label": "Tesouro"
            },
            {
                "value": "Torixoréu",
                "label": "Torixoréu"
            },
            {
                "value": "União do Sul",
                "label": "União do Sul"
            },
            {
                "value": "Vale de São Domingos",
                "label": "Vale de São Domingos"
            },
            {
                "value": "Várzea Grande",
                "label": "Várzea Grande"
            },
            {
                "value": "Vera",
                "label": "Vera"
            },
            {
                "value": "Vila Rica",
                "label": "Vila Rica"
            },
            {
                "value": "Nova Guarita",
                "label": "Nova Guarita"
            },
            {
                "value": "Nova Marilândia",
                "label": "Nova Marilândia"
            },
            {
                "value": "Nova Maringá",
                "label": "Nova Maringá"
            },
            {
                "value": "Nova Monte Verde",
                "label": "Nova Monte Verde"
            }
        ]
    },
    {
        "value": "PA",
        "label": "Pará",
        "children": [
            {
                "value": "Abaetetuba",
                "label": "Abaetetuba"
            },
            {
                "value": "Abel Figueiredo",
                "label": "Abel Figueiredo"
            },
            {
                "value": "Acará",
                "label": "Acará"
            },
            {
                "value": "Afuá",
                "label": "Afuá"
            },
            {
                "value": "Água Azul do Norte",
                "label": "Água Azul do Norte"
            },
            {
                "value": "Alenquer",
                "label": "Alenquer"
            },
            {
                "value": "Almeirim",
                "label": "Almeirim"
            },
            {
                "value": "Altamira",
                "label": "Altamira"
            },
            {
                "value": "Anajás",
                "label": "Anajás"
            },
            {
                "value": "Ananindeua",
                "label": "Ananindeua"
            },
            {
                "value": "Anapu",
                "label": "Anapu"
            },
            {
                "value": "Augusto Corrêa",
                "label": "Augusto Corrêa"
            },
            {
                "value": "Aurora do Pará",
                "label": "Aurora do Pará"
            },
            {
                "value": "Aveiro",
                "label": "Aveiro"
            },
            {
                "value": "Bagre",
                "label": "Bagre"
            },
            {
                "value": "Baião",
                "label": "Baião"
            },
            {
                "value": "Bannach",
                "label": "Bannach"
            },
            {
                "value": "Barcarena",
                "label": "Barcarena"
            },
            {
                "value": "Belém",
                "label": "Belém"
            },
            {
                "value": "Belterra",
                "label": "Belterra"
            },
            {
                "value": "Benevides",
                "label": "Benevides"
            },
            {
                "value": "Bom Jesus do Tocantins",
                "label": "Bom Jesus do Tocantins"
            },
            {
                "value": "Bonito",
                "label": "Bonito"
            },
            {
                "value": "Bragança",
                "label": "Bragança"
            },
            {
                "value": "Brasil Novo",
                "label": "Brasil Novo"
            },
            {
                "value": "Brejo Grande do Araguaia",
                "label": "Brejo Grande do Araguaia"
            },
            {
                "value": "Breu Branco",
                "label": "Breu Branco"
            },
            {
                "value": "Breves",
                "label": "Breves"
            },
            {
                "value": "Bujaru",
                "label": "Bujaru"
            },
            {
                "value": "Cachoeira do Piriá",
                "label": "Cachoeira do Piriá"
            },
            {
                "value": "Cachoeira do Arari",
                "label": "Cachoeira do Arari"
            },
            {
                "value": "Cametá",
                "label": "Cametá"
            },
            {
                "value": "Canaã dos Carajás",
                "label": "Canaã dos Carajás"
            },
            {
                "value": "Capanema",
                "label": "Capanema"
            },
            {
                "value": "Capitão Poço",
                "label": "Capitão Poço"
            },
            {
                "value": "Castanhal",
                "label": "Castanhal"
            },
            {
                "value": "Chaves",
                "label": "Chaves"
            },
            {
                "value": "Colares",
                "label": "Colares"
            },
            {
                "value": "Conceição do Araguaia",
                "label": "Conceição do Araguaia"
            },
            {
                "value": "Concórdia do Pará",
                "label": "Concórdia do Pará"
            },
            {
                "value": "Cumaru do Norte",
                "label": "Cumaru do Norte"
            },
            {
                "value": "Curionópolis",
                "label": "Curionópolis"
            },
            {
                "value": "Curralinho",
                "label": "Curralinho"
            },
            {
                "value": "Curuá",
                "label": "Curuá"
            },
            {
                "value": "Curuçá",
                "label": "Curuçá"
            },
            {
                "value": "Dom Eliseu",
                "label": "Dom Eliseu"
            },
            {
                "value": "Eldorado do Carajás",
                "label": "Eldorado do Carajás"
            },
            {
                "value": "Faro",
                "label": "Faro"
            },
            {
                "value": "Floresta do Araguaia",
                "label": "Floresta do Araguaia"
            },
            {
                "value": "Garrafão do Norte",
                "label": "Garrafão do Norte"
            },
            {
                "value": "Goianésia do Pará",
                "label": "Goianésia do Pará"
            },
            {
                "value": "Gurupá",
                "label": "Gurupá"
            },
            {
                "value": "Igarapé-Açu",
                "label": "Igarapé-Açu"
            },
            {
                "value": "Igarapé-Miri",
                "label": "Igarapé-Miri"
            },
            {
                "value": "Inhangapi",
                "label": "Inhangapi"
            },
            {
                "value": "Ipixuna do Pará",
                "label": "Ipixuna do Pará"
            },
            {
                "value": "Irituia",
                "label": "Irituia"
            },
            {
                "value": "Itaituba",
                "label": "Itaituba"
            },
            {
                "value": "Itupiranga",
                "label": "Itupiranga"
            },
            {
                "value": "Jacareacanga",
                "label": "Jacareacanga"
            },
            {
                "value": "Jacundá",
                "label": "Jacundá"
            },
            {
                "value": "Juruti",
                "label": "Juruti"
            },
            {
                "value": "Limoeiro do Ajuru",
                "label": "Limoeiro do Ajuru"
            },
            {
                "value": "Mãe do Rio",
                "label": "Mãe do Rio"
            },
            {
                "value": "Magalhães Barata",
                "label": "Magalhães Barata"
            },
            {
                "value": "Marabá",
                "label": "Marabá"
            },
            {
                "value": "Maracanã",
                "label": "Maracanã"
            },
            {
                "value": "Marapanim",
                "label": "Marapanim"
            },
            {
                "value": "Marituba",
                "label": "Marituba"
            },
            {
                "value": "Medicilândia",
                "label": "Medicilândia"
            },
            {
                "value": "Melgaço",
                "label": "Melgaço"
            },
            {
                "value": "Mocajuba",
                "label": "Mocajuba"
            },
            {
                "value": "Moju",
                "label": "Moju"
            },
            {
                "value": "Mojuí dos Campos",
                "label": "Mojuí dos Campos"
            },
            {
                "value": "Monte Alegre",
                "label": "Monte Alegre"
            },
            {
                "value": "Muaná",
                "label": "Muaná"
            },
            {
                "value": "Nova Esperança do Piriá",
                "label": "Nova Esperança do Piriá"
            },
            {
                "value": "Nova Ipixuna",
                "label": "Nova Ipixuna"
            },
            {
                "value": "Nova Timboteua",
                "label": "Nova Timboteua"
            },
            {
                "value": "Novo Progresso",
                "label": "Novo Progresso"
            },
            {
                "value": "Novo Repartimento",
                "label": "Novo Repartimento"
            },
            {
                "value": "Óbidos",
                "label": "Óbidos"
            },
            {
                "value": "Oeiras do Pará",
                "label": "Oeiras do Pará"
            },
            {
                "value": "Oriximiná",
                "label": "Oriximiná"
            },
            {
                "value": "Ourém",
                "label": "Ourém"
            },
            {
                "value": "Ourilândia do Norte",
                "label": "Ourilândia do Norte"
            },
            {
                "value": "Pacajá",
                "label": "Pacajá"
            },
            {
                "value": "Palestina do Pará",
                "label": "Palestina do Pará"
            },
            {
                "value": "Paragominas",
                "label": "Paragominas"
            },
            {
                "value": "Parauapebas",
                "label": "Parauapebas"
            },
            {
                "value": "Pau D'Arco",
                "label": "Pau D'Arco"
            },
            {
                "value": "Peixe-Boi",
                "label": "Peixe-Boi"
            },
            {
                "value": "Piçarra",
                "label": "Piçarra"
            },
            {
                "value": "Placas",
                "label": "Placas"
            },
            {
                "value": "Ponta de Pedras",
                "label": "Ponta de Pedras"
            },
            {
                "value": "Portel",
                "label": "Portel"
            },
            {
                "value": "Porto de Moz",
                "label": "Porto de Moz"
            },
            {
                "value": "Prainha",
                "label": "Prainha"
            },
            {
                "value": "Primavera",
                "label": "Primavera"
            },
            {
                "value": "Quatipuru",
                "label": "Quatipuru"
            },
            {
                "value": "Redenção",
                "label": "Redenção"
            },
            {
                "value": "Rio Maria",
                "label": "Rio Maria"
            },
            {
                "value": "Rondon do Pará",
                "label": "Rondon do Pará"
            },
            {
                "value": "Rurópolis",
                "label": "Rurópolis"
            },
            {
                "value": "Salinópolis",
                "label": "Salinópolis"
            },
            {
                "value": "Salvaterra",
                "label": "Salvaterra"
            },
            {
                "value": "Santa Bárbara do Pará",
                "label": "Santa Bárbara do Pará"
            },
            {
                "value": "Santa Cruz do Arari",
                "label": "Santa Cruz do Arari"
            },
            {
                "value": "Santa Izabel do Pará",
                "label": "Santa Izabel do Pará"
            },
            {
                "value": "Santa Luzia do Pará",
                "label": "Santa Luzia do Pará"
            },
            {
                "value": "Santa Maria das Barreiras",
                "label": "Santa Maria das Barreiras"
            },
            {
                "value": "Santa Maria do Pará",
                "label": "Santa Maria do Pará"
            },
            {
                "value": "Santana do Araguaia",
                "label": "Santana do Araguaia"
            },
            {
                "value": "Santarém",
                "label": "Santarém"
            },
            {
                "value": "Santarém Novo",
                "label": "Santarém Novo"
            },
            {
                "value": "Santo Antônio do Tauá",
                "label": "Santo Antônio do Tauá"
            },
            {
                "value": "São Caetano de Odivelas",
                "label": "São Caetano de Odivelas"
            },
            {
                "value": "São Domingos do Araguaia",
                "label": "São Domingos do Araguaia"
            },
            {
                "value": "São Domingos do Capim",
                "label": "São Domingos do Capim"
            },
            {
                "value": "São Félix do Xingu",
                "label": "São Félix do Xingu"
            },
            {
                "value": "São Francisco do Pará",
                "label": "São Francisco do Pará"
            },
            {
                "value": "São Geraldo do Araguaia",
                "label": "São Geraldo do Araguaia"
            },
            {
                "value": "São João da Ponta",
                "label": "São João da Ponta"
            },
            {
                "value": "São João de Pirabas",
                "label": "São João de Pirabas"
            },
            {
                "value": "São João do Araguaia",
                "label": "São João do Araguaia"
            },
            {
                "value": "São Miguel do Guamá",
                "label": "São Miguel do Guamá"
            },
            {
                "value": "São Sebastião da Boa Vista",
                "label": "São Sebastião da Boa Vista"
            },
            {
                "value": "Sapucaia",
                "label": "Sapucaia"
            },
            {
                "value": "Senador José Porfírio",
                "label": "Senador José Porfírio"
            },
            {
                "value": "Soure",
                "label": "Soure"
            },
            {
                "value": "Tailândia",
                "label": "Tailândia"
            },
            {
                "value": "Terra Alta",
                "label": "Terra Alta"
            },
            {
                "value": "Terra Santa",
                "label": "Terra Santa"
            },
            {
                "value": "Tomé-Açu",
                "label": "Tomé-Açu"
            },
            {
                "value": "Tracuateua",
                "label": "Tracuateua"
            },
            {
                "value": "Trairão",
                "label": "Trairão"
            },
            {
                "value": "Tucumã",
                "label": "Tucumã"
            },
            {
                "value": "Tucuruí",
                "label": "Tucuruí"
            },
            {
                "value": "Ulianópolis",
                "label": "Ulianópolis"
            },
            {
                "value": "Uruará",
                "label": "Uruará"
            },
            {
                "value": "Vigia",
                "label": "Vigia"
            },
            {
                "value": "Viseu",
                "label": "Viseu"
            },
            {
                "value": "Vitória do Xingu",
                "label": "Vitória do Xingu"
            },
            {
                "value": "Xinguara",
                "label": "Xinguara"
            }
        ]
    },
    {
        "value": "PB",
        "label": "Paraíba",
        "children": [
            {
                "value": "Água Branca",
                "label": "Água Branca"
            },
            {
                "value": "Aguiar",
                "label": "Aguiar"
            },
            {
                "value": "Alagoa Grande",
                "label": "Alagoa Grande"
            },
            {
                "value": "Alagoa Nova",
                "label": "Alagoa Nova"
            },
            {
                "value": "Alagoinha",
                "label": "Alagoinha"
            },
            {
                "value": "Alcantil",
                "label": "Alcantil"
            },
            {
                "value": "Algodão de Jandaíra",
                "label": "Algodão de Jandaíra"
            },
            {
                "value": "Alhandra",
                "label": "Alhandra"
            },
            {
                "value": "São João do Rio do Peixe",
                "label": "São João do Rio do Peixe"
            },
            {
                "value": "Amparo",
                "label": "Amparo"
            },
            {
                "value": "Aparecida",
                "label": "Aparecida"
            },
            {
                "value": "Araçagi",
                "label": "Araçagi"
            },
            {
                "value": "Arara",
                "label": "Arara"
            },
            {
                "value": "Araruna",
                "label": "Araruna"
            },
            {
                "value": "Areia",
                "label": "Areia"
            },
            {
                "value": "Areia de Baraúnas",
                "label": "Areia de Baraúnas"
            },
            {
                "value": "Areial",
                "label": "Areial"
            },
            {
                "value": "Aroeiras",
                "label": "Aroeiras"
            },
            {
                "value": "Assunção",
                "label": "Assunção"
            },
            {
                "value": "Baía da Traição",
                "label": "Baía da Traição"
            },
            {
                "value": "Bananeiras",
                "label": "Bananeiras"
            },
            {
                "value": "Baraúna",
                "label": "Baraúna"
            },
            {
                "value": "Barra de Santana",
                "label": "Barra de Santana"
            },
            {
                "value": "Barra de Santa Rosa",
                "label": "Barra de Santa Rosa"
            },
            {
                "value": "Barra de São Miguel",
                "label": "Barra de São Miguel"
            },
            {
                "value": "Bayeux",
                "label": "Bayeux"
            },
            {
                "value": "Belém",
                "label": "Belém"
            },
            {
                "value": "Belém do Brejo do Cruz",
                "label": "Belém do Brejo do Cruz"
            },
            {
                "value": "Bernardino Batista",
                "label": "Bernardino Batista"
            },
            {
                "value": "Boa Ventura",
                "label": "Boa Ventura"
            },
            {
                "value": "Boa Vista",
                "label": "Boa Vista"
            },
            {
                "value": "Bom Jesus",
                "label": "Bom Jesus"
            },
            {
                "value": "Bom Sucesso",
                "label": "Bom Sucesso"
            },
            {
                "value": "Bonito de Santa Fé",
                "label": "Bonito de Santa Fé"
            },
            {
                "value": "Boqueirão",
                "label": "Boqueirão"
            },
            {
                "value": "Igaracy",
                "label": "Igaracy"
            },
            {
                "value": "Borborema",
                "label": "Borborema"
            },
            {
                "value": "Brejo do Cruz",
                "label": "Brejo do Cruz"
            },
            {
                "value": "Brejo dos Santos",
                "label": "Brejo dos Santos"
            },
            {
                "value": "Caaporã",
                "label": "Caaporã"
            },
            {
                "value": "Cabaceiras",
                "label": "Cabaceiras"
            },
            {
                "value": "Cabedelo",
                "label": "Cabedelo"
            },
            {
                "value": "Cachoeira dos Índios",
                "label": "Cachoeira dos Índios"
            },
            {
                "value": "Cacimba de Areia",
                "label": "Cacimba de Areia"
            },
            {
                "value": "Cacimba de Dentro",
                "label": "Cacimba de Dentro"
            },
            {
                "value": "Cacimbas",
                "label": "Cacimbas"
            },
            {
                "value": "Caiçara",
                "label": "Caiçara"
            },
            {
                "value": "Cajazeiras",
                "label": "Cajazeiras"
            },
            {
                "value": "Cajazeirinhas",
                "label": "Cajazeirinhas"
            },
            {
                "value": "Caldas Brandão",
                "label": "Caldas Brandão"
            },
            {
                "value": "Camalaú",
                "label": "Camalaú"
            },
            {
                "value": "Campina Grande",
                "label": "Campina Grande"
            },
            {
                "value": "Capim",
                "label": "Capim"
            },
            {
                "value": "Caraúbas",
                "label": "Caraúbas"
            },
            {
                "value": "Carrapateira",
                "label": "Carrapateira"
            },
            {
                "value": "Casserengue",
                "label": "Casserengue"
            },
            {
                "value": "Catingueira",
                "label": "Catingueira"
            },
            {
                "value": "Catolé do Rocha",
                "label": "Catolé do Rocha"
            },
            {
                "value": "Caturité",
                "label": "Caturité"
            },
            {
                "value": "Conceição",
                "label": "Conceição"
            },
            {
                "value": "Condado",
                "label": "Condado"
            },
            {
                "value": "Conde",
                "label": "Conde"
            },
            {
                "value": "Congo",
                "label": "Congo"
            },
            {
                "value": "Coremas",
                "label": "Coremas"
            },
            {
                "value": "Coxixola",
                "label": "Coxixola"
            },
            {
                "value": "Cruz do Espírito Santo",
                "label": "Cruz do Espírito Santo"
            },
            {
                "value": "Cubati",
                "label": "Cubati"
            },
            {
                "value": "Cuité",
                "label": "Cuité"
            },
            {
                "value": "Cuitegi",
                "label": "Cuitegi"
            },
            {
                "value": "Cuité de Mamanguape",
                "label": "Cuité de Mamanguape"
            },
            {
                "value": "Curral de Cima",
                "label": "Curral de Cima"
            },
            {
                "value": "Curral Velho",
                "label": "Curral Velho"
            },
            {
                "value": "Damião",
                "label": "Damião"
            },
            {
                "value": "Desterro",
                "label": "Desterro"
            },
            {
                "value": "Vista Serrana",
                "label": "Vista Serrana"
            },
            {
                "value": "Diamante",
                "label": "Diamante"
            },
            {
                "value": "Dona Inês",
                "label": "Dona Inês"
            },
            {
                "value": "Duas Estradas",
                "label": "Duas Estradas"
            },
            {
                "value": "Emas",
                "label": "Emas"
            },
            {
                "value": "Esperança",
                "label": "Esperança"
            },
            {
                "value": "Fagundes",
                "label": "Fagundes"
            },
            {
                "value": "Frei Martinho",
                "label": "Frei Martinho"
            },
            {
                "value": "Gado Bravo",
                "label": "Gado Bravo"
            },
            {
                "value": "Guarabira",
                "label": "Guarabira"
            },
            {
                "value": "Gurinhém",
                "label": "Gurinhém"
            },
            {
                "value": "Gurjão",
                "label": "Gurjão"
            },
            {
                "value": "Ibiara",
                "label": "Ibiara"
            },
            {
                "value": "Imaculada",
                "label": "Imaculada"
            },
            {
                "value": "Ingá",
                "label": "Ingá"
            },
            {
                "value": "Itabaiana",
                "label": "Itabaiana"
            },
            {
                "value": "Itaporanga",
                "label": "Itaporanga"
            },
            {
                "value": "Itapororoca",
                "label": "Itapororoca"
            },
            {
                "value": "Itatuba",
                "label": "Itatuba"
            },
            {
                "value": "Jacaraú",
                "label": "Jacaraú"
            },
            {
                "value": "Jericó",
                "label": "Jericó"
            },
            {
                "value": "João Pessoa",
                "label": "João Pessoa"
            },
            {
                "value": "Juarez Távora",
                "label": "Juarez Távora"
            },
            {
                "value": "Juazeirinho",
                "label": "Juazeirinho"
            },
            {
                "value": "Junco do Seridó",
                "label": "Junco do Seridó"
            },
            {
                "value": "Juripiranga",
                "label": "Juripiranga"
            },
            {
                "value": "Juru",
                "label": "Juru"
            },
            {
                "value": "Lagoa",
                "label": "Lagoa"
            },
            {
                "value": "Lagoa de Dentro",
                "label": "Lagoa de Dentro"
            },
            {
                "value": "Lagoa Seca",
                "label": "Lagoa Seca"
            },
            {
                "value": "Lastro",
                "label": "Lastro"
            },
            {
                "value": "Livramento",
                "label": "Livramento"
            },
            {
                "value": "Logradouro",
                "label": "Logradouro"
            },
            {
                "value": "Lucena",
                "label": "Lucena"
            },
            {
                "value": "Mãe d'Água",
                "label": "Mãe d'Água"
            },
            {
                "value": "Malta",
                "label": "Malta"
            },
            {
                "value": "Mamanguape",
                "label": "Mamanguape"
            },
            {
                "value": "Manaíra",
                "label": "Manaíra"
            },
            {
                "value": "Marcação",
                "label": "Marcação"
            },
            {
                "value": "Mari",
                "label": "Mari"
            },
            {
                "value": "Marizópolis",
                "label": "Marizópolis"
            },
            {
                "value": "Massaranduba",
                "label": "Massaranduba"
            },
            {
                "value": "Mataraca",
                "label": "Mataraca"
            },
            {
                "value": "Matinhas",
                "label": "Matinhas"
            },
            {
                "value": "Mato Grosso",
                "label": "Mato Grosso"
            },
            {
                "value": "Maturéia",
                "label": "Maturéia"
            },
            {
                "value": "Mogeiro",
                "label": "Mogeiro"
            },
            {
                "value": "Montadas",
                "label": "Montadas"
            },
            {
                "value": "Monte Horebe",
                "label": "Monte Horebe"
            },
            {
                "value": "Monteiro",
                "label": "Monteiro"
            },
            {
                "value": "Mulungu",
                "label": "Mulungu"
            },
            {
                "value": "Natuba",
                "label": "Natuba"
            },
            {
                "value": "Nazarezinho",
                "label": "Nazarezinho"
            },
            {
                "value": "Nova Floresta",
                "label": "Nova Floresta"
            },
            {
                "value": "Nova Olinda",
                "label": "Nova Olinda"
            },
            {
                "value": "Nova Palmeira",
                "label": "Nova Palmeira"
            },
            {
                "value": "Olho d'Água",
                "label": "Olho d'Água"
            },
            {
                "value": "Olivedos",
                "label": "Olivedos"
            },
            {
                "value": "Ouro Velho",
                "label": "Ouro Velho"
            },
            {
                "value": "Parari",
                "label": "Parari"
            },
            {
                "value": "Passagem",
                "label": "Passagem"
            },
            {
                "value": "Patos",
                "label": "Patos"
            },
            {
                "value": "Paulista",
                "label": "Paulista"
            },
            {
                "value": "Pedra Branca",
                "label": "Pedra Branca"
            },
            {
                "value": "Pedra Lavrada",
                "label": "Pedra Lavrada"
            },
            {
                "value": "Pedras de Fogo",
                "label": "Pedras de Fogo"
            },
            {
                "value": "Piancó",
                "label": "Piancó"
            },
            {
                "value": "Picuí",
                "label": "Picuí"
            },
            {
                "value": "Pilar",
                "label": "Pilar"
            },
            {
                "value": "Pilões",
                "label": "Pilões"
            },
            {
                "value": "Pilõezinhos",
                "label": "Pilõezinhos"
            },
            {
                "value": "Pirpirituba",
                "label": "Pirpirituba"
            },
            {
                "value": "Pitimbu",
                "label": "Pitimbu"
            },
            {
                "value": "Pocinhos",
                "label": "Pocinhos"
            },
            {
                "value": "Poço Dantas",
                "label": "Poço Dantas"
            },
            {
                "value": "Poço de José de Moura",
                "label": "Poço de José de Moura"
            },
            {
                "value": "Pombal",
                "label": "Pombal"
            },
            {
                "value": "Prata",
                "label": "Prata"
            },
            {
                "value": "Princesa Isabel",
                "label": "Princesa Isabel"
            },
            {
                "value": "Puxinanã",
                "label": "Puxinanã"
            },
            {
                "value": "Queimadas",
                "label": "Queimadas"
            },
            {
                "value": "Quixaba",
                "label": "Quixaba"
            },
            {
                "value": "Remígio",
                "label": "Remígio"
            },
            {
                "value": "Pedro Régis",
                "label": "Pedro Régis"
            },
            {
                "value": "Riachão",
                "label": "Riachão"
            },
            {
                "value": "Riachão do Bacamarte",
                "label": "Riachão do Bacamarte"
            },
            {
                "value": "Riachão do Poço",
                "label": "Riachão do Poço"
            },
            {
                "value": "Riacho de Santo Antônio",
                "label": "Riacho de Santo Antônio"
            },
            {
                "value": "Riacho dos Cavalos",
                "label": "Riacho dos Cavalos"
            },
            {
                "value": "Rio Tinto",
                "label": "Rio Tinto"
            },
            {
                "value": "Salgadinho",
                "label": "Salgadinho"
            },
            {
                "value": "Salgado de São Félix",
                "label": "Salgado de São Félix"
            },
            {
                "value": "Santa Cecília",
                "label": "Santa Cecília"
            },
            {
                "value": "Santa Cruz",
                "label": "Santa Cruz"
            },
            {
                "value": "Santa Helena",
                "label": "Santa Helena"
            },
            {
                "value": "Santa Inês",
                "label": "Santa Inês"
            },
            {
                "value": "Santa Luzia",
                "label": "Santa Luzia"
            },
            {
                "value": "Santana de Mangueira",
                "label": "Santana de Mangueira"
            },
            {
                "value": "Santana dos Garrotes",
                "label": "Santana dos Garrotes"
            },
            {
                "value": "Joca Claudino",
                "label": "Joca Claudino"
            },
            {
                "value": "Santa Rita",
                "label": "Santa Rita"
            },
            {
                "value": "Santa Teresinha",
                "label": "Santa Teresinha"
            },
            {
                "value": "Santo André",
                "label": "Santo André"
            },
            {
                "value": "São Bento",
                "label": "São Bento"
            },
            {
                "value": "São Bentinho",
                "label": "São Bentinho"
            },
            {
                "value": "São Domingos do Cariri",
                "label": "São Domingos do Cariri"
            },
            {
                "value": "São Domingos",
                "label": "São Domingos"
            },
            {
                "value": "São Francisco",
                "label": "São Francisco"
            },
            {
                "value": "São João do Cariri",
                "label": "São João do Cariri"
            },
            {
                "value": "São João do Tigre",
                "label": "São João do Tigre"
            },
            {
                "value": "São José da Lagoa Tapada",
                "label": "São José da Lagoa Tapada"
            },
            {
                "value": "São José de Caiana",
                "label": "São José de Caiana"
            },
            {
                "value": "São José de Espinharas",
                "label": "São José de Espinharas"
            },
            {
                "value": "São José dos Ramos",
                "label": "São José dos Ramos"
            },
            {
                "value": "São José de Piranhas",
                "label": "São José de Piranhas"
            },
            {
                "value": "São José de Princesa",
                "label": "São José de Princesa"
            },
            {
                "value": "São José do Bonfim",
                "label": "São José do Bonfim"
            },
            {
                "value": "São José do Brejo do Cruz",
                "label": "São José do Brejo do Cruz"
            },
            {
                "value": "São José do Sabugi",
                "label": "São José do Sabugi"
            },
            {
                "value": "São José dos Cordeiros",
                "label": "São José dos Cordeiros"
            },
            {
                "value": "São Mamede",
                "label": "São Mamede"
            },
            {
                "value": "São Miguel de Taipu",
                "label": "São Miguel de Taipu"
            },
            {
                "value": "São Sebastião de Lagoa de Roça",
                "label": "São Sebastião de Lagoa de Roça"
            },
            {
                "value": "São Sebastião do Umbuzeiro",
                "label": "São Sebastião do Umbuzeiro"
            },
            {
                "value": "Sapé",
                "label": "Sapé"
            },
            {
                "value": "São Vicente do Seridó",
                "label": "São Vicente do Seridó"
            },
            {
                "value": "Serra Branca",
                "label": "Serra Branca"
            },
            {
                "value": "Serra da Raiz",
                "label": "Serra da Raiz"
            },
            {
                "value": "Serra Grande",
                "label": "Serra Grande"
            },
            {
                "value": "Serra Redonda",
                "label": "Serra Redonda"
            },
            {
                "value": "Serraria",
                "label": "Serraria"
            },
            {
                "value": "Sertãozinho",
                "label": "Sertãozinho"
            },
            {
                "value": "Sobrado",
                "label": "Sobrado"
            },
            {
                "value": "Solânea",
                "label": "Solânea"
            },
            {
                "value": "Soledade",
                "label": "Soledade"
            },
            {
                "value": "Sossêgo",
                "label": "Sossêgo"
            },
            {
                "value": "Sousa",
                "label": "Sousa"
            },
            {
                "value": "Sumé",
                "label": "Sumé"
            },
            {
                "value": "Tacima",
                "label": "Tacima"
            },
            {
                "value": "Taperoá",
                "label": "Taperoá"
            },
            {
                "value": "Tavares",
                "label": "Tavares"
            },
            {
                "value": "Teixeira",
                "label": "Teixeira"
            },
            {
                "value": "Tenório",
                "label": "Tenório"
            },
            {
                "value": "Triunfo",
                "label": "Triunfo"
            },
            {
                "value": "Uiraúna",
                "label": "Uiraúna"
            },
            {
                "value": "Umbuzeiro",
                "label": "Umbuzeiro"
            },
            {
                "value": "Várzea",
                "label": "Várzea"
            },
            {
                "value": "Vieirópolis",
                "label": "Vieirópolis"
            },
            {
                "value": "Zabelê",
                "label": "Zabelê"
            }
        ]
    },
    {
        "value": "PE",
        "label": "Pernambuco",
        "children": [
            {
                "value": "Abreu e Lima",
                "label": "Abreu e Lima"
            },
            {
                "value": "Afogados da Ingazeira",
                "label": "Afogados da Ingazeira"
            },
            {
                "value": "Afrânio",
                "label": "Afrânio"
            },
            {
                "value": "Agrestina",
                "label": "Agrestina"
            },
            {
                "value": "Água Preta",
                "label": "Água Preta"
            },
            {
                "value": "Águas Belas",
                "label": "Águas Belas"
            },
            {
                "value": "Alagoinha",
                "label": "Alagoinha"
            },
            {
                "value": "Aliança",
                "label": "Aliança"
            },
            {
                "value": "Altinho",
                "label": "Altinho"
            },
            {
                "value": "Amaraji",
                "label": "Amaraji"
            },
            {
                "value": "Angelim",
                "label": "Angelim"
            },
            {
                "value": "Araçoiaba",
                "label": "Araçoiaba"
            },
            {
                "value": "Araripina",
                "label": "Araripina"
            },
            {
                "value": "Arcoverde",
                "label": "Arcoverde"
            },
            {
                "value": "Barra de Guabiraba",
                "label": "Barra de Guabiraba"
            },
            {
                "value": "Barreiros",
                "label": "Barreiros"
            },
            {
                "value": "Belém de Maria",
                "label": "Belém de Maria"
            },
            {
                "value": "Belém do São Francisco",
                "label": "Belém do São Francisco"
            },
            {
                "value": "Belo Jardim",
                "label": "Belo Jardim"
            },
            {
                "value": "Betânia",
                "label": "Betânia"
            },
            {
                "value": "Bezerros",
                "label": "Bezerros"
            },
            {
                "value": "Bodocó",
                "label": "Bodocó"
            },
            {
                "value": "Bom Conselho",
                "label": "Bom Conselho"
            },
            {
                "value": "Bom Jardim",
                "label": "Bom Jardim"
            },
            {
                "value": "Bonito",
                "label": "Bonito"
            },
            {
                "value": "Brejão",
                "label": "Brejão"
            },
            {
                "value": "Brejinho",
                "label": "Brejinho"
            },
            {
                "value": "Brejo da Madre de Deus",
                "label": "Brejo da Madre de Deus"
            },
            {
                "value": "Buenos Aires",
                "label": "Buenos Aires"
            },
            {
                "value": "Buíque",
                "label": "Buíque"
            },
            {
                "value": "Cabo de Santo Agostinho",
                "label": "Cabo de Santo Agostinho"
            },
            {
                "value": "Cabrobó",
                "label": "Cabrobó"
            },
            {
                "value": "Cachoeirinha",
                "label": "Cachoeirinha"
            },
            {
                "value": "Caetés",
                "label": "Caetés"
            },
            {
                "value": "Calçado",
                "label": "Calçado"
            },
            {
                "value": "Calumbi",
                "label": "Calumbi"
            },
            {
                "value": "Camaragibe",
                "label": "Camaragibe"
            },
            {
                "value": "Camocim de São Félix",
                "label": "Camocim de São Félix"
            },
            {
                "value": "Camutanga",
                "label": "Camutanga"
            },
            {
                "value": "Canhotinho",
                "label": "Canhotinho"
            },
            {
                "value": "Capoeiras",
                "label": "Capoeiras"
            },
            {
                "value": "Carnaíba",
                "label": "Carnaíba"
            },
            {
                "value": "Carnaubeira da Penha",
                "label": "Carnaubeira da Penha"
            },
            {
                "value": "Carpina",
                "label": "Carpina"
            },
            {
                "value": "Caruaru",
                "label": "Caruaru"
            },
            {
                "value": "Casinhas",
                "label": "Casinhas"
            },
            {
                "value": "Catende",
                "label": "Catende"
            },
            {
                "value": "Cedro",
                "label": "Cedro"
            },
            {
                "value": "Chã de Alegria",
                "label": "Chã de Alegria"
            },
            {
                "value": "Chã Grande",
                "label": "Chã Grande"
            },
            {
                "value": "Condado",
                "label": "Condado"
            },
            {
                "value": "Correntes",
                "label": "Correntes"
            },
            {
                "value": "Cortês",
                "label": "Cortês"
            },
            {
                "value": "Cumaru",
                "label": "Cumaru"
            },
            {
                "value": "Cupira",
                "label": "Cupira"
            },
            {
                "value": "Custódia",
                "label": "Custódia"
            },
            {
                "value": "Dormentes",
                "label": "Dormentes"
            },
            {
                "value": "Escada",
                "label": "Escada"
            },
            {
                "value": "Exu",
                "label": "Exu"
            },
            {
                "value": "Feira Nova",
                "label": "Feira Nova"
            },
            {
                "value": "Fernando de Noronha",
                "label": "Fernando de Noronha"
            },
            {
                "value": "Ferreiros",
                "label": "Ferreiros"
            },
            {
                "value": "Flores",
                "label": "Flores"
            },
            {
                "value": "Floresta",
                "label": "Floresta"
            },
            {
                "value": "Frei Miguelinho",
                "label": "Frei Miguelinho"
            },
            {
                "value": "Gameleira",
                "label": "Gameleira"
            },
            {
                "value": "Garanhuns",
                "label": "Garanhuns"
            },
            {
                "value": "Glória do Goitá",
                "label": "Glória do Goitá"
            },
            {
                "value": "Goiana",
                "label": "Goiana"
            },
            {
                "value": "Granito",
                "label": "Granito"
            },
            {
                "value": "Gravatá",
                "label": "Gravatá"
            },
            {
                "value": "Iati",
                "label": "Iati"
            },
            {
                "value": "Ibimirim",
                "label": "Ibimirim"
            },
            {
                "value": "Ibirajuba",
                "label": "Ibirajuba"
            },
            {
                "value": "Igarassu",
                "label": "Igarassu"
            },
            {
                "value": "Iguaracy",
                "label": "Iguaracy"
            },
            {
                "value": "Inajá",
                "label": "Inajá"
            },
            {
                "value": "Ingazeira",
                "label": "Ingazeira"
            },
            {
                "value": "Ipojuca",
                "label": "Ipojuca"
            },
            {
                "value": "Ipubi",
                "label": "Ipubi"
            },
            {
                "value": "Itacuruba",
                "label": "Itacuruba"
            },
            {
                "value": "Itaíba",
                "label": "Itaíba"
            },
            {
                "value": "Ilha de Itamaracá",
                "label": "Ilha de Itamaracá"
            },
            {
                "value": "Itambé",
                "label": "Itambé"
            },
            {
                "value": "Itapetim",
                "label": "Itapetim"
            },
            {
                "value": "Itapissuma",
                "label": "Itapissuma"
            },
            {
                "value": "Itaquitinga",
                "label": "Itaquitinga"
            },
            {
                "value": "Jaboatão dos Guararapes",
                "label": "Jaboatão dos Guararapes"
            },
            {
                "value": "Jaqueira",
                "label": "Jaqueira"
            },
            {
                "value": "Jataúba",
                "label": "Jataúba"
            },
            {
                "value": "Jatobá",
                "label": "Jatobá"
            },
            {
                "value": "João Alfredo",
                "label": "João Alfredo"
            },
            {
                "value": "Joaquim Nabuco",
                "label": "Joaquim Nabuco"
            },
            {
                "value": "Jucati",
                "label": "Jucati"
            },
            {
                "value": "Jupi",
                "label": "Jupi"
            },
            {
                "value": "Jurema",
                "label": "Jurema"
            },
            {
                "value": "Lagoa do Carro",
                "label": "Lagoa do Carro"
            },
            {
                "value": "Lagoa de Itaenga",
                "label": "Lagoa de Itaenga"
            },
            {
                "value": "Lagoa do Ouro",
                "label": "Lagoa do Ouro"
            },
            {
                "value": "Lagoa dos Gatos",
                "label": "Lagoa dos Gatos"
            },
            {
                "value": "Lagoa Grande",
                "label": "Lagoa Grande"
            },
            {
                "value": "Lajedo",
                "label": "Lajedo"
            },
            {
                "value": "Limoeiro",
                "label": "Limoeiro"
            },
            {
                "value": "Macaparana",
                "label": "Macaparana"
            },
            {
                "value": "Machados",
                "label": "Machados"
            },
            {
                "value": "Manari",
                "label": "Manari"
            },
            {
                "value": "Maraial",
                "label": "Maraial"
            },
            {
                "value": "Mirandiba",
                "label": "Mirandiba"
            },
            {
                "value": "Moreno",
                "label": "Moreno"
            },
            {
                "value": "Nazaré da Mata",
                "label": "Nazaré da Mata"
            },
            {
                "value": "Olinda",
                "label": "Olinda"
            },
            {
                "value": "Orobó",
                "label": "Orobó"
            },
            {
                "value": "Orocó",
                "label": "Orocó"
            },
            {
                "value": "Ouricuri",
                "label": "Ouricuri"
            },
            {
                "value": "Palmares",
                "label": "Palmares"
            },
            {
                "value": "Palmeirina",
                "label": "Palmeirina"
            },
            {
                "value": "Panelas",
                "label": "Panelas"
            },
            {
                "value": "Paranatama",
                "label": "Paranatama"
            },
            {
                "value": "Parnamirim",
                "label": "Parnamirim"
            },
            {
                "value": "Passira",
                "label": "Passira"
            },
            {
                "value": "Paudalho",
                "label": "Paudalho"
            },
            {
                "value": "Paulista",
                "label": "Paulista"
            },
            {
                "value": "Pedra",
                "label": "Pedra"
            },
            {
                "value": "Pesqueira",
                "label": "Pesqueira"
            },
            {
                "value": "Petrolândia",
                "label": "Petrolândia"
            },
            {
                "value": "Petrolina",
                "label": "Petrolina"
            },
            {
                "value": "Poção",
                "label": "Poção"
            },
            {
                "value": "Pombos",
                "label": "Pombos"
            },
            {
                "value": "Primavera",
                "label": "Primavera"
            },
            {
                "value": "Quipapá",
                "label": "Quipapá"
            },
            {
                "value": "Quixaba",
                "label": "Quixaba"
            },
            {
                "value": "Recife",
                "label": "Recife"
            },
            {
                "value": "Riacho das Almas",
                "label": "Riacho das Almas"
            },
            {
                "value": "Ribeirão",
                "label": "Ribeirão"
            },
            {
                "value": "Rio Formoso",
                "label": "Rio Formoso"
            },
            {
                "value": "Sairé",
                "label": "Sairé"
            },
            {
                "value": "Salgadinho",
                "label": "Salgadinho"
            },
            {
                "value": "Salgueiro",
                "label": "Salgueiro"
            },
            {
                "value": "Saloá",
                "label": "Saloá"
            },
            {
                "value": "Sanharó",
                "label": "Sanharó"
            },
            {
                "value": "Santa Cruz",
                "label": "Santa Cruz"
            },
            {
                "value": "Santa Cruz da Baixa Verde",
                "label": "Santa Cruz da Baixa Verde"
            },
            {
                "value": "Santa Cruz do Capibaribe",
                "label": "Santa Cruz do Capibaribe"
            },
            {
                "value": "Santa Filomena",
                "label": "Santa Filomena"
            },
            {
                "value": "Santa Maria da Boa Vista",
                "label": "Santa Maria da Boa Vista"
            },
            {
                "value": "Santa Maria do Cambucá",
                "label": "Santa Maria do Cambucá"
            },
            {
                "value": "Santa Terezinha",
                "label": "Santa Terezinha"
            },
            {
                "value": "São Benedito do Sul",
                "label": "São Benedito do Sul"
            },
            {
                "value": "São Bento do Una",
                "label": "São Bento do Una"
            },
            {
                "value": "São Caitano",
                "label": "São Caitano"
            },
            {
                "value": "São João",
                "label": "São João"
            },
            {
                "value": "São Joaquim do Monte",
                "label": "São Joaquim do Monte"
            },
            {
                "value": "São José da Coroa Grande",
                "label": "São José da Coroa Grande"
            },
            {
                "value": "São José do Belmonte",
                "label": "São José do Belmonte"
            },
            {
                "value": "São José do Egito",
                "label": "São José do Egito"
            },
            {
                "value": "São Lourenço da Mata",
                "label": "São Lourenço da Mata"
            },
            {
                "value": "São Vicente Férrer",
                "label": "São Vicente Férrer"
            },
            {
                "value": "Serra Talhada",
                "label": "Serra Talhada"
            },
            {
                "value": "Serrita",
                "label": "Serrita"
            },
            {
                "value": "Sertânia",
                "label": "Sertânia"
            },
            {
                "value": "Sirinhaém",
                "label": "Sirinhaém"
            },
            {
                "value": "Moreilândia",
                "label": "Moreilândia"
            },
            {
                "value": "Solidão",
                "label": "Solidão"
            },
            {
                "value": "Surubim",
                "label": "Surubim"
            },
            {
                "value": "Tabira",
                "label": "Tabira"
            },
            {
                "value": "Tacaimbó",
                "label": "Tacaimbó"
            },
            {
                "value": "Tacaratu",
                "label": "Tacaratu"
            },
            {
                "value": "Tamandaré",
                "label": "Tamandaré"
            },
            {
                "value": "Taquaritinga do Norte",
                "label": "Taquaritinga do Norte"
            },
            {
                "value": "Terezinha",
                "label": "Terezinha"
            },
            {
                "value": "Terra Nova",
                "label": "Terra Nova"
            },
            {
                "value": "Timbaúba",
                "label": "Timbaúba"
            },
            {
                "value": "Toritama",
                "label": "Toritama"
            },
            {
                "value": "Tracunhaém",
                "label": "Tracunhaém"
            },
            {
                "value": "Trindade",
                "label": "Trindade"
            },
            {
                "value": "Triunfo",
                "label": "Triunfo"
            },
            {
                "value": "Tupanatinga",
                "label": "Tupanatinga"
            },
            {
                "value": "Tuparetama",
                "label": "Tuparetama"
            },
            {
                "value": "Venturosa",
                "label": "Venturosa"
            },
            {
                "value": "Verdejante",
                "label": "Verdejante"
            },
            {
                "value": "Vertente do Lério",
                "label": "Vertente do Lério"
            },
            {
                "value": "Vertentes",
                "label": "Vertentes"
            },
            {
                "value": "Vicência",
                "label": "Vicência"
            },
            {
                "value": "Vitória de Santo Antão",
                "label": "Vitória de Santo Antão"
            },
            {
                "value": "Xexéu",
                "label": "Xexéu"
            }
        ]
    },
    {
        "value": "PI",
        "label": "Piauí",
        "children": [
            {
                "value": "Acauã",
                "label": "Acauã"
            },
            {
                "value": "Agricolândia",
                "label": "Agricolândia"
            },
            {
                "value": "Água Branca",
                "label": "Água Branca"
            },
            {
                "value": "Alagoinha do Piauí",
                "label": "Alagoinha do Piauí"
            },
            {
                "value": "Alegrete do Piauí",
                "label": "Alegrete do Piauí"
            },
            {
                "value": "Alto Longá",
                "label": "Alto Longá"
            },
            {
                "value": "Altos",
                "label": "Altos"
            },
            {
                "value": "Alvorada do Gurguéia",
                "label": "Alvorada do Gurguéia"
            },
            {
                "value": "Amarante",
                "label": "Amarante"
            },
            {
                "value": "Angical do Piauí",
                "label": "Angical do Piauí"
            },
            {
                "value": "Anísio de Abreu",
                "label": "Anísio de Abreu"
            },
            {
                "value": "Antônio Almeida",
                "label": "Antônio Almeida"
            },
            {
                "value": "Aroazes",
                "label": "Aroazes"
            },
            {
                "value": "Aroeiras do Itaim",
                "label": "Aroeiras do Itaim"
            },
            {
                "value": "Arraial",
                "label": "Arraial"
            },
            {
                "value": "Assunção do Piauí",
                "label": "Assunção do Piauí"
            },
            {
                "value": "Avelino Lopes",
                "label": "Avelino Lopes"
            },
            {
                "value": "Baixa Grande do Ribeiro",
                "label": "Baixa Grande do Ribeiro"
            },
            {
                "value": "Barra D'Alcântara",
                "label": "Barra D'Alcântara"
            },
            {
                "value": "Barras",
                "label": "Barras"
            },
            {
                "value": "Barreiras do Piauí",
                "label": "Barreiras do Piauí"
            },
            {
                "value": "Barro Duro",
                "label": "Barro Duro"
            },
            {
                "value": "Batalha",
                "label": "Batalha"
            },
            {
                "value": "Bela Vista do Piauí",
                "label": "Bela Vista do Piauí"
            },
            {
                "value": "Belém do Piauí",
                "label": "Belém do Piauí"
            },
            {
                "value": "Beneditinos",
                "label": "Beneditinos"
            },
            {
                "value": "Bertolínia",
                "label": "Bertolínia"
            },
            {
                "value": "Betânia do Piauí",
                "label": "Betânia do Piauí"
            },
            {
                "value": "Boa Hora",
                "label": "Boa Hora"
            },
            {
                "value": "Bocaina",
                "label": "Bocaina"
            },
            {
                "value": "Bom Jesus",
                "label": "Bom Jesus"
            },
            {
                "value": "Bom Princípio do Piauí",
                "label": "Bom Princípio do Piauí"
            },
            {
                "value": "Bonfim do Piauí",
                "label": "Bonfim do Piauí"
            },
            {
                "value": "Boqueirão do Piauí",
                "label": "Boqueirão do Piauí"
            },
            {
                "value": "Brasileira",
                "label": "Brasileira"
            },
            {
                "value": "Brejo do Piauí",
                "label": "Brejo do Piauí"
            },
            {
                "value": "Buriti dos Lopes",
                "label": "Buriti dos Lopes"
            },
            {
                "value": "Buriti dos Montes",
                "label": "Buriti dos Montes"
            },
            {
                "value": "Cabeceiras do Piauí",
                "label": "Cabeceiras do Piauí"
            },
            {
                "value": "Cajazeiras do Piauí",
                "label": "Cajazeiras do Piauí"
            },
            {
                "value": "Cajueiro da Praia",
                "label": "Cajueiro da Praia"
            },
            {
                "value": "Caldeirão Grande do Piauí",
                "label": "Caldeirão Grande do Piauí"
            },
            {
                "value": "Campinas do Piauí",
                "label": "Campinas do Piauí"
            },
            {
                "value": "Campo Alegre do Fidalgo",
                "label": "Campo Alegre do Fidalgo"
            },
            {
                "value": "Campo Grande do Piauí",
                "label": "Campo Grande do Piauí"
            },
            {
                "value": "Campo Largo do Piauí",
                "label": "Campo Largo do Piauí"
            },
            {
                "value": "Campo Maior",
                "label": "Campo Maior"
            },
            {
                "value": "Canavieira",
                "label": "Canavieira"
            },
            {
                "value": "Canto do Buriti",
                "label": "Canto do Buriti"
            },
            {
                "value": "Capitão de Campos",
                "label": "Capitão de Campos"
            },
            {
                "value": "Capitão Gervásio Oliveira",
                "label": "Capitão Gervásio Oliveira"
            },
            {
                "value": "Caracol",
                "label": "Caracol"
            },
            {
                "value": "Caraúbas do Piauí",
                "label": "Caraúbas do Piauí"
            },
            {
                "value": "Caridade do Piauí",
                "label": "Caridade do Piauí"
            },
            {
                "value": "Castelo do Piauí",
                "label": "Castelo do Piauí"
            },
            {
                "value": "Caxingó",
                "label": "Caxingó"
            },
            {
                "value": "Cocal",
                "label": "Cocal"
            },
            {
                "value": "Cocal de Telha",
                "label": "Cocal de Telha"
            },
            {
                "value": "Cocal dos Alves",
                "label": "Cocal dos Alves"
            },
            {
                "value": "Coivaras",
                "label": "Coivaras"
            },
            {
                "value": "Colônia do Gurguéia",
                "label": "Colônia do Gurguéia"
            },
            {
                "value": "Colônia do Piauí",
                "label": "Colônia do Piauí"
            },
            {
                "value": "Conceição do Canindé",
                "label": "Conceição do Canindé"
            },
            {
                "value": "Coronel José Dias",
                "label": "Coronel José Dias"
            },
            {
                "value": "Corrente",
                "label": "Corrente"
            },
            {
                "value": "Cristalândia do Piauí",
                "label": "Cristalândia do Piauí"
            },
            {
                "value": "Cristino Castro",
                "label": "Cristino Castro"
            },
            {
                "value": "Curimatá",
                "label": "Curimatá"
            },
            {
                "value": "Currais",
                "label": "Currais"
            },
            {
                "value": "Curralinhos",
                "label": "Curralinhos"
            },
            {
                "value": "Curral Novo do Piauí",
                "label": "Curral Novo do Piauí"
            },
            {
                "value": "Demerval Lobão",
                "label": "Demerval Lobão"
            },
            {
                "value": "Dirceu Arcoverde",
                "label": "Dirceu Arcoverde"
            },
            {
                "value": "Dom Expedito Lopes",
                "label": "Dom Expedito Lopes"
            },
            {
                "value": "Domingos Mourão",
                "label": "Domingos Mourão"
            },
            {
                "value": "Dom Inocêncio",
                "label": "Dom Inocêncio"
            },
            {
                "value": "Elesbão Veloso",
                "label": "Elesbão Veloso"
            },
            {
                "value": "Eliseu Martins",
                "label": "Eliseu Martins"
            },
            {
                "value": "Esperantina",
                "label": "Esperantina"
            },
            {
                "value": "Fartura do Piauí",
                "label": "Fartura do Piauí"
            },
            {
                "value": "Flores do Piauí",
                "label": "Flores do Piauí"
            },
            {
                "value": "Floresta do Piauí",
                "label": "Floresta do Piauí"
            },
            {
                "value": "Floriano",
                "label": "Floriano"
            },
            {
                "value": "Francinópolis",
                "label": "Francinópolis"
            },
            {
                "value": "Francisco Ayres",
                "label": "Francisco Ayres"
            },
            {
                "value": "Francisco Macedo",
                "label": "Francisco Macedo"
            },
            {
                "value": "Francisco Santos",
                "label": "Francisco Santos"
            },
            {
                "value": "Fronteiras",
                "label": "Fronteiras"
            },
            {
                "value": "Geminiano",
                "label": "Geminiano"
            },
            {
                "value": "Gilbués",
                "label": "Gilbués"
            },
            {
                "value": "Guadalupe",
                "label": "Guadalupe"
            },
            {
                "value": "Guaribas",
                "label": "Guaribas"
            },
            {
                "value": "Hugo Napoleão",
                "label": "Hugo Napoleão"
            },
            {
                "value": "Ilha Grande",
                "label": "Ilha Grande"
            },
            {
                "value": "Inhuma",
                "label": "Inhuma"
            },
            {
                "value": "Ipiranga do Piauí",
                "label": "Ipiranga do Piauí"
            },
            {
                "value": "Isaías Coelho",
                "label": "Isaías Coelho"
            },
            {
                "value": "Itainópolis",
                "label": "Itainópolis"
            },
            {
                "value": "Itaueira",
                "label": "Itaueira"
            },
            {
                "value": "Jacobina do Piauí",
                "label": "Jacobina do Piauí"
            },
            {
                "value": "Jaicós",
                "label": "Jaicós"
            },
            {
                "value": "Jardim do Mulato",
                "label": "Jardim do Mulato"
            },
            {
                "value": "Jatobá do Piauí",
                "label": "Jatobá do Piauí"
            },
            {
                "value": "Jerumenha",
                "label": "Jerumenha"
            },
            {
                "value": "João Costa",
                "label": "João Costa"
            },
            {
                "value": "Joaquim Pires",
                "label": "Joaquim Pires"
            },
            {
                "value": "Joca Marques",
                "label": "Joca Marques"
            },
            {
                "value": "José de Freitas",
                "label": "José de Freitas"
            },
            {
                "value": "Juazeiro do Piauí",
                "label": "Juazeiro do Piauí"
            },
            {
                "value": "Júlio Borges",
                "label": "Júlio Borges"
            },
            {
                "value": "Jurema",
                "label": "Jurema"
            },
            {
                "value": "Lagoinha do Piauí",
                "label": "Lagoinha do Piauí"
            },
            {
                "value": "Lagoa Alegre",
                "label": "Lagoa Alegre"
            },
            {
                "value": "Lagoa do Barro do Piauí",
                "label": "Lagoa do Barro do Piauí"
            },
            {
                "value": "Lagoa de São Francisco",
                "label": "Lagoa de São Francisco"
            },
            {
                "value": "Lagoa do Piauí",
                "label": "Lagoa do Piauí"
            },
            {
                "value": "Lagoa do Sítio",
                "label": "Lagoa do Sítio"
            },
            {
                "value": "Landri Sales",
                "label": "Landri Sales"
            },
            {
                "value": "Luís Correia",
                "label": "Luís Correia"
            },
            {
                "value": "Luzilândia",
                "label": "Luzilândia"
            },
            {
                "value": "Madeiro",
                "label": "Madeiro"
            },
            {
                "value": "Manoel Emídio",
                "label": "Manoel Emídio"
            },
            {
                "value": "Marcolândia",
                "label": "Marcolândia"
            },
            {
                "value": "Marcos Parente",
                "label": "Marcos Parente"
            },
            {
                "value": "Massapê do Piauí",
                "label": "Massapê do Piauí"
            },
            {
                "value": "Matias Olímpio",
                "label": "Matias Olímpio"
            },
            {
                "value": "Miguel Alves",
                "label": "Miguel Alves"
            },
            {
                "value": "Miguel Leão",
                "label": "Miguel Leão"
            },
            {
                "value": "Milton Brandão",
                "label": "Milton Brandão"
            },
            {
                "value": "Monsenhor Gil",
                "label": "Monsenhor Gil"
            },
            {
                "value": "Monsenhor Hipólito",
                "label": "Monsenhor Hipólito"
            },
            {
                "value": "Monte Alegre do Piauí",
                "label": "Monte Alegre do Piauí"
            },
            {
                "value": "Morro Cabeça no Tempo",
                "label": "Morro Cabeça no Tempo"
            },
            {
                "value": "Morro do Chapéu do Piauí",
                "label": "Morro do Chapéu do Piauí"
            },
            {
                "value": "Murici dos Portelas",
                "label": "Murici dos Portelas"
            },
            {
                "value": "Nazaré do Piauí",
                "label": "Nazaré do Piauí"
            },
            {
                "value": "Nazária",
                "label": "Nazária"
            },
            {
                "value": "Nossa Senhora de Nazaré",
                "label": "Nossa Senhora de Nazaré"
            },
            {
                "value": "Nossa Senhora dos Remédios",
                "label": "Nossa Senhora dos Remédios"
            },
            {
                "value": "Novo Oriente do Piauí",
                "label": "Novo Oriente do Piauí"
            },
            {
                "value": "Novo Santo Antônio",
                "label": "Novo Santo Antônio"
            },
            {
                "value": "Oeiras",
                "label": "Oeiras"
            },
            {
                "value": "Olho D'Água do Piauí",
                "label": "Olho D'Água do Piauí"
            },
            {
                "value": "Padre Marcos",
                "label": "Padre Marcos"
            },
            {
                "value": "Paes Landim",
                "label": "Paes Landim"
            },
            {
                "value": "Pajeú do Piauí",
                "label": "Pajeú do Piauí"
            },
            {
                "value": "Palmeira do Piauí",
                "label": "Palmeira do Piauí"
            },
            {
                "value": "Palmeirais",
                "label": "Palmeirais"
            },
            {
                "value": "Paquetá",
                "label": "Paquetá"
            },
            {
                "value": "Parnaguá",
                "label": "Parnaguá"
            },
            {
                "value": "Parnaíba",
                "label": "Parnaíba"
            },
            {
                "value": "Passagem Franca do Piauí",
                "label": "Passagem Franca do Piauí"
            },
            {
                "value": "Patos do Piauí",
                "label": "Patos do Piauí"
            },
            {
                "value": "Pau D'Arco do Piauí",
                "label": "Pau D'Arco do Piauí"
            },
            {
                "value": "Paulistana",
                "label": "Paulistana"
            },
            {
                "value": "Pavussu",
                "label": "Pavussu"
            },
            {
                "value": "Pedro II",
                "label": "Pedro II"
            },
            {
                "value": "Pedro Laurentino",
                "label": "Pedro Laurentino"
            },
            {
                "value": "Nova Santa Rita",
                "label": "Nova Santa Rita"
            },
            {
                "value": "Picos",
                "label": "Picos"
            },
            {
                "value": "Pimenteiras",
                "label": "Pimenteiras"
            },
            {
                "value": "Pio IX",
                "label": "Pio IX"
            },
            {
                "value": "Piracuruca",
                "label": "Piracuruca"
            },
            {
                "value": "Piripiri",
                "label": "Piripiri"
            },
            {
                "value": "Porto",
                "label": "Porto"
            },
            {
                "value": "Porto Alegre do Piauí",
                "label": "Porto Alegre do Piauí"
            },
            {
                "value": "Prata do Piauí",
                "label": "Prata do Piauí"
            },
            {
                "value": "Queimada Nova",
                "label": "Queimada Nova"
            },
            {
                "value": "Redenção do Gurguéia",
                "label": "Redenção do Gurguéia"
            },
            {
                "value": "Regeneração",
                "label": "Regeneração"
            },
            {
                "value": "Riacho Frio",
                "label": "Riacho Frio"
            },
            {
                "value": "Ribeira do Piauí",
                "label": "Ribeira do Piauí"
            },
            {
                "value": "Ribeiro Gonçalves",
                "label": "Ribeiro Gonçalves"
            },
            {
                "value": "Rio Grande do Piauí",
                "label": "Rio Grande do Piauí"
            },
            {
                "value": "Santa Cruz do Piauí",
                "label": "Santa Cruz do Piauí"
            },
            {
                "value": "Santa Cruz dos Milagres",
                "label": "Santa Cruz dos Milagres"
            },
            {
                "value": "Santa Filomena",
                "label": "Santa Filomena"
            },
            {
                "value": "Santa Luz",
                "label": "Santa Luz"
            },
            {
                "value": "Santana do Piauí",
                "label": "Santana do Piauí"
            },
            {
                "value": "Santa Rosa do Piauí",
                "label": "Santa Rosa do Piauí"
            },
            {
                "value": "Santo Antônio de Lisboa",
                "label": "Santo Antônio de Lisboa"
            },
            {
                "value": "Santo Antônio dos Milagres",
                "label": "Santo Antônio dos Milagres"
            },
            {
                "value": "Santo Inácio do Piauí",
                "label": "Santo Inácio do Piauí"
            },
            {
                "value": "São Braz do Piauí",
                "label": "São Braz do Piauí"
            },
            {
                "value": "São Félix do Piauí",
                "label": "São Félix do Piauí"
            },
            {
                "value": "São Francisco de Assis do Piauí",
                "label": "São Francisco de Assis do Piauí"
            },
            {
                "value": "São Francisco do Piauí",
                "label": "São Francisco do Piauí"
            },
            {
                "value": "São Gonçalo do Gurguéia",
                "label": "São Gonçalo do Gurguéia"
            },
            {
                "value": "São Gonçalo do Piauí",
                "label": "São Gonçalo do Piauí"
            },
            {
                "value": "São João da Canabrava",
                "label": "São João da Canabrava"
            },
            {
                "value": "São João da Fronteira",
                "label": "São João da Fronteira"
            },
            {
                "value": "São João da Serra",
                "label": "São João da Serra"
            },
            {
                "value": "São João da Varjota",
                "label": "São João da Varjota"
            },
            {
                "value": "São João do Arraial",
                "label": "São João do Arraial"
            },
            {
                "value": "São João do Piauí",
                "label": "São João do Piauí"
            },
            {
                "value": "São José do Divino",
                "label": "São José do Divino"
            },
            {
                "value": "São José do Peixe",
                "label": "São José do Peixe"
            },
            {
                "value": "São José do Piauí",
                "label": "São José do Piauí"
            },
            {
                "value": "São Julião",
                "label": "São Julião"
            },
            {
                "value": "São Lourenço do Piauí",
                "label": "São Lourenço do Piauí"
            },
            {
                "value": "São Luis do Piauí",
                "label": "São Luis do Piauí"
            },
            {
                "value": "São Miguel da Baixa Grande",
                "label": "São Miguel da Baixa Grande"
            },
            {
                "value": "São Miguel do Fidalgo",
                "label": "São Miguel do Fidalgo"
            },
            {
                "value": "São Miguel do Tapuio",
                "label": "São Miguel do Tapuio"
            },
            {
                "value": "São Pedro do Piauí",
                "label": "São Pedro do Piauí"
            },
            {
                "value": "São Raimundo Nonato",
                "label": "São Raimundo Nonato"
            },
            {
                "value": "Sebastião Barros",
                "label": "Sebastião Barros"
            },
            {
                "value": "Sebastião Leal",
                "label": "Sebastião Leal"
            },
            {
                "value": "Sigefredo Pacheco",
                "label": "Sigefredo Pacheco"
            },
            {
                "value": "Simões",
                "label": "Simões"
            },
            {
                "value": "Simplício Mendes",
                "label": "Simplício Mendes"
            },
            {
                "value": "Socorro do Piauí",
                "label": "Socorro do Piauí"
            },
            {
                "value": "Sussuapara",
                "label": "Sussuapara"
            },
            {
                "value": "Tamboril do Piauí",
                "label": "Tamboril do Piauí"
            },
            {
                "value": "Tanque do Piauí",
                "label": "Tanque do Piauí"
            },
            {
                "value": "Teresina",
                "label": "Teresina"
            },
            {
                "value": "União",
                "label": "União"
            },
            {
                "value": "Uruçuí",
                "label": "Uruçuí"
            },
            {
                "value": "Valença do Piauí",
                "label": "Valença do Piauí"
            },
            {
                "value": "Várzea Branca",
                "label": "Várzea Branca"
            },
            {
                "value": "Várzea Grande",
                "label": "Várzea Grande"
            },
            {
                "value": "Vera Mendes",
                "label": "Vera Mendes"
            },
            {
                "value": "Vila Nova do Piauí",
                "label": "Vila Nova do Piauí"
            },
            {
                "value": "Wall Ferraz",
                "label": "Wall Ferraz"
            }
        ]
    },
    {
        "value": "PR",
        "label": "Paraná",
        "children": [
            {
                "value": "Abatiá",
                "label": "Abatiá"
            },
            {
                "value": "Adrianópolis",
                "label": "Adrianópolis"
            },
            {
                "value": "Agudos do Sul",
                "label": "Agudos do Sul"
            },
            {
                "value": "Almirante Tamandaré",
                "label": "Almirante Tamandaré"
            },
            {
                "value": "Altamira do Paraná",
                "label": "Altamira do Paraná"
            },
            {
                "value": "Altônia",
                "label": "Altônia"
            },
            {
                "value": "Alto Paraná",
                "label": "Alto Paraná"
            },
            {
                "value": "Alto Piquiri",
                "label": "Alto Piquiri"
            },
            {
                "value": "Alvorada do Sul",
                "label": "Alvorada do Sul"
            },
            {
                "value": "Amaporã",
                "label": "Amaporã"
            },
            {
                "value": "Ampére",
                "label": "Ampére"
            },
            {
                "value": "Anahy",
                "label": "Anahy"
            },
            {
                "value": "Andirá",
                "label": "Andirá"
            },
            {
                "value": "Ângulo",
                "label": "Ângulo"
            },
            {
                "value": "Antonina",
                "label": "Antonina"
            },
            {
                "value": "Antônio Olinto",
                "label": "Antônio Olinto"
            },
            {
                "value": "Apucarana",
                "label": "Apucarana"
            },
            {
                "value": "Arapongas",
                "label": "Arapongas"
            },
            {
                "value": "Arapoti",
                "label": "Arapoti"
            },
            {
                "value": "Arapuã",
                "label": "Arapuã"
            },
            {
                "value": "Araruna",
                "label": "Araruna"
            },
            {
                "value": "Araucária",
                "label": "Araucária"
            },
            {
                "value": "Ariranha do Ivaí",
                "label": "Ariranha do Ivaí"
            },
            {
                "value": "Assaí",
                "label": "Assaí"
            },
            {
                "value": "Assis Chateaubriand",
                "label": "Assis Chateaubriand"
            },
            {
                "value": "Astorga",
                "label": "Astorga"
            },
            {
                "value": "Atalaia",
                "label": "Atalaia"
            },
            {
                "value": "Balsa Nova",
                "label": "Balsa Nova"
            },
            {
                "value": "Bandeirantes",
                "label": "Bandeirantes"
            },
            {
                "value": "Barbosa Ferraz",
                "label": "Barbosa Ferraz"
            },
            {
                "value": "Barracão",
                "label": "Barracão"
            },
            {
                "value": "Barra do Jacaré",
                "label": "Barra do Jacaré"
            },
            {
                "value": "Bela Vista da Caroba",
                "label": "Bela Vista da Caroba"
            },
            {
                "value": "Bela Vista do Paraíso",
                "label": "Bela Vista do Paraíso"
            },
            {
                "value": "Bituruna",
                "label": "Bituruna"
            },
            {
                "value": "Boa Esperança",
                "label": "Boa Esperança"
            },
            {
                "value": "Boa Esperança do Iguaçu",
                "label": "Boa Esperança do Iguaçu"
            },
            {
                "value": "Boa Ventura de São Roque",
                "label": "Boa Ventura de São Roque"
            },
            {
                "value": "Boa Vista da Aparecida",
                "label": "Boa Vista da Aparecida"
            },
            {
                "value": "Bocaiúva do Sul",
                "label": "Bocaiúva do Sul"
            },
            {
                "value": "Bom Jesus do Sul",
                "label": "Bom Jesus do Sul"
            },
            {
                "value": "Bom Sucesso",
                "label": "Bom Sucesso"
            },
            {
                "value": "Bom Sucesso do Sul",
                "label": "Bom Sucesso do Sul"
            },
            {
                "value": "Borrazópolis",
                "label": "Borrazópolis"
            },
            {
                "value": "Braganey",
                "label": "Braganey"
            },
            {
                "value": "Brasilândia do Sul",
                "label": "Brasilândia do Sul"
            },
            {
                "value": "Cafeara",
                "label": "Cafeara"
            },
            {
                "value": "Cafelândia",
                "label": "Cafelândia"
            },
            {
                "value": "Cafezal do Sul",
                "label": "Cafezal do Sul"
            },
            {
                "value": "Califórnia",
                "label": "Califórnia"
            },
            {
                "value": "Cambará",
                "label": "Cambará"
            },
            {
                "value": "Cambé",
                "label": "Cambé"
            },
            {
                "value": "Cambira",
                "label": "Cambira"
            },
            {
                "value": "Campina da Lagoa",
                "label": "Campina da Lagoa"
            },
            {
                "value": "Campina do Simão",
                "label": "Campina do Simão"
            },
            {
                "value": "Campina Grande do Sul",
                "label": "Campina Grande do Sul"
            },
            {
                "value": "Campo Bonito",
                "label": "Campo Bonito"
            },
            {
                "value": "Campo do Tenente",
                "label": "Campo do Tenente"
            },
            {
                "value": "Campo Largo",
                "label": "Campo Largo"
            },
            {
                "value": "Campo Magro",
                "label": "Campo Magro"
            },
            {
                "value": "Campo Mourão",
                "label": "Campo Mourão"
            },
            {
                "value": "Cândido de Abreu",
                "label": "Cândido de Abreu"
            },
            {
                "value": "Candói",
                "label": "Candói"
            },
            {
                "value": "Cantagalo",
                "label": "Cantagalo"
            },
            {
                "value": "Capanema",
                "label": "Capanema"
            },
            {
                "value": "Capitão Leônidas Marques",
                "label": "Capitão Leônidas Marques"
            },
            {
                "value": "Carambeí",
                "label": "Carambeí"
            },
            {
                "value": "Carlópolis",
                "label": "Carlópolis"
            },
            {
                "value": "Cascavel",
                "label": "Cascavel"
            },
            {
                "value": "Castro",
                "label": "Castro"
            },
            {
                "value": "Catanduvas",
                "label": "Catanduvas"
            },
            {
                "value": "Centenário do Sul",
                "label": "Centenário do Sul"
            },
            {
                "value": "Cerro Azul",
                "label": "Cerro Azul"
            },
            {
                "value": "Céu Azul",
                "label": "Céu Azul"
            },
            {
                "value": "Chopinzinho",
                "label": "Chopinzinho"
            },
            {
                "value": "Cianorte",
                "label": "Cianorte"
            },
            {
                "value": "Cidade Gaúcha",
                "label": "Cidade Gaúcha"
            },
            {
                "value": "Clevelândia",
                "label": "Clevelândia"
            },
            {
                "value": "Colombo",
                "label": "Colombo"
            },
            {
                "value": "Colorado",
                "label": "Colorado"
            },
            {
                "value": "Congonhinhas",
                "label": "Congonhinhas"
            },
            {
                "value": "Conselheiro Mairinck",
                "label": "Conselheiro Mairinck"
            },
            {
                "value": "Contenda",
                "label": "Contenda"
            },
            {
                "value": "Corbélia",
                "label": "Corbélia"
            },
            {
                "value": "Cornélio Procópio",
                "label": "Cornélio Procópio"
            },
            {
                "value": "Coronel Domingos Soares",
                "label": "Coronel Domingos Soares"
            },
            {
                "value": "Coronel Vivida",
                "label": "Coronel Vivida"
            },
            {
                "value": "Corumbataí do Sul",
                "label": "Corumbataí do Sul"
            },
            {
                "value": "Cruzeiro do Iguaçu",
                "label": "Cruzeiro do Iguaçu"
            },
            {
                "value": "Cruzeiro do Oeste",
                "label": "Cruzeiro do Oeste"
            },
            {
                "value": "Cruzeiro do Sul",
                "label": "Cruzeiro do Sul"
            },
            {
                "value": "Cruz Machado",
                "label": "Cruz Machado"
            },
            {
                "value": "Cruzmaltina",
                "label": "Cruzmaltina"
            },
            {
                "value": "Curitiba",
                "label": "Curitiba"
            },
            {
                "value": "Curiúva",
                "label": "Curiúva"
            },
            {
                "value": "Diamante do Norte",
                "label": "Diamante do Norte"
            },
            {
                "value": "Diamante do Sul",
                "label": "Diamante do Sul"
            },
            {
                "value": "Diamante D'Oeste",
                "label": "Diamante D'Oeste"
            },
            {
                "value": "Dois Vizinhos",
                "label": "Dois Vizinhos"
            },
            {
                "value": "Douradina",
                "label": "Douradina"
            },
            {
                "value": "Doutor Camargo",
                "label": "Doutor Camargo"
            },
            {
                "value": "Enéas Marques",
                "label": "Enéas Marques"
            },
            {
                "value": "Engenheiro Beltrão",
                "label": "Engenheiro Beltrão"
            },
            {
                "value": "Esperança Nova",
                "label": "Esperança Nova"
            },
            {
                "value": "Entre Rios do Oeste",
                "label": "Entre Rios do Oeste"
            },
            {
                "value": "Espigão Alto do Iguaçu",
                "label": "Espigão Alto do Iguaçu"
            },
            {
                "value": "Farol",
                "label": "Farol"
            },
            {
                "value": "Faxinal",
                "label": "Faxinal"
            },
            {
                "value": "Fazenda Rio Grande",
                "label": "Fazenda Rio Grande"
            },
            {
                "value": "Fênix",
                "label": "Fênix"
            },
            {
                "value": "Fernandes Pinheiro",
                "label": "Fernandes Pinheiro"
            },
            {
                "value": "Figueira",
                "label": "Figueira"
            },
            {
                "value": "Floraí",
                "label": "Floraí"
            },
            {
                "value": "Flor da Serra do Sul",
                "label": "Flor da Serra do Sul"
            },
            {
                "value": "Floresta",
                "label": "Floresta"
            },
            {
                "value": "Florestópolis",
                "label": "Florestópolis"
            },
            {
                "value": "Flórida",
                "label": "Flórida"
            },
            {
                "value": "Formosa do Oeste",
                "label": "Formosa do Oeste"
            },
            {
                "value": "Foz do Iguaçu",
                "label": "Foz do Iguaçu"
            },
            {
                "value": "Francisco Alves",
                "label": "Francisco Alves"
            },
            {
                "value": "Francisco Beltrão",
                "label": "Francisco Beltrão"
            },
            {
                "value": "Foz do Jordão",
                "label": "Foz do Jordão"
            },
            {
                "value": "General Carneiro",
                "label": "General Carneiro"
            },
            {
                "value": "Godoy Moreira",
                "label": "Godoy Moreira"
            },
            {
                "value": "Goioerê",
                "label": "Goioerê"
            },
            {
                "value": "Goioxim",
                "label": "Goioxim"
            },
            {
                "value": "Grandes Rios",
                "label": "Grandes Rios"
            },
            {
                "value": "Guaíra",
                "label": "Guaíra"
            },
            {
                "value": "Guairaçá",
                "label": "Guairaçá"
            },
            {
                "value": "Guamiranga",
                "label": "Guamiranga"
            },
            {
                "value": "Guapirama",
                "label": "Guapirama"
            },
            {
                "value": "Guaporema",
                "label": "Guaporema"
            },
            {
                "value": "Guaraci",
                "label": "Guaraci"
            },
            {
                "value": "Guaraniaçu",
                "label": "Guaraniaçu"
            },
            {
                "value": "Guarapuava",
                "label": "Guarapuava"
            },
            {
                "value": "Guaraqueçaba",
                "label": "Guaraqueçaba"
            },
            {
                "value": "Guaratuba",
                "label": "Guaratuba"
            },
            {
                "value": "Honório Serpa",
                "label": "Honório Serpa"
            },
            {
                "value": "Ibaiti",
                "label": "Ibaiti"
            },
            {
                "value": "Ibema",
                "label": "Ibema"
            },
            {
                "value": "Ibiporã",
                "label": "Ibiporã"
            },
            {
                "value": "Icaraíma",
                "label": "Icaraíma"
            },
            {
                "value": "Iguaraçu",
                "label": "Iguaraçu"
            },
            {
                "value": "Iguatu",
                "label": "Iguatu"
            },
            {
                "value": "Imbaú",
                "label": "Imbaú"
            },
            {
                "value": "Imbituva",
                "label": "Imbituva"
            },
            {
                "value": "Inácio Martins",
                "label": "Inácio Martins"
            },
            {
                "value": "Inajá",
                "label": "Inajá"
            },
            {
                "value": "Indianópolis",
                "label": "Indianópolis"
            },
            {
                "value": "Ipiranga",
                "label": "Ipiranga"
            },
            {
                "value": "Iporã",
                "label": "Iporã"
            },
            {
                "value": "Iracema do Oeste",
                "label": "Iracema do Oeste"
            },
            {
                "value": "Irati",
                "label": "Irati"
            },
            {
                "value": "Iretama",
                "label": "Iretama"
            },
            {
                "value": "Itaguajé",
                "label": "Itaguajé"
            },
            {
                "value": "Itaipulândia",
                "label": "Itaipulândia"
            },
            {
                "value": "Itambaracá",
                "label": "Itambaracá"
            },
            {
                "value": "Itambé",
                "label": "Itambé"
            },
            {
                "value": "Itapejara d'Oeste",
                "label": "Itapejara d'Oeste"
            },
            {
                "value": "Itaperuçu",
                "label": "Itaperuçu"
            },
            {
                "value": "Itaúna do Sul",
                "label": "Itaúna do Sul"
            },
            {
                "value": "Ivaí",
                "label": "Ivaí"
            },
            {
                "value": "Ivaiporã",
                "label": "Ivaiporã"
            },
            {
                "value": "Ivaté",
                "label": "Ivaté"
            },
            {
                "value": "Ivatuba",
                "label": "Ivatuba"
            },
            {
                "value": "Jaboti",
                "label": "Jaboti"
            },
            {
                "value": "Jacarezinho",
                "label": "Jacarezinho"
            },
            {
                "value": "Jaguapitã",
                "label": "Jaguapitã"
            },
            {
                "value": "Jaguariaíva",
                "label": "Jaguariaíva"
            },
            {
                "value": "Jandaia do Sul",
                "label": "Jandaia do Sul"
            },
            {
                "value": "Janiópolis",
                "label": "Janiópolis"
            },
            {
                "value": "Japira",
                "label": "Japira"
            },
            {
                "value": "Japurá",
                "label": "Japurá"
            },
            {
                "value": "Jardim Alegre",
                "label": "Jardim Alegre"
            },
            {
                "value": "Jardim Olinda",
                "label": "Jardim Olinda"
            },
            {
                "value": "Jataizinho",
                "label": "Jataizinho"
            },
            {
                "value": "Jesuítas",
                "label": "Jesuítas"
            },
            {
                "value": "Joaquim Távora",
                "label": "Joaquim Távora"
            },
            {
                "value": "Jundiaí do Sul",
                "label": "Jundiaí do Sul"
            },
            {
                "value": "Juranda",
                "label": "Juranda"
            },
            {
                "value": "Jussara",
                "label": "Jussara"
            },
            {
                "value": "Kaloré",
                "label": "Kaloré"
            },
            {
                "value": "Lapa",
                "label": "Lapa"
            },
            {
                "value": "Laranjal",
                "label": "Laranjal"
            },
            {
                "value": "Laranjeiras do Sul",
                "label": "Laranjeiras do Sul"
            },
            {
                "value": "Leópolis",
                "label": "Leópolis"
            },
            {
                "value": "Lidianópolis",
                "label": "Lidianópolis"
            },
            {
                "value": "Lindoeste",
                "label": "Lindoeste"
            },
            {
                "value": "Loanda",
                "label": "Loanda"
            },
            {
                "value": "Lobato",
                "label": "Lobato"
            },
            {
                "value": "Londrina",
                "label": "Londrina"
            },
            {
                "value": "Luiziana",
                "label": "Luiziana"
            },
            {
                "value": "Lunardelli",
                "label": "Lunardelli"
            },
            {
                "value": "Lupionópolis",
                "label": "Lupionópolis"
            },
            {
                "value": "Mallet",
                "label": "Mallet"
            },
            {
                "value": "Mamborê",
                "label": "Mamborê"
            },
            {
                "value": "Mandaguaçu",
                "label": "Mandaguaçu"
            },
            {
                "value": "Mandaguari",
                "label": "Mandaguari"
            },
            {
                "value": "Mandirituba",
                "label": "Mandirituba"
            },
            {
                "value": "Manfrinópolis",
                "label": "Manfrinópolis"
            },
            {
                "value": "Mangueirinha",
                "label": "Mangueirinha"
            },
            {
                "value": "Manoel Ribas",
                "label": "Manoel Ribas"
            },
            {
                "value": "Marechal Cândido Rondon",
                "label": "Marechal Cândido Rondon"
            },
            {
                "value": "Maria Helena",
                "label": "Maria Helena"
            },
            {
                "value": "Marialva",
                "label": "Marialva"
            },
            {
                "value": "Marilândia do Sul",
                "label": "Marilândia do Sul"
            },
            {
                "value": "Marilena",
                "label": "Marilena"
            },
            {
                "value": "Mariluz",
                "label": "Mariluz"
            },
            {
                "value": "Maringá",
                "label": "Maringá"
            },
            {
                "value": "Mariópolis",
                "label": "Mariópolis"
            },
            {
                "value": "Maripá",
                "label": "Maripá"
            },
            {
                "value": "Marmeleiro",
                "label": "Marmeleiro"
            },
            {
                "value": "Marquinho",
                "label": "Marquinho"
            },
            {
                "value": "Marumbi",
                "label": "Marumbi"
            },
            {
                "value": "Matelândia",
                "label": "Matelândia"
            },
            {
                "value": "Matinhos",
                "label": "Matinhos"
            },
            {
                "value": "Mato Rico",
                "label": "Mato Rico"
            },
            {
                "value": "Mauá da Serra",
                "label": "Mauá da Serra"
            },
            {
                "value": "Medianeira",
                "label": "Medianeira"
            },
            {
                "value": "Mercedes",
                "label": "Mercedes"
            },
            {
                "value": "Mirador",
                "label": "Mirador"
            },
            {
                "value": "Miraselva",
                "label": "Miraselva"
            },
            {
                "value": "Missal",
                "label": "Missal"
            },
            {
                "value": "Moreira Sales",
                "label": "Moreira Sales"
            },
            {
                "value": "Morretes",
                "label": "Morretes"
            },
            {
                "value": "Munhoz de Melo",
                "label": "Munhoz de Melo"
            },
            {
                "value": "Nossa Senhora das Graças",
                "label": "Nossa Senhora das Graças"
            },
            {
                "value": "Nova Aliança do Ivaí",
                "label": "Nova Aliança do Ivaí"
            },
            {
                "value": "Nova América da Colina",
                "label": "Nova América da Colina"
            },
            {
                "value": "Nova Aurora",
                "label": "Nova Aurora"
            },
            {
                "value": "Nova Cantu",
                "label": "Nova Cantu"
            },
            {
                "value": "Nova Esperança",
                "label": "Nova Esperança"
            },
            {
                "value": "Nova Esperança do Sudoeste",
                "label": "Nova Esperança do Sudoeste"
            },
            {
                "value": "Nova Fátima",
                "label": "Nova Fátima"
            },
            {
                "value": "Nova Laranjeiras",
                "label": "Nova Laranjeiras"
            },
            {
                "value": "Nova Londrina",
                "label": "Nova Londrina"
            },
            {
                "value": "Nova Olímpia",
                "label": "Nova Olímpia"
            },
            {
                "value": "Nova Santa Bárbara",
                "label": "Nova Santa Bárbara"
            },
            {
                "value": "Nova Santa Rosa",
                "label": "Nova Santa Rosa"
            },
            {
                "value": "Nova Prata do Iguaçu",
                "label": "Nova Prata do Iguaçu"
            },
            {
                "value": "Nova Tebas",
                "label": "Nova Tebas"
            },
            {
                "value": "Novo Itacolomi",
                "label": "Novo Itacolomi"
            },
            {
                "value": "Ortigueira",
                "label": "Ortigueira"
            },
            {
                "value": "Ourizona",
                "label": "Ourizona"
            },
            {
                "value": "Ouro Verde do Oeste",
                "label": "Ouro Verde do Oeste"
            },
            {
                "value": "Paiçandu",
                "label": "Paiçandu"
            },
            {
                "value": "Palmas",
                "label": "Palmas"
            },
            {
                "value": "Palmeira",
                "label": "Palmeira"
            },
            {
                "value": "Palmital",
                "label": "Palmital"
            },
            {
                "value": "Palotina",
                "label": "Palotina"
            },
            {
                "value": "Paraíso do Norte",
                "label": "Paraíso do Norte"
            },
            {
                "value": "Paranacity",
                "label": "Paranacity"
            },
            {
                "value": "Paranaguá",
                "label": "Paranaguá"
            },
            {
                "value": "Paranapoema",
                "label": "Paranapoema"
            },
            {
                "value": "Paranavaí",
                "label": "Paranavaí"
            },
            {
                "value": "Pato Bragado",
                "label": "Pato Bragado"
            },
            {
                "value": "Pato Branco",
                "label": "Pato Branco"
            },
            {
                "value": "Paula Freitas",
                "label": "Paula Freitas"
            },
            {
                "value": "Paulo Frontin",
                "label": "Paulo Frontin"
            },
            {
                "value": "Peabiru",
                "label": "Peabiru"
            },
            {
                "value": "Perobal",
                "label": "Perobal"
            },
            {
                "value": "Pérola",
                "label": "Pérola"
            },
            {
                "value": "Pérola d'Oeste",
                "label": "Pérola d'Oeste"
            },
            {
                "value": "Piên",
                "label": "Piên"
            },
            {
                "value": "Pinhais",
                "label": "Pinhais"
            },
            {
                "value": "Pinhalão",
                "label": "Pinhalão"
            },
            {
                "value": "Pinhal de São Bento",
                "label": "Pinhal de São Bento"
            },
            {
                "value": "Pinhão",
                "label": "Pinhão"
            },
            {
                "value": "Piraí do Sul",
                "label": "Piraí do Sul"
            },
            {
                "value": "Piraquara",
                "label": "Piraquara"
            },
            {
                "value": "Pitanga",
                "label": "Pitanga"
            },
            {
                "value": "Pitangueiras",
                "label": "Pitangueiras"
            },
            {
                "value": "Planaltina do Paraná",
                "label": "Planaltina do Paraná"
            },
            {
                "value": "Planalto",
                "label": "Planalto"
            },
            {
                "value": "Ponta Grossa",
                "label": "Ponta Grossa"
            },
            {
                "value": "Pontal do Paraná",
                "label": "Pontal do Paraná"
            },
            {
                "value": "Porecatu",
                "label": "Porecatu"
            },
            {
                "value": "Porto Amazonas",
                "label": "Porto Amazonas"
            },
            {
                "value": "Porto Barreiro",
                "label": "Porto Barreiro"
            },
            {
                "value": "Porto Rico",
                "label": "Porto Rico"
            },
            {
                "value": "Porto Vitória",
                "label": "Porto Vitória"
            },
            {
                "value": "Prado Ferreira",
                "label": "Prado Ferreira"
            },
            {
                "value": "Pranchita",
                "label": "Pranchita"
            },
            {
                "value": "Presidente Castelo Branco",
                "label": "Presidente Castelo Branco"
            },
            {
                "value": "Primeiro de Maio",
                "label": "Primeiro de Maio"
            },
            {
                "value": "Prudentópolis",
                "label": "Prudentópolis"
            },
            {
                "value": "Quarto Centenário",
                "label": "Quarto Centenário"
            },
            {
                "value": "Quatiguá",
                "label": "Quatiguá"
            },
            {
                "value": "Quatro Barras",
                "label": "Quatro Barras"
            },
            {
                "value": "Quatro Pontes",
                "label": "Quatro Pontes"
            },
            {
                "value": "Quedas do Iguaçu",
                "label": "Quedas do Iguaçu"
            },
            {
                "value": "Querência do Norte",
                "label": "Querência do Norte"
            },
            {
                "value": "Quinta do Sol",
                "label": "Quinta do Sol"
            },
            {
                "value": "Quitandinha",
                "label": "Quitandinha"
            },
            {
                "value": "Ramilândia",
                "label": "Ramilândia"
            },
            {
                "value": "Rancho Alegre",
                "label": "Rancho Alegre"
            },
            {
                "value": "Rancho Alegre D'Oeste",
                "label": "Rancho Alegre D'Oeste"
            },
            {
                "value": "Realeza",
                "label": "Realeza"
            },
            {
                "value": "Rebouças",
                "label": "Rebouças"
            },
            {
                "value": "Renascença",
                "label": "Renascença"
            },
            {
                "value": "Reserva",
                "label": "Reserva"
            },
            {
                "value": "Reserva do Iguaçu",
                "label": "Reserva do Iguaçu"
            },
            {
                "value": "Ribeirão Claro",
                "label": "Ribeirão Claro"
            },
            {
                "value": "Ribeirão do Pinhal",
                "label": "Ribeirão do Pinhal"
            },
            {
                "value": "Rio Azul",
                "label": "Rio Azul"
            },
            {
                "value": "Rio Bom",
                "label": "Rio Bom"
            },
            {
                "value": "Rio Bonito do Iguaçu",
                "label": "Rio Bonito do Iguaçu"
            },
            {
                "value": "Rio Branco do Ivaí",
                "label": "Rio Branco do Ivaí"
            },
            {
                "value": "Rio Branco do Sul",
                "label": "Rio Branco do Sul"
            },
            {
                "value": "Rio Negro",
                "label": "Rio Negro"
            },
            {
                "value": "Rolândia",
                "label": "Rolândia"
            },
            {
                "value": "Roncador",
                "label": "Roncador"
            },
            {
                "value": "Rondon",
                "label": "Rondon"
            },
            {
                "value": "Rosário do Ivaí",
                "label": "Rosário do Ivaí"
            },
            {
                "value": "Sabáudia",
                "label": "Sabáudia"
            },
            {
                "value": "Salgado Filho",
                "label": "Salgado Filho"
            },
            {
                "value": "Salto do Itararé",
                "label": "Salto do Itararé"
            },
            {
                "value": "Salto do Lontra",
                "label": "Salto do Lontra"
            },
            {
                "value": "Santa Amélia",
                "label": "Santa Amélia"
            },
            {
                "value": "Santa Cecília do Pavão",
                "label": "Santa Cecília do Pavão"
            },
            {
                "value": "Santa Cruz de Monte Castelo",
                "label": "Santa Cruz de Monte Castelo"
            },
            {
                "value": "Santa Fé",
                "label": "Santa Fé"
            },
            {
                "value": "Santa Helena",
                "label": "Santa Helena"
            },
            {
                "value": "Santa Inês",
                "label": "Santa Inês"
            },
            {
                "value": "Santa Isabel do Ivaí",
                "label": "Santa Isabel do Ivaí"
            },
            {
                "value": "Santa Izabel do Oeste",
                "label": "Santa Izabel do Oeste"
            },
            {
                "value": "Santa Lúcia",
                "label": "Santa Lúcia"
            },
            {
                "value": "Santa Maria do Oeste",
                "label": "Santa Maria do Oeste"
            },
            {
                "value": "Santa Mariana",
                "label": "Santa Mariana"
            },
            {
                "value": "Santa Mônica",
                "label": "Santa Mônica"
            },
            {
                "value": "Santana do Itararé",
                "label": "Santana do Itararé"
            },
            {
                "value": "Santa Tereza do Oeste",
                "label": "Santa Tereza do Oeste"
            },
            {
                "value": "Santa Terezinha de Itaipu",
                "label": "Santa Terezinha de Itaipu"
            },
            {
                "value": "Santo Antônio da Platina",
                "label": "Santo Antônio da Platina"
            },
            {
                "value": "Santo Antônio do Caiuá",
                "label": "Santo Antônio do Caiuá"
            },
            {
                "value": "Santo Antônio do Paraíso",
                "label": "Santo Antônio do Paraíso"
            },
            {
                "value": "Santo Antônio do Sudoeste",
                "label": "Santo Antônio do Sudoeste"
            },
            {
                "value": "Santo Inácio",
                "label": "Santo Inácio"
            },
            {
                "value": "São Carlos do Ivaí",
                "label": "São Carlos do Ivaí"
            },
            {
                "value": "São Jerônimo da Serra",
                "label": "São Jerônimo da Serra"
            },
            {
                "value": "São João",
                "label": "São João"
            },
            {
                "value": "São João do Caiuá",
                "label": "São João do Caiuá"
            },
            {
                "value": "São João do Ivaí",
                "label": "São João do Ivaí"
            },
            {
                "value": "São João do Triunfo",
                "label": "São João do Triunfo"
            },
            {
                "value": "São Jorge d'Oeste",
                "label": "São Jorge d'Oeste"
            },
            {
                "value": "São Jorge do Ivaí",
                "label": "São Jorge do Ivaí"
            },
            {
                "value": "São Jorge do Patrocínio",
                "label": "São Jorge do Patrocínio"
            },
            {
                "value": "São José da Boa Vista",
                "label": "São José da Boa Vista"
            },
            {
                "value": "São José das Palmeiras",
                "label": "São José das Palmeiras"
            },
            {
                "value": "São José dos Pinhais",
                "label": "São José dos Pinhais"
            },
            {
                "value": "São Manoel do Paraná",
                "label": "São Manoel do Paraná"
            },
            {
                "value": "São Mateus do Sul",
                "label": "São Mateus do Sul"
            },
            {
                "value": "São Miguel do Iguaçu",
                "label": "São Miguel do Iguaçu"
            },
            {
                "value": "São Pedro do Iguaçu",
                "label": "São Pedro do Iguaçu"
            },
            {
                "value": "São Pedro do Ivaí",
                "label": "São Pedro do Ivaí"
            },
            {
                "value": "São Pedro do Paraná",
                "label": "São Pedro do Paraná"
            },
            {
                "value": "São Sebastião da Amoreira",
                "label": "São Sebastião da Amoreira"
            },
            {
                "value": "São Tomé",
                "label": "São Tomé"
            },
            {
                "value": "Sapopema",
                "label": "Sapopema"
            },
            {
                "value": "Sarandi",
                "label": "Sarandi"
            },
            {
                "value": "Saudade do Iguaçu",
                "label": "Saudade do Iguaçu"
            },
            {
                "value": "Sengés",
                "label": "Sengés"
            },
            {
                "value": "Serranópolis do Iguaçu",
                "label": "Serranópolis do Iguaçu"
            },
            {
                "value": "Sertaneja",
                "label": "Sertaneja"
            },
            {
                "value": "Sertanópolis",
                "label": "Sertanópolis"
            },
            {
                "value": "Siqueira Campos",
                "label": "Siqueira Campos"
            },
            {
                "value": "Sulina",
                "label": "Sulina"
            },
            {
                "value": "Tamarana",
                "label": "Tamarana"
            },
            {
                "value": "Tamboara",
                "label": "Tamboara"
            },
            {
                "value": "Tapejara",
                "label": "Tapejara"
            },
            {
                "value": "Tapira",
                "label": "Tapira"
            },
            {
                "value": "Teixeira Soares",
                "label": "Teixeira Soares"
            },
            {
                "value": "Telêmaco Borba",
                "label": "Telêmaco Borba"
            },
            {
                "value": "Terra Boa",
                "label": "Terra Boa"
            },
            {
                "value": "Terra Rica",
                "label": "Terra Rica"
            },
            {
                "value": "Terra Roxa",
                "label": "Terra Roxa"
            },
            {
                "value": "Tibagi",
                "label": "Tibagi"
            },
            {
                "value": "Tijucas do Sul",
                "label": "Tijucas do Sul"
            },
            {
                "value": "Toledo",
                "label": "Toledo"
            },
            {
                "value": "Tomazina",
                "label": "Tomazina"
            },
            {
                "value": "Três Barras do Paraná",
                "label": "Três Barras do Paraná"
            },
            {
                "value": "Tunas do Paraná",
                "label": "Tunas do Paraná"
            },
            {
                "value": "Tuneiras do Oeste",
                "label": "Tuneiras do Oeste"
            },
            {
                "value": "Tupãssi",
                "label": "Tupãssi"
            },
            {
                "value": "Turvo",
                "label": "Turvo"
            },
            {
                "value": "Ubiratã",
                "label": "Ubiratã"
            },
            {
                "value": "Umuarama",
                "label": "Umuarama"
            },
            {
                "value": "União da Vitória",
                "label": "União da Vitória"
            },
            {
                "value": "Uniflor",
                "label": "Uniflor"
            },
            {
                "value": "Uraí",
                "label": "Uraí"
            },
            {
                "value": "Wenceslau Braz",
                "label": "Wenceslau Braz"
            },
            {
                "value": "Ventania",
                "label": "Ventania"
            },
            {
                "value": "Vera Cruz do Oeste",
                "label": "Vera Cruz do Oeste"
            },
            {
                "value": "Verê",
                "label": "Verê"
            },
            {
                "value": "Alto Paraíso",
                "label": "Alto Paraíso"
            },
            {
                "value": "Doutor Ulysses",
                "label": "Doutor Ulysses"
            },
            {
                "value": "Virmond",
                "label": "Virmond"
            },
            {
                "value": "Vitorino",
                "label": "Vitorino"
            },
            {
                "value": "Xambrê",
                "label": "Xambrê"
            }
        ]
    },
    {
        "value": "RJ",
        "label": "Rio de Janeiro",
        "children": [
            {
                "value": "Angra dos Reis",
                "label": "Angra dos Reis"
            },
            {
                "value": "Aperibé",
                "label": "Aperibé"
            },
            {
                "value": "Araruama",
                "label": "Araruama"
            },
            {
                "value": "Areal",
                "label": "Areal"
            },
            {
                "value": "Armação dos Búzios",
                "label": "Armação dos Búzios"
            },
            {
                "value": "Arraial do Cabo",
                "label": "Arraial do Cabo"
            },
            {
                "value": "Barra do Piraí",
                "label": "Barra do Piraí"
            },
            {
                "value": "Barra Mansa",
                "label": "Barra Mansa"
            },
            {
                "value": "Belford Roxo",
                "label": "Belford Roxo"
            },
            {
                "value": "Bom Jardim",
                "label": "Bom Jardim"
            },
            {
                "value": "Bom Jesus do Itabapoana",
                "label": "Bom Jesus do Itabapoana"
            },
            {
                "value": "Cabo Frio",
                "label": "Cabo Frio"
            },
            {
                "value": "Cachoeiras de Macacu",
                "label": "Cachoeiras de Macacu"
            },
            {
                "value": "Cambuci",
                "label": "Cambuci"
            },
            {
                "value": "Carapebus",
                "label": "Carapebus"
            },
            {
                "value": "Comendador Levy Gasparian",
                "label": "Comendador Levy Gasparian"
            },
            {
                "value": "Campos dos Goytacazes",
                "label": "Campos dos Goytacazes"
            },
            {
                "value": "Cantagalo",
                "label": "Cantagalo"
            },
            {
                "value": "Cardoso Moreira",
                "label": "Cardoso Moreira"
            },
            {
                "value": "Carmo",
                "label": "Carmo"
            },
            {
                "value": "Casimiro de Abreu",
                "label": "Casimiro de Abreu"
            },
            {
                "value": "Conceição de Macabu",
                "label": "Conceição de Macabu"
            },
            {
                "value": "Cordeiro",
                "label": "Cordeiro"
            },
            {
                "value": "Duas Barras",
                "label": "Duas Barras"
            },
            {
                "value": "Duque de Caxias",
                "label": "Duque de Caxias"
            },
            {
                "value": "Engenheiro Paulo de Frontin",
                "label": "Engenheiro Paulo de Frontin"
            },
            {
                "value": "Guapimirim",
                "label": "Guapimirim"
            },
            {
                "value": "Iguaba Grande",
                "label": "Iguaba Grande"
            },
            {
                "value": "Itaboraí",
                "label": "Itaboraí"
            },
            {
                "value": "Itaguaí",
                "label": "Itaguaí"
            },
            {
                "value": "Italva",
                "label": "Italva"
            },
            {
                "value": "Itaocara",
                "label": "Itaocara"
            },
            {
                "value": "Itaperuna",
                "label": "Itaperuna"
            },
            {
                "value": "Itatiaia",
                "label": "Itatiaia"
            },
            {
                "value": "Japeri",
                "label": "Japeri"
            },
            {
                "value": "Laje do Muriaé",
                "label": "Laje do Muriaé"
            },
            {
                "value": "Macaé",
                "label": "Macaé"
            },
            {
                "value": "Macuco",
                "label": "Macuco"
            },
            {
                "value": "Magé",
                "label": "Magé"
            },
            {
                "value": "Mangaratiba",
                "label": "Mangaratiba"
            },
            {
                "value": "Maricá",
                "label": "Maricá"
            },
            {
                "value": "Mendes",
                "label": "Mendes"
            },
            {
                "value": "Mesquita",
                "label": "Mesquita"
            },
            {
                "value": "Miguel Pereira",
                "label": "Miguel Pereira"
            },
            {
                "value": "Miracema",
                "label": "Miracema"
            },
            {
                "value": "Natividade",
                "label": "Natividade"
            },
            {
                "value": "Nilópolis",
                "label": "Nilópolis"
            },
            {
                "value": "Niterói",
                "label": "Niterói"
            },
            {
                "value": "Nova Friburgo",
                "label": "Nova Friburgo"
            },
            {
                "value": "Nova Iguaçu",
                "label": "Nova Iguaçu"
            },
            {
                "value": "Paracambi",
                "label": "Paracambi"
            },
            {
                "value": "Paraíba do Sul",
                "label": "Paraíba do Sul"
            },
            {
                "value": "Paraty",
                "label": "Paraty"
            },
            {
                "value": "Paty do Alferes",
                "label": "Paty do Alferes"
            },
            {
                "value": "Petrópolis",
                "label": "Petrópolis"
            },
            {
                "value": "Pinheiral",
                "label": "Pinheiral"
            },
            {
                "value": "Piraí",
                "label": "Piraí"
            },
            {
                "value": "Porciúncula",
                "label": "Porciúncula"
            },
            {
                "value": "Porto Real",
                "label": "Porto Real"
            },
            {
                "value": "Quatis",
                "label": "Quatis"
            },
            {
                "value": "Queimados",
                "label": "Queimados"
            },
            {
                "value": "Quissamã",
                "label": "Quissamã"
            },
            {
                "value": "Resende",
                "label": "Resende"
            },
            {
                "value": "Rio Bonito",
                "label": "Rio Bonito"
            },
            {
                "value": "Rio Claro",
                "label": "Rio Claro"
            },
            {
                "value": "Rio das Flores",
                "label": "Rio das Flores"
            },
            {
                "value": "Rio das Ostras",
                "label": "Rio das Ostras"
            },
            {
                "value": "Rio de Janeiro",
                "label": "Rio de Janeiro"
            },
            {
                "value": "Santa Maria Madalena",
                "label": "Santa Maria Madalena"
            },
            {
                "value": "Santo Antônio de Pádua",
                "label": "Santo Antônio de Pádua"
            },
            {
                "value": "São Francisco de Itabapoana",
                "label": "São Francisco de Itabapoana"
            },
            {
                "value": "São Fidélis",
                "label": "São Fidélis"
            },
            {
                "value": "São Gonçalo",
                "label": "São Gonçalo"
            },
            {
                "value": "São João da Barra",
                "label": "São João da Barra"
            },
            {
                "value": "São João de Meriti",
                "label": "São João de Meriti"
            },
            {
                "value": "São José de Ubá",
                "label": "São José de Ubá"
            },
            {
                "value": "São José do Vale do Rio Preto",
                "label": "São José do Vale do Rio Preto"
            },
            {
                "value": "São Pedro da Aldeia",
                "label": "São Pedro da Aldeia"
            },
            {
                "value": "São Sebastião do Alto",
                "label": "São Sebastião do Alto"
            },
            {
                "value": "Sapucaia",
                "label": "Sapucaia"
            },
            {
                "value": "Saquarema",
                "label": "Saquarema"
            },
            {
                "value": "Seropédica",
                "label": "Seropédica"
            },
            {
                "value": "Silva Jardim",
                "label": "Silva Jardim"
            },
            {
                "value": "Sumidouro",
                "label": "Sumidouro"
            },
            {
                "value": "Tanguá",
                "label": "Tanguá"
            },
            {
                "value": "Teresópolis",
                "label": "Teresópolis"
            },
            {
                "value": "Trajano de Moraes",
                "label": "Trajano de Moraes"
            },
            {
                "value": "Três Rios",
                "label": "Três Rios"
            },
            {
                "value": "Valença",
                "label": "Valença"
            },
            {
                "value": "Varre-Sai",
                "label": "Varre-Sai"
            },
            {
                "value": "Vassouras",
                "label": "Vassouras"
            },
            {
                "value": "Volta Redonda",
                "label": "Volta Redonda"
            }
        ]
    },
    {
        "value": "RN",
        "label": "Rio Grande do Norte",
        "children": [
            {
                "value": "Acari",
                "label": "Acari"
            },
            {
                "value": "Açu",
                "label": "Açu"
            },
            {
                "value": "Afonso Bezerra",
                "label": "Afonso Bezerra"
            },
            {
                "value": "Água Nova",
                "label": "Água Nova"
            },
            {
                "value": "Alexandria",
                "label": "Alexandria"
            },
            {
                "value": "Almino Afonso",
                "label": "Almino Afonso"
            },
            {
                "value": "Alto do Rodrigues",
                "label": "Alto do Rodrigues"
            },
            {
                "value": "Angicos",
                "label": "Angicos"
            },
            {
                "value": "Antônio Martins",
                "label": "Antônio Martins"
            },
            {
                "value": "Apodi",
                "label": "Apodi"
            },
            {
                "value": "Areia Branca",
                "label": "Areia Branca"
            },
            {
                "value": "Arês",
                "label": "Arês"
            },
            {
                "value": "Campo Grande",
                "label": "Campo Grande"
            },
            {
                "value": "Baía Formosa",
                "label": "Baía Formosa"
            },
            {
                "value": "Baraúna",
                "label": "Baraúna"
            },
            {
                "value": "Barcelona",
                "label": "Barcelona"
            },
            {
                "value": "Bento Fernandes",
                "label": "Bento Fernandes"
            },
            {
                "value": "Bodó",
                "label": "Bodó"
            },
            {
                "value": "Bom Jesus",
                "label": "Bom Jesus"
            },
            {
                "value": "Brejinho",
                "label": "Brejinho"
            },
            {
                "value": "Caiçara do Norte",
                "label": "Caiçara do Norte"
            },
            {
                "value": "Caiçara do Rio do Vento",
                "label": "Caiçara do Rio do Vento"
            },
            {
                "value": "Caicó",
                "label": "Caicó"
            },
            {
                "value": "Campo Redondo",
                "label": "Campo Redondo"
            },
            {
                "value": "Canguaretama",
                "label": "Canguaretama"
            },
            {
                "value": "Caraúbas",
                "label": "Caraúbas"
            },
            {
                "value": "Carnaúba dos Dantas",
                "label": "Carnaúba dos Dantas"
            },
            {
                "value": "Carnaubais",
                "label": "Carnaubais"
            },
            {
                "value": "Ceará-Mirim",
                "label": "Ceará-Mirim"
            },
            {
                "value": "Cerro Corá",
                "label": "Cerro Corá"
            },
            {
                "value": "Coronel Ezequiel",
                "label": "Coronel Ezequiel"
            },
            {
                "value": "Coronel João Pessoa",
                "label": "Coronel João Pessoa"
            },
            {
                "value": "Cruzeta",
                "label": "Cruzeta"
            },
            {
                "value": "Currais Novos",
                "label": "Currais Novos"
            },
            {
                "value": "Doutor Severiano",
                "label": "Doutor Severiano"
            },
            {
                "value": "Parnamirim",
                "label": "Parnamirim"
            },
            {
                "value": "Encanto",
                "label": "Encanto"
            },
            {
                "value": "Equador",
                "label": "Equador"
            },
            {
                "value": "Espírito Santo",
                "label": "Espírito Santo"
            },
            {
                "value": "Extremoz",
                "label": "Extremoz"
            },
            {
                "value": "Felipe Guerra",
                "label": "Felipe Guerra"
            },
            {
                "value": "Fernando Pedroza",
                "label": "Fernando Pedroza"
            },
            {
                "value": "Florânia",
                "label": "Florânia"
            },
            {
                "value": "Francisco Dantas",
                "label": "Francisco Dantas"
            },
            {
                "value": "Frutuoso Gomes",
                "label": "Frutuoso Gomes"
            },
            {
                "value": "Galinhos",
                "label": "Galinhos"
            },
            {
                "value": "Goianinha",
                "label": "Goianinha"
            },
            {
                "value": "Governador Dix-Sept Rosado",
                "label": "Governador Dix-Sept Rosado"
            },
            {
                "value": "Grossos",
                "label": "Grossos"
            },
            {
                "value": "Guamaré",
                "label": "Guamaré"
            },
            {
                "value": "Ielmo Marinho",
                "label": "Ielmo Marinho"
            },
            {
                "value": "Ipanguaçu",
                "label": "Ipanguaçu"
            },
            {
                "value": "Ipueira",
                "label": "Ipueira"
            },
            {
                "value": "Itajá",
                "label": "Itajá"
            },
            {
                "value": "Itaú",
                "label": "Itaú"
            },
            {
                "value": "Jaçanã",
                "label": "Jaçanã"
            },
            {
                "value": "Jandaíra",
                "label": "Jandaíra"
            },
            {
                "value": "Janduís",
                "label": "Janduís"
            },
            {
                "value": "Januário Cicco",
                "label": "Januário Cicco"
            },
            {
                "value": "Japi",
                "label": "Japi"
            },
            {
                "value": "Jardim de Angicos",
                "label": "Jardim de Angicos"
            },
            {
                "value": "Jardim de Piranhas",
                "label": "Jardim de Piranhas"
            },
            {
                "value": "Jardim do Seridó",
                "label": "Jardim do Seridó"
            },
            {
                "value": "João Câmara",
                "label": "João Câmara"
            },
            {
                "value": "João Dias",
                "label": "João Dias"
            },
            {
                "value": "José da Penha",
                "label": "José da Penha"
            },
            {
                "value": "Jucurutu",
                "label": "Jucurutu"
            },
            {
                "value": "Jundiá",
                "label": "Jundiá"
            },
            {
                "value": "Lagoa d'Anta",
                "label": "Lagoa d'Anta"
            },
            {
                "value": "Lagoa de Pedras",
                "label": "Lagoa de Pedras"
            },
            {
                "value": "Lagoa de Velhos",
                "label": "Lagoa de Velhos"
            },
            {
                "value": "Lagoa Nova",
                "label": "Lagoa Nova"
            },
            {
                "value": "Lagoa Salgada",
                "label": "Lagoa Salgada"
            },
            {
                "value": "Lajes",
                "label": "Lajes"
            },
            {
                "value": "Lajes Pintadas",
                "label": "Lajes Pintadas"
            },
            {
                "value": "Lucrécia",
                "label": "Lucrécia"
            },
            {
                "value": "Luís Gomes",
                "label": "Luís Gomes"
            },
            {
                "value": "Macaíba",
                "label": "Macaíba"
            },
            {
                "value": "Macau",
                "label": "Macau"
            },
            {
                "value": "Major Sales",
                "label": "Major Sales"
            },
            {
                "value": "Marcelino Vieira",
                "label": "Marcelino Vieira"
            },
            {
                "value": "Martins",
                "label": "Martins"
            },
            {
                "value": "Maxaranguape",
                "label": "Maxaranguape"
            },
            {
                "value": "Messias Targino",
                "label": "Messias Targino"
            },
            {
                "value": "Montanhas",
                "label": "Montanhas"
            },
            {
                "value": "Monte Alegre",
                "label": "Monte Alegre"
            },
            {
                "value": "Monte das Gameleiras",
                "label": "Monte das Gameleiras"
            },
            {
                "value": "Mossoró",
                "label": "Mossoró"
            },
            {
                "value": "Natal",
                "label": "Natal"
            },
            {
                "value": "Nísia Floresta",
                "label": "Nísia Floresta"
            },
            {
                "value": "Nova Cruz",
                "label": "Nova Cruz"
            },
            {
                "value": "Olho d'Água do Borges",
                "label": "Olho d'Água do Borges"
            },
            {
                "value": "Ouro Branco",
                "label": "Ouro Branco"
            },
            {
                "value": "Paraná",
                "label": "Paraná"
            },
            {
                "value": "Paraú",
                "label": "Paraú"
            },
            {
                "value": "Parazinho",
                "label": "Parazinho"
            },
            {
                "value": "Parelhas",
                "label": "Parelhas"
            },
            {
                "value": "Rio do Fogo",
                "label": "Rio do Fogo"
            },
            {
                "value": "Passa e Fica",
                "label": "Passa e Fica"
            },
            {
                "value": "Passagem",
                "label": "Passagem"
            },
            {
                "value": "Patu",
                "label": "Patu"
            },
            {
                "value": "Santa Maria",
                "label": "Santa Maria"
            },
            {
                "value": "Pau dos Ferros",
                "label": "Pau dos Ferros"
            },
            {
                "value": "Pedra Grande",
                "label": "Pedra Grande"
            },
            {
                "value": "Pedra Preta",
                "label": "Pedra Preta"
            },
            {
                "value": "Pedro Avelino",
                "label": "Pedro Avelino"
            },
            {
                "value": "Pedro Velho",
                "label": "Pedro Velho"
            },
            {
                "value": "Pendências",
                "label": "Pendências"
            },
            {
                "value": "Pilões",
                "label": "Pilões"
            },
            {
                "value": "Poço Branco",
                "label": "Poço Branco"
            },
            {
                "value": "Portalegre",
                "label": "Portalegre"
            },
            {
                "value": "Porto do Mangue",
                "label": "Porto do Mangue"
            },
            {
                "value": "Serra Caiada",
                "label": "Serra Caiada"
            },
            {
                "value": "Pureza",
                "label": "Pureza"
            },
            {
                "value": "Rafael Fernandes",
                "label": "Rafael Fernandes"
            },
            {
                "value": "Rafael Godeiro",
                "label": "Rafael Godeiro"
            },
            {
                "value": "Riacho da Cruz",
                "label": "Riacho da Cruz"
            },
            {
                "value": "Riacho de Santana",
                "label": "Riacho de Santana"
            },
            {
                "value": "Riachuelo",
                "label": "Riachuelo"
            },
            {
                "value": "Rodolfo Fernandes",
                "label": "Rodolfo Fernandes"
            },
            {
                "value": "Tibau",
                "label": "Tibau"
            },
            {
                "value": "Ruy Barbosa",
                "label": "Ruy Barbosa"
            },
            {
                "value": "Santa Cruz",
                "label": "Santa Cruz"
            },
            {
                "value": "Santana do Matos",
                "label": "Santana do Matos"
            },
            {
                "value": "Santana do Seridó",
                "label": "Santana do Seridó"
            },
            {
                "value": "Santo Antônio",
                "label": "Santo Antônio"
            },
            {
                "value": "São Bento do Norte",
                "label": "São Bento do Norte"
            },
            {
                "value": "São Bento do Trairí",
                "label": "São Bento do Trairí"
            },
            {
                "value": "São Fernando",
                "label": "São Fernando"
            },
            {
                "value": "São Francisco do Oeste",
                "label": "São Francisco do Oeste"
            },
            {
                "value": "São Gonçalo do Amarante",
                "label": "São Gonçalo do Amarante"
            },
            {
                "value": "São João do Sabugi",
                "label": "São João do Sabugi"
            },
            {
                "value": "São José de Mipibu",
                "label": "São José de Mipibu"
            },
            {
                "value": "São José do Campestre",
                "label": "São José do Campestre"
            },
            {
                "value": "São José do Seridó",
                "label": "São José do Seridó"
            },
            {
                "value": "São Miguel",
                "label": "São Miguel"
            },
            {
                "value": "São Miguel do Gostoso",
                "label": "São Miguel do Gostoso"
            },
            {
                "value": "São Paulo do Potengi",
                "label": "São Paulo do Potengi"
            },
            {
                "value": "São Pedro",
                "label": "São Pedro"
            },
            {
                "value": "São Rafael",
                "label": "São Rafael"
            },
            {
                "value": "São Tomé",
                "label": "São Tomé"
            },
            {
                "value": "São Vicente",
                "label": "São Vicente"
            },
            {
                "value": "Senador Elói de Souza",
                "label": "Senador Elói de Souza"
            },
            {
                "value": "Senador Georgino Avelino",
                "label": "Senador Georgino Avelino"
            },
            {
                "value": "Serra de São Bento",
                "label": "Serra de São Bento"
            },
            {
                "value": "Serra do Mel",
                "label": "Serra do Mel"
            },
            {
                "value": "Serra Negra do Norte",
                "label": "Serra Negra do Norte"
            },
            {
                "value": "Serrinha",
                "label": "Serrinha"
            },
            {
                "value": "Serrinha dos Pintos",
                "label": "Serrinha dos Pintos"
            },
            {
                "value": "Severiano Melo",
                "label": "Severiano Melo"
            },
            {
                "value": "Sítio Novo",
                "label": "Sítio Novo"
            },
            {
                "value": "Taboleiro Grande",
                "label": "Taboleiro Grande"
            },
            {
                "value": "Taipu",
                "label": "Taipu"
            },
            {
                "value": "Tangará",
                "label": "Tangará"
            },
            {
                "value": "Tenente Ananias",
                "label": "Tenente Ananias"
            },
            {
                "value": "Tenente Laurentino Cruz",
                "label": "Tenente Laurentino Cruz"
            },
            {
                "value": "Tibau do Sul",
                "label": "Tibau do Sul"
            },
            {
                "value": "Timbaúba dos Batistas",
                "label": "Timbaúba dos Batistas"
            },
            {
                "value": "Touros",
                "label": "Touros"
            },
            {
                "value": "Triunfo Potiguar",
                "label": "Triunfo Potiguar"
            },
            {
                "value": "Umarizal",
                "label": "Umarizal"
            },
            {
                "value": "Upanema",
                "label": "Upanema"
            },
            {
                "value": "Várzea",
                "label": "Várzea"
            },
            {
                "value": "Venha-Ver",
                "label": "Venha-Ver"
            },
            {
                "value": "Vera Cruz",
                "label": "Vera Cruz"
            },
            {
                "value": "Viçosa",
                "label": "Viçosa"
            },
            {
                "value": "Vila Flor",
                "label": "Vila Flor"
            }
        ]
    },
    {
        "value": "RO",
        "label": "Rondônia",
        "children": [
            {
                "value": "Alta Floresta D'Oeste",
                "label": "Alta Floresta D'Oeste"
            },
            {
                "value": "Ariquemes",
                "label": "Ariquemes"
            },
            {
                "value": "Cabixi",
                "label": "Cabixi"
            },
            {
                "value": "Cacoal",
                "label": "Cacoal"
            },
            {
                "value": "Cerejeiras",
                "label": "Cerejeiras"
            },
            {
                "value": "Colorado do Oeste",
                "label": "Colorado do Oeste"
            },
            {
                "value": "Corumbiara",
                "label": "Corumbiara"
            },
            {
                "value": "Costa Marques",
                "label": "Costa Marques"
            },
            {
                "value": "Espigão D'Oeste",
                "label": "Espigão D'Oeste"
            },
            {
                "value": "Guajará-Mirim",
                "label": "Guajará-Mirim"
            },
            {
                "value": "Jaru",
                "label": "Jaru"
            },
            {
                "value": "Ji-Paraná",
                "label": "Ji-Paraná"
            },
            {
                "value": "Machadinho D'Oeste",
                "label": "Machadinho D'Oeste"
            },
            {
                "value": "Nova Brasilândia D'Oeste",
                "label": "Nova Brasilândia D'Oeste"
            },
            {
                "value": "Ouro Preto do Oeste",
                "label": "Ouro Preto do Oeste"
            },
            {
                "value": "Pimenta Bueno",
                "label": "Pimenta Bueno"
            },
            {
                "value": "Porto Velho",
                "label": "Porto Velho"
            },
            {
                "value": "Presidente Médici",
                "label": "Presidente Médici"
            },
            {
                "value": "Rio Crespo",
                "label": "Rio Crespo"
            },
            {
                "value": "Rolim de Moura",
                "label": "Rolim de Moura"
            },
            {
                "value": "Santa Luzia D'Oeste",
                "label": "Santa Luzia D'Oeste"
            },
            {
                "value": "Vilhena",
                "label": "Vilhena"
            },
            {
                "value": "São Miguel do Guaporé",
                "label": "São Miguel do Guaporé"
            },
            {
                "value": "Nova Mamoré",
                "label": "Nova Mamoré"
            },
            {
                "value": "Alvorada D'Oeste",
                "label": "Alvorada D'Oeste"
            },
            {
                "value": "Alto Alegre dos Parecis",
                "label": "Alto Alegre dos Parecis"
            },
            {
                "value": "Alto Paraíso",
                "label": "Alto Paraíso"
            },
            {
                "value": "Buritis",
                "label": "Buritis"
            },
            {
                "value": "Novo Horizonte do Oeste",
                "label": "Novo Horizonte do Oeste"
            },
            {
                "value": "Cacaulândia",
                "label": "Cacaulândia"
            },
            {
                "value": "Campo Novo de Rondônia",
                "label": "Campo Novo de Rondônia"
            },
            {
                "value": "Candeias do Jamari",
                "label": "Candeias do Jamari"
            },
            {
                "value": "Castanheiras",
                "label": "Castanheiras"
            },
            {
                "value": "Chupinguaia",
                "label": "Chupinguaia"
            },
            {
                "value": "Cujubim",
                "label": "Cujubim"
            },
            {
                "value": "Governador Jorge Teixeira",
                "label": "Governador Jorge Teixeira"
            },
            {
                "value": "Itapuã do Oeste",
                "label": "Itapuã do Oeste"
            },
            {
                "value": "Ministro Andreazza",
                "label": "Ministro Andreazza"
            },
            {
                "value": "Mirante da Serra",
                "label": "Mirante da Serra"
            },
            {
                "value": "Monte Negro",
                "label": "Monte Negro"
            },
            {
                "value": "Nova União",
                "label": "Nova União"
            },
            {
                "value": "Parecis",
                "label": "Parecis"
            },
            {
                "value": "Pimenteiras do Oeste",
                "label": "Pimenteiras do Oeste"
            },
            {
                "value": "Primavera de Rondônia",
                "label": "Primavera de Rondônia"
            },
            {
                "value": "São Felipe D'Oeste",
                "label": "São Felipe D'Oeste"
            },
            {
                "value": "São Francisco do Guaporé",
                "label": "São Francisco do Guaporé"
            },
            {
                "value": "Seringueiras",
                "label": "Seringueiras"
            },
            {
                "value": "Teixeirópolis",
                "label": "Teixeirópolis"
            },
            {
                "value": "Theobroma",
                "label": "Theobroma"
            },
            {
                "value": "Urupá",
                "label": "Urupá"
            },
            {
                "value": "Vale do Anari",
                "label": "Vale do Anari"
            },
            {
                "value": "Vale do Paraíso",
                "label": "Vale do Paraíso"
            }
        ]
    },
    {
        "value": "RR",
        "label": "Roraima",
        "children": [
            {
                "value": "Amajari",
                "label": "Amajari"
            },
            {
                "value": "Alto Alegre",
                "label": "Alto Alegre"
            },
            {
                "value": "Boa Vista",
                "label": "Boa Vista"
            },
            {
                "value": "Bonfim",
                "label": "Bonfim"
            },
            {
                "value": "Cantá",
                "label": "Cantá"
            },
            {
                "value": "Caracaraí",
                "label": "Caracaraí"
            },
            {
                "value": "Caroebe",
                "label": "Caroebe"
            },
            {
                "value": "Iracema",
                "label": "Iracema"
            },
            {
                "value": "Mucajaí",
                "label": "Mucajaí"
            },
            {
                "value": "Normandia",
                "label": "Normandia"
            },
            {
                "value": "Pacaraima",
                "label": "Pacaraima"
            },
            {
                "value": "Rorainópolis",
                "label": "Rorainópolis"
            },
            {
                "value": "São João da Baliza",
                "label": "São João da Baliza"
            },
            {
                "value": "São Luiz",
                "label": "São Luiz"
            },
            {
                "value": "Uiramutã",
                "label": "Uiramutã"
            }
        ]
    },
    {
        "value": "RS",
        "label": "Rio Grande do Sul",
        "children": [
            {
                "value": "Aceguá",
                "label": "Aceguá"
            },
            {
                "value": "Água Santa",
                "label": "Água Santa"
            },
            {
                "value": "Agudo",
                "label": "Agudo"
            },
            {
                "value": "Ajuricaba",
                "label": "Ajuricaba"
            },
            {
                "value": "Alecrim",
                "label": "Alecrim"
            },
            {
                "value": "Alegrete",
                "label": "Alegrete"
            },
            {
                "value": "Alegria",
                "label": "Alegria"
            },
            {
                "value": "Almirante Tamandaré do Sul",
                "label": "Almirante Tamandaré do Sul"
            },
            {
                "value": "Alpestre",
                "label": "Alpestre"
            },
            {
                "value": "Alto Alegre",
                "label": "Alto Alegre"
            },
            {
                "value": "Alto Feliz",
                "label": "Alto Feliz"
            },
            {
                "value": "Alvorada",
                "label": "Alvorada"
            },
            {
                "value": "Amaral Ferrador",
                "label": "Amaral Ferrador"
            },
            {
                "value": "Ametista do Sul",
                "label": "Ametista do Sul"
            },
            {
                "value": "André da Rocha",
                "label": "André da Rocha"
            },
            {
                "value": "Anta Gorda",
                "label": "Anta Gorda"
            },
            {
                "value": "Antônio Prado",
                "label": "Antônio Prado"
            },
            {
                "value": "Arambaré",
                "label": "Arambaré"
            },
            {
                "value": "Araricá",
                "label": "Araricá"
            },
            {
                "value": "Aratiba",
                "label": "Aratiba"
            },
            {
                "value": "Arroio do Meio",
                "label": "Arroio do Meio"
            },
            {
                "value": "Arroio do Sal",
                "label": "Arroio do Sal"
            },
            {
                "value": "Arroio do Padre",
                "label": "Arroio do Padre"
            },
            {
                "value": "Arroio dos Ratos",
                "label": "Arroio dos Ratos"
            },
            {
                "value": "Arroio do Tigre",
                "label": "Arroio do Tigre"
            },
            {
                "value": "Arroio Grande",
                "label": "Arroio Grande"
            },
            {
                "value": "Arvorezinha",
                "label": "Arvorezinha"
            },
            {
                "value": "Augusto Pestana",
                "label": "Augusto Pestana"
            },
            {
                "value": "Áurea",
                "label": "Áurea"
            },
            {
                "value": "Bagé",
                "label": "Bagé"
            },
            {
                "value": "Balneário Pinhal",
                "label": "Balneário Pinhal"
            },
            {
                "value": "Barão",
                "label": "Barão"
            },
            {
                "value": "Barão de Cotegipe",
                "label": "Barão de Cotegipe"
            },
            {
                "value": "Barão do Triunfo",
                "label": "Barão do Triunfo"
            },
            {
                "value": "Barracão",
                "label": "Barracão"
            },
            {
                "value": "Barra do Guarita",
                "label": "Barra do Guarita"
            },
            {
                "value": "Barra do Quaraí",
                "label": "Barra do Quaraí"
            },
            {
                "value": "Barra do Ribeiro",
                "label": "Barra do Ribeiro"
            },
            {
                "value": "Barra do Rio Azul",
                "label": "Barra do Rio Azul"
            },
            {
                "value": "Barra Funda",
                "label": "Barra Funda"
            },
            {
                "value": "Barros Cassal",
                "label": "Barros Cassal"
            },
            {
                "value": "Benjamin Constant do Sul",
                "label": "Benjamin Constant do Sul"
            },
            {
                "value": "Bento Gonçalves",
                "label": "Bento Gonçalves"
            },
            {
                "value": "Boa Vista das Missões",
                "label": "Boa Vista das Missões"
            },
            {
                "value": "Boa Vista do Buricá",
                "label": "Boa Vista do Buricá"
            },
            {
                "value": "Boa Vista do Cadeado",
                "label": "Boa Vista do Cadeado"
            },
            {
                "value": "Boa Vista do Incra",
                "label": "Boa Vista do Incra"
            },
            {
                "value": "Boa Vista do Sul",
                "label": "Boa Vista do Sul"
            },
            {
                "value": "Bom Jesus",
                "label": "Bom Jesus"
            },
            {
                "value": "Bom Princípio",
                "label": "Bom Princípio"
            },
            {
                "value": "Bom Progresso",
                "label": "Bom Progresso"
            },
            {
                "value": "Bom Retiro do Sul",
                "label": "Bom Retiro do Sul"
            },
            {
                "value": "Boqueirão do Leão",
                "label": "Boqueirão do Leão"
            },
            {
                "value": "Bossoroca",
                "label": "Bossoroca"
            },
            {
                "value": "Bozano",
                "label": "Bozano"
            },
            {
                "value": "Braga",
                "label": "Braga"
            },
            {
                "value": "Brochier",
                "label": "Brochier"
            },
            {
                "value": "Butiá",
                "label": "Butiá"
            },
            {
                "value": "Caçapava do Sul",
                "label": "Caçapava do Sul"
            },
            {
                "value": "Cacequi",
                "label": "Cacequi"
            },
            {
                "value": "Cachoeira do Sul",
                "label": "Cachoeira do Sul"
            },
            {
                "value": "Cachoeirinha",
                "label": "Cachoeirinha"
            },
            {
                "value": "Cacique Doble",
                "label": "Cacique Doble"
            },
            {
                "value": "Caibaté",
                "label": "Caibaté"
            },
            {
                "value": "Caiçara",
                "label": "Caiçara"
            },
            {
                "value": "Camaquã",
                "label": "Camaquã"
            },
            {
                "value": "Camargo",
                "label": "Camargo"
            },
            {
                "value": "Cambará do Sul",
                "label": "Cambará do Sul"
            },
            {
                "value": "Campestre da Serra",
                "label": "Campestre da Serra"
            },
            {
                "value": "Campina das Missões",
                "label": "Campina das Missões"
            },
            {
                "value": "Campinas do Sul",
                "label": "Campinas do Sul"
            },
            {
                "value": "Campo Bom",
                "label": "Campo Bom"
            },
            {
                "value": "Campo Novo",
                "label": "Campo Novo"
            },
            {
                "value": "Campos Borges",
                "label": "Campos Borges"
            },
            {
                "value": "Candelária",
                "label": "Candelária"
            },
            {
                "value": "Cândido Godói",
                "label": "Cândido Godói"
            },
            {
                "value": "Candiota",
                "label": "Candiota"
            },
            {
                "value": "Canela",
                "label": "Canela"
            },
            {
                "value": "Canguçu",
                "label": "Canguçu"
            },
            {
                "value": "Canoas",
                "label": "Canoas"
            },
            {
                "value": "Canudos do Vale",
                "label": "Canudos do Vale"
            },
            {
                "value": "Capão Bonito do Sul",
                "label": "Capão Bonito do Sul"
            },
            {
                "value": "Capão da Canoa",
                "label": "Capão da Canoa"
            },
            {
                "value": "Capão do Cipó",
                "label": "Capão do Cipó"
            },
            {
                "value": "Capão do Leão",
                "label": "Capão do Leão"
            },
            {
                "value": "Capivari do Sul",
                "label": "Capivari do Sul"
            },
            {
                "value": "Capela de Santana",
                "label": "Capela de Santana"
            },
            {
                "value": "Capitão",
                "label": "Capitão"
            },
            {
                "value": "Carazinho",
                "label": "Carazinho"
            },
            {
                "value": "Caraá",
                "label": "Caraá"
            },
            {
                "value": "Carlos Barbosa",
                "label": "Carlos Barbosa"
            },
            {
                "value": "Carlos Gomes",
                "label": "Carlos Gomes"
            },
            {
                "value": "Casca",
                "label": "Casca"
            },
            {
                "value": "Caseiros",
                "label": "Caseiros"
            },
            {
                "value": "Catuípe",
                "label": "Catuípe"
            },
            {
                "value": "Caxias do Sul",
                "label": "Caxias do Sul"
            },
            {
                "value": "Centenário",
                "label": "Centenário"
            },
            {
                "value": "Cerrito",
                "label": "Cerrito"
            },
            {
                "value": "Cerro Branco",
                "label": "Cerro Branco"
            },
            {
                "value": "Cerro Grande",
                "label": "Cerro Grande"
            },
            {
                "value": "Cerro Grande do Sul",
                "label": "Cerro Grande do Sul"
            },
            {
                "value": "Cerro Largo",
                "label": "Cerro Largo"
            },
            {
                "value": "Chapada",
                "label": "Chapada"
            },
            {
                "value": "Charqueadas",
                "label": "Charqueadas"
            },
            {
                "value": "Charrua",
                "label": "Charrua"
            },
            {
                "value": "Chiapetta",
                "label": "Chiapetta"
            },
            {
                "value": "Chuí",
                "label": "Chuí"
            },
            {
                "value": "Chuvisca",
                "label": "Chuvisca"
            },
            {
                "value": "Cidreira",
                "label": "Cidreira"
            },
            {
                "value": "Ciríaco",
                "label": "Ciríaco"
            },
            {
                "value": "Colinas",
                "label": "Colinas"
            },
            {
                "value": "Colorado",
                "label": "Colorado"
            },
            {
                "value": "Condor",
                "label": "Condor"
            },
            {
                "value": "Constantina",
                "label": "Constantina"
            },
            {
                "value": "Coqueiro Baixo",
                "label": "Coqueiro Baixo"
            },
            {
                "value": "Coqueiros do Sul",
                "label": "Coqueiros do Sul"
            },
            {
                "value": "Coronel Barros",
                "label": "Coronel Barros"
            },
            {
                "value": "Coronel Bicaco",
                "label": "Coronel Bicaco"
            },
            {
                "value": "Coronel Pilar",
                "label": "Coronel Pilar"
            },
            {
                "value": "Cotiporã",
                "label": "Cotiporã"
            },
            {
                "value": "Coxilha",
                "label": "Coxilha"
            },
            {
                "value": "Crissiumal",
                "label": "Crissiumal"
            },
            {
                "value": "Cristal",
                "label": "Cristal"
            },
            {
                "value": "Cristal do Sul",
                "label": "Cristal do Sul"
            },
            {
                "value": "Cruz Alta",
                "label": "Cruz Alta"
            },
            {
                "value": "Cruzaltense",
                "label": "Cruzaltense"
            },
            {
                "value": "Cruzeiro do Sul",
                "label": "Cruzeiro do Sul"
            },
            {
                "value": "David Canabarro",
                "label": "David Canabarro"
            },
            {
                "value": "Derrubadas",
                "label": "Derrubadas"
            },
            {
                "value": "Dezesseis de Novembro",
                "label": "Dezesseis de Novembro"
            },
            {
                "value": "Dilermando de Aguiar",
                "label": "Dilermando de Aguiar"
            },
            {
                "value": "Dois Irmãos",
                "label": "Dois Irmãos"
            },
            {
                "value": "Dois Irmãos das Missões",
                "label": "Dois Irmãos das Missões"
            },
            {
                "value": "Dois Lajeados",
                "label": "Dois Lajeados"
            },
            {
                "value": "Dom Feliciano",
                "label": "Dom Feliciano"
            },
            {
                "value": "Dom Pedro de Alcântara",
                "label": "Dom Pedro de Alcântara"
            },
            {
                "value": "Dom Pedrito",
                "label": "Dom Pedrito"
            },
            {
                "value": "Dona Francisca",
                "label": "Dona Francisca"
            },
            {
                "value": "Doutor Maurício Cardoso",
                "label": "Doutor Maurício Cardoso"
            },
            {
                "value": "Doutor Ricardo",
                "label": "Doutor Ricardo"
            },
            {
                "value": "Eldorado do Sul",
                "label": "Eldorado do Sul"
            },
            {
                "value": "Encantado",
                "label": "Encantado"
            },
            {
                "value": "Encruzilhada do Sul",
                "label": "Encruzilhada do Sul"
            },
            {
                "value": "Engenho Velho",
                "label": "Engenho Velho"
            },
            {
                "value": "Entre-Ijuís",
                "label": "Entre-Ijuís"
            },
            {
                "value": "Entre Rios do Sul",
                "label": "Entre Rios do Sul"
            },
            {
                "value": "Erebango",
                "label": "Erebango"
            },
            {
                "value": "Erechim",
                "label": "Erechim"
            },
            {
                "value": "Ernestina",
                "label": "Ernestina"
            },
            {
                "value": "Herval",
                "label": "Herval"
            },
            {
                "value": "Erval Grande",
                "label": "Erval Grande"
            },
            {
                "value": "Erval Seco",
                "label": "Erval Seco"
            },
            {
                "value": "Esmeralda",
                "label": "Esmeralda"
            },
            {
                "value": "Esperança do Sul",
                "label": "Esperança do Sul"
            },
            {
                "value": "Espumoso",
                "label": "Espumoso"
            },
            {
                "value": "Estação",
                "label": "Estação"
            },
            {
                "value": "Estância Velha",
                "label": "Estância Velha"
            },
            {
                "value": "Esteio",
                "label": "Esteio"
            },
            {
                "value": "Estrela",
                "label": "Estrela"
            },
            {
                "value": "Estrela Velha",
                "label": "Estrela Velha"
            },
            {
                "value": "Eugênio de Castro",
                "label": "Eugênio de Castro"
            },
            {
                "value": "Fagundes Varela",
                "label": "Fagundes Varela"
            },
            {
                "value": "Farroupilha",
                "label": "Farroupilha"
            },
            {
                "value": "Faxinal do Soturno",
                "label": "Faxinal do Soturno"
            },
            {
                "value": "Faxinalzinho",
                "label": "Faxinalzinho"
            },
            {
                "value": "Fazenda Vilanova",
                "label": "Fazenda Vilanova"
            },
            {
                "value": "Feliz",
                "label": "Feliz"
            },
            {
                "value": "Flores da Cunha",
                "label": "Flores da Cunha"
            },
            {
                "value": "Floriano Peixoto",
                "label": "Floriano Peixoto"
            },
            {
                "value": "Fontoura Xavier",
                "label": "Fontoura Xavier"
            },
            {
                "value": "Formigueiro",
                "label": "Formigueiro"
            },
            {
                "value": "Forquetinha",
                "label": "Forquetinha"
            },
            {
                "value": "Fortaleza dos Valos",
                "label": "Fortaleza dos Valos"
            },
            {
                "value": "Frederico Westphalen",
                "label": "Frederico Westphalen"
            },
            {
                "value": "Garibaldi",
                "label": "Garibaldi"
            },
            {
                "value": "Garruchos",
                "label": "Garruchos"
            },
            {
                "value": "Gaurama",
                "label": "Gaurama"
            },
            {
                "value": "General Câmara",
                "label": "General Câmara"
            },
            {
                "value": "Gentil",
                "label": "Gentil"
            },
            {
                "value": "Getúlio Vargas",
                "label": "Getúlio Vargas"
            },
            {
                "value": "Giruá",
                "label": "Giruá"
            },
            {
                "value": "Glorinha",
                "label": "Glorinha"
            },
            {
                "value": "Gramado",
                "label": "Gramado"
            },
            {
                "value": "Gramado dos Loureiros",
                "label": "Gramado dos Loureiros"
            },
            {
                "value": "Gramado Xavier",
                "label": "Gramado Xavier"
            },
            {
                "value": "Gravataí",
                "label": "Gravataí"
            },
            {
                "value": "Guabiju",
                "label": "Guabiju"
            },
            {
                "value": "Guaíba",
                "label": "Guaíba"
            },
            {
                "value": "Guaporé",
                "label": "Guaporé"
            },
            {
                "value": "Guarani das Missões",
                "label": "Guarani das Missões"
            },
            {
                "value": "Harmonia",
                "label": "Harmonia"
            },
            {
                "value": "Herveiras",
                "label": "Herveiras"
            },
            {
                "value": "Horizontina",
                "label": "Horizontina"
            },
            {
                "value": "Hulha Negra",
                "label": "Hulha Negra"
            },
            {
                "value": "Humaitá",
                "label": "Humaitá"
            },
            {
                "value": "Ibarama",
                "label": "Ibarama"
            },
            {
                "value": "Ibiaçá",
                "label": "Ibiaçá"
            },
            {
                "value": "Ibiraiaras",
                "label": "Ibiraiaras"
            },
            {
                "value": "Ibirapuitã",
                "label": "Ibirapuitã"
            },
            {
                "value": "Ibirubá",
                "label": "Ibirubá"
            },
            {
                "value": "Igrejinha",
                "label": "Igrejinha"
            },
            {
                "value": "Ijuí",
                "label": "Ijuí"
            },
            {
                "value": "Ilópolis",
                "label": "Ilópolis"
            },
            {
                "value": "Imbé",
                "label": "Imbé"
            },
            {
                "value": "Imigrante",
                "label": "Imigrante"
            },
            {
                "value": "Independência",
                "label": "Independência"
            },
            {
                "value": "Inhacorá",
                "label": "Inhacorá"
            },
            {
                "value": "Ipê",
                "label": "Ipê"
            },
            {
                "value": "Ipiranga do Sul",
                "label": "Ipiranga do Sul"
            },
            {
                "value": "Iraí",
                "label": "Iraí"
            },
            {
                "value": "Itaara",
                "label": "Itaara"
            },
            {
                "value": "Itacurubi",
                "label": "Itacurubi"
            },
            {
                "value": "Itapuca",
                "label": "Itapuca"
            },
            {
                "value": "Itaqui",
                "label": "Itaqui"
            },
            {
                "value": "Itati",
                "label": "Itati"
            },
            {
                "value": "Itatiba do Sul",
                "label": "Itatiba do Sul"
            },
            {
                "value": "Ivorá",
                "label": "Ivorá"
            },
            {
                "value": "Ivoti",
                "label": "Ivoti"
            },
            {
                "value": "Jaboticaba",
                "label": "Jaboticaba"
            },
            {
                "value": "Jacuizinho",
                "label": "Jacuizinho"
            },
            {
                "value": "Jacutinga",
                "label": "Jacutinga"
            },
            {
                "value": "Jaguarão",
                "label": "Jaguarão"
            },
            {
                "value": "Jaguari",
                "label": "Jaguari"
            },
            {
                "value": "Jaquirana",
                "label": "Jaquirana"
            },
            {
                "value": "Jari",
                "label": "Jari"
            },
            {
                "value": "Jóia",
                "label": "Jóia"
            },
            {
                "value": "Júlio de Castilhos",
                "label": "Júlio de Castilhos"
            },
            {
                "value": "Lagoa Bonita do Sul",
                "label": "Lagoa Bonita do Sul"
            },
            {
                "value": "Lagoão",
                "label": "Lagoão"
            },
            {
                "value": "Lagoa dos Três Cantos",
                "label": "Lagoa dos Três Cantos"
            },
            {
                "value": "Lagoa Vermelha",
                "label": "Lagoa Vermelha"
            },
            {
                "value": "Lajeado",
                "label": "Lajeado"
            },
            {
                "value": "Lajeado do Bugre",
                "label": "Lajeado do Bugre"
            },
            {
                "value": "Lavras do Sul",
                "label": "Lavras do Sul"
            },
            {
                "value": "Liberato Salzano",
                "label": "Liberato Salzano"
            },
            {
                "value": "Lindolfo Collor",
                "label": "Lindolfo Collor"
            },
            {
                "value": "Linha Nova",
                "label": "Linha Nova"
            },
            {
                "value": "Machadinho",
                "label": "Machadinho"
            },
            {
                "value": "Maçambará",
                "label": "Maçambará"
            },
            {
                "value": "Mampituba",
                "label": "Mampituba"
            },
            {
                "value": "Manoel Viana",
                "label": "Manoel Viana"
            },
            {
                "value": "Maquiné",
                "label": "Maquiné"
            },
            {
                "value": "Maratá",
                "label": "Maratá"
            },
            {
                "value": "Marau",
                "label": "Marau"
            },
            {
                "value": "Marcelino Ramos",
                "label": "Marcelino Ramos"
            },
            {
                "value": "Mariana Pimentel",
                "label": "Mariana Pimentel"
            },
            {
                "value": "Mariano Moro",
                "label": "Mariano Moro"
            },
            {
                "value": "Marques de Souza",
                "label": "Marques de Souza"
            },
            {
                "value": "Mata",
                "label": "Mata"
            },
            {
                "value": "Mato Castelhano",
                "label": "Mato Castelhano"
            },
            {
                "value": "Mato Leitão",
                "label": "Mato Leitão"
            },
            {
                "value": "Mato Queimado",
                "label": "Mato Queimado"
            },
            {
                "value": "Maximiliano de Almeida",
                "label": "Maximiliano de Almeida"
            },
            {
                "value": "Minas do Leão",
                "label": "Minas do Leão"
            },
            {
                "value": "Miraguaí",
                "label": "Miraguaí"
            },
            {
                "value": "Montauri",
                "label": "Montauri"
            },
            {
                "value": "Monte Alegre dos Campos",
                "label": "Monte Alegre dos Campos"
            },
            {
                "value": "Monte Belo do Sul",
                "label": "Monte Belo do Sul"
            },
            {
                "value": "Montenegro",
                "label": "Montenegro"
            },
            {
                "value": "Mormaço",
                "label": "Mormaço"
            },
            {
                "value": "Morrinhos do Sul",
                "label": "Morrinhos do Sul"
            },
            {
                "value": "Morro Redondo",
                "label": "Morro Redondo"
            },
            {
                "value": "Morro Reuter",
                "label": "Morro Reuter"
            },
            {
                "value": "Mostardas",
                "label": "Mostardas"
            },
            {
                "value": "Muçum",
                "label": "Muçum"
            },
            {
                "value": "Muitos Capões",
                "label": "Muitos Capões"
            },
            {
                "value": "Muliterno",
                "label": "Muliterno"
            },
            {
                "value": "Não-Me-Toque",
                "label": "Não-Me-Toque"
            },
            {
                "value": "Nicolau Vergueiro",
                "label": "Nicolau Vergueiro"
            },
            {
                "value": "Nonoai",
                "label": "Nonoai"
            },
            {
                "value": "Nova Alvorada",
                "label": "Nova Alvorada"
            },
            {
                "value": "Nova Araçá",
                "label": "Nova Araçá"
            },
            {
                "value": "Nova Bassano",
                "label": "Nova Bassano"
            },
            {
                "value": "Nova Boa Vista",
                "label": "Nova Boa Vista"
            },
            {
                "value": "Nova Bréscia",
                "label": "Nova Bréscia"
            },
            {
                "value": "Nova Candelária",
                "label": "Nova Candelária"
            },
            {
                "value": "Nova Esperança do Sul",
                "label": "Nova Esperança do Sul"
            },
            {
                "value": "Nova Hartz",
                "label": "Nova Hartz"
            },
            {
                "value": "Nova Pádua",
                "label": "Nova Pádua"
            },
            {
                "value": "Nova Palma",
                "label": "Nova Palma"
            },
            {
                "value": "Nova Petrópolis",
                "label": "Nova Petrópolis"
            },
            {
                "value": "Nova Prata",
                "label": "Nova Prata"
            },
            {
                "value": "Nova Ramada",
                "label": "Nova Ramada"
            },
            {
                "value": "Nova Roma do Sul",
                "label": "Nova Roma do Sul"
            },
            {
                "value": "Nova Santa Rita",
                "label": "Nova Santa Rita"
            },
            {
                "value": "Novo Cabrais",
                "label": "Novo Cabrais"
            },
            {
                "value": "Novo Hamburgo",
                "label": "Novo Hamburgo"
            },
            {
                "value": "Novo Machado",
                "label": "Novo Machado"
            },
            {
                "value": "Novo Tiradentes",
                "label": "Novo Tiradentes"
            },
            {
                "value": "Novo Xingu",
                "label": "Novo Xingu"
            },
            {
                "value": "Novo Barreiro",
                "label": "Novo Barreiro"
            },
            {
                "value": "Osório",
                "label": "Osório"
            },
            {
                "value": "Paim Filho",
                "label": "Paim Filho"
            },
            {
                "value": "Palmares do Sul",
                "label": "Palmares do Sul"
            },
            {
                "value": "Palmeira das Missões",
                "label": "Palmeira das Missões"
            },
            {
                "value": "Palmitinho",
                "label": "Palmitinho"
            },
            {
                "value": "Panambi",
                "label": "Panambi"
            },
            {
                "value": "Pantano Grande",
                "label": "Pantano Grande"
            },
            {
                "value": "Paraí",
                "label": "Paraí"
            },
            {
                "value": "Paraíso do Sul",
                "label": "Paraíso do Sul"
            },
            {
                "value": "Pareci Novo",
                "label": "Pareci Novo"
            },
            {
                "value": "Parobé",
                "label": "Parobé"
            },
            {
                "value": "Passa Sete",
                "label": "Passa Sete"
            },
            {
                "value": "Passo do Sobrado",
                "label": "Passo do Sobrado"
            },
            {
                "value": "Passo Fundo",
                "label": "Passo Fundo"
            },
            {
                "value": "Paulo Bento",
                "label": "Paulo Bento"
            },
            {
                "value": "Paverama",
                "label": "Paverama"
            },
            {
                "value": "Pedras Altas",
                "label": "Pedras Altas"
            },
            {
                "value": "Pedro Osório",
                "label": "Pedro Osório"
            },
            {
                "value": "Pejuçara",
                "label": "Pejuçara"
            },
            {
                "value": "Pelotas",
                "label": "Pelotas"
            },
            {
                "value": "Picada Café",
                "label": "Picada Café"
            },
            {
                "value": "Pinhal",
                "label": "Pinhal"
            },
            {
                "value": "Pinhal da Serra",
                "label": "Pinhal da Serra"
            },
            {
                "value": "Pinhal Grande",
                "label": "Pinhal Grande"
            },
            {
                "value": "Pinheirinho do Vale",
                "label": "Pinheirinho do Vale"
            },
            {
                "value": "Pinheiro Machado",
                "label": "Pinheiro Machado"
            },
            {
                "value": "Pinto Bandeira",
                "label": "Pinto Bandeira"
            },
            {
                "value": "Pirapó",
                "label": "Pirapó"
            },
            {
                "value": "Piratini",
                "label": "Piratini"
            },
            {
                "value": "Planalto",
                "label": "Planalto"
            },
            {
                "value": "Poço das Antas",
                "label": "Poço das Antas"
            },
            {
                "value": "Pontão",
                "label": "Pontão"
            },
            {
                "value": "Ponte Preta",
                "label": "Ponte Preta"
            },
            {
                "value": "Portão",
                "label": "Portão"
            },
            {
                "value": "Porto Alegre",
                "label": "Porto Alegre"
            },
            {
                "value": "Porto Lucena",
                "label": "Porto Lucena"
            },
            {
                "value": "Porto Mauá",
                "label": "Porto Mauá"
            },
            {
                "value": "Porto Vera Cruz",
                "label": "Porto Vera Cruz"
            },
            {
                "value": "Porto Xavier",
                "label": "Porto Xavier"
            },
            {
                "value": "Pouso Novo",
                "label": "Pouso Novo"
            },
            {
                "value": "Presidente Lucena",
                "label": "Presidente Lucena"
            },
            {
                "value": "Progresso",
                "label": "Progresso"
            },
            {
                "value": "Protásio Alves",
                "label": "Protásio Alves"
            },
            {
                "value": "Putinga",
                "label": "Putinga"
            },
            {
                "value": "Quaraí",
                "label": "Quaraí"
            },
            {
                "value": "Quatro Irmãos",
                "label": "Quatro Irmãos"
            },
            {
                "value": "Quevedos",
                "label": "Quevedos"
            },
            {
                "value": "Quinze de Novembro",
                "label": "Quinze de Novembro"
            },
            {
                "value": "Redentora",
                "label": "Redentora"
            },
            {
                "value": "Relvado",
                "label": "Relvado"
            },
            {
                "value": "Restinga Sêca",
                "label": "Restinga Sêca"
            },
            {
                "value": "Rio dos Índios",
                "label": "Rio dos Índios"
            },
            {
                "value": "Rio Grande",
                "label": "Rio Grande"
            },
            {
                "value": "Rio Pardo",
                "label": "Rio Pardo"
            },
            {
                "value": "Riozinho",
                "label": "Riozinho"
            },
            {
                "value": "Roca Sales",
                "label": "Roca Sales"
            },
            {
                "value": "Rodeio Bonito",
                "label": "Rodeio Bonito"
            },
            {
                "value": "Rolador",
                "label": "Rolador"
            },
            {
                "value": "Rolante",
                "label": "Rolante"
            },
            {
                "value": "Ronda Alta",
                "label": "Ronda Alta"
            },
            {
                "value": "Rondinha",
                "label": "Rondinha"
            },
            {
                "value": "Roque Gonzales",
                "label": "Roque Gonzales"
            },
            {
                "value": "Rosário do Sul",
                "label": "Rosário do Sul"
            },
            {
                "value": "Sagrada Família",
                "label": "Sagrada Família"
            },
            {
                "value": "Saldanha Marinho",
                "label": "Saldanha Marinho"
            },
            {
                "value": "Salto do Jacuí",
                "label": "Salto do Jacuí"
            },
            {
                "value": "Salvador das Missões",
                "label": "Salvador das Missões"
            },
            {
                "value": "Salvador do Sul",
                "label": "Salvador do Sul"
            },
            {
                "value": "Sananduva",
                "label": "Sananduva"
            },
            {
                "value": "Santa Bárbara do Sul",
                "label": "Santa Bárbara do Sul"
            },
            {
                "value": "Santa Cecília do Sul",
                "label": "Santa Cecília do Sul"
            },
            {
                "value": "Santa Clara do Sul",
                "label": "Santa Clara do Sul"
            },
            {
                "value": "Santa Cruz do Sul",
                "label": "Santa Cruz do Sul"
            },
            {
                "value": "Santa Maria",
                "label": "Santa Maria"
            },
            {
                "value": "Santa Maria do Herval",
                "label": "Santa Maria do Herval"
            },
            {
                "value": "Santa Margarida do Sul",
                "label": "Santa Margarida do Sul"
            },
            {
                "value": "Santana da Boa Vista",
                "label": "Santana da Boa Vista"
            },
            {
                "value": "Sant'Ana do Livramento",
                "label": "Sant'Ana do Livramento"
            },
            {
                "value": "Santa Rosa",
                "label": "Santa Rosa"
            },
            {
                "value": "Santa Tereza",
                "label": "Santa Tereza"
            },
            {
                "value": "Santa Vitória do Palmar",
                "label": "Santa Vitória do Palmar"
            },
            {
                "value": "Santiago",
                "label": "Santiago"
            },
            {
                "value": "Santo Ângelo",
                "label": "Santo Ângelo"
            },
            {
                "value": "Santo Antônio do Palma",
                "label": "Santo Antônio do Palma"
            },
            {
                "value": "Santo Antônio da Patrulha",
                "label": "Santo Antônio da Patrulha"
            },
            {
                "value": "Santo Antônio das Missões",
                "label": "Santo Antônio das Missões"
            },
            {
                "value": "Santo Antônio do Planalto",
                "label": "Santo Antônio do Planalto"
            },
            {
                "value": "Santo Augusto",
                "label": "Santo Augusto"
            },
            {
                "value": "Santo Cristo",
                "label": "Santo Cristo"
            },
            {
                "value": "Santo Expedito do Sul",
                "label": "Santo Expedito do Sul"
            },
            {
                "value": "São Borja",
                "label": "São Borja"
            },
            {
                "value": "São Domingos do Sul",
                "label": "São Domingos do Sul"
            },
            {
                "value": "São Francisco de Assis",
                "label": "São Francisco de Assis"
            },
            {
                "value": "São Francisco de Paula",
                "label": "São Francisco de Paula"
            },
            {
                "value": "São Gabriel",
                "label": "São Gabriel"
            },
            {
                "value": "São Jerônimo",
                "label": "São Jerônimo"
            },
            {
                "value": "São João da Urtiga",
                "label": "São João da Urtiga"
            },
            {
                "value": "São João do Polêsine",
                "label": "São João do Polêsine"
            },
            {
                "value": "São Jorge",
                "label": "São Jorge"
            },
            {
                "value": "São José das Missões",
                "label": "São José das Missões"
            },
            {
                "value": "São José do Herval",
                "label": "São José do Herval"
            },
            {
                "value": "São José do Hortêncio",
                "label": "São José do Hortêncio"
            },
            {
                "value": "São José do Inhacorá",
                "label": "São José do Inhacorá"
            },
            {
                "value": "São José do Norte",
                "label": "São José do Norte"
            },
            {
                "value": "São José do Ouro",
                "label": "São José do Ouro"
            },
            {
                "value": "São José do Sul",
                "label": "São José do Sul"
            },
            {
                "value": "São José dos Ausentes",
                "label": "São José dos Ausentes"
            },
            {
                "value": "São Leopoldo",
                "label": "São Leopoldo"
            },
            {
                "value": "São Lourenço do Sul",
                "label": "São Lourenço do Sul"
            },
            {
                "value": "São Luiz Gonzaga",
                "label": "São Luiz Gonzaga"
            },
            {
                "value": "São Marcos",
                "label": "São Marcos"
            },
            {
                "value": "São Martinho",
                "label": "São Martinho"
            },
            {
                "value": "São Martinho da Serra",
                "label": "São Martinho da Serra"
            },
            {
                "value": "São Miguel das Missões",
                "label": "São Miguel das Missões"
            },
            {
                "value": "São Nicolau",
                "label": "São Nicolau"
            },
            {
                "value": "São Paulo das Missões",
                "label": "São Paulo das Missões"
            },
            {
                "value": "São Pedro da Serra",
                "label": "São Pedro da Serra"
            },
            {
                "value": "São Pedro das Missões",
                "label": "São Pedro das Missões"
            },
            {
                "value": "São Pedro do Butiá",
                "label": "São Pedro do Butiá"
            },
            {
                "value": "São Pedro do Sul",
                "label": "São Pedro do Sul"
            },
            {
                "value": "São Sebastião do Caí",
                "label": "São Sebastião do Caí"
            },
            {
                "value": "São Sepé",
                "label": "São Sepé"
            },
            {
                "value": "São Valentim",
                "label": "São Valentim"
            },
            {
                "value": "São Valentim do Sul",
                "label": "São Valentim do Sul"
            },
            {
                "value": "São Valério do Sul",
                "label": "São Valério do Sul"
            },
            {
                "value": "São Vendelino",
                "label": "São Vendelino"
            },
            {
                "value": "São Vicente do Sul",
                "label": "São Vicente do Sul"
            },
            {
                "value": "Sapiranga",
                "label": "Sapiranga"
            },
            {
                "value": "Sapucaia do Sul",
                "label": "Sapucaia do Sul"
            },
            {
                "value": "Sarandi",
                "label": "Sarandi"
            },
            {
                "value": "Seberi",
                "label": "Seberi"
            },
            {
                "value": "Sede Nova",
                "label": "Sede Nova"
            },
            {
                "value": "Segredo",
                "label": "Segredo"
            },
            {
                "value": "Selbach",
                "label": "Selbach"
            },
            {
                "value": "Senador Salgado Filho",
                "label": "Senador Salgado Filho"
            },
            {
                "value": "Sentinela do Sul",
                "label": "Sentinela do Sul"
            },
            {
                "value": "Serafina Corrêa",
                "label": "Serafina Corrêa"
            },
            {
                "value": "Sério",
                "label": "Sério"
            },
            {
                "value": "Sertão",
                "label": "Sertão"
            },
            {
                "value": "Sertão Santana",
                "label": "Sertão Santana"
            },
            {
                "value": "Sete de Setembro",
                "label": "Sete de Setembro"
            },
            {
                "value": "Severiano de Almeida",
                "label": "Severiano de Almeida"
            },
            {
                "value": "Silveira Martins",
                "label": "Silveira Martins"
            },
            {
                "value": "Sinimbu",
                "label": "Sinimbu"
            },
            {
                "value": "Sobradinho",
                "label": "Sobradinho"
            },
            {
                "value": "Soledade",
                "label": "Soledade"
            },
            {
                "value": "Tabaí",
                "label": "Tabaí"
            },
            {
                "value": "Tapejara",
                "label": "Tapejara"
            },
            {
                "value": "Tapera",
                "label": "Tapera"
            },
            {
                "value": "Tapes",
                "label": "Tapes"
            },
            {
                "value": "Taquara",
                "label": "Taquara"
            },
            {
                "value": "Taquari",
                "label": "Taquari"
            },
            {
                "value": "Taquaruçu do Sul",
                "label": "Taquaruçu do Sul"
            },
            {
                "value": "Tavares",
                "label": "Tavares"
            },
            {
                "value": "Tenente Portela",
                "label": "Tenente Portela"
            },
            {
                "value": "Terra de Areia",
                "label": "Terra de Areia"
            },
            {
                "value": "Teutônia",
                "label": "Teutônia"
            },
            {
                "value": "Tio Hugo",
                "label": "Tio Hugo"
            },
            {
                "value": "Tiradentes do Sul",
                "label": "Tiradentes do Sul"
            },
            {
                "value": "Toropi",
                "label": "Toropi"
            },
            {
                "value": "Torres",
                "label": "Torres"
            },
            {
                "value": "Tramandaí",
                "label": "Tramandaí"
            },
            {
                "value": "Travesseiro",
                "label": "Travesseiro"
            },
            {
                "value": "Três Arroios",
                "label": "Três Arroios"
            },
            {
                "value": "Três Cachoeiras",
                "label": "Três Cachoeiras"
            },
            {
                "value": "Três Coroas",
                "label": "Três Coroas"
            },
            {
                "value": "Três de Maio",
                "label": "Três de Maio"
            },
            {
                "value": "Três Forquilhas",
                "label": "Três Forquilhas"
            },
            {
                "value": "Três Palmeiras",
                "label": "Três Palmeiras"
            },
            {
                "value": "Três Passos",
                "label": "Três Passos"
            },
            {
                "value": "Trindade do Sul",
                "label": "Trindade do Sul"
            },
            {
                "value": "Triunfo",
                "label": "Triunfo"
            },
            {
                "value": "Tucunduva",
                "label": "Tucunduva"
            },
            {
                "value": "Tunas",
                "label": "Tunas"
            },
            {
                "value": "Tupanci do Sul",
                "label": "Tupanci do Sul"
            },
            {
                "value": "Tupanciretã",
                "label": "Tupanciretã"
            },
            {
                "value": "Tupandi",
                "label": "Tupandi"
            },
            {
                "value": "Tuparendi",
                "label": "Tuparendi"
            },
            {
                "value": "Turuçu",
                "label": "Turuçu"
            },
            {
                "value": "Ubiretama",
                "label": "Ubiretama"
            },
            {
                "value": "União da Serra",
                "label": "União da Serra"
            },
            {
                "value": "Unistalda",
                "label": "Unistalda"
            },
            {
                "value": "Uruguaiana",
                "label": "Uruguaiana"
            },
            {
                "value": "Vacaria",
                "label": "Vacaria"
            },
            {
                "value": "Vale Verde",
                "label": "Vale Verde"
            },
            {
                "value": "Vale do Sol",
                "label": "Vale do Sol"
            },
            {
                "value": "Vale Real",
                "label": "Vale Real"
            },
            {
                "value": "Vanini",
                "label": "Vanini"
            },
            {
                "value": "Venâncio Aires",
                "label": "Venâncio Aires"
            },
            {
                "value": "Vera Cruz",
                "label": "Vera Cruz"
            },
            {
                "value": "Veranópolis",
                "label": "Veranópolis"
            },
            {
                "value": "Vespasiano Corrêa",
                "label": "Vespasiano Corrêa"
            },
            {
                "value": "Viadutos",
                "label": "Viadutos"
            },
            {
                "value": "Viamão",
                "label": "Viamão"
            },
            {
                "value": "Vicente Dutra",
                "label": "Vicente Dutra"
            },
            {
                "value": "Victor Graeff",
                "label": "Victor Graeff"
            },
            {
                "value": "Vila Flores",
                "label": "Vila Flores"
            },
            {
                "value": "Vila Lângaro",
                "label": "Vila Lângaro"
            },
            {
                "value": "Vila Maria",
                "label": "Vila Maria"
            },
            {
                "value": "Vila Nova do Sul",
                "label": "Vila Nova do Sul"
            },
            {
                "value": "Vista Alegre",
                "label": "Vista Alegre"
            },
            {
                "value": "Vista Alegre do Prata",
                "label": "Vista Alegre do Prata"
            },
            {
                "value": "Vista Gaúcha",
                "label": "Vista Gaúcha"
            },
            {
                "value": "Vitória das Missões",
                "label": "Vitória das Missões"
            },
            {
                "value": "Westfália",
                "label": "Westfália"
            },
            {
                "value": "Xangri-lá",
                "label": "Xangri-lá"
            }
        ]
    },
    {
        "value": "SC",
        "label": "Santa Catarina",
        "children": [
            {
                "value": "Abdon Batista",
                "label": "Abdon Batista"
            },
            {
                "value": "Abelardo Luz",
                "label": "Abelardo Luz"
            },
            {
                "value": "Agrolândia",
                "label": "Agrolândia"
            },
            {
                "value": "Agronômica",
                "label": "Agronômica"
            },
            {
                "value": "Água Doce",
                "label": "Água Doce"
            },
            {
                "value": "Águas de Chapecó",
                "label": "Águas de Chapecó"
            },
            {
                "value": "Águas Frias",
                "label": "Águas Frias"
            },
            {
                "value": "Águas Mornas",
                "label": "Águas Mornas"
            },
            {
                "value": "Alfredo Wagner",
                "label": "Alfredo Wagner"
            },
            {
                "value": "Alto Bela Vista",
                "label": "Alto Bela Vista"
            },
            {
                "value": "Anchieta",
                "label": "Anchieta"
            },
            {
                "value": "Angelina",
                "label": "Angelina"
            },
            {
                "value": "Anita Garibaldi",
                "label": "Anita Garibaldi"
            },
            {
                "value": "Anitápolis",
                "label": "Anitápolis"
            },
            {
                "value": "Antônio Carlos",
                "label": "Antônio Carlos"
            },
            {
                "value": "Apiúna",
                "label": "Apiúna"
            },
            {
                "value": "Arabutã",
                "label": "Arabutã"
            },
            {
                "value": "Araquari",
                "label": "Araquari"
            },
            {
                "value": "Araranguá",
                "label": "Araranguá"
            },
            {
                "value": "Armazém",
                "label": "Armazém"
            },
            {
                "value": "Arroio Trinta",
                "label": "Arroio Trinta"
            },
            {
                "value": "Arvoredo",
                "label": "Arvoredo"
            },
            {
                "value": "Ascurra",
                "label": "Ascurra"
            },
            {
                "value": "Atalanta",
                "label": "Atalanta"
            },
            {
                "value": "Aurora",
                "label": "Aurora"
            },
            {
                "value": "Balneário Arroio do Silva",
                "label": "Balneário Arroio do Silva"
            },
            {
                "value": "Balneário Camboriú",
                "label": "Balneário Camboriú"
            },
            {
                "value": "Balneário Barra do Sul",
                "label": "Balneário Barra do Sul"
            },
            {
                "value": "Balneário Gaivota",
                "label": "Balneário Gaivota"
            },
            {
                "value": "Bandeirante",
                "label": "Bandeirante"
            },
            {
                "value": "Barra Bonita",
                "label": "Barra Bonita"
            },
            {
                "value": "Barra Velha",
                "label": "Barra Velha"
            },
            {
                "value": "Bela Vista do Toldo",
                "label": "Bela Vista do Toldo"
            },
            {
                "value": "Belmonte",
                "label": "Belmonte"
            },
            {
                "value": "Benedito Novo",
                "label": "Benedito Novo"
            },
            {
                "value": "Biguaçu",
                "label": "Biguaçu"
            },
            {
                "value": "Blumenau",
                "label": "Blumenau"
            },
            {
                "value": "Bocaina do Sul",
                "label": "Bocaina do Sul"
            },
            {
                "value": "Bombinhas",
                "label": "Bombinhas"
            },
            {
                "value": "Bom Jardim da Serra",
                "label": "Bom Jardim da Serra"
            },
            {
                "value": "Bom Jesus",
                "label": "Bom Jesus"
            },
            {
                "value": "Bom Jesus do Oeste",
                "label": "Bom Jesus do Oeste"
            },
            {
                "value": "Bom Retiro",
                "label": "Bom Retiro"
            },
            {
                "value": "Botuverá",
                "label": "Botuverá"
            },
            {
                "value": "Braço do Norte",
                "label": "Braço do Norte"
            },
            {
                "value": "Braço do Trombudo",
                "label": "Braço do Trombudo"
            },
            {
                "value": "Brunópolis",
                "label": "Brunópolis"
            },
            {
                "value": "Brusque",
                "label": "Brusque"
            },
            {
                "value": "Caçador",
                "label": "Caçador"
            },
            {
                "value": "Caibi",
                "label": "Caibi"
            },
            {
                "value": "Calmon",
                "label": "Calmon"
            },
            {
                "value": "Camboriú",
                "label": "Camboriú"
            },
            {
                "value": "Capão Alto",
                "label": "Capão Alto"
            },
            {
                "value": "Campo Alegre",
                "label": "Campo Alegre"
            },
            {
                "value": "Campo Belo do Sul",
                "label": "Campo Belo do Sul"
            },
            {
                "value": "Campo Erê",
                "label": "Campo Erê"
            },
            {
                "value": "Campos Novos",
                "label": "Campos Novos"
            },
            {
                "value": "Canelinha",
                "label": "Canelinha"
            },
            {
                "value": "Canoinhas",
                "label": "Canoinhas"
            },
            {
                "value": "Capinzal",
                "label": "Capinzal"
            },
            {
                "value": "Capivari de Baixo",
                "label": "Capivari de Baixo"
            },
            {
                "value": "Catanduvas",
                "label": "Catanduvas"
            },
            {
                "value": "Caxambu do Sul",
                "label": "Caxambu do Sul"
            },
            {
                "value": "Celso Ramos",
                "label": "Celso Ramos"
            },
            {
                "value": "Cerro Negro",
                "label": "Cerro Negro"
            },
            {
                "value": "Chapadão do Lageado",
                "label": "Chapadão do Lageado"
            },
            {
                "value": "Chapecó",
                "label": "Chapecó"
            },
            {
                "value": "Cocal do Sul",
                "label": "Cocal do Sul"
            },
            {
                "value": "Concórdia",
                "label": "Concórdia"
            },
            {
                "value": "Cordilheira Alta",
                "label": "Cordilheira Alta"
            },
            {
                "value": "Coronel Freitas",
                "label": "Coronel Freitas"
            },
            {
                "value": "Coronel Martins",
                "label": "Coronel Martins"
            },
            {
                "value": "Corupá",
                "label": "Corupá"
            },
            {
                "value": "Correia Pinto",
                "label": "Correia Pinto"
            },
            {
                "value": "Criciúma",
                "label": "Criciúma"
            },
            {
                "value": "Cunha Porã",
                "label": "Cunha Porã"
            },
            {
                "value": "Cunhataí",
                "label": "Cunhataí"
            },
            {
                "value": "Curitibanos",
                "label": "Curitibanos"
            },
            {
                "value": "Descanso",
                "label": "Descanso"
            },
            {
                "value": "Dionísio Cerqueira",
                "label": "Dionísio Cerqueira"
            },
            {
                "value": "Dona Emma",
                "label": "Dona Emma"
            },
            {
                "value": "Doutor Pedrinho",
                "label": "Doutor Pedrinho"
            },
            {
                "value": "Entre Rios",
                "label": "Entre Rios"
            },
            {
                "value": "Ermo",
                "label": "Ermo"
            },
            {
                "value": "Erval Velho",
                "label": "Erval Velho"
            },
            {
                "value": "Faxinal dos Guedes",
                "label": "Faxinal dos Guedes"
            },
            {
                "value": "Flor do Sertão",
                "label": "Flor do Sertão"
            },
            {
                "value": "Florianópolis",
                "label": "Florianópolis"
            },
            {
                "value": "Formosa do Sul",
                "label": "Formosa do Sul"
            },
            {
                "value": "Forquilhinha",
                "label": "Forquilhinha"
            },
            {
                "value": "Fraiburgo",
                "label": "Fraiburgo"
            },
            {
                "value": "Frei Rogério",
                "label": "Frei Rogério"
            },
            {
                "value": "Galvão",
                "label": "Galvão"
            },
            {
                "value": "Garopaba",
                "label": "Garopaba"
            },
            {
                "value": "Garuva",
                "label": "Garuva"
            },
            {
                "value": "Gaspar",
                "label": "Gaspar"
            },
            {
                "value": "Governador Celso Ramos",
                "label": "Governador Celso Ramos"
            },
            {
                "value": "Grão Pará",
                "label": "Grão Pará"
            },
            {
                "value": "Gravatal",
                "label": "Gravatal"
            },
            {
                "value": "Guabiruba",
                "label": "Guabiruba"
            },
            {
                "value": "Guaraciaba",
                "label": "Guaraciaba"
            },
            {
                "value": "Guaramirim",
                "label": "Guaramirim"
            },
            {
                "value": "Guarujá do Sul",
                "label": "Guarujá do Sul"
            },
            {
                "value": "Guatambú",
                "label": "Guatambú"
            },
            {
                "value": "Herval d'Oeste",
                "label": "Herval d'Oeste"
            },
            {
                "value": "Ibiam",
                "label": "Ibiam"
            },
            {
                "value": "Ibicaré",
                "label": "Ibicaré"
            },
            {
                "value": "Ibirama",
                "label": "Ibirama"
            },
            {
                "value": "Içara",
                "label": "Içara"
            },
            {
                "value": "Ilhota",
                "label": "Ilhota"
            },
            {
                "value": "Imaruí",
                "label": "Imaruí"
            },
            {
                "value": "Imbituba",
                "label": "Imbituba"
            },
            {
                "value": "Imbuia",
                "label": "Imbuia"
            },
            {
                "value": "Indaial",
                "label": "Indaial"
            },
            {
                "value": "Iomerê",
                "label": "Iomerê"
            },
            {
                "value": "Ipira",
                "label": "Ipira"
            },
            {
                "value": "Iporã do Oeste",
                "label": "Iporã do Oeste"
            },
            {
                "value": "Ipuaçu",
                "label": "Ipuaçu"
            },
            {
                "value": "Ipumirim",
                "label": "Ipumirim"
            },
            {
                "value": "Iraceminha",
                "label": "Iraceminha"
            },
            {
                "value": "Irani",
                "label": "Irani"
            },
            {
                "value": "Irati",
                "label": "Irati"
            },
            {
                "value": "Irineópolis",
                "label": "Irineópolis"
            },
            {
                "value": "Itá",
                "label": "Itá"
            },
            {
                "value": "Itaiópolis",
                "label": "Itaiópolis"
            },
            {
                "value": "Itajaí",
                "label": "Itajaí"
            },
            {
                "value": "Itapema",
                "label": "Itapema"
            },
            {
                "value": "Itapiranga",
                "label": "Itapiranga"
            },
            {
                "value": "Itapoá",
                "label": "Itapoá"
            },
            {
                "value": "Ituporanga",
                "label": "Ituporanga"
            },
            {
                "value": "Jaborá",
                "label": "Jaborá"
            },
            {
                "value": "Jacinto Machado",
                "label": "Jacinto Machado"
            },
            {
                "value": "Jaguaruna",
                "label": "Jaguaruna"
            },
            {
                "value": "Jaraguá do Sul",
                "label": "Jaraguá do Sul"
            },
            {
                "value": "Jardinópolis",
                "label": "Jardinópolis"
            },
            {
                "value": "Joaçaba",
                "label": "Joaçaba"
            },
            {
                "value": "Joinville",
                "label": "Joinville"
            },
            {
                "value": "José Boiteux",
                "label": "José Boiteux"
            },
            {
                "value": "Jupiá",
                "label": "Jupiá"
            },
            {
                "value": "Lacerdópolis",
                "label": "Lacerdópolis"
            },
            {
                "value": "Lages",
                "label": "Lages"
            },
            {
                "value": "Laguna",
                "label": "Laguna"
            },
            {
                "value": "Lajeado Grande",
                "label": "Lajeado Grande"
            },
            {
                "value": "Laurentino",
                "label": "Laurentino"
            },
            {
                "value": "Lauro Müller",
                "label": "Lauro Müller"
            },
            {
                "value": "Lebon Régis",
                "label": "Lebon Régis"
            },
            {
                "value": "Leoberto Leal",
                "label": "Leoberto Leal"
            },
            {
                "value": "Lindóia do Sul",
                "label": "Lindóia do Sul"
            },
            {
                "value": "Lontras",
                "label": "Lontras"
            },
            {
                "value": "Luiz Alves",
                "label": "Luiz Alves"
            },
            {
                "value": "Luzerna",
                "label": "Luzerna"
            },
            {
                "value": "Macieira",
                "label": "Macieira"
            },
            {
                "value": "Mafra",
                "label": "Mafra"
            },
            {
                "value": "Major Gercino",
                "label": "Major Gercino"
            },
            {
                "value": "Major Vieira",
                "label": "Major Vieira"
            },
            {
                "value": "Maracajá",
                "label": "Maracajá"
            },
            {
                "value": "Maravilha",
                "label": "Maravilha"
            },
            {
                "value": "Marema",
                "label": "Marema"
            },
            {
                "value": "Massaranduba",
                "label": "Massaranduba"
            },
            {
                "value": "Matos Costa",
                "label": "Matos Costa"
            },
            {
                "value": "Meleiro",
                "label": "Meleiro"
            },
            {
                "value": "Mirim Doce",
                "label": "Mirim Doce"
            },
            {
                "value": "Modelo",
                "label": "Modelo"
            },
            {
                "value": "Mondaí",
                "label": "Mondaí"
            },
            {
                "value": "Monte Carlo",
                "label": "Monte Carlo"
            },
            {
                "value": "Monte Castelo",
                "label": "Monte Castelo"
            },
            {
                "value": "Morro da Fumaça",
                "label": "Morro da Fumaça"
            },
            {
                "value": "Morro Grande",
                "label": "Morro Grande"
            },
            {
                "value": "Navegantes",
                "label": "Navegantes"
            },
            {
                "value": "Nova Erechim",
                "label": "Nova Erechim"
            },
            {
                "value": "Nova Itaberaba",
                "label": "Nova Itaberaba"
            },
            {
                "value": "Nova Trento",
                "label": "Nova Trento"
            },
            {
                "value": "Nova Veneza",
                "label": "Nova Veneza"
            },
            {
                "value": "Novo Horizonte",
                "label": "Novo Horizonte"
            },
            {
                "value": "Orleans",
                "label": "Orleans"
            },
            {
                "value": "Otacílio Costa",
                "label": "Otacílio Costa"
            },
            {
                "value": "Ouro",
                "label": "Ouro"
            },
            {
                "value": "Ouro Verde",
                "label": "Ouro Verde"
            },
            {
                "value": "Paial",
                "label": "Paial"
            },
            {
                "value": "Painel",
                "label": "Painel"
            },
            {
                "value": "Palhoça",
                "label": "Palhoça"
            },
            {
                "value": "Palma Sola",
                "label": "Palma Sola"
            },
            {
                "value": "Palmeira",
                "label": "Palmeira"
            },
            {
                "value": "Palmitos",
                "label": "Palmitos"
            },
            {
                "value": "Papanduva",
                "label": "Papanduva"
            },
            {
                "value": "Paraíso",
                "label": "Paraíso"
            },
            {
                "value": "Passo de Torres",
                "label": "Passo de Torres"
            },
            {
                "value": "Passos Maia",
                "label": "Passos Maia"
            },
            {
                "value": "Paulo Lopes",
                "label": "Paulo Lopes"
            },
            {
                "value": "Pedras Grandes",
                "label": "Pedras Grandes"
            },
            {
                "value": "Penha",
                "label": "Penha"
            },
            {
                "value": "Peritiba",
                "label": "Peritiba"
            },
            {
                "value": "Pescaria Brava",
                "label": "Pescaria Brava"
            },
            {
                "value": "Petrolândia",
                "label": "Petrolândia"
            },
            {
                "value": "Balneário Piçarras",
                "label": "Balneário Piçarras"
            },
            {
                "value": "Pinhalzinho",
                "label": "Pinhalzinho"
            },
            {
                "value": "Pinheiro Preto",
                "label": "Pinheiro Preto"
            },
            {
                "value": "Piratuba",
                "label": "Piratuba"
            },
            {
                "value": "Planalto Alegre",
                "label": "Planalto Alegre"
            },
            {
                "value": "Pomerode",
                "label": "Pomerode"
            },
            {
                "value": "Ponte Alta",
                "label": "Ponte Alta"
            },
            {
                "value": "Ponte Alta do Norte",
                "label": "Ponte Alta do Norte"
            },
            {
                "value": "Ponte Serrada",
                "label": "Ponte Serrada"
            },
            {
                "value": "Porto Belo",
                "label": "Porto Belo"
            },
            {
                "value": "Porto União",
                "label": "Porto União"
            },
            {
                "value": "Pouso Redondo",
                "label": "Pouso Redondo"
            },
            {
                "value": "Praia Grande",
                "label": "Praia Grande"
            },
            {
                "value": "Presidente Castello Branco",
                "label": "Presidente Castello Branco"
            },
            {
                "value": "Presidente Getúlio",
                "label": "Presidente Getúlio"
            },
            {
                "value": "Presidente Nereu",
                "label": "Presidente Nereu"
            },
            {
                "value": "Princesa",
                "label": "Princesa"
            },
            {
                "value": "Quilombo",
                "label": "Quilombo"
            },
            {
                "value": "Rancho Queimado",
                "label": "Rancho Queimado"
            },
            {
                "value": "Rio das Antas",
                "label": "Rio das Antas"
            },
            {
                "value": "Rio do Campo",
                "label": "Rio do Campo"
            },
            {
                "value": "Rio do Oeste",
                "label": "Rio do Oeste"
            },
            {
                "value": "Rio dos Cedros",
                "label": "Rio dos Cedros"
            },
            {
                "value": "Rio do Sul",
                "label": "Rio do Sul"
            },
            {
                "value": "Rio Fortuna",
                "label": "Rio Fortuna"
            },
            {
                "value": "Rio Negrinho",
                "label": "Rio Negrinho"
            },
            {
                "value": "Rio Rufino",
                "label": "Rio Rufino"
            },
            {
                "value": "Riqueza",
                "label": "Riqueza"
            },
            {
                "value": "Rodeio",
                "label": "Rodeio"
            },
            {
                "value": "Romelândia",
                "label": "Romelândia"
            },
            {
                "value": "Salete",
                "label": "Salete"
            },
            {
                "value": "Saltinho",
                "label": "Saltinho"
            },
            {
                "value": "Salto Veloso",
                "label": "Salto Veloso"
            },
            {
                "value": "Sangão",
                "label": "Sangão"
            },
            {
                "value": "Santa Cecília",
                "label": "Santa Cecília"
            },
            {
                "value": "Santa Helena",
                "label": "Santa Helena"
            },
            {
                "value": "Santa Rosa de Lima",
                "label": "Santa Rosa de Lima"
            },
            {
                "value": "Santa Rosa do Sul",
                "label": "Santa Rosa do Sul"
            },
            {
                "value": "Santa Terezinha",
                "label": "Santa Terezinha"
            },
            {
                "value": "Santa Terezinha do Progresso",
                "label": "Santa Terezinha do Progresso"
            },
            {
                "value": "Santiago do Sul",
                "label": "Santiago do Sul"
            },
            {
                "value": "Santo Amaro da Imperatriz",
                "label": "Santo Amaro da Imperatriz"
            },
            {
                "value": "São Bernardino",
                "label": "São Bernardino"
            },
            {
                "value": "São Bento do Sul",
                "label": "São Bento do Sul"
            },
            {
                "value": "São Bonifácio",
                "label": "São Bonifácio"
            },
            {
                "value": "São Carlos",
                "label": "São Carlos"
            },
            {
                "value": "São Cristóvão do Sul",
                "label": "São Cristóvão do Sul"
            },
            {
                "value": "São Domingos",
                "label": "São Domingos"
            },
            {
                "value": "São Francisco do Sul",
                "label": "São Francisco do Sul"
            },
            {
                "value": "São João do Oeste",
                "label": "São João do Oeste"
            },
            {
                "value": "São João Batista",
                "label": "São João Batista"
            },
            {
                "value": "São João do Itaperiú",
                "label": "São João do Itaperiú"
            },
            {
                "value": "São João do Sul",
                "label": "São João do Sul"
            },
            {
                "value": "São Joaquim",
                "label": "São Joaquim"
            },
            {
                "value": "São José",
                "label": "São José"
            },
            {
                "value": "São José do Cedro",
                "label": "São José do Cedro"
            },
            {
                "value": "São José do Cerrito",
                "label": "São José do Cerrito"
            },
            {
                "value": "São Lourenço do Oeste",
                "label": "São Lourenço do Oeste"
            },
            {
                "value": "São Ludgero",
                "label": "São Ludgero"
            },
            {
                "value": "São Martinho",
                "label": "São Martinho"
            },
            {
                "value": "São Miguel da Boa Vista",
                "label": "São Miguel da Boa Vista"
            },
            {
                "value": "São Miguel do Oeste",
                "label": "São Miguel do Oeste"
            },
            {
                "value": "São Pedro de Alcântara",
                "label": "São Pedro de Alcântara"
            },
            {
                "value": "Saudades",
                "label": "Saudades"
            },
            {
                "value": "Schroeder",
                "label": "Schroeder"
            },
            {
                "value": "Seara",
                "label": "Seara"
            },
            {
                "value": "Serra Alta",
                "label": "Serra Alta"
            },
            {
                "value": "Siderópolis",
                "label": "Siderópolis"
            },
            {
                "value": "Sombrio",
                "label": "Sombrio"
            },
            {
                "value": "Sul Brasil",
                "label": "Sul Brasil"
            },
            {
                "value": "Taió",
                "label": "Taió"
            },
            {
                "value": "Tangará",
                "label": "Tangará"
            },
            {
                "value": "Tigrinhos",
                "label": "Tigrinhos"
            },
            {
                "value": "Tijucas",
                "label": "Tijucas"
            },
            {
                "value": "Timbé do Sul",
                "label": "Timbé do Sul"
            },
            {
                "value": "Timbó",
                "label": "Timbó"
            },
            {
                "value": "Timbó Grande",
                "label": "Timbó Grande"
            },
            {
                "value": "Três Barras",
                "label": "Três Barras"
            },
            {
                "value": "Treviso",
                "label": "Treviso"
            },
            {
                "value": "Treze de Maio",
                "label": "Treze de Maio"
            },
            {
                "value": "Treze Tílias",
                "label": "Treze Tílias"
            },
            {
                "value": "Trombudo Central",
                "label": "Trombudo Central"
            },
            {
                "value": "Tubarão",
                "label": "Tubarão"
            },
            {
                "value": "Tunápolis",
                "label": "Tunápolis"
            },
            {
                "value": "Turvo",
                "label": "Turvo"
            },
            {
                "value": "União do Oeste",
                "label": "União do Oeste"
            },
            {
                "value": "Urubici",
                "label": "Urubici"
            },
            {
                "value": "Urupema",
                "label": "Urupema"
            },
            {
                "value": "Urussanga",
                "label": "Urussanga"
            },
            {
                "value": "Vargeão",
                "label": "Vargeão"
            },
            {
                "value": "Vargem",
                "label": "Vargem"
            },
            {
                "value": "Vargem Bonita",
                "label": "Vargem Bonita"
            },
            {
                "value": "Vidal Ramos",
                "label": "Vidal Ramos"
            },
            {
                "value": "Videira",
                "label": "Videira"
            },
            {
                "value": "Vitor Meireles",
                "label": "Vitor Meireles"
            },
            {
                "value": "Witmarsum",
                "label": "Witmarsum"
            },
            {
                "value": "Xanxerê",
                "label": "Xanxerê"
            },
            {
                "value": "Xavantina",
                "label": "Xavantina"
            },
            {
                "value": "Xaxim",
                "label": "Xaxim"
            },
            {
                "value": "Zortéa",
                "label": "Zortéa"
            },
            {
                "value": "Balneário Rincão",
                "label": "Balneário Rincão"
            }
        ]
    },
    {
        "value": "SE",
        "label": "Sergipe",
        "children": [
            {
                "value": "Amparo do São Francisco",
                "label": "Amparo do São Francisco"
            },
            {
                "value": "Aquidabã",
                "label": "Aquidabã"
            },
            {
                "value": "Aracaju",
                "label": "Aracaju"
            },
            {
                "value": "Arauá",
                "label": "Arauá"
            },
            {
                "value": "Areia Branca",
                "label": "Areia Branca"
            },
            {
                "value": "Barra dos Coqueiros",
                "label": "Barra dos Coqueiros"
            },
            {
                "value": "Boquim",
                "label": "Boquim"
            },
            {
                "value": "Brejo Grande",
                "label": "Brejo Grande"
            },
            {
                "value": "Campo do Brito",
                "label": "Campo do Brito"
            },
            {
                "value": "Canhoba",
                "label": "Canhoba"
            },
            {
                "value": "Canindé de São Francisco",
                "label": "Canindé de São Francisco"
            },
            {
                "value": "Capela",
                "label": "Capela"
            },
            {
                "value": "Carira",
                "label": "Carira"
            },
            {
                "value": "Carmópolis",
                "label": "Carmópolis"
            },
            {
                "value": "Cedro de São João",
                "label": "Cedro de São João"
            },
            {
                "value": "Cristinápolis",
                "label": "Cristinápolis"
            },
            {
                "value": "Cumbe",
                "label": "Cumbe"
            },
            {
                "value": "Divina Pastora",
                "label": "Divina Pastora"
            },
            {
                "value": "Estância",
                "label": "Estância"
            },
            {
                "value": "Feira Nova",
                "label": "Feira Nova"
            },
            {
                "value": "Frei Paulo",
                "label": "Frei Paulo"
            },
            {
                "value": "Gararu",
                "label": "Gararu"
            },
            {
                "value": "General Maynard",
                "label": "General Maynard"
            },
            {
                "value": "Gracho Cardoso",
                "label": "Gracho Cardoso"
            },
            {
                "value": "Ilha das Flores",
                "label": "Ilha das Flores"
            },
            {
                "value": "Indiaroba",
                "label": "Indiaroba"
            },
            {
                "value": "Itabaiana",
                "label": "Itabaiana"
            },
            {
                "value": "Itabaianinha",
                "label": "Itabaianinha"
            },
            {
                "value": "Itabi",
                "label": "Itabi"
            },
            {
                "value": "Itaporanga d'Ajuda",
                "label": "Itaporanga d'Ajuda"
            },
            {
                "value": "Japaratuba",
                "label": "Japaratuba"
            },
            {
                "value": "Japoatã",
                "label": "Japoatã"
            },
            {
                "value": "Lagarto",
                "label": "Lagarto"
            },
            {
                "value": "Laranjeiras",
                "label": "Laranjeiras"
            },
            {
                "value": "Macambira",
                "label": "Macambira"
            },
            {
                "value": "Malhada dos Bois",
                "label": "Malhada dos Bois"
            },
            {
                "value": "Malhador",
                "label": "Malhador"
            },
            {
                "value": "Maruim",
                "label": "Maruim"
            },
            {
                "value": "Moita Bonita",
                "label": "Moita Bonita"
            },
            {
                "value": "Monte Alegre de Sergipe",
                "label": "Monte Alegre de Sergipe"
            },
            {
                "value": "Muribeca",
                "label": "Muribeca"
            },
            {
                "value": "Neópolis",
                "label": "Neópolis"
            },
            {
                "value": "Nossa Senhora Aparecida",
                "label": "Nossa Senhora Aparecida"
            },
            {
                "value": "Nossa Senhora da Glória",
                "label": "Nossa Senhora da Glória"
            },
            {
                "value": "Nossa Senhora das Dores",
                "label": "Nossa Senhora das Dores"
            },
            {
                "value": "Nossa Senhora de Lourdes",
                "label": "Nossa Senhora de Lourdes"
            },
            {
                "value": "Nossa Senhora do Socorro",
                "label": "Nossa Senhora do Socorro"
            },
            {
                "value": "Pacatuba",
                "label": "Pacatuba"
            },
            {
                "value": "Pedra Mole",
                "label": "Pedra Mole"
            },
            {
                "value": "Pedrinhas",
                "label": "Pedrinhas"
            },
            {
                "value": "Pinhão",
                "label": "Pinhão"
            },
            {
                "value": "Pirambu",
                "label": "Pirambu"
            },
            {
                "value": "Poço Redondo",
                "label": "Poço Redondo"
            },
            {
                "value": "Poço Verde",
                "label": "Poço Verde"
            },
            {
                "value": "Porto da Folha",
                "label": "Porto da Folha"
            },
            {
                "value": "Propriá",
                "label": "Propriá"
            },
            {
                "value": "Riachão do Dantas",
                "label": "Riachão do Dantas"
            },
            {
                "value": "Riachuelo",
                "label": "Riachuelo"
            },
            {
                "value": "Ribeirópolis",
                "label": "Ribeirópolis"
            },
            {
                "value": "Rosário do Catete",
                "label": "Rosário do Catete"
            },
            {
                "value": "Salgado",
                "label": "Salgado"
            },
            {
                "value": "Santa Luzia do Itanhy",
                "label": "Santa Luzia do Itanhy"
            },
            {
                "value": "Santana do São Francisco",
                "label": "Santana do São Francisco"
            },
            {
                "value": "Santa Rosa de Lima",
                "label": "Santa Rosa de Lima"
            },
            {
                "value": "Santo Amaro das Brotas",
                "label": "Santo Amaro das Brotas"
            },
            {
                "value": "São Cristóvão",
                "label": "São Cristóvão"
            },
            {
                "value": "São Domingos",
                "label": "São Domingos"
            },
            {
                "value": "São Francisco",
                "label": "São Francisco"
            },
            {
                "value": "São Miguel do Aleixo",
                "label": "São Miguel do Aleixo"
            },
            {
                "value": "Simão Dias",
                "label": "Simão Dias"
            },
            {
                "value": "Siriri",
                "label": "Siriri"
            },
            {
                "value": "Telha",
                "label": "Telha"
            },
            {
                "value": "Tobias Barreto",
                "label": "Tobias Barreto"
            },
            {
                "value": "Tomar do Geru",
                "label": "Tomar do Geru"
            },
            {
                "value": "Umbaúba",
                "label": "Umbaúba"
            }
        ]
    },
    {
        "value": "SP",
        "label": "São Paulo",
        "children": [
            {
                "value": "Adamantina",
                "label": "Adamantina"
            },
            {
                "value": "Adolfo",
                "label": "Adolfo"
            },
            {
                "value": "Aguaí",
                "label": "Aguaí"
            },
            {
                "value": "Águas da Prata",
                "label": "Águas da Prata"
            },
            {
                "value": "Águas de Lindóia",
                "label": "Águas de Lindóia"
            },
            {
                "value": "Águas de Santa Bárbara",
                "label": "Águas de Santa Bárbara"
            },
            {
                "value": "Águas de São Pedro",
                "label": "Águas de São Pedro"
            },
            {
                "value": "Agudos",
                "label": "Agudos"
            },
            {
                "value": "Alambari",
                "label": "Alambari"
            },
            {
                "value": "Alfredo Marcondes",
                "label": "Alfredo Marcondes"
            },
            {
                "value": "Altair",
                "label": "Altair"
            },
            {
                "value": "Altinópolis",
                "label": "Altinópolis"
            },
            {
                "value": "Alto Alegre",
                "label": "Alto Alegre"
            },
            {
                "value": "Alumínio",
                "label": "Alumínio"
            },
            {
                "value": "Álvares Florence",
                "label": "Álvares Florence"
            },
            {
                "value": "Álvares Machado",
                "label": "Álvares Machado"
            },
            {
                "value": "Álvaro de Carvalho",
                "label": "Álvaro de Carvalho"
            },
            {
                "value": "Alvinlândia",
                "label": "Alvinlândia"
            },
            {
                "value": "Americana",
                "label": "Americana"
            },
            {
                "value": "Américo Brasiliense",
                "label": "Américo Brasiliense"
            },
            {
                "value": "Américo de Campos",
                "label": "Américo de Campos"
            },
            {
                "value": "Amparo",
                "label": "Amparo"
            },
            {
                "value": "Analândia",
                "label": "Analândia"
            },
            {
                "value": "Andradina",
                "label": "Andradina"
            },
            {
                "value": "Angatuba",
                "label": "Angatuba"
            },
            {
                "value": "Anhembi",
                "label": "Anhembi"
            },
            {
                "value": "Anhumas",
                "label": "Anhumas"
            },
            {
                "value": "Aparecida",
                "label": "Aparecida"
            },
            {
                "value": "Aparecida d'Oeste",
                "label": "Aparecida d'Oeste"
            },
            {
                "value": "Apiaí",
                "label": "Apiaí"
            },
            {
                "value": "Araçariguama",
                "label": "Araçariguama"
            },
            {
                "value": "Araçatuba",
                "label": "Araçatuba"
            },
            {
                "value": "Araçoiaba da Serra",
                "label": "Araçoiaba da Serra"
            },
            {
                "value": "Aramina",
                "label": "Aramina"
            },
            {
                "value": "Arandu",
                "label": "Arandu"
            },
            {
                "value": "Arapeí",
                "label": "Arapeí"
            },
            {
                "value": "Araraquara",
                "label": "Araraquara"
            },
            {
                "value": "Araras",
                "label": "Araras"
            },
            {
                "value": "Arco-Íris",
                "label": "Arco-Íris"
            },
            {
                "value": "Arealva",
                "label": "Arealva"
            },
            {
                "value": "Areias",
                "label": "Areias"
            },
            {
                "value": "Areiópolis",
                "label": "Areiópolis"
            },
            {
                "value": "Ariranha",
                "label": "Ariranha"
            },
            {
                "value": "Artur Nogueira",
                "label": "Artur Nogueira"
            },
            {
                "value": "Arujá",
                "label": "Arujá"
            },
            {
                "value": "Aspásia",
                "label": "Aspásia"
            },
            {
                "value": "Assis",
                "label": "Assis"
            },
            {
                "value": "Atibaia",
                "label": "Atibaia"
            },
            {
                "value": "Auriflama",
                "label": "Auriflama"
            },
            {
                "value": "Avaí",
                "label": "Avaí"
            },
            {
                "value": "Avanhandava",
                "label": "Avanhandava"
            },
            {
                "value": "Avaré",
                "label": "Avaré"
            },
            {
                "value": "Bady Bassitt",
                "label": "Bady Bassitt"
            },
            {
                "value": "Balbinos",
                "label": "Balbinos"
            },
            {
                "value": "Bálsamo",
                "label": "Bálsamo"
            },
            {
                "value": "Bananal",
                "label": "Bananal"
            },
            {
                "value": "Barão de Antonina",
                "label": "Barão de Antonina"
            },
            {
                "value": "Barbosa",
                "label": "Barbosa"
            },
            {
                "value": "Bariri",
                "label": "Bariri"
            },
            {
                "value": "Barra Bonita",
                "label": "Barra Bonita"
            },
            {
                "value": "Barra do Chapéu",
                "label": "Barra do Chapéu"
            },
            {
                "value": "Barra do Turvo",
                "label": "Barra do Turvo"
            },
            {
                "value": "Barretos",
                "label": "Barretos"
            },
            {
                "value": "Barrinha",
                "label": "Barrinha"
            },
            {
                "value": "Barueri",
                "label": "Barueri"
            },
            {
                "value": "Bastos",
                "label": "Bastos"
            },
            {
                "value": "Batatais",
                "label": "Batatais"
            },
            {
                "value": "Bauru",
                "label": "Bauru"
            },
            {
                "value": "Bebedouro",
                "label": "Bebedouro"
            },
            {
                "value": "Bento de Abreu",
                "label": "Bento de Abreu"
            },
            {
                "value": "Bernardino de Campos",
                "label": "Bernardino de Campos"
            },
            {
                "value": "Bertioga",
                "label": "Bertioga"
            },
            {
                "value": "Bilac",
                "label": "Bilac"
            },
            {
                "value": "Birigui",
                "label": "Birigui"
            },
            {
                "value": "Biritiba Mirim",
                "label": "Biritiba Mirim"
            },
            {
                "value": "Boa Esperança do Sul",
                "label": "Boa Esperança do Sul"
            },
            {
                "value": "Bocaina",
                "label": "Bocaina"
            },
            {
                "value": "Bofete",
                "label": "Bofete"
            },
            {
                "value": "Boituva",
                "label": "Boituva"
            },
            {
                "value": "Bom Jesus dos Perdões",
                "label": "Bom Jesus dos Perdões"
            },
            {
                "value": "Bom Sucesso de Itararé",
                "label": "Bom Sucesso de Itararé"
            },
            {
                "value": "Borá",
                "label": "Borá"
            },
            {
                "value": "Boracéia",
                "label": "Boracéia"
            },
            {
                "value": "Borborema",
                "label": "Borborema"
            },
            {
                "value": "Borebi",
                "label": "Borebi"
            },
            {
                "value": "Botucatu",
                "label": "Botucatu"
            },
            {
                "value": "Bragança Paulista",
                "label": "Bragança Paulista"
            },
            {
                "value": "Braúna",
                "label": "Braúna"
            },
            {
                "value": "Brejo Alegre",
                "label": "Brejo Alegre"
            },
            {
                "value": "Brodowski",
                "label": "Brodowski"
            },
            {
                "value": "Brotas",
                "label": "Brotas"
            },
            {
                "value": "Buri",
                "label": "Buri"
            },
            {
                "value": "Buritama",
                "label": "Buritama"
            },
            {
                "value": "Buritizal",
                "label": "Buritizal"
            },
            {
                "value": "Cabrália Paulista",
                "label": "Cabrália Paulista"
            },
            {
                "value": "Cabreúva",
                "label": "Cabreúva"
            },
            {
                "value": "Caçapava",
                "label": "Caçapava"
            },
            {
                "value": "Cachoeira Paulista",
                "label": "Cachoeira Paulista"
            },
            {
                "value": "Caconde",
                "label": "Caconde"
            },
            {
                "value": "Cafelândia",
                "label": "Cafelândia"
            },
            {
                "value": "Caiabu",
                "label": "Caiabu"
            },
            {
                "value": "Caieiras",
                "label": "Caieiras"
            },
            {
                "value": "Caiuá",
                "label": "Caiuá"
            },
            {
                "value": "Cajamar",
                "label": "Cajamar"
            },
            {
                "value": "Cajati",
                "label": "Cajati"
            },
            {
                "value": "Cajobi",
                "label": "Cajobi"
            },
            {
                "value": "Cajuru",
                "label": "Cajuru"
            },
            {
                "value": "Campina do Monte Alegre",
                "label": "Campina do Monte Alegre"
            },
            {
                "value": "Campinas",
                "label": "Campinas"
            },
            {
                "value": "Campo Limpo Paulista",
                "label": "Campo Limpo Paulista"
            },
            {
                "value": "Campos do Jordão",
                "label": "Campos do Jordão"
            },
            {
                "value": "Campos Novos Paulista",
                "label": "Campos Novos Paulista"
            },
            {
                "value": "Cananéia",
                "label": "Cananéia"
            },
            {
                "value": "Canas",
                "label": "Canas"
            },
            {
                "value": "Cândido Mota",
                "label": "Cândido Mota"
            },
            {
                "value": "Cândido Rodrigues",
                "label": "Cândido Rodrigues"
            },
            {
                "value": "Canitar",
                "label": "Canitar"
            },
            {
                "value": "Capão Bonito",
                "label": "Capão Bonito"
            },
            {
                "value": "Capela do Alto",
                "label": "Capela do Alto"
            },
            {
                "value": "Capivari",
                "label": "Capivari"
            },
            {
                "value": "Caraguatatuba",
                "label": "Caraguatatuba"
            },
            {
                "value": "Carapicuíba",
                "label": "Carapicuíba"
            },
            {
                "value": "Cardoso",
                "label": "Cardoso"
            },
            {
                "value": "Casa Branca",
                "label": "Casa Branca"
            },
            {
                "value": "Cássia dos Coqueiros",
                "label": "Cássia dos Coqueiros"
            },
            {
                "value": "Castilho",
                "label": "Castilho"
            },
            {
                "value": "Catanduva",
                "label": "Catanduva"
            },
            {
                "value": "Catiguá",
                "label": "Catiguá"
            },
            {
                "value": "Cedral",
                "label": "Cedral"
            },
            {
                "value": "Cerqueira César",
                "label": "Cerqueira César"
            },
            {
                "value": "Cerquilho",
                "label": "Cerquilho"
            },
            {
                "value": "Cesário Lange",
                "label": "Cesário Lange"
            },
            {
                "value": "Charqueada",
                "label": "Charqueada"
            },
            {
                "value": "Clementina",
                "label": "Clementina"
            },
            {
                "value": "Colina",
                "label": "Colina"
            },
            {
                "value": "Colômbia",
                "label": "Colômbia"
            },
            {
                "value": "Conchal",
                "label": "Conchal"
            },
            {
                "value": "Conchas",
                "label": "Conchas"
            },
            {
                "value": "Cordeirópolis",
                "label": "Cordeirópolis"
            },
            {
                "value": "Coroados",
                "label": "Coroados"
            },
            {
                "value": "Coronel Macedo",
                "label": "Coronel Macedo"
            },
            {
                "value": "Corumbataí",
                "label": "Corumbataí"
            },
            {
                "value": "Cosmópolis",
                "label": "Cosmópolis"
            },
            {
                "value": "Cosmorama",
                "label": "Cosmorama"
            },
            {
                "value": "Cotia",
                "label": "Cotia"
            },
            {
                "value": "Cravinhos",
                "label": "Cravinhos"
            },
            {
                "value": "Cristais Paulista",
                "label": "Cristais Paulista"
            },
            {
                "value": "Cruzália",
                "label": "Cruzália"
            },
            {
                "value": "Cruzeiro",
                "label": "Cruzeiro"
            },
            {
                "value": "Cubatão",
                "label": "Cubatão"
            },
            {
                "value": "Cunha",
                "label": "Cunha"
            },
            {
                "value": "Descalvado",
                "label": "Descalvado"
            },
            {
                "value": "Diadema",
                "label": "Diadema"
            },
            {
                "value": "Dirce Reis",
                "label": "Dirce Reis"
            },
            {
                "value": "Divinolândia",
                "label": "Divinolândia"
            },
            {
                "value": "Dobrada",
                "label": "Dobrada"
            },
            {
                "value": "Dois Córregos",
                "label": "Dois Córregos"
            },
            {
                "value": "Dolcinópolis",
                "label": "Dolcinópolis"
            },
            {
                "value": "Dourado",
                "label": "Dourado"
            },
            {
                "value": "Dracena",
                "label": "Dracena"
            },
            {
                "value": "Duartina",
                "label": "Duartina"
            },
            {
                "value": "Dumont",
                "label": "Dumont"
            },
            {
                "value": "Echaporã",
                "label": "Echaporã"
            },
            {
                "value": "Eldorado",
                "label": "Eldorado"
            },
            {
                "value": "Elias Fausto",
                "label": "Elias Fausto"
            },
            {
                "value": "Elisiário",
                "label": "Elisiário"
            },
            {
                "value": "Embaúba",
                "label": "Embaúba"
            },
            {
                "value": "Embu das Artes",
                "label": "Embu das Artes"
            },
            {
                "value": "Embu-Guaçu",
                "label": "Embu-Guaçu"
            },
            {
                "value": "Emilianópolis",
                "label": "Emilianópolis"
            },
            {
                "value": "Engenheiro Coelho",
                "label": "Engenheiro Coelho"
            },
            {
                "value": "Espírito Santo do Pinhal",
                "label": "Espírito Santo do Pinhal"
            },
            {
                "value": "Espírito Santo do Turvo",
                "label": "Espírito Santo do Turvo"
            },
            {
                "value": "Estrela d'Oeste",
                "label": "Estrela d'Oeste"
            },
            {
                "value": "Estrela do Norte",
                "label": "Estrela do Norte"
            },
            {
                "value": "Euclides da Cunha Paulista",
                "label": "Euclides da Cunha Paulista"
            },
            {
                "value": "Fartura",
                "label": "Fartura"
            },
            {
                "value": "Fernandópolis",
                "label": "Fernandópolis"
            },
            {
                "value": "Fernando Prestes",
                "label": "Fernando Prestes"
            },
            {
                "value": "Fernão",
                "label": "Fernão"
            },
            {
                "value": "Ferraz de Vasconcelos",
                "label": "Ferraz de Vasconcelos"
            },
            {
                "value": "Flora Rica",
                "label": "Flora Rica"
            },
            {
                "value": "Floreal",
                "label": "Floreal"
            },
            {
                "value": "Flórida Paulista",
                "label": "Flórida Paulista"
            },
            {
                "value": "Florínea",
                "label": "Florínea"
            },
            {
                "value": "Franca",
                "label": "Franca"
            },
            {
                "value": "Francisco Morato",
                "label": "Francisco Morato"
            },
            {
                "value": "Franco da Rocha",
                "label": "Franco da Rocha"
            },
            {
                "value": "Gabriel Monteiro",
                "label": "Gabriel Monteiro"
            },
            {
                "value": "Gália",
                "label": "Gália"
            },
            {
                "value": "Garça",
                "label": "Garça"
            },
            {
                "value": "Gastão Vidigal",
                "label": "Gastão Vidigal"
            },
            {
                "value": "Gavião Peixoto",
                "label": "Gavião Peixoto"
            },
            {
                "value": "General Salgado",
                "label": "General Salgado"
            },
            {
                "value": "Getulina",
                "label": "Getulina"
            },
            {
                "value": "Glicério",
                "label": "Glicério"
            },
            {
                "value": "Guaiçara",
                "label": "Guaiçara"
            },
            {
                "value": "Guaimbê",
                "label": "Guaimbê"
            },
            {
                "value": "Guaíra",
                "label": "Guaíra"
            },
            {
                "value": "Guapiaçu",
                "label": "Guapiaçu"
            },
            {
                "value": "Guapiara",
                "label": "Guapiara"
            },
            {
                "value": "Guará",
                "label": "Guará"
            },
            {
                "value": "Guaraçaí",
                "label": "Guaraçaí"
            },
            {
                "value": "Guaraci",
                "label": "Guaraci"
            },
            {
                "value": "Guarani d'Oeste",
                "label": "Guarani d'Oeste"
            },
            {
                "value": "Guarantã",
                "label": "Guarantã"
            },
            {
                "value": "Guararapes",
                "label": "Guararapes"
            },
            {
                "value": "Guararema",
                "label": "Guararema"
            },
            {
                "value": "Guaratinguetá",
                "label": "Guaratinguetá"
            },
            {
                "value": "Guareí",
                "label": "Guareí"
            },
            {
                "value": "Guariba",
                "label": "Guariba"
            },
            {
                "value": "Guarujá",
                "label": "Guarujá"
            },
            {
                "value": "Guarulhos",
                "label": "Guarulhos"
            },
            {
                "value": "Guatapará",
                "label": "Guatapará"
            },
            {
                "value": "Guzolândia",
                "label": "Guzolândia"
            },
            {
                "value": "Herculândia",
                "label": "Herculândia"
            },
            {
                "value": "Holambra",
                "label": "Holambra"
            },
            {
                "value": "Hortolândia",
                "label": "Hortolândia"
            },
            {
                "value": "Iacanga",
                "label": "Iacanga"
            },
            {
                "value": "Iacri",
                "label": "Iacri"
            },
            {
                "value": "Iaras",
                "label": "Iaras"
            },
            {
                "value": "Ibaté",
                "label": "Ibaté"
            },
            {
                "value": "Ibirá",
                "label": "Ibirá"
            },
            {
                "value": "Ibirarema",
                "label": "Ibirarema"
            },
            {
                "value": "Ibitinga",
                "label": "Ibitinga"
            },
            {
                "value": "Ibiúna",
                "label": "Ibiúna"
            },
            {
                "value": "Icém",
                "label": "Icém"
            },
            {
                "value": "Iepê",
                "label": "Iepê"
            },
            {
                "value": "Igaraçu do Tietê",
                "label": "Igaraçu do Tietê"
            },
            {
                "value": "Igarapava",
                "label": "Igarapava"
            },
            {
                "value": "Igaratá",
                "label": "Igaratá"
            },
            {
                "value": "Iguape",
                "label": "Iguape"
            },
            {
                "value": "Ilhabela",
                "label": "Ilhabela"
            },
            {
                "value": "Ilha Comprida",
                "label": "Ilha Comprida"
            },
            {
                "value": "Ilha Solteira",
                "label": "Ilha Solteira"
            },
            {
                "value": "Indaiatuba",
                "label": "Indaiatuba"
            },
            {
                "value": "Indiana",
                "label": "Indiana"
            },
            {
                "value": "Indiaporã",
                "label": "Indiaporã"
            },
            {
                "value": "Inúbia Paulista",
                "label": "Inúbia Paulista"
            },
            {
                "value": "Ipaussu",
                "label": "Ipaussu"
            },
            {
                "value": "Iperó",
                "label": "Iperó"
            },
            {
                "value": "Ipeúna",
                "label": "Ipeúna"
            },
            {
                "value": "Ipiguá",
                "label": "Ipiguá"
            },
            {
                "value": "Iporanga",
                "label": "Iporanga"
            },
            {
                "value": "Ipuã",
                "label": "Ipuã"
            },
            {
                "value": "Iracemápolis",
                "label": "Iracemápolis"
            },
            {
                "value": "Irapuã",
                "label": "Irapuã"
            },
            {
                "value": "Irapuru",
                "label": "Irapuru"
            },
            {
                "value": "Itaberá",
                "label": "Itaberá"
            },
            {
                "value": "Itaí",
                "label": "Itaí"
            },
            {
                "value": "Itajobi",
                "label": "Itajobi"
            },
            {
                "value": "Itaju",
                "label": "Itaju"
            },
            {
                "value": "Itanhaém",
                "label": "Itanhaém"
            },
            {
                "value": "Itaoca",
                "label": "Itaoca"
            },
            {
                "value": "Itapecerica da Serra",
                "label": "Itapecerica da Serra"
            },
            {
                "value": "Itapetininga",
                "label": "Itapetininga"
            },
            {
                "value": "Itapeva",
                "label": "Itapeva"
            },
            {
                "value": "Itapevi",
                "label": "Itapevi"
            },
            {
                "value": "Itapira",
                "label": "Itapira"
            },
            {
                "value": "Itapirapuã Paulista",
                "label": "Itapirapuã Paulista"
            },
            {
                "value": "Itápolis",
                "label": "Itápolis"
            },
            {
                "value": "Itaporanga",
                "label": "Itaporanga"
            },
            {
                "value": "Itapuí",
                "label": "Itapuí"
            },
            {
                "value": "Itapura",
                "label": "Itapura"
            },
            {
                "value": "Itaquaquecetuba",
                "label": "Itaquaquecetuba"
            },
            {
                "value": "Itararé",
                "label": "Itararé"
            },
            {
                "value": "Itariri",
                "label": "Itariri"
            },
            {
                "value": "Itatiba",
                "label": "Itatiba"
            },
            {
                "value": "Itatinga",
                "label": "Itatinga"
            },
            {
                "value": "Itirapina",
                "label": "Itirapina"
            },
            {
                "value": "Itirapuã",
                "label": "Itirapuã"
            },
            {
                "value": "Itobi",
                "label": "Itobi"
            },
            {
                "value": "Itu",
                "label": "Itu"
            },
            {
                "value": "Itupeva",
                "label": "Itupeva"
            },
            {
                "value": "Ituverava",
                "label": "Ituverava"
            },
            {
                "value": "Jaborandi",
                "label": "Jaborandi"
            },
            {
                "value": "Jaboticabal",
                "label": "Jaboticabal"
            },
            {
                "value": "Jacareí",
                "label": "Jacareí"
            },
            {
                "value": "Jaci",
                "label": "Jaci"
            },
            {
                "value": "Jacupiranga",
                "label": "Jacupiranga"
            },
            {
                "value": "Jaguariúna",
                "label": "Jaguariúna"
            },
            {
                "value": "Jales",
                "label": "Jales"
            },
            {
                "value": "Jambeiro",
                "label": "Jambeiro"
            },
            {
                "value": "Jandira",
                "label": "Jandira"
            },
            {
                "value": "Jardinópolis",
                "label": "Jardinópolis"
            },
            {
                "value": "Jarinu",
                "label": "Jarinu"
            },
            {
                "value": "Jaú",
                "label": "Jaú"
            },
            {
                "value": "Jeriquara",
                "label": "Jeriquara"
            },
            {
                "value": "Joanópolis",
                "label": "Joanópolis"
            },
            {
                "value": "João Ramalho",
                "label": "João Ramalho"
            },
            {
                "value": "José Bonifácio",
                "label": "José Bonifácio"
            },
            {
                "value": "Júlio Mesquita",
                "label": "Júlio Mesquita"
            },
            {
                "value": "Jumirim",
                "label": "Jumirim"
            },
            {
                "value": "Jundiaí",
                "label": "Jundiaí"
            },
            {
                "value": "Junqueirópolis",
                "label": "Junqueirópolis"
            },
            {
                "value": "Juquiá",
                "label": "Juquiá"
            },
            {
                "value": "Juquitiba",
                "label": "Juquitiba"
            },
            {
                "value": "Lagoinha",
                "label": "Lagoinha"
            },
            {
                "value": "Laranjal Paulista",
                "label": "Laranjal Paulista"
            },
            {
                "value": "Lavínia",
                "label": "Lavínia"
            },
            {
                "value": "Lavrinhas",
                "label": "Lavrinhas"
            },
            {
                "value": "Leme",
                "label": "Leme"
            },
            {
                "value": "Lençóis Paulista",
                "label": "Lençóis Paulista"
            },
            {
                "value": "Limeira",
                "label": "Limeira"
            },
            {
                "value": "Lindóia",
                "label": "Lindóia"
            },
            {
                "value": "Lins",
                "label": "Lins"
            },
            {
                "value": "Lorena",
                "label": "Lorena"
            },
            {
                "value": "Lourdes",
                "label": "Lourdes"
            },
            {
                "value": "Louveira",
                "label": "Louveira"
            },
            {
                "value": "Lucélia",
                "label": "Lucélia"
            },
            {
                "value": "Lucianópolis",
                "label": "Lucianópolis"
            },
            {
                "value": "Luís Antônio",
                "label": "Luís Antônio"
            },
            {
                "value": "Luiziânia",
                "label": "Luiziânia"
            },
            {
                "value": "Lupércio",
                "label": "Lupércio"
            },
            {
                "value": "Lutécia",
                "label": "Lutécia"
            },
            {
                "value": "Macatuba",
                "label": "Macatuba"
            },
            {
                "value": "Macaubal",
                "label": "Macaubal"
            },
            {
                "value": "Macedônia",
                "label": "Macedônia"
            },
            {
                "value": "Magda",
                "label": "Magda"
            },
            {
                "value": "Mairinque",
                "label": "Mairinque"
            },
            {
                "value": "Mairiporã",
                "label": "Mairiporã"
            },
            {
                "value": "Manduri",
                "label": "Manduri"
            },
            {
                "value": "Marabá Paulista",
                "label": "Marabá Paulista"
            },
            {
                "value": "Maracaí",
                "label": "Maracaí"
            },
            {
                "value": "Marapoama",
                "label": "Marapoama"
            },
            {
                "value": "Mariápolis",
                "label": "Mariápolis"
            },
            {
                "value": "Marília",
                "label": "Marília"
            },
            {
                "value": "Marinópolis",
                "label": "Marinópolis"
            },
            {
                "value": "Martinópolis",
                "label": "Martinópolis"
            },
            {
                "value": "Matão",
                "label": "Matão"
            },
            {
                "value": "Mauá",
                "label": "Mauá"
            },
            {
                "value": "Mendonça",
                "label": "Mendonça"
            },
            {
                "value": "Meridiano",
                "label": "Meridiano"
            },
            {
                "value": "Mesópolis",
                "label": "Mesópolis"
            },
            {
                "value": "Miguelópolis",
                "label": "Miguelópolis"
            },
            {
                "value": "Mineiros do Tietê",
                "label": "Mineiros do Tietê"
            },
            {
                "value": "Miracatu",
                "label": "Miracatu"
            },
            {
                "value": "Mira Estrela",
                "label": "Mira Estrela"
            },
            {
                "value": "Mirandópolis",
                "label": "Mirandópolis"
            },
            {
                "value": "Mirante do Paranapanema",
                "label": "Mirante do Paranapanema"
            },
            {
                "value": "Mirassol",
                "label": "Mirassol"
            },
            {
                "value": "Mirassolândia",
                "label": "Mirassolândia"
            },
            {
                "value": "Mococa",
                "label": "Mococa"
            },
            {
                "value": "Mogi das Cruzes",
                "label": "Mogi das Cruzes"
            },
            {
                "value": "Mogi Guaçu",
                "label": "Mogi Guaçu"
            },
            {
                "value": "Mogi Mirim",
                "label": "Mogi Mirim"
            },
            {
                "value": "Mombuca",
                "label": "Mombuca"
            },
            {
                "value": "Monções",
                "label": "Monções"
            },
            {
                "value": "Mongaguá",
                "label": "Mongaguá"
            },
            {
                "value": "Monte Alegre do Sul",
                "label": "Monte Alegre do Sul"
            },
            {
                "value": "Monte Alto",
                "label": "Monte Alto"
            },
            {
                "value": "Monte Aprazível",
                "label": "Monte Aprazível"
            },
            {
                "value": "Monte Azul Paulista",
                "label": "Monte Azul Paulista"
            },
            {
                "value": "Monte Castelo",
                "label": "Monte Castelo"
            },
            {
                "value": "Monteiro Lobato",
                "label": "Monteiro Lobato"
            },
            {
                "value": "Monte Mor",
                "label": "Monte Mor"
            },
            {
                "value": "Morro Agudo",
                "label": "Morro Agudo"
            },
            {
                "value": "Morungaba",
                "label": "Morungaba"
            },
            {
                "value": "Motuca",
                "label": "Motuca"
            },
            {
                "value": "Murutinga do Sul",
                "label": "Murutinga do Sul"
            },
            {
                "value": "Nantes",
                "label": "Nantes"
            },
            {
                "value": "Narandiba",
                "label": "Narandiba"
            },
            {
                "value": "Natividade da Serra",
                "label": "Natividade da Serra"
            },
            {
                "value": "Nazaré Paulista",
                "label": "Nazaré Paulista"
            },
            {
                "value": "Neves Paulista",
                "label": "Neves Paulista"
            },
            {
                "value": "Nhandeara",
                "label": "Nhandeara"
            },
            {
                "value": "Nipoã",
                "label": "Nipoã"
            },
            {
                "value": "Nova Aliança",
                "label": "Nova Aliança"
            },
            {
                "value": "Nova Campina",
                "label": "Nova Campina"
            },
            {
                "value": "Nova Canaã Paulista",
                "label": "Nova Canaã Paulista"
            },
            {
                "value": "Nova Castilho",
                "label": "Nova Castilho"
            },
            {
                "value": "Nova Europa",
                "label": "Nova Europa"
            },
            {
                "value": "Nova Granada",
                "label": "Nova Granada"
            },
            {
                "value": "Nova Guataporanga",
                "label": "Nova Guataporanga"
            },
            {
                "value": "Nova Independência",
                "label": "Nova Independência"
            },
            {
                "value": "Novais",
                "label": "Novais"
            },
            {
                "value": "Nova Luzitânia",
                "label": "Nova Luzitânia"
            },
            {
                "value": "Nova Odessa",
                "label": "Nova Odessa"
            },
            {
                "value": "Novo Horizonte",
                "label": "Novo Horizonte"
            },
            {
                "value": "Nuporanga",
                "label": "Nuporanga"
            },
            {
                "value": "Ocauçu",
                "label": "Ocauçu"
            },
            {
                "value": "Óleo",
                "label": "Óleo"
            },
            {
                "value": "Olímpia",
                "label": "Olímpia"
            },
            {
                "value": "Onda Verde",
                "label": "Onda Verde"
            },
            {
                "value": "Oriente",
                "label": "Oriente"
            },
            {
                "value": "Orindiúva",
                "label": "Orindiúva"
            },
            {
                "value": "Orlândia",
                "label": "Orlândia"
            },
            {
                "value": "Osasco",
                "label": "Osasco"
            },
            {
                "value": "Oscar Bressane",
                "label": "Oscar Bressane"
            },
            {
                "value": "Osvaldo Cruz",
                "label": "Osvaldo Cruz"
            },
            {
                "value": "Ourinhos",
                "label": "Ourinhos"
            },
            {
                "value": "Ouroeste",
                "label": "Ouroeste"
            },
            {
                "value": "Ouro Verde",
                "label": "Ouro Verde"
            },
            {
                "value": "Pacaembu",
                "label": "Pacaembu"
            },
            {
                "value": "Palestina",
                "label": "Palestina"
            },
            {
                "value": "Palmares Paulista",
                "label": "Palmares Paulista"
            },
            {
                "value": "Palmeira d'Oeste",
                "label": "Palmeira d'Oeste"
            },
            {
                "value": "Palmital",
                "label": "Palmital"
            },
            {
                "value": "Panorama",
                "label": "Panorama"
            },
            {
                "value": "Paraguaçu Paulista",
                "label": "Paraguaçu Paulista"
            },
            {
                "value": "Paraibuna",
                "label": "Paraibuna"
            },
            {
                "value": "Paraíso",
                "label": "Paraíso"
            },
            {
                "value": "Paranapanema",
                "label": "Paranapanema"
            },
            {
                "value": "Paranapuã",
                "label": "Paranapuã"
            },
            {
                "value": "Parapuã",
                "label": "Parapuã"
            },
            {
                "value": "Pardinho",
                "label": "Pardinho"
            },
            {
                "value": "Pariquera-Açu",
                "label": "Pariquera-Açu"
            },
            {
                "value": "Parisi",
                "label": "Parisi"
            },
            {
                "value": "Patrocínio Paulista",
                "label": "Patrocínio Paulista"
            },
            {
                "value": "Paulicéia",
                "label": "Paulicéia"
            },
            {
                "value": "Paulínia",
                "label": "Paulínia"
            },
            {
                "value": "Paulistânia",
                "label": "Paulistânia"
            },
            {
                "value": "Paulo de Faria",
                "label": "Paulo de Faria"
            },
            {
                "value": "Pederneiras",
                "label": "Pederneiras"
            },
            {
                "value": "Pedra Bela",
                "label": "Pedra Bela"
            },
            {
                "value": "Pedranópolis",
                "label": "Pedranópolis"
            },
            {
                "value": "Pedregulho",
                "label": "Pedregulho"
            },
            {
                "value": "Pedreira",
                "label": "Pedreira"
            },
            {
                "value": "Pedrinhas Paulista",
                "label": "Pedrinhas Paulista"
            },
            {
                "value": "Pedro de Toledo",
                "label": "Pedro de Toledo"
            },
            {
                "value": "Penápolis",
                "label": "Penápolis"
            },
            {
                "value": "Pereira Barreto",
                "label": "Pereira Barreto"
            },
            {
                "value": "Pereiras",
                "label": "Pereiras"
            },
            {
                "value": "Peruíbe",
                "label": "Peruíbe"
            },
            {
                "value": "Piacatu",
                "label": "Piacatu"
            },
            {
                "value": "Piedade",
                "label": "Piedade"
            },
            {
                "value": "Pilar do Sul",
                "label": "Pilar do Sul"
            },
            {
                "value": "Pindamonhangaba",
                "label": "Pindamonhangaba"
            },
            {
                "value": "Pindorama",
                "label": "Pindorama"
            },
            {
                "value": "Pinhalzinho",
                "label": "Pinhalzinho"
            },
            {
                "value": "Piquerobi",
                "label": "Piquerobi"
            },
            {
                "value": "Piquete",
                "label": "Piquete"
            },
            {
                "value": "Piracaia",
                "label": "Piracaia"
            },
            {
                "value": "Piracicaba",
                "label": "Piracicaba"
            },
            {
                "value": "Piraju",
                "label": "Piraju"
            },
            {
                "value": "Pirajuí",
                "label": "Pirajuí"
            },
            {
                "value": "Pirangi",
                "label": "Pirangi"
            },
            {
                "value": "Pirapora do Bom Jesus",
                "label": "Pirapora do Bom Jesus"
            },
            {
                "value": "Pirapozinho",
                "label": "Pirapozinho"
            },
            {
                "value": "Pirassununga",
                "label": "Pirassununga"
            },
            {
                "value": "Piratininga",
                "label": "Piratininga"
            },
            {
                "value": "Pitangueiras",
                "label": "Pitangueiras"
            },
            {
                "value": "Planalto",
                "label": "Planalto"
            },
            {
                "value": "Platina",
                "label": "Platina"
            },
            {
                "value": "Poá",
                "label": "Poá"
            },
            {
                "value": "Poloni",
                "label": "Poloni"
            },
            {
                "value": "Pompéia",
                "label": "Pompéia"
            },
            {
                "value": "Pongaí",
                "label": "Pongaí"
            },
            {
                "value": "Pontal",
                "label": "Pontal"
            },
            {
                "value": "Pontalinda",
                "label": "Pontalinda"
            },
            {
                "value": "Pontes Gestal",
                "label": "Pontes Gestal"
            },
            {
                "value": "Populina",
                "label": "Populina"
            },
            {
                "value": "Porangaba",
                "label": "Porangaba"
            },
            {
                "value": "Porto Feliz",
                "label": "Porto Feliz"
            },
            {
                "value": "Porto Ferreira",
                "label": "Porto Ferreira"
            },
            {
                "value": "Potim",
                "label": "Potim"
            },
            {
                "value": "Potirendaba",
                "label": "Potirendaba"
            },
            {
                "value": "Pracinha",
                "label": "Pracinha"
            },
            {
                "value": "Pradópolis",
                "label": "Pradópolis"
            },
            {
                "value": "Praia Grande",
                "label": "Praia Grande"
            },
            {
                "value": "Pratânia",
                "label": "Pratânia"
            },
            {
                "value": "Presidente Alves",
                "label": "Presidente Alves"
            },
            {
                "value": "Presidente Bernardes",
                "label": "Presidente Bernardes"
            },
            {
                "value": "Presidente Epitácio",
                "label": "Presidente Epitácio"
            },
            {
                "value": "Presidente Prudente",
                "label": "Presidente Prudente"
            },
            {
                "value": "Presidente Venceslau",
                "label": "Presidente Venceslau"
            },
            {
                "value": "Promissão",
                "label": "Promissão"
            },
            {
                "value": "Quadra",
                "label": "Quadra"
            },
            {
                "value": "Quatá",
                "label": "Quatá"
            },
            {
                "value": "Queiroz",
                "label": "Queiroz"
            },
            {
                "value": "Queluz",
                "label": "Queluz"
            },
            {
                "value": "Quintana",
                "label": "Quintana"
            },
            {
                "value": "Rafard",
                "label": "Rafard"
            },
            {
                "value": "Rancharia",
                "label": "Rancharia"
            },
            {
                "value": "Redenção da Serra",
                "label": "Redenção da Serra"
            },
            {
                "value": "Regente Feijó",
                "label": "Regente Feijó"
            },
            {
                "value": "Reginópolis",
                "label": "Reginópolis"
            },
            {
                "value": "Registro",
                "label": "Registro"
            },
            {
                "value": "Restinga",
                "label": "Restinga"
            },
            {
                "value": "Ribeira",
                "label": "Ribeira"
            },
            {
                "value": "Ribeirão Bonito",
                "label": "Ribeirão Bonito"
            },
            {
                "value": "Ribeirão Branco",
                "label": "Ribeirão Branco"
            },
            {
                "value": "Ribeirão Corrente",
                "label": "Ribeirão Corrente"
            },
            {
                "value": "Ribeirão do Sul",
                "label": "Ribeirão do Sul"
            },
            {
                "value": "Ribeirão dos Índios",
                "label": "Ribeirão dos Índios"
            },
            {
                "value": "Ribeirão Grande",
                "label": "Ribeirão Grande"
            },
            {
                "value": "Ribeirão Pires",
                "label": "Ribeirão Pires"
            },
            {
                "value": "Ribeirão Preto",
                "label": "Ribeirão Preto"
            },
            {
                "value": "Riversul",
                "label": "Riversul"
            },
            {
                "value": "Rifaina",
                "label": "Rifaina"
            },
            {
                "value": "Rincão",
                "label": "Rincão"
            },
            {
                "value": "Rinópolis",
                "label": "Rinópolis"
            },
            {
                "value": "Rio Claro",
                "label": "Rio Claro"
            },
            {
                "value": "Rio das Pedras",
                "label": "Rio das Pedras"
            },
            {
                "value": "Rio Grande da Serra",
                "label": "Rio Grande da Serra"
            },
            {
                "value": "Riolândia",
                "label": "Riolândia"
            },
            {
                "value": "Rosana",
                "label": "Rosana"
            },
            {
                "value": "Roseira",
                "label": "Roseira"
            },
            {
                "value": "Rubiácea",
                "label": "Rubiácea"
            },
            {
                "value": "Rubinéia",
                "label": "Rubinéia"
            },
            {
                "value": "Sabino",
                "label": "Sabino"
            },
            {
                "value": "Sagres",
                "label": "Sagres"
            },
            {
                "value": "Sales",
                "label": "Sales"
            },
            {
                "value": "Sales Oliveira",
                "label": "Sales Oliveira"
            },
            {
                "value": "Salesópolis",
                "label": "Salesópolis"
            },
            {
                "value": "Salmourão",
                "label": "Salmourão"
            },
            {
                "value": "Saltinho",
                "label": "Saltinho"
            },
            {
                "value": "Salto",
                "label": "Salto"
            },
            {
                "value": "Salto de Pirapora",
                "label": "Salto de Pirapora"
            },
            {
                "value": "Salto Grande",
                "label": "Salto Grande"
            },
            {
                "value": "Sandovalina",
                "label": "Sandovalina"
            },
            {
                "value": "Santa Adélia",
                "label": "Santa Adélia"
            },
            {
                "value": "Santa Albertina",
                "label": "Santa Albertina"
            },
            {
                "value": "Santa Bárbara d'Oeste",
                "label": "Santa Bárbara d'Oeste"
            },
            {
                "value": "Santa Branca",
                "label": "Santa Branca"
            },
            {
                "value": "Santa Clara d'Oeste",
                "label": "Santa Clara d'Oeste"
            },
            {
                "value": "Santa Cruz da Conceição",
                "label": "Santa Cruz da Conceição"
            },
            {
                "value": "Santa Cruz da Esperança",
                "label": "Santa Cruz da Esperança"
            },
            {
                "value": "Santa Cruz das Palmeiras",
                "label": "Santa Cruz das Palmeiras"
            },
            {
                "value": "Santa Cruz do Rio Pardo",
                "label": "Santa Cruz do Rio Pardo"
            },
            {
                "value": "Santa Ernestina",
                "label": "Santa Ernestina"
            },
            {
                "value": "Santa Fé do Sul",
                "label": "Santa Fé do Sul"
            },
            {
                "value": "Santa Gertrudes",
                "label": "Santa Gertrudes"
            },
            {
                "value": "Santa Isabel",
                "label": "Santa Isabel"
            },
            {
                "value": "Santa Lúcia",
                "label": "Santa Lúcia"
            },
            {
                "value": "Santa Maria da Serra",
                "label": "Santa Maria da Serra"
            },
            {
                "value": "Santa Mercedes",
                "label": "Santa Mercedes"
            },
            {
                "value": "Santana da Ponte Pensa",
                "label": "Santana da Ponte Pensa"
            },
            {
                "value": "Santana de Parnaíba",
                "label": "Santana de Parnaíba"
            },
            {
                "value": "Santa Rita d'Oeste",
                "label": "Santa Rita d'Oeste"
            },
            {
                "value": "Santa Rita do Passa Quatro",
                "label": "Santa Rita do Passa Quatro"
            },
            {
                "value": "Santa Rosa de Viterbo",
                "label": "Santa Rosa de Viterbo"
            },
            {
                "value": "Santa Salete",
                "label": "Santa Salete"
            },
            {
                "value": "Santo Anastácio",
                "label": "Santo Anastácio"
            },
            {
                "value": "Santo André",
                "label": "Santo André"
            },
            {
                "value": "Santo Antônio da Alegria",
                "label": "Santo Antônio da Alegria"
            },
            {
                "value": "Santo Antônio de Posse",
                "label": "Santo Antônio de Posse"
            },
            {
                "value": "Santo Antônio do Aracanguá",
                "label": "Santo Antônio do Aracanguá"
            },
            {
                "value": "Santo Antônio do Jardim",
                "label": "Santo Antônio do Jardim"
            },
            {
                "value": "Santo Antônio do Pinhal",
                "label": "Santo Antônio do Pinhal"
            },
            {
                "value": "Santo Expedito",
                "label": "Santo Expedito"
            },
            {
                "value": "Santópolis do Aguapeí",
                "label": "Santópolis do Aguapeí"
            },
            {
                "value": "Santos",
                "label": "Santos"
            },
            {
                "value": "São Bento do Sapucaí",
                "label": "São Bento do Sapucaí"
            },
            {
                "value": "São Bernardo do Campo",
                "label": "São Bernardo do Campo"
            },
            {
                "value": "São Caetano do Sul",
                "label": "São Caetano do Sul"
            },
            {
                "value": "São Carlos",
                "label": "São Carlos"
            },
            {
                "value": "São Francisco",
                "label": "São Francisco"
            },
            {
                "value": "São João da Boa Vista",
                "label": "São João da Boa Vista"
            },
            {
                "value": "São João das Duas Pontes",
                "label": "São João das Duas Pontes"
            },
            {
                "value": "São João de Iracema",
                "label": "São João de Iracema"
            },
            {
                "value": "São João do Pau d'Alho",
                "label": "São João do Pau d'Alho"
            },
            {
                "value": "São Joaquim da Barra",
                "label": "São Joaquim da Barra"
            },
            {
                "value": "São José da Bela Vista",
                "label": "São José da Bela Vista"
            },
            {
                "value": "São José do Barreiro",
                "label": "São José do Barreiro"
            },
            {
                "value": "São José do Rio Pardo",
                "label": "São José do Rio Pardo"
            },
            {
                "value": "São José do Rio Preto",
                "label": "São José do Rio Preto"
            },
            {
                "value": "São José dos Campos",
                "label": "São José dos Campos"
            },
            {
                "value": "São Lourenço da Serra",
                "label": "São Lourenço da Serra"
            },
            {
                "value": "São Luiz do Paraitinga",
                "label": "São Luiz do Paraitinga"
            },
            {
                "value": "São Manuel",
                "label": "São Manuel"
            },
            {
                "value": "São Miguel Arcanjo",
                "label": "São Miguel Arcanjo"
            },
            {
                "value": "São Paulo",
                "label": "São Paulo"
            },
            {
                "value": "São Pedro",
                "label": "São Pedro"
            },
            {
                "value": "São Pedro do Turvo",
                "label": "São Pedro do Turvo"
            },
            {
                "value": "São Roque",
                "label": "São Roque"
            },
            {
                "value": "São Sebastião",
                "label": "São Sebastião"
            },
            {
                "value": "São Sebastião da Grama",
                "label": "São Sebastião da Grama"
            },
            {
                "value": "São Simão",
                "label": "São Simão"
            },
            {
                "value": "São Vicente",
                "label": "São Vicente"
            },
            {
                "value": "Sarapuí",
                "label": "Sarapuí"
            },
            {
                "value": "Sarutaiá",
                "label": "Sarutaiá"
            },
            {
                "value": "Sebastianópolis do Sul",
                "label": "Sebastianópolis do Sul"
            },
            {
                "value": "Serra Azul",
                "label": "Serra Azul"
            },
            {
                "value": "Serrana",
                "label": "Serrana"
            },
            {
                "value": "Serra Negra",
                "label": "Serra Negra"
            },
            {
                "value": "Sertãozinho",
                "label": "Sertãozinho"
            },
            {
                "value": "Sete Barras",
                "label": "Sete Barras"
            },
            {
                "value": "Severínia",
                "label": "Severínia"
            },
            {
                "value": "Silveiras",
                "label": "Silveiras"
            },
            {
                "value": "Socorro",
                "label": "Socorro"
            },
            {
                "value": "Sorocaba",
                "label": "Sorocaba"
            },
            {
                "value": "Sud Mennucci",
                "label": "Sud Mennucci"
            },
            {
                "value": "Sumaré",
                "label": "Sumaré"
            },
            {
                "value": "Suzano",
                "label": "Suzano"
            },
            {
                "value": "Suzanápolis",
                "label": "Suzanápolis"
            },
            {
                "value": "Tabapuã",
                "label": "Tabapuã"
            },
            {
                "value": "Tabatinga",
                "label": "Tabatinga"
            },
            {
                "value": "Taboão da Serra",
                "label": "Taboão da Serra"
            },
            {
                "value": "Taciba",
                "label": "Taciba"
            },
            {
                "value": "Taguaí",
                "label": "Taguaí"
            },
            {
                "value": "Taiaçu",
                "label": "Taiaçu"
            },
            {
                "value": "Taiúva",
                "label": "Taiúva"
            },
            {
                "value": "Tambaú",
                "label": "Tambaú"
            },
            {
                "value": "Tanabi",
                "label": "Tanabi"
            },
            {
                "value": "Tapiraí",
                "label": "Tapiraí"
            },
            {
                "value": "Tapiratiba",
                "label": "Tapiratiba"
            },
            {
                "value": "Taquaral",
                "label": "Taquaral"
            },
            {
                "value": "Taquaritinga",
                "label": "Taquaritinga"
            },
            {
                "value": "Taquarituba",
                "label": "Taquarituba"
            },
            {
                "value": "Taquarivaí",
                "label": "Taquarivaí"
            },
            {
                "value": "Tarabai",
                "label": "Tarabai"
            },
            {
                "value": "Tarumã",
                "label": "Tarumã"
            },
            {
                "value": "Tatuí",
                "label": "Tatuí"
            },
            {
                "value": "Taubaté",
                "label": "Taubaté"
            },
            {
                "value": "Tejupá",
                "label": "Tejupá"
            },
            {
                "value": "Teodoro Sampaio",
                "label": "Teodoro Sampaio"
            },
            {
                "value": "Terra Roxa",
                "label": "Terra Roxa"
            },
            {
                "value": "Tietê",
                "label": "Tietê"
            },
            {
                "value": "Timburi",
                "label": "Timburi"
            },
            {
                "value": "Torre de Pedra",
                "label": "Torre de Pedra"
            },
            {
                "value": "Torrinha",
                "label": "Torrinha"
            },
            {
                "value": "Trabiju",
                "label": "Trabiju"
            },
            {
                "value": "Tremembé",
                "label": "Tremembé"
            },
            {
                "value": "Três Fronteiras",
                "label": "Três Fronteiras"
            },
            {
                "value": "Tuiuti",
                "label": "Tuiuti"
            },
            {
                "value": "Tupã",
                "label": "Tupã"
            },
            {
                "value": "Tupi Paulista",
                "label": "Tupi Paulista"
            },
            {
                "value": "Turiúba",
                "label": "Turiúba"
            },
            {
                "value": "Turmalina",
                "label": "Turmalina"
            },
            {
                "value": "Ubarana",
                "label": "Ubarana"
            },
            {
                "value": "Ubatuba",
                "label": "Ubatuba"
            },
            {
                "value": "Ubirajara",
                "label": "Ubirajara"
            },
            {
                "value": "Uchoa",
                "label": "Uchoa"
            },
            {
                "value": "União Paulista",
                "label": "União Paulista"
            },
            {
                "value": "Urânia",
                "label": "Urânia"
            },
            {
                "value": "Uru",
                "label": "Uru"
            },
            {
                "value": "Urupês",
                "label": "Urupês"
            },
            {
                "value": "Valentim Gentil",
                "label": "Valentim Gentil"
            },
            {
                "value": "Valinhos",
                "label": "Valinhos"
            },
            {
                "value": "Valparaíso",
                "label": "Valparaíso"
            },
            {
                "value": "Vargem",
                "label": "Vargem"
            },
            {
                "value": "Vargem Grande do Sul",
                "label": "Vargem Grande do Sul"
            },
            {
                "value": "Vargem Grande Paulista",
                "label": "Vargem Grande Paulista"
            },
            {
                "value": "Várzea Paulista",
                "label": "Várzea Paulista"
            },
            {
                "value": "Vera Cruz",
                "label": "Vera Cruz"
            },
            {
                "value": "Vinhedo",
                "label": "Vinhedo"
            },
            {
                "value": "Viradouro",
                "label": "Viradouro"
            },
            {
                "value": "Vista Alegre do Alto",
                "label": "Vista Alegre do Alto"
            },
            {
                "value": "Vitória Brasil",
                "label": "Vitória Brasil"
            },
            {
                "value": "Votorantim",
                "label": "Votorantim"
            },
            {
                "value": "Votuporanga",
                "label": "Votuporanga"
            },
            {
                "value": "Zacarias",
                "label": "Zacarias"
            },
            {
                "value": "Chavantes",
                "label": "Chavantes"
            },
            {
                "value": "Estiva Gerbi",
                "label": "Estiva Gerbi"
            }
        ]
    },
    {
        "ID": "27",
        "value": "TO",
        "label": "Tocantins",
        "children": [
            {
                "value": "Abreulândia",
                "label": "Abreulândia"
            },
            {
                "value": "Aguiarnópolis",
                "label": "Aguiarnópolis"
            },
            {
                "value": "Aliança do Tocantins",
                "label": "Aliança do Tocantins"
            },
            {
                "value": "Almas",
                "label": "Almas"
            },
            {
                "value": "Alvorada",
                "label": "Alvorada"
            },
            {
                "value": "Ananás",
                "label": "Ananás"
            },
            {
                "value": "Angico",
                "label": "Angico"
            },
            {
                "value": "Aparecida do Rio Negro",
                "label": "Aparecida do Rio Negro"
            },
            {
                "value": "Aragominas",
                "label": "Aragominas"
            },
            {
                "value": "Araguacema",
                "label": "Araguacema"
            },
            {
                "value": "Araguaçu",
                "label": "Araguaçu"
            },
            {
                "value": "Araguaína",
                "label": "Araguaína"
            },
            {
                "value": "Araguanã",
                "label": "Araguanã"
            },
            {
                "value": "Araguatins",
                "label": "Araguatins"
            },
            {
                "value": "Arapoema",
                "label": "Arapoema"
            },
            {
                "value": "Arraias",
                "label": "Arraias"
            },
            {
                "value": "Augustinópolis",
                "label": "Augustinópolis"
            },
            {
                "value": "Aurora do Tocantins",
                "label": "Aurora do Tocantins"
            },
            {
                "value": "Axixá do Tocantins",
                "label": "Axixá do Tocantins"
            },
            {
                "value": "Babaçulândia",
                "label": "Babaçulândia"
            },
            {
                "value": "Bandeirantes do Tocantins",
                "label": "Bandeirantes do Tocantins"
            },
            {
                "value": "Barra do Ouro",
                "label": "Barra do Ouro"
            },
            {
                "value": "Barrolândia",
                "label": "Barrolândia"
            },
            {
                "value": "Bernardo Sayão",
                "label": "Bernardo Sayão"
            },
            {
                "value": "Bom Jesus do Tocantins",
                "label": "Bom Jesus do Tocantins"
            },
            {
                "value": "Brasilândia do Tocantins",
                "label": "Brasilândia do Tocantins"
            },
            {
                "value": "Brejinho de Nazaré",
                "label": "Brejinho de Nazaré"
            },
            {
                "value": "Buriti do Tocantins",
                "label": "Buriti do Tocantins"
            },
            {
                "value": "Cachoeirinha",
                "label": "Cachoeirinha"
            },
            {
                "value": "Campos Lindos",
                "label": "Campos Lindos"
            },
            {
                "value": "Cariri do Tocantins",
                "label": "Cariri do Tocantins"
            },
            {
                "value": "Carmolândia",
                "label": "Carmolândia"
            },
            {
                "value": "Carrasco Bonito",
                "label": "Carrasco Bonito"
            },
            {
                "value": "Caseara",
                "label": "Caseara"
            },
            {
                "value": "Centenário",
                "label": "Centenário"
            },
            {
                "value": "Chapada de Areia",
                "label": "Chapada de Areia"
            },
            {
                "value": "Chapada da Natividade",
                "label": "Chapada da Natividade"
            },
            {
                "value": "Colinas do Tocantins",
                "label": "Colinas do Tocantins"
            },
            {
                "value": "Combinado",
                "label": "Combinado"
            },
            {
                "value": "Conceição do Tocantins",
                "label": "Conceição do Tocantins"
            },
            {
                "value": "Couto Magalhães",
                "label": "Couto Magalhães"
            },
            {
                "value": "Cristalândia",
                "label": "Cristalândia"
            },
            {
                "value": "Crixás do Tocantins",
                "label": "Crixás do Tocantins"
            },
            {
                "value": "Darcinópolis",
                "label": "Darcinópolis"
            },
            {
                "value": "Dianópolis",
                "label": "Dianópolis"
            },
            {
                "value": "Divinópolis do Tocantins",
                "label": "Divinópolis do Tocantins"
            },
            {
                "value": "Dois Irmãos do Tocantins",
                "label": "Dois Irmãos do Tocantins"
            },
            {
                "value": "Dueré",
                "label": "Dueré"
            },
            {
                "value": "Esperantina",
                "label": "Esperantina"
            },
            {
                "value": "Fátima",
                "label": "Fátima"
            },
            {
                "value": "Figueirópolis",
                "label": "Figueirópolis"
            },
            {
                "value": "Filadélfia",
                "label": "Filadélfia"
            },
            {
                "value": "Formoso do Araguaia",
                "label": "Formoso do Araguaia"
            },
            {
                "value": "Tabocão",
                "label": "Tabocão"
            },
            {
                "value": "Goianorte",
                "label": "Goianorte"
            },
            {
                "value": "Goiatins",
                "label": "Goiatins"
            },
            {
                "value": "Guaraí",
                "label": "Guaraí"
            },
            {
                "value": "Gurupi",
                "label": "Gurupi"
            },
            {
                "value": "Ipueiras",
                "label": "Ipueiras"
            },
            {
                "value": "Itacajá",
                "label": "Itacajá"
            },
            {
                "value": "Itaguatins",
                "label": "Itaguatins"
            },
            {
                "value": "Itapiratins",
                "label": "Itapiratins"
            },
            {
                "value": "Itaporã do Tocantins",
                "label": "Itaporã do Tocantins"
            },
            {
                "value": "Jaú do Tocantins",
                "label": "Jaú do Tocantins"
            },
            {
                "value": "Juarina",
                "label": "Juarina"
            },
            {
                "value": "Lagoa da Confusão",
                "label": "Lagoa da Confusão"
            },
            {
                "value": "Lagoa do Tocantins",
                "label": "Lagoa do Tocantins"
            },
            {
                "value": "Lajeado",
                "label": "Lajeado"
            },
            {
                "value": "Lavandeira",
                "label": "Lavandeira"
            },
            {
                "value": "Lizarda",
                "label": "Lizarda"
            },
            {
                "value": "Luzinópolis",
                "label": "Luzinópolis"
            },
            {
                "value": "Marianópolis do Tocantins",
                "label": "Marianópolis do Tocantins"
            },
            {
                "value": "Mateiros",
                "label": "Mateiros"
            },
            {
                "value": "Maurilândia do Tocantins",
                "label": "Maurilândia do Tocantins"
            },
            {
                "value": "Miracema do Tocantins",
                "label": "Miracema do Tocantins"
            },
            {
                "value": "Miranorte",
                "label": "Miranorte"
            },
            {
                "value": "Monte do Carmo",
                "label": "Monte do Carmo"
            },
            {
                "value": "Monte Santo do Tocantins",
                "label": "Monte Santo do Tocantins"
            },
            {
                "value": "Palmeiras do Tocantins",
                "label": "Palmeiras do Tocantins"
            },
            {
                "value": "Muricilândia",
                "label": "Muricilândia"
            },
            {
                "value": "Natividade",
                "label": "Natividade"
            },
            {
                "value": "Nazaré",
                "label": "Nazaré"
            },
            {
                "value": "Nova Olinda",
                "label": "Nova Olinda"
            },
            {
                "value": "Nova Rosalândia",
                "label": "Nova Rosalândia"
            },
            {
                "value": "Novo Acordo",
                "label": "Novo Acordo"
            },
            {
                "value": "Novo Alegre",
                "label": "Novo Alegre"
            },
            {
                "value": "Novo Jardim",
                "label": "Novo Jardim"
            },
            {
                "value": "Oliveira de Fátima",
                "label": "Oliveira de Fátima"
            },
            {
                "value": "Palmeirante",
                "label": "Palmeirante"
            },
            {
                "value": "Palmeirópolis",
                "label": "Palmeirópolis"
            },
            {
                "value": "Paraíso do Tocantins",
                "label": "Paraíso do Tocantins"
            },
            {
                "value": "Paranã",
                "label": "Paranã"
            },
            {
                "value": "Pau D'Arco",
                "label": "Pau D'Arco"
            },
            {
                "value": "Pedro Afonso",
                "label": "Pedro Afonso"
            },
            {
                "value": "Peixe",
                "label": "Peixe"
            },
            {
                "value": "Pequizeiro",
                "label": "Pequizeiro"
            },
            {
                "value": "Colméia",
                "label": "Colméia"
            },
            {
                "value": "Pindorama do Tocantins",
                "label": "Pindorama do Tocantins"
            },
            {
                "value": "Piraquê",
                "label": "Piraquê"
            },
            {
                "value": "Pium",
                "label": "Pium"
            },
            {
                "value": "Ponte Alta do Bom Jesus",
                "label": "Ponte Alta do Bom Jesus"
            },
            {
                "value": "Ponte Alta do Tocantins",
                "label": "Ponte Alta do Tocantins"
            },
            {
                "value": "Porto Alegre do Tocantins",
                "label": "Porto Alegre do Tocantins"
            },
            {
                "value": "Porto Nacional",
                "label": "Porto Nacional"
            },
            {
                "value": "Praia Norte",
                "label": "Praia Norte"
            },
            {
                "value": "Presidente Kennedy",
                "label": "Presidente Kennedy"
            },
            {
                "value": "Pugmil",
                "label": "Pugmil"
            },
            {
                "value": "Recursolândia",
                "label": "Recursolândia"
            },
            {
                "value": "Riachinho",
                "label": "Riachinho"
            },
            {
                "value": "Rio da Conceição",
                "label": "Rio da Conceição"
            },
            {
                "value": "Rio dos Bois",
                "label": "Rio dos Bois"
            },
            {
                "value": "Rio Sono",
                "label": "Rio Sono"
            },
            {
                "value": "Sampaio",
                "label": "Sampaio"
            },
            {
                "value": "Sandolândia",
                "label": "Sandolândia"
            },
            {
                "value": "Santa Fé do Araguaia",
                "label": "Santa Fé do Araguaia"
            },
            {
                "value": "Santa Maria do Tocantins",
                "label": "Santa Maria do Tocantins"
            },
            {
                "value": "Santa Rita do Tocantins",
                "label": "Santa Rita do Tocantins"
            },
            {
                "value": "Santa Rosa do Tocantins",
                "label": "Santa Rosa do Tocantins"
            },
            {
                "value": "Santa Tereza do Tocantins",
                "label": "Santa Tereza do Tocantins"
            },
            {
                "value": "Santa Terezinha do Tocantins",
                "label": "Santa Terezinha do Tocantins"
            },
            {
                "value": "São Bento do Tocantins",
                "label": "São Bento do Tocantins"
            },
            {
                "value": "São Félix do Tocantins",
                "label": "São Félix do Tocantins"
            },
            {
                "value": "São Miguel do Tocantins",
                "label": "São Miguel do Tocantins"
            },
            {
                "value": "São Salvador do Tocantins",
                "label": "São Salvador do Tocantins"
            },
            {
                "value": "São Sebastião do Tocantins",
                "label": "São Sebastião do Tocantins"
            },
            {
                "value": "São Valério",
                "label": "São Valério"
            },
            {
                "value": "Silvanópolis",
                "label": "Silvanópolis"
            },
            {
                "value": "Sítio Novo do Tocantins",
                "label": "Sítio Novo do Tocantins"
            },
            {
                "value": "Sucupira",
                "label": "Sucupira"
            },
            {
                "value": "Taguatinga",
                "label": "Taguatinga"
            },
            {
                "value": "Taipas do Tocantins",
                "label": "Taipas do Tocantins"
            },
            {
                "value": "Talismã",
                "label": "Talismã"
            },
            {
                "value": "Palmas",
                "label": "Palmas"
            },
            {
                "value": "Tocantínia",
                "label": "Tocantínia"
            },
            {
                "value": "Tocantinópolis",
                "label": "Tocantinópolis"
            },
            {
                "value": "Tupirama",
                "label": "Tupirama"
            },
            {
                "value": "Tupiratins",
                "label": "Tupiratins"
            },
            {
                "value": "Wanderlândia",
                "label": "Wanderlândia"
            },
            {
                "value": "Xambioá",
                "label": "Xambioá"
            }
        ]
    }
]
