<template>
    <v-app style="background-color: #f8fafc !important;">
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-overlay :value="loading_dialog">
            <v-dialog v-model.lazy="loading_dialog" hide-overlay persistent width="400">
                <v-card color="primary" dark>
                    <v-card-text class="pt-2">
                        {{ loading_message }}
                        <v-progress-linear indeterminate color="white" class="mt-6 mb-0"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-overlay>
        <!--Dialog ---------------------------------------------------------------------------------------------------->
        <v-sheet outlined flat class="ma-0" color="#F8FAFC" height="auto">
            <v-sheet ref="form" flat height="auto" outlined>
                <v-tabs class="flat-tab" fixed-tabs grow background-color="primary">
                    <v-tab>
                        <v-icon left>mdi-cash-multiple</v-icon>
                        Revisão Financeira - {{ this.contact_deal.TITLE }}
                    </v-tab>
                    <v-tab-item>
                        <v-card flat color="#F8FAFC">
                            <v-container class="fill-height">
                                <v-layout justify-center>
                                    <v-col ref="form" class="text-xs-center">
                                        <v-row class="mb-2" no-gutters justify="start">
                                            <h5>Alterar Status</h5>
                                        </v-row>
                                        <v-row no-gutters class="ml-12 mr-12">
                                            <v-col class="mr-1 ml-1">
                                                <a>Valor Final</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e5 }"
                                                    disabled
                                                    label="%"
                                                    v-model.lazy="e5"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="mr-1 ml-1">
                                                <a>Original</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e8 }"
                                                    disabled
                                                    label="%"
                                                    v-model.lazy="e8"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <!----------------------------------------------------------------------------->
                                        <v-divider></v-divider>
                                        <v-row no-gutters justify="start">
                                            <h5>Desconto UFV e Adicionais</h5>
                                            <a class="ml-2">(Desconto indivídual aplicado na UFV)</a>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col class="mr-1 ml-1">
                                                <a>Desconto Base UFV</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c13 }"
                                                    disabled
                                                    label="%"
                                                    v-model.lazy="c13"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c14 }"
                                                    disabled
                                                    label="Valor Base"
                                                    v-model.lazy="c14"
                                                ></v-text-field>
                                                <a>Desconto Máximo</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c17 }"
                                                    disabled
                                                    label="%"
                                                    v-model.lazy="c17"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c18 }"
                                                    disabled
                                                    label="BDI R$/Mód"
                                                    v-model.lazy="c18"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="mr-1 ml-1">
                                                <a style="color: #2fa360">Desconto Ofertado UFV</a>
                                                <v-row no-gutters>
                                                    <v-text-field
                                                        label="Porcentagem"
                                                        v-model.lazy="desconto_ufv"
                                                        v-money="percentage"
                                                    ></v-text-field>
                                                    <v-btn @click="updateForm" class="ma-2" tile large color="success"
                                                           icon>
                                                        <v-icon>mdi-reload</v-icon>
                                                    </v-btn>
                                                </v-row>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e14 }"
                                                    disabled
                                                    label="Desconto"
                                                    v-model.lazy="e14"
                                                ></v-text-field>
                                                <a>Desconto Máximo (R$)</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e17 }"
                                                    disabled
                                                    label="%"
                                                    v-model.lazy="e17"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <!----------------------------------------------------------------------------->
                                        <v-divider></v-divider>
                                        <v-row no-gutters class="mb-2" justify="start">
                                            <a>(Desconto indivídual aplicado aos Custos Adicionais de Conexão)</a>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col class="mr-1 ml-1">
                                                <a>Desconto Base CA Conexão</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c22 }"
                                                    disabled
                                                    label="%"
                                                    v-model.lazy="c22"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c23 }"
                                                    disabled
                                                    label="Valor Base"
                                                    v-model.lazy="c23"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <a style="color: #2fa360">Desconto Ofertado CA Conexão</a>
                                                <v-row no-gutters>
                                                    <v-text-field
                                                        label="Porcentagem"
                                                        v-model.lazy="desconto_ca"
                                                        v-money="percentage"
                                                    ></v-text-field>
                                                    <v-btn class="ma-2" @click="updateForm" tile large color="success"
                                                           icon>
                                                        <v-icon>mdi-reload</v-icon>
                                                    </v-btn>
                                                </v-row>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e23 }"
                                                    disabled
                                                    label="Desconto"
                                                    v-model.lazy="e23"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <!------------------------------------------------------------------------->
                                        <v-divider/>
                                        <v-row>
                                            <v-col class="mr-1 ml-1">
                                                <a>Enerzee</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c27 }"
                                                    disabled
                                                    label="Divisor"
                                                    v-model.lazy="c27"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c28 }"
                                                    disabled
                                                    label="R$"
                                                    v-model.lazy="c28"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c29 }"
                                                    disabled
                                                    label="BDI Corrigido"
                                                    v-model.lazy="c29"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="mr-1 ml-1">
                                                <a>Comissão - Rede</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e27 }"
                                                    disabled
                                                    label="Divisor"
                                                    v-model.lazy="e27"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e28 }"
                                                    disabled
                                                    label="R$"
                                                    v-model.lazy="e28"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e29 }"
                                                    disabled
                                                    label="Rede Final"
                                                    v-model.lazy="e29"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <!----------------------------------------------------------------------------->
                                        <v-divider></v-divider>
                                        <v-row no-gutters justify="start">
                                            <h5>Comissão - Rede</h5>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col class="mr-1 ml-1">
                                                <a>Praticado</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c33 }"
                                                    disabled
                                                    label="Direct"
                                                    v-model.lazy="c33"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c34 }"
                                                    disabled
                                                    label="15%"
                                                    v-model.lazy="c34"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c35 }"
                                                    disabled
                                                    label="Pontos (R$)"
                                                    v-model.lazy="c35"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c36 }"
                                                    disabled
                                                    label="Pontos"
                                                    v-model.lazy="c36"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c37 }"
                                                    disabled
                                                    label="Soma"
                                                    v-model.lazy="c37"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <a>Novo</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e33 }"
                                                    disabled
                                                    label="Direct"
                                                    v-model.lazy="e33"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e34 }"
                                                    disabled
                                                    label="15%"
                                                    v-model.lazy="e34"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e35 }"
                                                    disabled
                                                    label="Pontos (R$)"
                                                    v-model.lazy="e35"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e36 }"
                                                    disabled
                                                    label="Pontos"
                                                    v-model.lazy="e36"
                                                ></v-text-field>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e37 }"
                                                    disabled
                                                    label="Soma"
                                                    v-model.lazy="e37"
                                                ></v-text-field>
                                            </v-col>
                                            <!------------------------------------------------------------------------->
                                        </v-row>
                                        <!------------------------------------------------------------------------->
                                        <v-divider/>
                                        <v-row class="mb-2" no-gutters justify="start">
                                            <h5>Instalação</h5>
                                        </v-row>
                                        <v-row no-gutters class="ml-12 mr-12">
                                            <v-col class="mr-1 ml-1">
                                                <a>Praticado - Preço por Módulo</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c42 }"
                                                    disabled
                                                    label="Preço"
                                                    v-model.lazy="c42"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="mr-1 ml-1">
                                                <a>Novo - Preço por Módulo</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e42 }"
                                                    disabled
                                                    label="Preço"
                                                    v-model.lazy="e42"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <!------------------------------------------------------------------------->
                                        <v-divider/>
                                        <v-row class="mb-2" no-gutters justify="start">
                                            <h5>BDI</h5>
                                        </v-row>
                                        <v-row no-gutters class="ml-12 mr-12">
                                            <v-col class="mr-1 ml-1">
                                                <a>Praticado - Preço por Módulo</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': c47 }"
                                                    disabled
                                                    label="Preço"
                                                    v-model.lazy="c47"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="mr-1 ml-1">
                                                <a>Novo - Preço por Módulo</a>
                                                <v-text-field
                                                    :class="{ 'my-text-style': e47 }"
                                                    disabled
                                                    label="Preço"
                                                    v-model.lazy="e47"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <!----------------------------------------------------------------------------->
                                    </v-col>
                                </v-layout>
                            </v-container>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-sheet>
        </v-sheet>
        <v-col>
            <v-row justify="end" align="end">
                <v-col class="d-flex ma-2">
                    <v-btn class="ml-auto" color="primary" @click="submit">Enviar</v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-app>
</template>

<script>
import {required} from 'vuelidate/lib/validators';
import {
    padroes_entrada,
    transformadores,
    subestacoes,
    cabines,
    m_inversor,
    categorias,
    lista_tensao,
    p_modulos,
    telhados,
    responsabilidades_cabine,
    responsabilidades_extensao,
    responsabilidades_infraestrutura,
    responsabilidades_padrao_entrada,
    responsabilidades_subestacao,
    responsabilidades_transformador
} from '../data/SelectData';

export default {
    name: "RevisaoTecnica",
    props: ['username', 'id'],

    data() {
        return {
            deal: [],
            contact_deal: [],
            loading_message: null,
            loading_dialog: null,
            //==========================================================================================================
            padroes_entrada: padroes_entrada,
            transformadores: transformadores,
            subestacoes: subestacoes,
            cabines: cabines,
            m_inversor: m_inversor,
            categorias: categorias,
            lista_tensao: lista_tensao,
            p_modulos: p_modulos,
            telhados: telhados,
            responsabilidades_cabine: responsabilidades_cabine,
            responsabilidades_extensao: responsabilidades_extensao,
            responsabilidades_infraestrutura: responsabilidades_infraestrutura,
            responsabilidades_padrao_entrada: responsabilidades_padrao_entrada,
            responsabilidades_subestacao: responsabilidades_subestacao,
            responsabilidades_transformador: responsabilidades_transformador,
            //==========================================================================================================
            percentage: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '%',
                precision: 2,
                masked: true
            },
            // END - Settings ==========================================================================================
            desconto_ufv: 0,
            desconto_ca: 0,

            c5: null, e5: null,
            c13: null, e8: null,
            c14: null, e14: null,
            c17: null, e17: null,
            c18: null, e23: null,
            c22: null, e27: null,
            c23: null, e28: null,
            c27: null, e29: null,
            c28: null, e33: null,
            c29: null, e34: null,
            c34: null, e35: null,
            c33: null, e37: null,
            c35: null, e36: null,
            c36: null, e42: null,
            c37: null, e47: null,
            c42: null,
            c47: null,
            //==========================================================================================================
        }
    },

    async created() {
        await this.setLoading(true, 'Carregando...')
        await this.setDeal();
        if (await this.checkPermissao()) {
            await this.checkFase();
            await this.getRevisaoFinanceira(this.id, this.desconto_ufv, this.desconto_ca, 'Não');
            await this.setFormData();
            await this.setLoading(false, '');
        } else {
            await this.setLoading(false, '');
            await this.$router.push('/');
        }
    },

    methods: {
        async setDeal() {
            await this.$http.get('proposta/' + this.id + '/edit').then(resp => {
                this.contact_deal = resp.data.message[1];
            });
        },

        async checkPermissao() {
            if (this.username.bitrix_id === this.contact_deal['ASSIGNED_BY_ID'] || this.username.tipo_contato === '7429') {
                return true;
            } else {
                this.toastNotificate('Você não tem permissão para acessar esta página!', 'error', 2500)
                return false;
            }
        },

        async checkFase() {
            if (this.contact_deal['STAGE_ID'] === 'C8:5' || this.contact_deal['STAGE_ID'] === 'C8:FINAL_INVOICE') {
                return true;
            } else {
                this.toastNotificate('A proposta ainda não pode receber desconto...', 'error', 2500)
                this.loading_dialog = false;
                return this.$router.push('/');
            }
        },

        toastNotificate(message, type, duration) {
            return this.$toast.open({
                message: message,
                type: type,
                duration: duration,
            });
        },

        setLoading(state, message) {
            this.loading_dialog = state;
            this.loading_message = message;
        },

        async updateForm() {
            await this.setLoading(true, 'Um momento, por favor...');
            await this.getRevisaoFinanceira(this.id, this.desconto_ufv, this.desconto_ca, 'Não');
            await this.setFormData();
            await this.setLoading(false, '');
        },

        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async getRevisaoFinanceira(id, ufv, ca, revisao) {
            await this.$http.get('proposta/revisao_financeira/' + id + '/' + ufv + 25 + '/' + ca + 25 + '/' + revisao).then(resp => {
                // console.log(resp.data[0][0].values[0][0]);
                this.deal = resp.data[0];
            });
        },

        setFormData() {
            this.c5 = this.deal[0].values[0][0];
            this.e5 = this.deal[1].values[0][0];
            this.e8 = this.deal[2].values[0][0];
            this.c13 = this.deal[3].values[0][0];
            this.c14 = this.deal[4].values[0][0];
            this.c17 = this.deal[5].values[0][0];
            this.c18 = this.deal[6].values[0][0];
            this.e14 = this.deal[7].values[0][0];
            this.e17 = this.deal[8].values[0][0];
            this.c22 = this.deal[9].values[0][0];
            this.c23 = this.deal[10].values[0][0];
            this.e23 = this.deal[11].values[0][0];
            this.c27 = this.deal[12].values[0][0];
            this.c28 = this.deal[13].values[0][0];
            this.c29 = this.deal[14].values[0][0];
            this.e27 = this.deal[15].values[0][0];
            this.e28 = this.deal[16].values[0][0];
            this.e29 = this.deal[17].values[0][0];
            this.c33 = this.deal[18].values[0][0];
            this.c34 = this.deal[19].values[0][0];
            this.c35 = this.deal[20].values[0][0];
            this.c36 = this.deal[21].values[0][0];
            this.c37 = this.deal[22].values[0][0];
            this.e33 = this.deal[23].values[0][0];
            this.e34 = this.deal[24].values[0][0];
            this.e35 = this.deal[25].values[0][0];
            this.e36 = this.deal[26].values[0][0];
            this.e37 = this.deal[27].values[0][0];
            this.c42 = this.deal[28].values[0][0];
            this.e42 = this.deal[29].values[0][0];
            this.c47 = this.deal[30].values[0][0];
            this.e47 = this.deal[31].values[0][0];
        },

        async submit() {
            await this.setLoading(true, 'Cadastrando desconto, por favor, aguarde...');
            await this.$http.post('proposta/revisao_financeira', {
                id: this.id,
                ufv: this.desconto_ufv,
                ca: this.desconto_ca,
                revisao: 'Sim'
            }).then(resp => {
                console.log(resp.data);
                this.setLoading(false, '');
                this.$toast.open({
                    message: 'Desconto cadastrado com sucesso!',
                    type: "success",
                    duration: 2500,
                });
                this.$router.push('/');
            }).catch(function (error) {
                this.setLoading(false, '');
                this.$router.push('/');
            });
        },

        itemErrors(item) {
            const errors = []
            if (!item.$dirty) return errors
            !item.required && errors.push('Obrigatório.')
            return errors
        },
    },

    watch: {},

    validations: {},
}
</script>

<style scoped>
.my-text-style >>> .v-text-field__slot input {
    color: #385d7a;
}
</style>
