import Vue from 'vue'
import axios from 'axios'

// axios.defaults.baseURL = process.env.APP_URL;
axios.defaults.withCredentials = true;
// axios.defaults.timeout = 1000 * 60;

Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios
    }
})
