import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const opts = {
    breakpoint: {
        thresholds: {
            xs: 900,
        },
        scrollBarWidth: 24,
    },
};

export default new Vuetify(opts);
