<template>
    <v-app style="height: 0" class="mx-4">
        <v-responsive class="overflow-x-auto">
            <v-card flat outlined class="container-fluid pa-0 my-2" min-width="1100" max-width="2000">
                <v-toolbar flat color="white">
                    <v-row>
                        <v-col>
                            <v-toolbar-title style="font-size: 24px" class="font-weight-medium ma-2">
                                Bem-Vindo(a), {{ nome }}.
                            </v-toolbar-title>
                        </v-col>
                        <v-col cols="auto" class="ml-auto">
                            <v-tabs background-color="white" color="green" right>
                                <v-tab @click="changeTab(1)">Lista de propostas</v-tab>
                                <v-tab @click="changeTab(2)">Dashboard</v-tab>
                                <v-btn @click="getProposta()" class="mr-2" elevation="0" large rounded color="primary">
                                    <v-icon class="mr-2">mdi-beaker-plus-outline</v-icon>
                                    Nova proposta
                                </v-btn>
                                <Notifications @open_detalhes="getDetails" :user_id="this.username.id" />
                            </v-tabs>
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-sheet v-if="tab_1">
                    <v-card flat>
                        <v-responsive class="overflow-x-auto">
                            <v-card-title>
                                <v-text-field label="Pesquisar" v-model="search" prepend-icon="mdi-magnify" single-line hide-details></v-text-field>
                            </v-card-title>
                            <v-data-table :headers="headers" :items="propostas_nao_perdidas" :search="search" :loading="loading" @click:row="view" mobile-breakpoint="0" loading-text="Carregando, aguarde..." no-data-text="Nenhum registro encontrado"></v-data-table>
                        </v-responsive>
                    </v-card>
                </v-sheet>
                <v-sheet v-if="tab_2">
                    <ListaKanban :propostas="lista_kanban" :lista_kanban="lista_kanban_dialog" @update-dialog="updateListaKanbanDialog" @show-details="view" />
                    <v-row style="min-height: 530px" no-gutters class="ml-4 mr-4 pt-12" align="start" justify="center">
                        <v-col align="center" class="ml-1 mr-1">
                            <v-card flat @click="openLista(getPropostasEnviadas)" tile width="100%">
                                <v-sheet tile height="300" color="grey">
                                    <sheet-header>PROPOSTAS ENVIADAS:</sheet-header>
                                    <div style="height: 250px" class="d-flex justify-content-center align-center ">
                                        <p class="font-weight-black white--text card_number">
                                            {{ getPropostasEnviadas.length }}
                                        </p>
                                    </div>
                                </v-sheet>
                                <v-sheet color="grey">
                                    <SheetFooter>{{
                                        getPropostas_Money(getPropostasEnviadas)
                                    }}</SheetFooter>
                                </v-sheet>
                            </v-card>
                        </v-col>
                        <v-col align="center" class="ml-1 mr-1">
                            <v-card flat @click="openLista(getPropostasEmProcesso)" tile width="100%">
                                <v-sheet tile class="justify-center" height="300" color="blue">
                                    <sheet-header>PROPOSTAS EM PROCESSO</sheet-header>
                                    <div style="height: 250px" class="d-flex justify-content-center align-center">
                                        <p class="font-weight-black white--text card_number">
                                            {{ getPropostasEmProcesso.length }}
                                        </p>
                                    </div>
                                </v-sheet>
                                <v-sheet color="blue">
                                    <SheetFooter>{{
                                        getPropostas_Money(
                                            getPropostasEmProcesso
                                        )
                                    }}</SheetFooter>
                                </v-sheet>
                            </v-card>
                        </v-col>
                        <v-col align="center" class="ml-1 mr-1">
                            <v-card flat @click="openLista(getPropostasGanhas)" tile width="100%">
                                <v-sheet tile height="300" color="green">
                                    <sheet-header>PROPOSTAS GANHAS</sheet-header>
                                    <div style="height: 250px" class=" d-flex justify-content-center align-center">
                                        <p class="font-weight-black white--text card_number">
                                            {{ getPropostasGanhas.length }}
                                        </p>
                                    </div>
                                </v-sheet>
                                <v-sheet color="green">
                                    <SheetFooter>{{
                                        getPropostas_Money(getPropostasGanhas)
                                    }}</SheetFooter>
                                </v-sheet>
                            </v-card>
                        </v-col>
                        <v-col align="center" class="ml-1 mr-1">
                            <v-card flat @click="openLista(getPropostasPerdidas)" tile width="100%">
                                <v-sheet tile height="300" color="red">
                                    <sheet-header>PROPOSTAS PERDIDAS</sheet-header>
                                    <div style="height: 250px" class=" d-flex justify-content-center align-center">
                                        <p class="font-weight-black white--text card_number">
                                            {{ getPropostasPerdidas.length }}
                                        </p>
                                    </div>
                                </v-sheet>
                                <v-sheet color="red">
                                    <SheetFooter>{{
                                        getPropostas_Money(getPropostasPerdidas)
                                    }}</SheetFooter>
                                </v-sheet>
                            </v-card>
                        </v-col>
                        <!--                        <v-col align="center" class="ml-1 mr-1">-->
                        <!--                            <v-card flat tile width="100%">-->
                        <!--                                <v-sheet tile height="300" color="orange">-->
                        <!--                                    <sheet-header>PROPOSTAS COM TAREFAS ATRASADAS</sheet-header>-->
                        <!--                                    <div style="height: 250px" class="d-flex justify-content-center align-center">-->
                        <!--                                        <p -->
                        <!--                                           class="font-weight-black white&#45;&#45;text card_number">-->
                        <!--                                            {{ this.propostas_atrasadas }}-->
                        <!--                                        </p>-->
                        <!--                                    </div>-->
                        <!--                                </v-sheet>-->
                        <!--                                <v-sheet color="orange">-->
                        <!--                                    <SheetFooter></SheetFooter>-->
                        <!--                                </v-sheet>-->
                        <!--                            </v-card>-->
                        <!--                        </v-col>-->
                    </v-row>
                </v-sheet>
            </v-card>
            <Detalhes :details="details" :propostas_cliente="propostas_cliente" :start_page="page" :user="username" @update-details="updateDetails">
            </Detalhes>
        </v-responsive>
    </v-app>
</template>

<script>
import Detalhes from "./Detalhes/Detalhes";
import ListaKanban from "./Dialogs/ListaKanban";
import Notifications from "./Notifications/Notifications";

export default {
    name: "dashboard",
    props: ["username"],

    components: {
        Notifications,
        Detalhes,
        ListaKanban,
        SheetHeader: {
            functional: true,

            render(h, {
                children
            }) {
                return h(
                    "v-sheet", {
                        staticClass: "font-weight-bold rounded-0 mt-auto align-center justify-center d-flex",
                        props: {
                            color: "rgba(0, 0, 0, .36)",
                            dark: true,
                            height: 50,
                        },
                    },
                    children
                );
            },
        },
        SheetFooter: {
            functional: true,

            render(h, {
                children
            }) {
                return h(
                    "v-sheet", {
                        staticClass: "font-weight-bold rounded-0 mt-auto align-center justify-center d-flex",
                        props: {
                            color: "rgba(0, 0, 0, .36)",
                            dark: true,
                            height: 50,
                        },
                    },
                    children
                );
            },
        },
    },

    data() {
        return {
            tab_1: true,
            tab_2: null,
            loading: false,
            singleExpand: false,
            details: false,
            search: "",
            page: 0,
            headers: [{
                    text: "ID",
                    value: "ID"
                },
                {
                    text: "Fase",
                    value: "STAGE_ID"
                },
                {
                    text: "Cliente",
                    value: "TITLE"
                },
                {
                    text: "Valor",
                    value: "UF_CRM_1594641774"
                },
                {
                    text: "Tempo restante",
                    value: "AVAILABLE"
                },
                {
                    text: "Vencimento",
                    value: "UF_CRM_1601920651"
                },
            ],
            total_propostas: 0, //Todas as propostas de um cliente especifico
            propostas_cliente: [], //Todas as propostas de um cliente especifico
            proposta_cliente: [], //Uma proposta de um cliente especifico
            propostas: [], //Todas as propostas de todos os clientes
            clientes: [], //Todas as propostas de cada cliente (Existe distincao entre clientes
            propostas_nao_perdidas: [],
            propostas_perdidas: [],
            unidade_consumidora: [],
            lista_kanban: [],
            lista_kanban_dialog: false,
            notifications_dialog: false,
            notifications: [],
            notification: "",
            propostas_atrasadas: 0,

            nome: this.username.name,
            now: null,

            money: {
                decimal: ",",
                thousands: ".",
                prefix: "R$ ",
                suffix: "",
                precision: 2,
                masked: false,
            },
        };
    },

    computed: {
        getPropostasEnviadas() {
            let propostas = [];
            for (const key in this.propostas) {
                if (this.propostas[key]["STAGE_ID"] === "Prospecção") {
                    propostas.push(this.propostas[key]);
                }
            }
            return propostas;
        },

        getPropostasEmProcesso() {
            let propostas = [];
            for (const key in this.propostas) {
                if (
                    this.propostas[key]["STAGE_ID"] !== "C8:LOSE" &&
                    this.propostas[key]["STAGE_ID"] !== "Prospecção" &&
                    this.propostas[key]["STAGE_ID"] !== "Negócio fechado!"
                ) {
                    propostas.push(this.propostas[key]);
                }
            }
            return propostas;
        },

        getPropostasGanhas() {
            let propostas = [];
            for (const key in this.propostas) {
                if (this.propostas[key]["STAGE_ID"] === "Negócio fechado!") {
                    propostas.push(this.propostas[key]);
                }
            }
            return propostas;
        },

        getPropostasPerdidas() {
            return this.propostas_perdidas;
            // let propostas_perdidas = [];
            // for (const key in this.propostas) {
            //     if (this.propostas[key]['STAGE_ID'] === 'C8:LOSE' && this.propostas[key]['UF_CRM_1557855808722'] !== false) {
            //         if (this.propostas[key]['UF_CRM_1557855808722'].find(element => element !== 7347) !== undefined) {
            //             propostas_perdidas.push(this.propostas[key]);
            //         }
            //     }
            // }
            // return propostas_perdidas;
        },
    },

    methods: {
        setPropostasAtrasadas() {
            this.$http.get("get_tarefas_atrasadas").then((response) => {
                this.propostas_atrasadas = response.data;
            });
        },

        getPropostas_Money(propostas) {
            let money = 0;
            for (const proposta of propostas) {
                if (proposta.UF_CRM_1594641774) {
                    if (proposta.UF_CRM_1594641774 !== "Sem informações") {
                        money += this.unformat(proposta.UF_CRM_1594641774);
                    }
                }
            }

            return this.formatToBrl(money);
        },

        formatToBrl(value) {
            // Create our number formatter.
            var formatter = new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
            return formatter.format(value);
        },

        unformat(value) {
            //Convertendo a fomratacao em dinheiro para decimal
            let aux = value.slice(2).replace(/\./g, "");
            return Number(aux.replace(/\,/g, "."));
        },

        changeTab(tab) {
            switch (tab) {
                case 1:
                    this.tab_1 = true;
                    this.tab_2 = false;
                    break;
                case 2:
                    this.tab_2 = true;
                    this.tab_1 = false;
                    break;
                default:
                    console.log("Erro inesperado: onChangeTab");
                    break;
            }
        },
        getFase(stage_id) {
            switch (stage_id) {
                case "C8:23":
                    return "Proposta incompleta";
                case "C8:NEW":
                    return "Prospecção";
                case "C8:9":
                    return "Gerando proposta";
                case "C8:PREPARATION":
                    return "Elaborando proposta";
                case "C8:8":
                    return "Aprovação da diretoria";
                case "C8:24":
                    return "Proposta Aprovada";
                case "C8:FINAL_INVOICE":
                    return "Aprovação do cliente";
                case "C8:10":
                    return "Viabilidade técnica";
                case "C8:13":
                    return "Validação da visita";
                case "C8:19":
                    return "Aguardando novo endereço";
                case "C8:1":
                    return "Readequando proposta";
                case "C8:5":
                    return "Método de pagamento";
                case "C8:16":
                    return "Validação Método de Pagamento";
                case "C8:11":
                    return "Geração de Contrato";
                case "C8:14":
                    return "Validação do Contrato";
                case "C8:15":
                    return "Validação da Readequação";
                case "C8:7":
                    return "Confirmação financeira";
                case "C8:LOSE":
                    return "C8:LOSE";
                case "C8:WON":
                    return "Negócio fechado!";
                case "C8:22":
                    return "Proposta Vencida";
                case "C8:18":
                    return "Teste emissão venda API";
                case "C8:20":
                    return "Migração de Negócios";
                case "C8:25":
                    return "teste automacao2";
                default:
                    return stage_id;
            }
        },

        openLista(propostas) {
            this.lista_kanban_dialog = true;
            this.lista_kanban = propostas;
        },

        updateDetails(state) {
            this.details = state;
        },

        updateListaKanbanDialog(state) {
            this.lista_kanban_dialog = state;
        },
        updateNotificationsDialog(state) {
            this.notifications_dialog = state;
        },

        async getListaUC($deal_id) {
            try {
                this.unidade_consumidora = await this.$http
                    .get("proposta.uc.get", {
                        params: {
                            ID: $deal_id,
                        },
                    })
                    .catch(function (error) {
                        throw error;
                    });
            } catch (error) {
                this.pushNotification(
                    "Ops, parece ter algum problema com sua conexão...",
                    "error",
                    10000
                );
            }
        },

        setPropostasNaoPerdidas() {
            let propostas_nao_perdidas_aux = [];
            let propostas_perdidas_aux = [];

            for (const index in this.propostas) {
                if (this.propostas[index]["STAGE_ID"] !== "C8:LOSE") {
                    propostas_nao_perdidas_aux.push(this.propostas[index]);
                } else {
                    propostas_perdidas_aux.push(this.propostas[index]);
                }
            }

            this.propostas_nao_perdidas = propostas_nao_perdidas_aux;
            this.propostas_perdidas = propostas_perdidas_aux;
        },

        getProposta() {
            this.$router.push("/proposta");
        },

        view(e) {
            //armazena todas as propostas de um determinado cliente ao array

            if (e["CONTACT_ID"]) {
                this.propostas_cliente = this.clientes.find(
                    (element) => element[0]["CONTACT_ID"] === e["CONTACT_ID"]
                );
            } else {
                this.propostas_cliente = this.clientes.find(
                    (element) => element[0]["COMPANY_ID"] === e["COMPANY_ID"]
                );
            }

            //Seleciona a proposta escolhida e atribui a pagina de detalhes
            for (let i = 0; i < this.propostas_cliente.length; i++) {
                if (e["ID"] === this.propostas_cliente[i]["ID"]) {
                    this.page = i + 1;
                }
            }
            this.details = true;
        },

        getDetails(id) {
            //armazena todas as propostas de um determinado cliente ao array
            this.propostas_cliente = this.clientes.find(
                (element) => element[0]["ID"] === id
            );

            //Seleciona a proposta escolhida e atribui a pagina de detalhes
            for (let i = 0; i < this.propostas_cliente.length; i++) {
                if (id === this.propostas_cliente[i]["ID"]) {
                    this.page = i + 1;
                }
            }
            this.details = true;
            this.details = true;
        },

        diff_days(date2, date1) {
            // Discard the time and time-zone information.
            const utc1 = Date.UTC(
                date1.getFullYear(),
                date1.getMonth(),
                date1.getDate()
            );
            const utc2 = Date.UTC(
                date2.getFullYear(),
                date2.getMonth(),
                date2.getDate()
            );
            let diff = Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
            if (diff < 0) {
                return "Vencida";
            } else {
                return diff + " Dia(s)";
            }
        },

        async check_total() {
            let total_deal = await this.$http
                .get("proposta", {
                    params: {
                        total: true,
                    },
                })
                .then((response) => {
                    this.total_propostas = response.data;
                });

            return this.total_propostas;
        },

        prepare_dashboard(propostas) {
            let clientes_id = [];
            this.propostas = propostas;
            this.setPropostasNaoPerdidas();

            for (let index in this.propostas) {
                //Inicializa os campos de data =========================================================================
                if (this.propostas[index]["UF_CRM_1601920651"]) {
                    //Converte as datas do padrao ISO em UTC para String local =========================================
                    let monName = [
                        "Janeiro",
                        "Fevereiro",
                        "Março",
                        "Abril",
                        "Maio",
                        "Junho",
                        "Julho",
                        "Agosto",
                        "Setembro",
                        "Outubro",
                        "Novembro",
                        "Dezembro",
                    ];
                    let CLOSEDATE = new Date(
                        this.propostas[index]["UF_CRM_1601920651"]
                    );
                    this.propostas[index]["UF_CRM_1601920651"] =
                        CLOSEDATE.getUTCDate() +
                        " de " +
                        monName[CLOSEDATE.getMonth()] +
                        " de " +
                        CLOSEDATE.getFullYear();

                    let NOW = new Date();
                    this.propostas[index]["AVAILABLE"] = this.diff_days(
                        CLOSEDATE,
                        NOW
                    );
                } else {
                    this.propostas[index]["UF_CRM_1601920651"] =
                        "Sem informações";
                    this.propostas[index]["AVAILABLE"] = "Sem informações";
                }

                //Verifica se o orçamento ja foi feito ou não ==========================================================
                if (!this.propostas[index]["UF_CRM_1594641774"]) {
                    this.propostas[index]["UF_CRM_1594641774"] =
                        "Sem informações";
                } else {
                    this.propostas[index]["UF_CRM_1594641774"] =
                        this.formatToBrl(
                            this.propostas[index]["UF_CRM_1594641774"].slice(
                                0,
                                -4
                            )
                        );
                }

                //Adiciona o id de cada cliente, apenas uma vez, ao array clientes_id
                if (this.propostas[index]["CONTACT_ID"]) {
                    if (
                        !clientes_id.find(
                            (element) =>
                            element === this.propostas[index]["CONTACT_ID"]
                        )
                    ) {
                        clientes_id.push(this.propostas[index]["CONTACT_ID"]);
                    }
                } else {
                    if (
                        !clientes_id.find(
                            (element) =>
                            element === this.propostas[index]["COMPANY_ID"]
                        )
                    ) {
                        clientes_id.push(this.propostas[index]["COMPANY_ID"]);
                    }
                }

                //Configura o STAGE_ID
                this.propostas[index]["STAGE_ID"] = this.getFase(
                    this.propostas[index]["STAGE_ID"]
                );
            }
            //======================================================================================================

            return clientes_id;
        },

        async prepare_parcial() {
            return await this.$http
                .get("proposta", {
                    params: {
                        parcial: true,
                    },
                })
                .then((resp) => {
                    return this.prepare_dashboard(resp.data);
                })
                .catch(function (error) {
                    throw error;
                });
        },

        async prepare_total() {
            return await this.$http
                .get("proposta")
                .then((resp) => {
                    this.loading = false;

                    return this.prepare_dashboard(resp.data);
                })
                .catch(function (error) {
                    throw error;
                });
        },

        //Adiciona em um array de arrays, todas as propostas de um determinado cliente.
        organizaPropostas(clientes_id) {
            let clientes = [];

            for (let i = 0; i < clientes_id.length; i++) {
                clientes.push(
                    this.propostas.filter(function (proposta) {
                        if (proposta["CONTACT_ID"] === clientes_id[i]) {
                            return proposta;
                        } else if (proposta["COMPANY_ID"] === clientes_id[i]) {
                            return proposta;
                        }
                    })
                );
            }
            this.clientes = clientes;
        },
    },

    async created() {
        this.loading = true;
        try {
            let clientes_id = [];

            if ((await this.check_total()) > 200) {
                clientes_id = await this.prepare_parcial();

                this.pushNotification(
                    "Novas propostas estão sendo carregadas em segundo plano",
                    "default",
                    5000
                );

                this.organizaPropostas(clientes_id);

                clientes_id = await this.prepare_total();
            } else {
                clientes_id = await this.prepare_total();
            }

            this.organizaPropostas(clientes_id);
        } catch (error) {
            this.loading = false;
            console.log(error);
            this.pushNotification(
                "Ops, parece ter algum problema com sua conexão...",
                "error",
                5000
            );
        }
        this.setPropostasAtrasadas();
    },
};
</script>

<style scoped>
.v-application--wrap {
    min-height: 80vh !important;
}

.card_number {
    font-size: 110px !important;
}
</style>
