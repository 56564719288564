<template>
    <v-app style="height: 0">
        <v-row justify="center" no-gutters>
            <v-card width="100%" max-width="1500" style="background-color: #fafafa;" flat outlined>
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-toolbar-title v-if="proposta.TITLE">{{ proposta.TITLE }}</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card-text flat style="background-color: #fafafa;" class="pl-10 pr-10" v-if="proposta">
                    <v-row align="start" no-gutters>
                        <!-- Tabela com dados da proposta ------------------------------------------------------------->
                        <v-col sm="auto">
                            <v-row align="center">
                                <v-container>
                                    <v-row align="center" no-gutters>
                                        <v-card style="background-color: #fafafa;" class="h5" flat tile>
                                            Dados da proposta
                                        </v-card>
                                    </v-row>
                                    <v-row align="center" no-gutters>
                                        <v-col cols="auto" sm="auto" class="font-weight-bold">
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                ID da proposta
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Valor à vista
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Vencimento
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Potência pico do sistema
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Número de módulos
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Potência dos módulos
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Tipo de telhado (Estrutura de fixação)
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Consumo anual considerado
                                            </v-card>
                                            <v-card style="background-color: #f5f5f5;" class="pa-4" outlined tile>
                                                Produção Anual de Energia
                                            </v-card>
                                        </v-col>
                                        <v-col cols="auto" sm="auto">
                                            <v-card class="pa-4" outlined tile>
                                                {{ proposta.ID }}
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div
                                                    v-if="!proposta.UF_CRM_1594641774">
                                                    Sem informações
                                                </div>
                                                <div v-else>
                                                    {{ proposta.UF_CRM_1594641774 }}
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="proposta.UF_CRM_1601920651 !== ''">
                                                    {{ proposta.UF_CRM_1601920651 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="proposta.UF_CRM_1594640613">
                                                    {{ proposta.UF_CRM_1594640613 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="proposta.UF_CRM_1594641197">
                                                    {{ proposta.UF_CRM_1594641197 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="proposta.UF_CRM_1594641341">
                                                    {{ proposta.UF_CRM_1594641341 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="proposta.UF_CRM_1594641266">
                                                    {{ proposta.UF_CRM_1594641266 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="proposta.UF_CRM_1594640657">
                                                    {{ proposta.UF_CRM_1594640657 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                            <v-card class="pa-4" outlined tile>
                                                <div v-if="proposta.UF_CRM_1594641231">
                                                    {{ proposta.UF_CRM_1594641231 }}
                                                </div>
                                                <div v-else>
                                                    Sem informações
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-row>
                        </v-col>
                        <!-- Tabela com dados da proposta ------------------------------------------------------------->

                        <!-- Botoes de gerenciamento ------------------------------------------------------------------>
                        <v-col sm="auto" style="min-width: 30%" class="ml-auto mr-2">
                            <v-row align="center">
                                <v-container>
                                    <v-row align="center" no-gutters justify="start">
                                        <h5 style="color: #1976D2">Situação da proposta:</h5>
                                        <v-divider class="ml-2"></v-divider>
                                    </v-row>
                                    <v-row align="center" no-gutters justify="center" class="ma-2"
                                           v-if="proposta.AVAILABLE !== 'Vencida'">
                                        <v-col justify="center" v-if="proposta.STAGE_ID === ''">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-clock-time-five</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Aguardando envio</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center" v-if="proposta.STAGE_ID === 'C8:LOSE'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-close</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Proposta perdida</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:NEW'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-clock-start</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Prospecção</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-cogs</v-icon>
                                                        <h5>Gerando Proposta</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:9'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-cogs</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Gerando Proposta</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-wrench</v-icon>
                                                        <h5>Elaborando proposta</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:PREPARATION'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-wrench</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Elaborando proposta</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-check-decagram</v-icon>
                                                        <h5>Aprovação da diretoria</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:8'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-check-decagram</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Aprovação da diretoria</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-account-check</v-icon>
                                                        <h5>Aprovação do cliente</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:FINAL_INVOICE'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-account-check</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Aprovação do cliente</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-beaker-question</v-icon>
                                                        <h5>Viabilidade técnica</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:10'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-beaker-question</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Viabilidade técnica</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-beaker-question</v-icon>
                                                        <h5>Validação da visita</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:13'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-beaker-question</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Validação da visita</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-beaker-question</v-icon>
                                                        <h5>Readequando proposta</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:19'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-map-clock</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Aguardando novo endereço</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-beaker-question</v-icon>
                                                        <h5>Viabilidade técnica</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:1'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-beaker-question</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Readequando proposta</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-cash-multiple</v-icon>
                                                        <h5>Método de pagamento</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:5'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-cash-multiple</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Método de pagamento</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-refresh</v-icon>
                                                        <h5>Validação Método de Pagamento</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:16'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-refresh</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Validação Método de Pagamento</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-file</v-icon>
                                                        <h5>Geração de Contrato</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:11'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-file</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Geração de Contrato</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-book-check</v-icon>
                                                        <h5>Validação do Contrato</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:14'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-book-check</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Validação do Contrato</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-cash-check</v-icon>
                                                        <h5>Confirmação financeira</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:7'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-cash-check</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Confirmação financeira</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center" no-gutters justify="start">
                                                <h6>Próxima etapa:</h6>
                                                <v-divider class="ml-2"></v-divider>
                                            </v-row>
                                            <v-row align="center" class="d-flex">
                                                <v-col class="ml-n6">
                                                    <v-row align="center" no-gutters justify="center">
                                                        <v-icon class="ma-2" size="50px">mdi-check-bold</v-icon>
                                                        <h5>Negócio fechado!</h5>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col justify="center"
                                               v-if="proposta.STAGE_ID === 'C8:WON'">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-check-bold</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Negócio fechado!</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else-if="proposta.AVAILABLE === 'Vencida'">
                                        <v-col justify="center">
                                            <v-row align="center" class="d-flex">
                                                <v-col class="mr-n6">
                                                    <v-row align="center" no-gutters justify="center" class="mb-2">
                                                        <v-icon size="70px">mdi-timer-off</v-icon>
                                                    </v-row>
                                                    <v-row align="center" no-gutters justify="center" class="mt-2">
                                                        <h2 align="center">Proposta vencida!</h2>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mb-4"></v-divider>
                                    <v-row v-if="form.STAGE_ID === ''" justify="center">
                                        <v-btn @click="dialog_info=true" color="primary" x-large elevation="0">Enviar
                                            informações
                                        </v-btn>
                                    </v-row>
                                    <InformacoesAdicionais @update-dialog="(state) => this.dialog_info = state"
                                                           :dados_cadastrais="dados_cadastrais"
                                                           :dialog_info=dialog_info>
                                    </InformacoesAdicionais>
                                </v-container>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
    </v-app>
</template>

<script>
import InformacoesAdicionais from "./Dialogs/InformacoesAdicionais";
import axios from "axios";

export default {
    name: "Inativo",

    components: {
        InformacoesAdicionais
    },

    props: ['cpf_patrocinador', 'pre_cadastro', 'user'],

    data() {
        return {
            dialog_info: false,
            form: {
                TITLE: '',
                ID: 0,
                UF_CRM_1594641774: '',
                UF_CRM_1601920651: '',
                UF_CRM_1594640613: '',
                UF_CRM_1594641197: '',
                UF_CRM_1594641341: '',
                UF_CRM_1594641266: '',
                UF_CRM_1594640657: '',
                UF_CRM_1594641231: '',
                STAGE_ID: '',
                AVAILABLE: ''
            },
            dados_cadastrais: {
                cpf_patrocinador: '',
                pessoa: '',
                nome: '',
                email: '',
                cpfCnpj: '',
                telefone: '',
                endereco: {
                    rua: '',
                    bairro: '',
                    cidade: '',
                    estado: '',
                    idend: null,
                    cep: ''
                },
                enderecoInstalacao: {
                    cep: null,
                    rua: '',
                    bairro: '',
                    cidade: '',
                    estado: '',
                    possuiAreaTelhado: ''
                }
            }
        }
    },

    async created() {
        this.form.TITLE = this.$props.pre_cadastro.nome;

        await axios.get('/pre/cadastro/' + this.$props.pre_cadastro.cpfCnpj).then(response => {
            if (response.data[0].UF_CRM_1601920651 !== '') {
                let monName = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
                let CLOSEDATE = new Date(response.data[0].UF_CRM_1601920651);
                this.form.UF_CRM_1601920651 = CLOSEDATE.getUTCDate() + ' de ' + monName[CLOSEDATE.getMonth()] + ' de ' + CLOSEDATE.getFullYear();
            }
            this.form.TITLE = response.data[0].TITLE;
            this.form.ID = response.data[0].ID;
            this.form.UF_CRM_1594641774 = response.data[0].UF_CRM_1594641774;
            this.form.UF_CRM_1594640613 = response.data[0].UF_CRM_1594640613;
            this.form.UF_CRM_1594641197 = response.data[0].UF_CRM_1594641197;
            this.form.UF_CRM_1594641341 = response.data[0].UF_CRM_1594641341;
            this.form.UF_CRM_1594641266 = response.data[0].UF_CRM_1594641266;
            this.form.UF_CRM_1594640657 = response.data[0].UF_CRM_1594640657;
            this.form.UF_CRM_1594641231 = response.data[0].UF_CRM_1594641231;
            this.form.STAGE_ID = response.data[0].STAGE_ID;
            this.form.AVAILABLE = response.data[0].AVAILABLE;
        }).catch(error => {
            this.setDadosCadastrais(this.$props.cpf_patrocinador, this.$props.pre_cadastro, JSON.parse(this.$props.pre_cadastro.endereco), JSON.parse(this.$props.pre_cadastro.enderecoInstalacao));
            console.log(this.dados_cadastrais);
        });

    },

    methods: {
        setDadosCadastrais(cpf_patrocinador, dados, endereco, endereco_instalacao) {
            this.dados_cadastrais = {
                cpf_patrocinador: cpf_patrocinador,
                pessoa: dados.pessoa,
                nome: dados.nome,
                email: dados.email,
                cpfCnpj: dados.cpfCnpj,
                telefone: dados.telefone,
                endereco: {
                    rua: endereco.rua,
                    bairro: endereco.bairro,
                    cidade: endereco.cidade,
                    estado: endereco.estado,
                    idend: endereco.idend,
                    cep: endereco.cep,
                },
                enderecoInstalacao: {
                    cep: endereco_instalacao.cep,
                    rua: endereco_instalacao.rua,
                    bairro: endereco_instalacao.bairro,
                    cidade: endereco_instalacao.cidade,
                    estado: endereco_instalacao.estado,
                    possuiAreaTelhado: endereco_instalacao.possuiAreaTelhado
                }
            }
        },
    },

    computed: {
        proposta() {
            return {
                TITLE: this.form.TITLE,
                ID: this.form.ID,
                UF_CRM_1594641774: this.form.UF_CRM_1594641774,
                UF_CRM_1601920651: this.form.UF_CRM_1601920651,
                UF_CRM_1594640613: this.form.UF_CRM_1594640613,
                UF_CRM_1594641197: this.form.UF_CRM_1594641197,
                UF_CRM_1594641341: this.form.UF_CRM_1594641341,
                UF_CRM_1594641266: this.form.UF_CRM_1594641266,
                UF_CRM_1594640657: this.form.UF_CRM_1594640657,
                UF_CRM_1594641231: this.form.UF_CRM_1594641231,
                STAGE_ID: this.form.STAGE_ID,
                AVAILABLE: this.form.AVAILABLE
            }
        }
    }
    ,
}
</script>

<style scoped>

</style>
